import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";

// import components
import Header from "components/header/header";
import Footer from "components/footer/footer";
import Usersidebar from "components/user-sidebar/user-sidebar";

// mui components
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
  Paper,
  Grid,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  tableCellClasses,
  TableCell,
  TableRow,
  TableSortLabel,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  CardContent,
} from "@mui/material";

// scss file
import "./Attendance.scss"

// mui icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { ContainerLoader } from "components/common/ContainerLoader";
import Pagination from "components/pagination/pagination";
import AlertMessage from "components/message/message";
import { ValidatorForm } from "react-material-ui-form-validator";
import { HiOutlineArrowNarrowDown, HiOutlineSearch } from "react-icons/hi";
import UserProfileAction from "redux/actions/UserProfielAction";
import { useDispatch, useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary["dark"],
    color: theme.palette.common.white,
    fontFamily: "Barlow !important",
    fontWeight: "bold",
    textAlign: "left",
    position: "relative",
    // width: "100%",
    minWidth: "max-content",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Barlow !important",
    textAlign: "left",
    color: "#3C4E6A",
    borderBottom: "1px solid #CCCCCC",
    width: "auto",
    padding: "12px 15px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    textAlign: "left",
    fontSize: "1.5rem!important",
    fontWeight: "bold!important",
    color: "#000059",
    fontFamily: "Barlow!important",
  },
  wrapper: {
    display: "flex",
    flex: " 1 1",
    flexDirection: "column",
    minWidth: "0",
    minHeight: "100vh",
    transition: "margin 0.3s",
    padding: "3rem 1.6rem 2.8rem 1.6rem",
  },
  filterfield: {
    width: "100%",
    height: "100%",
    backgroundColor: "#fff!important",
    padding: "0.7rem 1rem",
    border: "1px solid #CDCDCE",
    borderRadius: "0.2rem",

    fontSize: "1rem",
    fontFamily: "Barlow",
    color: "#999CA4E",
    "&:focus": {
      outline: "none",
    },
  },
  filterlabel: {
    fontSize: "1rem",
    fontFamily: "Barlow",
    color: "#000059",
    fontWeight: "500",
    float: "left!important",
  },

}));

const Card = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(3),
  height: "100%!important",
  color: theme.palette.text.secondary,
}));

const Attendance = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const limit = useSelector((state) => state.UserProfile?.data?.per_page_limit);
  //from Data
  const [filterData, setFilterData] = useState({
    account_type: ["Vendor"],
    limit: 10,
    skip: 0,
    sortBy: "",
    sortOrder: "",
    team: "",
    username: "",
  })
  // message state 
  const [message, setMessage] = useState({
    open: false,
    message: '',
    alertColor: ''
  });
  const [loadingIcon, setLoadingIcon] = useState({
    loading: false,
    id: ""
  });
  const [sortingData, setSortingData] = useState({
    sortOrder: null,
    sortBy: null
  });
  const [state, setState] = useState({
    currentColumn: null,
    activeasc: false,
    activedesc: false,
    styleInactive: false,
    direction: '',
  });
  const [loading, setLoading] = useState(true);
  const [eventstatus, setEventSatus] = useState({});
  const [statusLoad, setStatusLoad] = useState(true)
  const [allteamlist, setAllteamlist] = useState([]);
  const [allAttendence, setAllAttendence] = useState([]);
  const [userName, setUserName] = useState([])
  const [userNameFilter, setUserNameFilter] = useState("")
  // pagination
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [searchPage, setSearchPage] = React.useState(0);
  const [skip, setSkip] = React.useState(0);
  const [pageLimit, setPageLimit] = React.useState(limit);
  //sorting 


  const handleRotateIcon = (columnname) => {
    if (state.currentColumn === columnname) {
      if (!state.styleInactive) {
        sortDataByMonths({ column: columnname, asc: true });
        setState((prevState) => ({
          ...prevState,
          styleInactive: true,
          activeasc: false,
          activedesc: false,
          direction: 'asc',
        }));
      } else if (state.styleInactive && !state.activeasc) {
        sortDataByMonths({ column: columnname, asc: false });
        setState((prevState) => ({
          ...prevState,
          activeasc: true,
          activedesc: false,
          direction: 'desc',
        }));
      } else if (state.styleInactive && state.activeasc) {
        sortDataByMonths({ column: columnname, asc: true });
        setState((prevState) => ({
          ...prevState,
          activeasc: false,
          activedesc: false,
          direction: 'asc',
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        currentColumn: columnname,
        activeasc: false,
        activedesc: false,
        styleInactive: false,
        direction: '',
      }));
      sortDataByMonths({ column: columnname, asc: true });
      setState((prevState) => ({
        ...prevState,
        styleInactive: true,
        currentColumn: columnname,
        direction: 'asc',
      }));
    }
  };

  const sortDataByMonths = (params) => {
    if (params) {
      if (params.column) {
        setSortingData((prevSortingData) => ({
          ...prevSortingData,
          sortOrder: params.asc === true ? 'asc' : 'desc',
          sortBy: params.column,
        }), () => {
          getAllAccounts();
        });
      }
    }
  };

  const getTeamList = async () => {
    try {
      const temalistapi = process.env.REACT_APP_API_URL + "/api/user/listAllTeams";
      const response = await axios.post(temalistapi, {},
        {
          headers: {
            authorization: 'Bearer ' + Cookies.get('accessToken')
          }
        });
      const { data } = response;
      if (data.error) {
        setMessage({
          ...message,
          open: true,
          message: 'Something went wrong',
          alertColor: 'error'
        });
      } else {
        const sorted = data?.data?.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        });
        setAllteamlist(sorted)
      }
    } catch (error) {
      setMessage({
        ...message,
        open: true,
        message: 'Something went wrong',
        alertColor: 'error'
      });
    }
  }

  const getEventStatus = async (id) => {
    setLoadingIcon({
      id: id,
      loading: true
    });
    try {
      await setEventSatus({})
      setStatusLoad(true);
      const getStatus = process.env.REACT_APP_API_URL + "/api/account/getAccountEventsDelivered"
      const response = await axios.post(getStatus, { _id: id }, {
        headers: {
          authorization: 'Bearer ' + Cookies.get('accessToken')
        }
      });
      const { data } = response;
      if (data.err) {
        setMessage({
          ...message,
          open: true,
          message: 'Something went wrong',
          alertColor: 'error'
        });
        setStatusLoad(false)
      } else {
        setEventSatus(data.data)
        setStatusLoad(false)
      }
    } catch (error) {
      setMessage({
        ...message,
        open: true,
        message: 'Something went wrong',
        alertColor: 'error'
      });
      setStatusLoad(false)
    }
  }

  const getUsers = async () => {
    try {
      const vendorList_api_url = process.env.REACT_APP_API_URL + "/api/account/listAllAccounts"
      const response = await axios.post(vendorList_api_url, { type: 'user' }, {
        headers: {
          authorization: 'Bearer ' + Cookies.get('accessToken')
        }
      });
      const { data } = response;
      if (data.error) {
        setMessage({
          ...message,
          open: true,
          message: 'Something went wrong',
          alertColor: 'error'
        });
      } else {
        const newUsersData = data?.data?.map(itm => itm.username)
        const uniqueValues = [...new Set(newUsersData)]
        setUserName(uniqueValues);
      }
    } catch (error) {
      setMessage({
        ...message,
        open: true,
        message: 'Something went wrong',
        alertColor: 'error'
      });
    }
  };

  const getAllAccounts = async (skippage = 0, pagelimit = pageLimit) => {
    setSearchPage(1);
    setPageLimit(pagelimit);
    try {
      setLoading(true)
      const paramas = {
        ...filterData,
        sortBy: sortingData.sortBy,
        sortOrder: sortingData.sortOrder,
        skip: skippage,
        limit: pagelimit ?? 10,
        username: userNameFilter,
      }
      const vendorList_api_url = process.env.REACT_APP_API_URL + "/api/account/getAllAccounts"
      const response = await axios.post(vendorList_api_url, paramas, {
        headers: {
          authorization: 'Bearer ' + Cookies.get('accessToken')
        }
      });
      const { data } = response;
      if (data.error) {
        setMessage({
          ...message,
          open: true,
          message: 'Something went wrong',
          alertColor: 'error'
        });
        setLoading(false)
      } else {
        const newData = data?.data?.map((item) => ({
          ...item,
          id: item._id
        }));
        setAllAttendence(newData);
        setTotalRecords(data.count);
        setLoading(false)
      }
    } catch (error) {
      setMessage({
        ...message,
        open: true,
        message: 'Something went wrong',
        alertColor: 'error'
      });
      setLoading(false)
    }
  };

  const updateProfile = async (perpageLimit) => {
    try {
      const updateDetail_api_url =
        process.env.REACT_APP_API_URL + "/api/user/updateUser";
      const response = await axios.post(
        updateDetail_api_url,
        { per_page_limit: perpageLimit },
        {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        }
      );
      const { data } = response;
      if (data.error) {
        setMessage({
          ...message,
          open: true,
          message: 'Something went wrong',
          alertColor: 'error'
        });
      } else {
        dispatch(UserProfileAction());
      }
    } catch (error) {
      setLoading(false)
    }
  };

  const handleClose = () => {
    setMessage({
      ...message,
      open: false,
      message: '',
      alertColor: ''
    })
  };

  const handleSelectTeam = (event, value) => {
    if (value !== null && value !== undefined) {
      setFilterData({ ...filterData, team: value.name });
    } else {
      setFilterData({ ...filterData, team: null });
    }
  };

  const changePageLimit = (e) => {
    setSearchPage(1);
    setPageLimit(parseInt(e.target.value));
    updateProfile(e.target.value);
    getAllAccounts(0, parseInt(e.target.value));
    setSearchPage(1);
  };

  const SearchDisable = (value) => {
    setSearchPage(value);
  };

  const onPageChanged = (data) => {
    const { currentPage } = data;
    setSkip((currentPage - 1) * pageLimit);
    getAllAccounts((currentPage - 1) * pageLimit, pageLimit);
  };

  const handleSubmit = async () => {
    await getAllAccounts()
  };

  useEffect(() => {
    getAllAccounts();
  }, [sortingData])

  useEffect(() => {
    getTeamList();
    getUsers();
  }, [])

  return (
    <>
      <div className="user-dashboard-wrapper attendance-page">
        <Helmet>
          <title>Attendance</title>
        </Helmet>
        <Header />
        <div className="c-app c-default-layout user-dashboard-body">
          <Usersidebar />
          <div className="c-wrapper">
            <div className="c-body">
              <main className="c-main">
                <Card
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    color: "#000059!important",
                    textAlign: "left",
                    padding: "30px",
                    backgroundColor: "#fbfbfb;",
                  }}
                >
                  <Typography className={classes.heading}>
                    Attendance
                  </Typography>
                  <ValidatorForm
                    onSubmit={handleSubmit}
                    className="filter-form mb-5"
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          textAlign: "left",
                          marginTop: "10px",
                          marginBottom: "20px",
                        }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                      >
                        <Grid item xs={12} sm={4} md={4} className="Vendor-input1">
                          <div>
                            <label className={classes.filterlabel}>Team</label>
                            <Autocomplete
                              fullWidth
                              className="filter-input"
                              name="team"
                              options={allteamlist}
                              onChange={handleSelectTeam}
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) =>
                                <TextField
                                  placeholder="Search Team"
                                  {...params}
                                  variant="outlined"
                                />}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} className="Vendor-input">
                          <div>
                            <label className={classes.filterlabel}>Username</label>
                            <Autocomplete
                              fullWidth
                              className="filter-input"
                              name="username"
                              options={userName}
                              onChange={(e, newValue) => setUserNameFilter(newValue !== null ? newValue : '')}
                              getOptionLabel={(option) => option}
                              renderInput={(params) =>
                                <TextField
                                  placeholder="Search Username"
                                  {...params}
                                  variant="outlined"
                                />}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2} sx={{ position: 'relative', top: '30px' }} >
                          <Button
                            variant="contained"
                            type="submit"
                            className="filter-btn"
                            startIcon={<HiOutlineSearch />}
                          >
                            Search
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </ValidatorForm>
                  {!loading ? (
                    <CardContent
                      sx={{
                        padding: "0px!important",
                        marginTop: "10px!important",
                      }}
                      className="plan-tbl vendor-outer"
                    >
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 900 }} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                <TableSortLabel
                                  IconComponent={HiOutlineArrowNarrowDown}
                                  active={
                                    state.currentColumn === "league"
                                  }
                                  direction={
                                    state.currentColumn === "league"
                                      ? state.direction
                                      : "asc"
                                  }
                                  onClick={() => handleRotateIcon("league")}
                                >
                                  League
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell>
                                <TableSortLabel
                                  IconComponent={HiOutlineArrowNarrowDown}
                                  active={
                                    state.currentColumn === "team"
                                  }
                                  direction={
                                    state.currentColumn === "team"
                                      ? state.direction
                                      : "asc"
                                  }
                                  onClick={() => handleRotateIcon("team")}
                                >
                                  Team
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell>
                                <TableSortLabel
                                  IconComponent={HiOutlineArrowNarrowDown}
                                  active={
                                    state.currentColumn === "username"
                                  }
                                  direction={
                                    state.currentColumn === "username"
                                      ? state.direction
                                      : "asc"
                                  }
                                  onClick={() => handleRotateIcon("username")}
                                >
                                  Username
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell>
                                <div
                                  style={{
                                    minWidth: "max-content",
                                    width: "17rem"
                                  }}
                                >
                                  Status
                                </div>
                              </StyledTableCell>
                              <StyledTableCell>
                                <div
                                  style={{
                                    width: "100%",
                                    minWidth: "max-content",
                                  }}
                                >
                                  Action
                                </div>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {allAttendence && allAttendence?.length > 0 ? (
                              <>
                                {allAttendence && allAttendence?.map((item, index) => (
                                  <StyledTableRow>
                                    <StyledTableCell component="td" scope="row">
                                      {item && item.league}
                                    </StyledTableCell>
                                    <StyledTableCell component="td" scope="row">
                                      {item && item.team}
                                    </StyledTableCell>
                                    <StyledTableCell component="td" scope="row">
                                      {item && item.username}
                                    </StyledTableCell>
                                    <StyledTableCell component="td" scope="row">
                                      <div style={{ display: "flex", justifyContent: "left", alignItems: "center" }} >
                                        {eventstatus._id === item._id && <>
                                          <p style={{ margin: "0", fontWeight: "bold" }}>
                                            {eventstatus.totalEvents > 0 && eventstatus.eventDelivered ? parseFloat(eventstatus.eventDelivered / eventstatus.totalEvents * 100).toFixed() : 0}% Events Attended (<span>{eventstatus.eventDelivered}/{eventstatus.totalEvents}</span>)
                                          </p>   </>
                                        }
                                        {
                                          item._id === loadingIcon.id ? <> {statusLoad && <CircularProgress color="primary" style={{ width: "27px", height: "27px", color: "#002986" }} />}<VisibilityOffIcon style={{ cursor: "pointer", fontSize: "1.3rem", color: "#002986", margin: "0 5px" }} onClick={() => {
                                            setEventSatus({})
                                            setLoadingIcon({
                                              id: '',
                                              loading: false
                                            })
                                          }} />  </> : <VisibilityIcon style={{ cursor: "pointer", fontSize: "1.3rem", color: "#002986", margin: "0 5px" }} onClick={() => getEventStatus(item._id)} />
                                        }
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell component="td" scope="row">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <Button component={Link}
                                          size="small"
                                          variant="contained"
                                          sx={{
                                            backgroundColor: "#002986!important",
                                            color: "white!important",
                                            padding: "2px 5px!important",
                                            textTransform: "none!important",
                                            fontWeight: "bold",
                                            fontFamily: "Barlow!important"
                                          }}
                                          to={`/attendance/${item?.id}`}
                                        >
                                          View
                                        </Button>
                                      </div>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </>
                            ) : (
                              <>
                                <StyledTableRow>
                                  <td
                                    colSpan={13}
                                    style={{ padding: "2.6rem 1rem" }}
                                  >
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "1.6rem",
                                        textAlign: "center",
                                      }}
                                    >
                                      No Records Found
                                    </Typography>
                                  </td>
                                </StyledTableRow>
                              </>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  ) : (
                    <ContainerLoader />
                  )}
                  <Grid container spacing={2} sx={{ marginTop: "20px" }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box>
                        {totalRecords > 0 && (
                          <Pagination
                            key="paginationnew"
                            searchPage={searchPage}
                            SearchDisable={SearchDisable}
                            totalRecords={totalRecords}
                            pageLimit={pageLimit}
                            onPageChanged={onPageChanged}
                          />
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box>
                        <div
                          style={{
                            textAlign: "right",
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                          }}
                        >
                          <label>Items per page :</label>
                          <FormControl
                            sx={{
                              m: 1,
                              minWidth: 50,
                            }}
                            size="small"
                          >
                            <Select
                              defaultValue={pageLimit}
                              value={pageLimit}
                              name="limit"
                              sx={{
                                "& .MuiSelect-select": {
                                  padding: "4px 34px 4px 14px!important",
                                  fontFamily: "Barlow",
                                },
                              }}
                              onChange={(e) => changePageLimit(e)}
                            >
                              <MenuItem value="10">10</MenuItem>
                              <MenuItem value="20">20</MenuItem>
                              <MenuItem value="50">50</MenuItem>
                              <MenuItem value="100">100</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </main>
            </div>
          </div>
        </div>
        <AlertMessage
          key="alertbtn"
          open={message.open}
          message={message.message}
          severity={message.alertColor}
          closeCall={handleClose}
        />
        <Footer />
      </div>
    </>
  );
};

export default Attendance;