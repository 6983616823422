import React from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import {
  CCard,
  CCardBody,
  CCol,
  CRow
} from "@coreui/react";
import { ValidatorForm } from "react-material-ui-form-validator";

// mui ui components
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  Table,
  Paper,
  TextField,
  Button,
  IconButton,
  Autocomplete,
  Card,
  Chip,
} from "@mui/material";

// icon mui
import { Clear, Event } from "@mui/icons-material";
import { HiOutlineSearch } from "react-icons/hi";
import { HiOutlineDownload } from "react-icons/hi";

// import components
import Header from "components/header/header";
import Footer from "components/footer/footer";
import Usersidebar from "components/user-sidebar/user-sidebar";
import customDateFormatLocal from "utils/customDateFormatLocal";
import UserProfileAction from "redux/actions/UserProfielAction";
import Pagination from "components/pagination/pagination";
import Cookies from "js-cookie";
import customDateFormat from "utils/customDateFormat";
import { ContainerLoader } from "components/common/ContainerLoader";

// scss import file
import "react-datepicker/dist/react-datepicker.css";
import "./userdashboard.scss";
import "./historyofpayment.scss";
import { changeDollarAmountformat } from "utils/changeDollarFormat";
import { subDays } from "date-fns";

const moment = extendMoment(originalMoment);
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3858A0",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontFamily: "Barlow !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Barlow !important",
    padding: "13px!important",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const options = [
  { label: 'All', days: null },
  { label: 'Last Week', days: 7 },
  { label: 'Last Month', days: 30 },
  { label: 'Last Year', days: 336 },
  { label: 'Select Custom Date', days: null }, // Custom option for manual date range
];

class Historypayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usersData: [],
      loader: true,
      open: false,
      message: "",
      crossCheck: true,
      openingBalance: 0.00,
      totalDebit: 0.00,
      totalCredit: 0.00,
      closingBalance: 0.00,
      successError: "success",
      selectedDate: null,
      selectedendDate: null,
      filterDate: {
        keyword: "",
        fromDate: null,
        toDate: null,
      },
      receipt: {},
      dateErrorMsg: "",
      vendorValue: null,
      pageLimit: 10,
      modalShow: false,
      timezone: null,
      pos: [],
      isShowCustomeDate: false,
      searchPage: 0,
      skip: 0,
      totalRecords: 0,
      creditAdded: "all"
    };

    this.changePageLimit = this.changePageLimit.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSelectChangeDate = this.handleSelectChangeDate.bind(this);
    this.clearDatehandle = this.clearDatehandle.bind(this);
  }

  async componentDidMount() {
    await this.getTimezone();
    this.getData();
  }

  getTimezone = () => {
    if (this.props.UserProfile.data !== undefined) {
      this.setState({
        pageLimit: this.props.UserProfile.data.per_page_limit,
        timezone: this.props.UserProfile.data.timezone,
      });
    }
  };

  handleChangeCreditAdded = (event) => {
    this.setState({ creditAdded: event.target.value });
  }

  onPageChanged = (data) => {
    const { currentPage } = data;
    this.setState(
      { loader: true, skip: this.state.pageLimit * (currentPage - 1) },
      () => {
        this.getData();
      }
    );
  };

  SearchDisable = (value) => {
    this.setState({ searchPage: value });
  };

  getData = () => {
    const data = {
      ...this.state.filterDate,
      skip: this.state.skip,
      limit: this.state.pageLimit,
      credit_added_method: this.state.creditAdded === "all" ? null : this.state.creditAdded
    };
    const historyPayment_api_url =
      process.env.REACT_APP_API_URL + "/api/user/historyOfPayments";

    axios
      .post(historyPayment_api_url, data, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
        console.log();
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            usersData: data.data,
            totalRecords: data.total,
            openingBalance: data.opening_balance ?? 0.00,
            totalDebit: data.total_debit ?? 0.00,
            totalCredit: data?.total_credit ?? 0.00,
            closingBalance: data?.closing_balance ?? 0.00,
          });
          /*
                var pays = []
                var balance = 0
                data.data.map((pay, i) => {
                    if(pay.entry_type === "debit") {
                        balance = parseFloat(balance) - parseFloat(Number(pay.amount).toFixed(2))
                    }else {
                        balance = parseFloat(balance) + parseFloat(Number(pay.amount).toFixed(2))
                    }

                    pays.push({
                        created: pay.created,
                        description: pay.description,
                        amount: pay.amount,
                        entry_type: pay.entry_type,
                        heading: pay.heading,
                        balance: balance,
                        _id: pay._id
                    })

                    if(i === (data.data.length - 1)) {
                        this.setState({ loader: false, usersData: pays })
                    }
                })
                */
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ loader: false });
      });
  };

  updateProfile() {
    this.setState({ loader: true }, () => {
      const updateDetail_api_url =
        process.env.REACT_APP_API_URL + "/api/user/updateUser";
      axios
        .post(
          updateDetail_api_url,
          { per_page_limit: this.state.pageLimit },
          {
            headers: {
              authorization: "Bearer " + Cookies.get("accessToken"),
            },
          }
        )
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "error",
            });
          } else {
            this.props.getUserProfile();
            this.setState({
              open: true,
              message: data.message,
              successError: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loader: false });
        });
    });
  }

  changePageLimit = (evt) => {
    this.setState(
      { searchPage: 1, pageLimit: parseInt(evt.target.value) },
      () => {
        this.updateProfile();
        this.getData();
      }
    );
  };

  onHandleChange = (e) => {
    const { filterDate } = this.state;
    filterDate[e.target.name] = e.target.value;
    this.setState({ filterDate });
  };

  setStartDate = async (dates) => {
    const { filterDate } = this.state;
    const [start, end] = dates;
    this.setState({ selectedendDate: end, selectedDate: start });
    // fomating date to isoString
    const datestart = moment(start).format("YYYY-MM-DDTHH:mm:ss");
    const dateend = moment(end).format("YYYY-MM-DDTHH:mm:ss");
    filterDate["fromDate"] = datestart + ".000Z";
    if (end) {
      filterDate["toDate"] = dateend + ".000Z";
    }
    this.setState({ filterDate, crossCheck: false });

  };

  clearDate = () => {
    const { filterDate } = this.state;
    this.setState({ selectedendDate: null, selectedDate: null });
    filterDate["fromDate"] = null;
    filterDate["toDate"] = null;
    this.setState({ filterDate, crossCheck: true, isShowCustomeDate: false });
  };

  clearDatehandle = () => {
    const { filterDate } = this.state;
    this.setState({ selectedendDate: null, selectedDate: null });
    filterDate["fromDate"] = null;
    filterDate["toDate"] = null;
    this.setState({ filterDate, isShowCustomeDate: false });
  }

  handleSubmit = () => {
    this.setState({ loader: true }, () => {
      this.getData();
    });
  };

  onHide = () => {
    this.setState({ modalShow: false });
  };

  testWhite(x) {
    var white = new RegExp(/^\s$/);
    return white.test(x.charAt(0));
  }

  wordWrap(str, maxWidth) {
    var newLineStr = "\n";
    var res = "";
    while (str?.length > maxWidth) {
      var found = false;
      // Inserts new line at first whitespace of the line
      for (var i = maxWidth - 1; i >= 0; i--) {
        if (this.testWhite(str?.charAt(i))) {
          res = res + [str?.slice(0, i), newLineStr]?.join("");
          str = str?.slice(i + 1);
          found = true;
          break;
        }
      }
      // Inserts new line at maxWidth position, the word is too long to wrap
      if (!found) {
        res += [str?.slice(0, maxWidth), newLineStr]?.join("");
        str = str?.slice(maxWidth);
      }
    }
    return res + str;
  }

  handleCapitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  }

  handleClick = (item) => {
    this.setState({ receipt: item, modalShow: true });
    if (item.platform === "anonymous_browser_license") {
      const header = [
        "License Key",
        "License Type",
        "Generated Date",
      ];
      let data = [];
      if (item?.license_ids?.length > 0) {
        data = item?.license_ids?.map((row) => {
          return [
            row?.license_key ?? null,
            this.handleCapitalize(row?.license_type ?? null),
            customDateFormatLocal(row?.createdAt ?? null)
          ];
        });
      }
      const tableWidth = 180;
      const columnWidths = [70, 40, 70];
      const rowHeight = 10;
      let doc = new jsPDF("p", "mm", "a4");
      const logoImg = require("images/logo.png");
      console.log(logoImg);
      doc.addImage(logoImg, "PNG", 70, 10, 60, 60);
      doc.setFontSize(24);
      doc.setFont("cursive", "bold");
      doc.text("Receipt", 86, 80);
      doc.setFontSize(16);
      doc.setFont("cursive", "bold");
      doc.text("Heading:", 20, 100);
      doc.text("Credit:", 20, 110);
      doc.text("Balance:", 20, 120);
      doc.text("Date time:", 20, 130);
      doc.setFontSize(16);
      doc.setFont("cursive", "normal");
      doc.text(item?.heading, 60, 100);
      doc.text("$" + item?.amount, 60, 110);
      doc.text("$" + item?.balance, 60, 120);
      doc.text(customDateFormatLocal(item?.created), 60, 130);
      if (
        (item.license_ids !== "" && item.license_ids !== null) ||
        item.license_ids.length > 0
      ) {
        doc.autoTable({
          startY: 140,
          head: [header],
          body: data,
          theme: "grid",
          columnStyles: {
            0: { cellWidth: columnWidths[0], fontSize: 10 },
            1: { cellWidth: columnWidths[1], fontSize: 10 },
            2: { cellWidth: columnWidths[2], fontSize: 10 },
          },
          styles: {
            fontSize: 8,
            cellPadding: 2,
            overflow: "linebreak",
          },
          tableWidth: tableWidth,
          rowPageBreak: "auto",
          headStyles: {
            fillColor: [56, 88, 160],
          },
          alternateRowStyles: {
            fillColor: [245, 245, 245],
          },
          rowHeight: rowHeight,
        });
      }
      doc.save(`payment-receipt ${customDateFormat(item.created)}.pdf`);
    } else if (item?.platform === "ticket_liberty") {
      if (item?.heading === "Package Purchased") {
        const header = [
          "Package Name",
          "Price",
          "Tickets Included",
          "Price Per Additional Ticket",
        ];
        let data = [];
        if (item?.soldinventory !== "" && item?.soldinventory !== null) {
          data && data?.push([
            item?.soldinventory?.name,
            `${changeDollarAmountformat(item?.soldinventory?.price)}/month`,
            item?.soldinventory?.tickets_count,
            `${changeDollarAmountformat(item?.soldinventory?.additional_ticket_price)}`,
          ]);
        }
        const tableWidth = 180;
        const columnWidths = [40, 40, 40, 40, 40, 50];
        const rowHeight = 10;
        let doc = new jsPDF("p", "mm", "a4");
        const logoImg = require("images/logo.png");
        doc.addImage(logoImg, "PNG", 70, 10, 60, 60);
        doc.setFontSize(24);
        doc.setFont("cursive", "bold");
        doc.text("Receipt", 86, 80);
        doc.setFontSize(16);
        doc.setFont("cursive", "bold");
        doc.text("Heading:", 20, 100);
        doc.text("Credit:", 20, 110);
        doc.text("Balance:", 20, 120);
        doc.text("Date time:", 20, 130);
        if (
          ((item?.soldinventory !== "" && item?.soldinventory !== null))
        ) {
          doc.text("Description:", 20, 140);
        } else if (item?.description ?? null) {
          doc.text("Description:", 20, 140);
        }
        doc.setFontSize(14);
        doc.setFont("cursive", "normal");
        doc.text(item.heading, 60, 100);
        doc.text("$" + item?.amount, 60, 110);
        doc.text("$" + item?.balance, 60, 120);
        doc.text(customDateFormatLocal(item?.created), 60, 130);
        if (
          ((item?.soldinventory !== "" && item?.soldinventory !== null))
        ) {
          doc.text(this.wordWrap(item?.description ?? ''), 60, 140);
        } else if (item?.description) {
          doc.text(this.wordWrap(item?.description ?? ''), 60, 140);
        }
        if (
          (item?.soldinventory !== "" && item?.soldinventory !== null)
        ) {
          doc.autoTable({
            startY: 150,
            head: [header],
            body: data,
            theme: "grid",
            columnStyles: {
              0: { cellWidth: columnWidths[0], fontSize: 10 },
              1: { cellWidth: columnWidths[1], fontSize: 10 },
              2: { cellWidth: columnWidths[2], fontSize: 10 },
            },
            styles: {
              fontSize: 8,
              cellPadding: 2,
              overflow: "linebreak",
            },
            tableWidth: tableWidth,
            rowPageBreak: "auto",
            headStyles: {
              fillColor: [56, 88, 160],
            },
            alternateRowStyles: {
              fillColor: [245, 245, 245],
            },
            rowHeight: rowHeight,
          });
        }
        doc.save(`payment-receipt ${customDateFormat(item.created)}.pdf`);
      } else {
        const header = [
          "Event Name",
          "Event Date",
          "Vendor Email",
          "Section",
          "Row",
          "Seat",
        ];
        let data = [];
        if (item?.sold_ids?.length > 0) {
          data = item?.sold_ids?.map((row) => {
            return [
              row?.event[0]?.name,
              customDateFormatLocal(row?.event[0]?.date),
              row?.vendor?.username,
              row?.section,
              row?.row,
              row?.seatNumbers,
            ];
          });
        } else if (item?.soldinventory !== "" && item?.soldinventory !== null) {
          data && data?.push([
            item?.soldinventory?.event[0].name,
            customDateFormatLocal(item?.soldinventory?.event[0]?.date),
            item?.soldinventory?.vendor?.username,
            item?.soldinventory?.section,
            item?.soldinventory?.row,
            item?.soldinventory?.seatNumbers,
          ]);
        }
        const tableWidth = 180;
        const columnWidths = [40, 40, 40, 40, 40, 50];
        const rowHeight = 10;
        let doc = new jsPDF("p", "mm", "a4");
        const logoImg = require("images/logo.png");
        doc.addImage(logoImg, "PNG", 70, 10, 60, 60);
        doc.setFontSize(24);
        doc.setFont("cursive", "bold");
        doc.text("Receipt", 86, 80);
        doc.setFontSize(16);
        doc.setFont("cursive", "bold");
        doc.text("Heading:", 20, 100);
        doc.text("Credit:", 20, 110);
        doc.text("Balance:", 20, 120);
        doc.text("Date time:", 20, 130);
        if (
          item?.description !== "" &&
          ((item?.soldinventory !== "" && item?.soldinventory !== null) ||
            (item?.sold_ids && item?.sold_ids?.length > 0))
        ) {
          doc.text("Description:", 20, 140);
        } else if (item?.description) {
          doc.text("Description:", 20, 140);
        }
        doc.setFontSize(14);
        doc.setFont("cursive", "normal");
        doc.text(item.heading, 60, 100);
        doc.text("$" + item?.amount, 60, 110);
        doc.text("$" + item?.balance, 60, 120);
        doc.text(customDateFormatLocal(item?.created), 60, 130);
        if (
          item?.description !== "" &&
          ((item?.soldinventory !== "" && item?.soldinventory !== null) ||
            (item?.sold_ids && item?.sold_ids?.length > 0))
        ) {
          doc.text(this.wordWrap(item?.description), 60, 140);
        } else if (item?.description) {
          doc.text(this.wordWrap(item?.description), 60, 140);
        }
        if (
          (item?.soldinventory !== "" && item?.soldinventory !== null) ||
          (item?.sold_ids && item?.sold_ids?.length > 0)
        ) {
          doc.autoTable({
            startY: 150,
            head: [header],
            body: data,
            theme: "grid",
            columnStyles: {
              0: { cellWidth: columnWidths[0], fontSize: 10 },
              1: { cellWidth: columnWidths[1], fontSize: 10 },
              2: { cellWidth: columnWidths[2], fontSize: 10 },
            },
            styles: {
              fontSize: 8,
              cellPadding: 2,
              overflow: "linebreak",
            },
            tableWidth: tableWidth,
            rowPageBreak: "auto",
            headStyles: {
              fillColor: [56, 88, 160],
            },
            alternateRowStyles: {
              fillColor: [245, 245, 245],
            },
            rowHeight: rowHeight,
          });
        }
        doc.save(`payment-receipt ${customDateFormat(item.created)}.pdf`);
      }
    }
  };

  handleSelectChangeDate = (event, newValue) => {
    const selectedOption = options.find(opt => opt.label === newValue.label);
    if (selectedOption) {
      if (selectedOption.label === 'Select Custom Date' && selectedOption.days === null) {
        this.setState({ isShowCustomeDate: true });
        const { filterDate } = this.state;
        this.setState({ selectedendDate: null, selectedDate: null, vendorValue: selectedOption.label });
        filterDate["fromDate"] = null;
        filterDate["toDate"] = null;
      } else if (selectedOption.label === 'All' && selectedOption.days === null) {
        const { filterDate } = this.state;
        filterDate["fromDate"] = null;
        filterDate["toDate"] = null;
        this.setState({ filterDate, selectedendDate: null, selectedDate: null, vendorValue: selectedOption.label });
      } else {
        if (selectedOption.days !== null) {
          const { filterDate } = this.state;
          const today = new Date();
          const endDate = subDays(today, selectedOption.days)
          this.setState({ selectedendDate: endDate, selectedDate: today });
          // fomating date to isoString
          const datestart = moment(today).format("YYYY-MM-DD");
          const dateend = moment(endDate).format("YYYY-MM-DD");
          filterDate["fromDate"] = dateend + "T00:00:00.000Z";
          if (endDate) {
            filterDate["toDate"] = datestart + "T00:00:00.000Z";
          }
          this.setState({ filterDate, isShowCustomeDate: false, vendorValue: selectedOption.label });
        } else {
          const { filterDate } = this.state;
          this.setState({ selectedendDate: null, selectedDate: null });
          filterDate["fromDate"] = null;
          filterDate["toDate"] = null;
          this.setState({ filterDate, isShowCustomeDate: false, vendorValue: selectedOption.label });
        }
      }
    }
  };

  render() {
    const {
      usersData,
      crossCheck,
      vendorValue,
      loader,
      timezone,
      isShowCustomeDate,
      openingBalance,
      totalDebit,
      totalCredit,
      closingBalance
    } = this.state;

    return (
      <>
        <div className="user-dashboard-wrapper">
          <Helmet>
            <title>History of Payment</title>
          </Helmet>
          <Header />
          <div className="c-app c-default-layout user-dashboard-body">
            <Usersidebar />
            <div className="c-wrapper history-payment-outer">
              <div className="c-body">
                <main className="c-main">
                  <CRow>
                    <CCol xs="12" lg="12">
                      <div className="notification-outer">
                        <CCard style={{ width: "100%" }} >
                          <CCardBody className="plan-tbl history-payment">
                            <>
                              <div className="usage-tbl-header">
                                <h3>History of Payment</h3>
                              </div>
                              <ValidatorForm
                                onSubmit={this.handleSubmit}
                                className="filter-form mb-5"
                              >
                                <Box sx={{ flexGrow: 1 }}>
                                  <Grid
                                    container
                                    spacing={{ xs: 2, md: 2, lg: 2 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                  >
                                    <Grid item xs={12} sm={4} md={3} lg={3}>

                                      <label className="Account_details_box-manage-license">
                                        Search Date
                                      </label>
                                      {!isShowCustomeDate ?
                                        <Autocomplete
                                          fullWidth
                                          className="filter-input"
                                          disablePortal
                                          id="combo-box-demo"
                                          getOptionLabel={(option) => option?.label}
                                          options={options}
                                          onChange={this.handleSelectChangeDate}
                                          renderInput={(params) =>
                                            <TextField
                                              {...params}
                                              value={vendorValue}
                                              placeholder="Select Date"
                                              variant="outlined" />}
                                        />
                                        :
                                        <DatePicker
                                          placeholderText="Search Date"
                                          selected={this.state.selectedDate}
                                          startDate={this.state.selectedDate}
                                          endDate={this.state.selectedendDate}
                                          monthsShown={this.state.monthsShown}
                                          selectsRange
                                          isClearable={true}
                                          customInput={
                                            <TextField
                                              variant="outlined"
                                              className="filter-input"
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment
                                                    position="end"
                                                    className="date-icon"
                                                  >
                                                    <div className="d-flex">
                                                      {crossCheck ? (
                                                        <IconButton
                                                          aria-label="toggle password visibility"
                                                          edge="end"
                                                          onClick={() =>
                                                            this.clearDatehandle()
                                                          }
                                                        >
                                                          <Clear className="text-dark" />
                                                        </IconButton>
                                                      ) : (
                                                        <IconButton
                                                          edge="end"
                                                          onClick={() =>
                                                            this.clearDate()
                                                          }
                                                        >
                                                          <Clear className="text-dark" />
                                                        </IconButton>
                                                      )}
                                                    </div>
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          }
                                          onChange={(dates) =>
                                            this.setStartDate(dates)
                                          }
                                        />
                                      }
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3} lg={3}>
                                      <FormControl fullWidth>
                                        <label className="Account_details_box-manage-license">
                                          Credit Added Method
                                        </label>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          name="status"
                                          value={this.state.creditAdded}
                                          onChange={(e) => this.handleChangeCreditAdded(e)}
                                          size="small"
                                          defaultValue="all"
                                          sx={{
                                            textAlign: "left",
                                          }}
                                        >
                                          <MenuItem value="all">All</MenuItem>
                                          <MenuItem value="manually_added">Manually Added by Admin</MenuItem>
                                          <MenuItem value="purchased_via_stripe">Credit Purchased via Stripe</MenuItem>
                                          <MenuItem value="auto_via_stripe">Auto Added via Stripe</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2} lg={2} sx={{ paddingTop: "47px!important" }} >
                                      <Button
                                        variant="contained"
                                        type="submit"
                                        className="filter-btn "
                                        startIcon={<HiOutlineSearch />}
                                      >
                                        Search
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </ValidatorForm>
                              <TableContainer sx={{ boxShadow: "none" }} component={Paper}>
                                <Table
                                  sx={{ width: "45%", }}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell sx={{ padding: "8px" }} align="left">
                                        Opening Balance
                                      </TableCell>
                                      <TableCell sx={{ padding: "8px" }} align="left">
                                        Total Debit
                                      </TableCell>
                                      <TableCell sx={{ padding: "8px" }} align="left">
                                        Total Credit
                                      </TableCell>
                                      <TableCell sx={{ padding: "8px" }} align="left">
                                        Closing Balance
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell sx={{ padding: "8px" }}
                                        component="th"
                                        scope="row"
                                      >
                                        {changeDollarAmountformat(openingBalance ?? 0.00) ?? `$${parseFloat(0.00)}`}
                                      </TableCell>
                                      <TableCell
                                        sx={{ padding: "8px" }}
                                        align="left"
                                        component="td"
                                        scope="row"
                                      >
                                        {changeDollarAmountformat(totalDebit ?? 0.00) ?? `$${parseFloat(0.00)}`}
                                      </TableCell>
                                      <TableCell sx={{ padding: "8px" }} align="left">
                                        {changeDollarAmountformat(totalCredit ?? 0.00) ?? `$${parseFloat(0.00)}`}
                                      </TableCell>
                                      <TableCell sx={{ padding: "8px" }} align="left">
                                        {changeDollarAmountformat(closingBalance ?? 0.00) ?? `$${parseFloat(0.00)}`}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <br />
                              {loader ? (
                                <ContainerLoader />
                              ) : (
                                <>
                                  <TableContainer component={Paper}>
                                    <Table
                                      sx={{ minWidth: 700 }}
                                      aria-label="customized table"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <StyledTableCell align="left">
                                            Date
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            Description
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            Debit
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            Credit
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            Ending Balance
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            Receipt
                                          </StyledTableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {usersData.length === 0 && (
                                          <TableRow
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                { border: 0 },
                                            }}
                                          >
                                            <TableCell
                                              colSpan={5}
                                              align="center"
                                              sx={{
                                                textAlign: "center!important",
                                              }}
                                            >
                                              <div
                                                className="no-data-found"
                                                style={{
                                                  padding: "20px",
                                                }}
                                              >
                                                <h3>No Data Found</h3>
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        )}
                                        {usersData && usersData.map((row, index) => (
                                          <StyledTableRow key={index}>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {customDateFormatLocal(
                                                row.created,
                                                timezone
                                              )}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              align="left"
                                              component="td"
                                              scope="row"
                                            >
                                              <div className="container-tablecell">
                                                {row?.heading.length > 35 ? (
                                                  <section>
                                                    <div className="outer-wrapper-payment">
                                                      <div className="inner-wrapper">
                                                        {row?.heading}
                                                      </div>
                                                    </div>
                                                  </section>
                                                ) : (
                                                  <>
                                                    {row?.heading}
                                                  </>
                                                )}
                                              </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                              {row?.isPackagePurchased ?
                                                <Chip
                                                  label="Subscribed"
                                                  color="success"
                                                  size="small"
                                                  sx={{
                                                    color: "#fff",
                                                  }}
                                                />
                                                :
                                                null
                                              }
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                              {row.entry_type === "debit"
                                                ? (changeDollarAmountformat(Number(row.amount).toFixed(2)))
                                                :
                                                ("-")
                                              }
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                              {row.entry_type === "debit"
                                                ? ("-")
                                                : (changeDollarAmountformat(Number(row.amount).toFixed(2)))}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              align="left"
                                              sx={{
                                                textAlign: "left!important",
                                              }}
                                            >
                                              {changeDollarAmountformat(Number(row.balance).toFixed(2))}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                              <IconButton
                                                aria-label="download"
                                                size="small"
                                                onClick={() =>
                                                  this.handleClick(row)
                                                }
                                              >
                                                <HiOutlineDownload
                                                  style={{
                                                    color: "#B90F0F",
                                                    fontSize: "24px",
                                                  }}
                                                />
                                              </IconButton>
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </>
                              )}
                              <Grid
                                container
                                spacing={2}
                                sx={{ marginTop: "20px" }}
                              >
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  {this.state.totalRecords > 0 && (
                                    <Pagination
                                      key="paginationnew"
                                      searchPage={this.state.searchPage}
                                      SearchDisable={this.SearchDisable}
                                      totalRecords={this.state.totalRecords}
                                      pageLimit={this.state.pageLimit}
                                      onPageChanged={this.onPageChanged}
                                    />
                                  )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <div
                                    style={{
                                      textAlign: "right",
                                      display: "flex",
                                      justifyContent: "end",
                                      alignItems: "center",
                                    }}
                                  >
                                    <label>Items per page :</label>
                                    <FormControl
                                      sx={{
                                        m: 1,
                                        minWidth: 50,
                                      }}
                                      size="small"
                                    >
                                      <Select
                                        defaultValue={this.state.pageLimit}
                                        value={this.state.pageLimit}
                                        name="limit"
                                        sx={{
                                          "& .MuiSelect-select": {
                                            padding: "4px 14px",
                                            fontFamily: "Barlow",
                                          },
                                        }}
                                        onChange={(e) => this.changePageLimit(e)}
                                      >
                                        <MenuItem value="10">10</MenuItem>
                                        <MenuItem value="20">20</MenuItem>
                                        <MenuItem value="50">50</MenuItem>
                                        <MenuItem value="100">100</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </div>
                                </Grid>
                              </Grid>
                            </>
                          </CCardBody>
                        </CCard>
                      </div>
                    </CCol>
                  </CRow>
                </main>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    UserProfile: state.UserProfile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(UserProfileAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Historypayment);
