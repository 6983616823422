import React from 'react';
import { Button, Row, Col, Container,} from 'react-bootstrap';
import './subscription.css';
import Commonheader from '../../components/common-header/common-headre';
import { Helmet } from "react-helmet";
import './subscription.css';
import Basic from '../../images/basic.png'
import Regular from '../../images/regular.png'
import Standard from '../../images/standard.png'
import Premium from '../../images/premium.png'
import axios from 'axios';
import AlertMessage from '../../components/message/message';
class Subscription extends React.Component {

    state = {
        loader: false,
        planData: [],
        planChooseLoader: false,
        open: false,
        message: '',
        successError: '',
        checkApiValidate: false
    }

    async componentDidMount() {

        //Verification Api Call
        const data = {
            code: this.props.match.params.id
        }
        const validate_api_url = process.env.REACT_APP_API_URL + "/api/user/validate"
        await axios.post(validate_api_url, data)
            .then((res) => {
              
                if (res.data.status) {
                    this.setState({
                        open: true,
                        successError: 'success',
                        loader: false,
                        message: res.data.message,
                        checkApiValidate: true
                    })
                }
                else {
                  
                    this.setState({
                        open: true,
                        successError: 'error',
                        loader: false,
                        message: res.data.message
                    })
                }

            }).catch((error) => {
                this.setState({
                    open: true,
                    successError: 'error',
                    loader: false,
                    message: "Something went wrong, please try again"
                })

            })

        //Data List Api
        if (this.state.checkApiValidate) {
            const packageList_api_url = process.env.REACT_APP_API_URL + "/api/package/getAll"
            await axios.get(packageList_api_url)
                .then((response) => {
                    const { data } = response;
                  
                    if (data.error) {
                     
                        this.setState({
                            successError: 'error',
                            open: true,
                            loader: false,
                            message:"Something went wrong"
                        })
                    }
                    else {
                       
                        this.setState({
                            successError: 'success',
                            open: true,
                            loader: false,
                            planData: data
                        })
                    }
                }).catch((error) => {
                   
                    this.setState({
                        loader: false,
                        open: true,
                        message: "Something went wrong",
                        successError: 'error',
                    })
                })
        }
        else {
            this.setState({
                successError: 'error',
                open: true,
                loader: false,
                message: "Verification Failed. Either your verification Code Is Invalid Or Expired"
            })
        }


    }

    callSubscriptionPlans = async (e) => {
     
        this.setState({
            planChooseLoader: true
        })
        const data = {
            package: e._id,
            user_id: localStorage.getItem('userSignUpToken')
        }

        const addUserPlan_api_url = process.env.REACT_APP_API_URL + "/api/user/addUserPackage"

        await axios.post(addUserPlan_api_url, data)
            .then((res) => {

             
                if (res.status === true) {
                  
                    this.setState({
                        planChooseLoader: false,
                        open: true,
                        message: data.message,
                        successError: 'success'
                    })

                    this.props.history.push("/welcome")
                    localStorage.removeItem("userSignUpToken");
                }
                else {
                    this.setState({
                        planChooseLoader: false,
                        open: true,
                        message: data.message,
                        successError: 'error'
                    })
                   
                }

            }).catch((error) => {
                this.setState({
                    planChooseLoader: false,
                    open: true,
                    message: "Something went wrong, please try again",
                    successError: 'error',
                })
            })

    }
    handleClose = (event, reason) => {
        this.setState({
            open: false
        })
    };
    render() {
   
        const { planData, } = this.state
        return (
            <div>
                {
                    this.state.loader ? <div className="loader-min"><div className="loader"></div></div> : ''
                }
                {
                    this.state.planChooseLoader ? <div className="loader-min"><div className="loader"></div></div> : ''
                }

                {/* <Header/> */}
                <Helmet>
                    <title>Subscription Plan</title>
                </Helmet>
                <Container>

                    <div className="subscription-wrapper">
                        <Commonheader title="Choose Your Plan" description=" " nextDes="" />
                        {/* <div className="plan-outer"> */}
                        <Row style={{ marginTop: '60px' }}>

                            {
                                planData.data !== undefined ?
                                    planData.data.map((data, key) => {
                                     
                                        return (
                                            <Col md={3} sm={6} className="flex-container subscription-flex">
                                                <div className="plan-outer">
                                                    <div>
                                                        <div className="plan-img-outer">
                                                            {
                                                                data.name === "Premium Package" ? <img alt="" src={Premium} className="plan-img" /> :
                                                                    data.name === "Starter Package" ? <img alt="" src={Basic} className="plan-img" /> : data.name === "Pro Package" ?
                                                                        <img alt="" src={Regular} className="plan-img" /> : <img alt="" src={Standard} className="plan-img" />
                                                            }
                                                        </div>
                                                        <h2>
                                                            {data.name}
                                                        </h2>

                                                        <div className="plan-price">
                                                            {data.price} <span className="per-month">/month</span>
                                                        </div>
                                                        <div dangerouslySetInnerHTML={{
                                                            __html: data.description
                                                        }}>

                                                        </div>
                                                        <div className="plan-btn">
                                                            <Button variant="primary" className="full-width login-btn" onClick={() => this.callSubscriptionPlans(data)}>
                                                                Choose Plan
                                                                    </Button>
                                                        </div>

                                                    </div>
                                                </div>

                                            </Col>
                                        )
                                    })
                                    : ''
                            }
                        </Row>
                        <AlertMessage open={this.state.open} message={this.state.message} severity={this.state.successError} closeCall={this.handleClose} />
                    </div>
                </Container>
                {/* <Footer/> */}
            </div>
        )
    }
}
export default Subscription;