// npm packgaes 
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import originalMoment from "moment";
import { extendMoment } from "moment-range";

// mui  components
import {
    Autocomplete,
    Box,
    Button,
    Card,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

// mui icons components
import { HiOutlineSearch } from 'react-icons/hi';
import { indigo } from '@mui/material/colors';

// components
import { useAdminAPICollection } from 'Hooks/useAdminAPICollection';
import useAlertMessage from 'Hooks/useAlertMessage';
import AlertMessage from 'components/message/message';
import CustomDataTable from 'utils/customDataTable';
import CustomPagination from 'utils/customPagination';
import CountdownRealTime from 'utils/countdownRealTime';
import { changeAmountformat, changeDollarAmountformat } from 'utils/changeDollarFormat';
import "./package.scss"
import { StatusChip } from 'utils/statusChip';

const moment = extendMoment(originalMoment);

const columns = [
    { field: 'user', headerName: 'Users' },
    { field: 'package_name', headerName: 'Package Name' },
    { field: 'price', headerName: 'Price' },
    { field: 'ticket_delivered_available', headerName: 'Tickets Usage' },
    { field: 'dollars_paid', headerName: 'Dollars paid for Overage use ' },
    { field: 'status', headerName: 'Status' },
    { field: 'auto_renewal', headerName: 'Auto Renewal' },
    { field: 'time_left', headerName: 'Duration Left' },
];

const UsersPackages = () => {
    const todayDate = new Date();
    const {
        allUserPackagesAPI,
        getAllUsersAPI
    } = useAdminAPICollection();
    const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
    const [isLoading, setIsLoading] = useState(false);
    const [userList, setUserList] = useState([]);
    const [packageList, setPackageList] = useState([]);
    const [durationDate, setdurationDate] = useState(new Date());
    const [totalRecords, setTotalRecords] = useState(0);
    const [formData, setFormData] = useState({
        data_year: "",
        data_month: null,
        users: null,
        status: "all"
    });
    const [pagination, setPagination] = useState({
        searchPage: 0,
        skip: 0,
        pageLimit: 10
    });

    const getAllPackage = async (pageskip = 0, limitpage) => {
        try {
            let params = {
                ...formData,
                users: formData.users ? formData.users._id : "",
                skip: pageskip,
                limit: limitpage,
                status: formData.status && formData.status !== "all" ? formData.status : null,
            };
            if (formData?.status === "all") {
                delete params.status;
            }
            setIsLoading(true);
            const response = await allUserPackagesAPI(params);
            const { data } = response;
            if (data.error) {
                setIsLoading(false);
                errorMessage(data?.message, "warning");
                setPackageList([])
            } else {
                setPackageList(data?.data ?? [])
                setTotalRecords(data.count ?? 0)
                setIsLoading(false);
            }
        } catch (error) {
            setPackageList([])
            errorMessage();
            setIsLoading(false);
        }
    }

    const getAllUsers = async () => {
        try {
            const respones = await getAllUsersAPI()
            const { data } = respones;
            if (data.error) {
                errorMessage()
            } else {
                const sorted = data.data.sort((a, b) => {
                    if (a.email.toLowerCase().trim() < b.email.toLowerCase().trim())
                        return -1;
                    if (a.email.toLowerCase().trim() > b.email.toLowerCase().trim())
                        return 1;
                    return 0;
                });
                setUserList(sorted ?? [])
            }
        } catch (error) {
            errorMessage()
        }
    };

    const handleSelectUser = (event, value) => {
        if (value) {
            setFormData({
                ...formData,
                users: value
            })
        } else {
            setFormData({
                ...formData,
                users: null
            })
        }
    };

    const handleChangeActive = (event) => {
        setFormData({
            ...formData,
            status: event.target.value
        });
    }

    const selectTimeDurtion = (dates) => {
        setdurationDate(dates);
        formData["data_month"] = moment(dates).format("MM");
        formData["data_year"] = moment(dates).format("YYYY");
        setFormData(formData);
    }

    const changePageLimit = (e) => {
        setPagination({
            ...pagination,
            searchPage: 1,
            pageLimit: parseInt(e.target.value)
        })
        getAllPackage(0, parseInt(e.target.value));
    };

    const onPageChanged = (data) => {
        const { currentPage } = data;
        setPagination({
            ...pagination,
            skip: (currentPage - 1) * pagination.pageLimit
        })
        getAllPackage((currentPage - 1) * pagination.pageLimit, pagination.pageLimit);
    };

    const SearchDisable = (value) => {
        setPagination({
            ...pagination,
            searchPage: value,
        })
    };

    const handleSubmitFilter = () => {
        getAllPackage(pagination?.skip, pagination?.pageLimit)
    }

    const calculateAdditionalCost = (ticketsDelivered, ticketsCount, additionalTicketPrice) => {
        if (ticketsDelivered > ticketsCount) {
            const difference = ticketsDelivered - ticketsCount;
            return difference * additionalTicketPrice;
        }
        return 0;
    };

    useEffect(() => {
        getAllPackage(pagination?.skip, pagination?.pageLimit)
        getAllUsers()
    }, []);

    return (
        <>
            <Helmet>
                <title>Users Packages</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card
                        className="instruction-box"
                        sx={{
                            width: "100%",
                            height: "100%",
                            bgcolor: "background.paper",
                            boxShadow: 1,
                            borderRadius: 1,
                            p: 2,
                            mb: 5,
                            display: "flex",
                            flexDirection: "column",
                            minHeight: "100vh",
                            maxWidth: "100%",
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ pt: "5" }}>
                                <Typography
                                    variant="p"
                                    component="h4"
                                    gutterBottom
                                    sx={{
                                        fontSize: "1.5rem",
                                        fontWeight: "bold",
                                        color: indigo[900],
                                        textAlign: "left",
                                        mb: 2,
                                        fontFamily: "Barlow  , sans-serif!important",
                                    }}
                                >
                                    Users Packages
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                sx={{ pt: "5" }}
                            >
                                <ValidatorForm
                                    onSubmit={handleSubmitFilter}
                                    className="filter-form mb-5"
                                >
                                    <Box >
                                        <Grid
                                            container
                                            spacing={{ xs: 2, md: 2, lg: 2 }}
                                        >
                                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                                                <label className="Account_details_box-manage-license">Users</label>
                                                <Autocomplete
                                                    fullWidth
                                                    id="checkboxes-tags-demo"
                                                    options={userList}
                                                    label="Users"
                                                    disableCloseOnSelect
                                                    onChange={handleSelectUser}
                                                    value={formData.users}
                                                    getOptionLabel={(option) => option.email}
                                                    renderInput={(params) => (
                                                        <TextValidator
                                                            {...params}

                                                            sx={{
                                                                "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error ": {
                                                                    color: "#C4C4C4"
                                                                },
                                                            }}
                                                            variant="outlined"
                                                            label="Users"
                                                            value={formData.users}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={3}
                                                md={3}
                                                lg={3}
                                                sx={{ textAlign: "left" }}
                                            >
                                                <FormControl fullWidth>
                                                    <label className="Account_details_box-manage-license">
                                                        Status
                                                    </label>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="status"
                                                        value={formData?.status}
                                                        onChange={(e) => handleChangeActive(e)}
                                                        size="small"
                                                        defaultValue="all"
                                                        sx={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        <MenuItem value="all">All</MenuItem>
                                                        <MenuItem value="active">Active</MenuItem>
                                                        <MenuItem value="expired">Expired</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={3}
                                                md={3}
                                                lg={3}
                                                xl={3}
                                            >
                                                <label className="Account_details_box-manage-license">Select Month</label>
                                                <div className="timeduration-package" style={{ width: "100%" }}>
                                                    <DatePicker
                                                        fullWidth
                                                        selected={durationDate}
                                                        onChange={(date) =>
                                                            selectTimeDurtion(date)
                                                        }
                                                        dateFormat="MMM/yyyy"
                                                        showMonthYearPicker
                                                        maxDate={todayDate}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3} sx={{ marginTop: "31px" }}>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    className="filter-btn"
                                                    startIcon={<HiOutlineSearch />}
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </ValidatorForm>
                            </Grid>
                        </Grid>
                        {/* {custom Data table} */}
                        <CustomDataTable
                            totalRecords={`${changeDollarAmountformat(packageList?.reduce((acc, item) => acc + parseFloat(item?.packageId?.price), 0))}`}
                            addBottomCell={true}
                            columns={columns}
                            isLoading={isLoading}
                            data={packageList.length > 0
                                ? packageList.map((item) => {
                                    const ticketsDelivered = item.ticketsDelivered ?? 0;
                                    const ticketsCount = item.packageId.tickets_count ?? 0;
                                    const deliveredAvailable = ticketsCount > 0 ? `${(ticketsDelivered)}/${changeAmountformat(ticketsCount)}` : "-";
                                    return {
                                        ...item,
                                        id: item.id,
                                        user: item.userId.email ?? "-",
                                        package_name: item.packageId.name ?? "-",
                                        price: `${changeDollarAmountformat(item.packageId.price)}/month` ?? "-",
                                        ticket_delivered_available: deliveredAvailable,
                                        dollars_paid: calculateAdditionalCost(ticketsDelivered, ticketsCount, item.packageId.additional_ticket_price ?? 0),
                                        status: item.status ? <StatusChip status={item?.status} /> : '-',
                                        auto_renewal: item?.auto_renewal ? item?.auto_renewal === "on" ? "ON" : "OFF" : "-",
                                        time_left: (
                                            <CountdownRealTime
                                                targetDate={item.validUpto}
                                                timeZone="America/New_York"
                                            />
                                        ),
                                    };
                                })
                                : []}
                        />
                        <br />
                        {packageList && packageList.length > 0 && (
                            <>
                                {totalRecords > 0 && (
                                    <CustomPagination
                                        key="paginationnew"
                                        searchPage={pagination?.searchPage}
                                        SearchDisable={SearchDisable}
                                        totalRecords={totalRecords}
                                        pageLimit={pagination?.pageLimit}
                                        onPageChanged={onPageChanged}
                                    />
                                )}
                                <div
                                    style={{
                                        textAlign: "right",
                                        display: "flex",
                                        justifyContent: "end",
                                        alignItems: "center",
                                    }}
                                >
                                    <label>Items per page :</label>
                                    <FormControl
                                        sx={{
                                            m: 1,
                                            minWidth: 50,
                                        }}
                                        size="small"
                                    >
                                        <Select
                                            defaultValue={pagination?.pageLimit}
                                            value={pagination?.pageLimit}
                                            name="limit"
                                            sx={{
                                                "& .MuiSelect-select": {
                                                    padding: "4px 34px 4px 14px!important",
                                                    fontFamily: "Barlow",
                                                },
                                            }}
                                            onChange={(e) => changePageLimit(e)}
                                        >
                                            <MenuItem value="10">10</MenuItem>
                                            <MenuItem value="20">20</MenuItem>
                                            <MenuItem value="50">50</MenuItem>
                                            <MenuItem value="100">100</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </>
                        )}
                    </Card>
                </Grid>
            </Grid>
            {/* alert  message  */}
            <AlertMessage
                open={alertMessage.openAlert}
                message={alertMessage.message}
                severity={alertMessage.alertSeverity}
                closeCall={hideAlert}
            />
        </>
    )
}

export default UsersPackages;
