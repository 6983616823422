import Cookies from "js-cookie";
import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const token = Cookies.get("accessToken");
        if (token) {
            config.headers.Authorization = "Bearer " + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const useAxios = () => {
    const axiosRequest = async (url = '', method = 'post', data = {}) => {
        try {
            const axiosMethod = {
                get: axiosInstance.get,
                post: axiosInstance.post,
                patch: axiosInstance.patch,
                delete: axiosInstance.delete,
            }[method];
            if (!axiosMethod) {
                throw new Error('Invalid method specified');
            }
            const response = await axiosMethod(url, method === 'get' ? null : data);
            return response;
        } catch (error) {
            throw error;
        }
    };

    return { axiosRequest };
};
