import React from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import axios from "axios";
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  Box,
  CircularProgress,
  Grid,
  CardContent,
  Card,
  CardHeader,
  InputAdornment,
  FormControl,
  IconButton,
  Autocomplete,
} from "@mui/material";
import AlertMessage from "../alert/alert";
import { Helmet } from "react-helmet";
import "../common.css";
import { ArrowBack } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";

const top100Films = [
  { title: "Skybox" },
  { title: "Manual/None" },
  { title: "Other" },
];
const marketPlace = [
  { title: "VividSeats" },
  { title: "SeatGeek" },
  { title: "Manual/None" },
  { title: "Other" },
];

class Useredit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      names: "",
      email: "",
      password: "",
      c_password: "",
      businessName: "",
      businessEmail: "",
      showpassword: "hidepassword",
      selectedDate: new Date('2024-01-01'),
      crossCheck: true,
      Marketplace: [],
      POS: [],
      ref_code: "",
      ticket_price: 0,
      access_browser: "No",
      roles: [],
      error: [],
      loader: false,
      open: false,
      message: "",
      successError: "",
      axs_price: 0,
      primaryLicense: 0,
      secondaryLicense: 0,
      testUser: "No",
      nt_ticket_price: 0,
      allPackage: [],
      package: {},
      upcoming_package: null,
      current_package: null,
      istrue: false,
      value: '0%'
    };
    this.inputRef = React.createRef();
  }
  async componentDidMount() {
    this.getAllPackage()
    const data = {
      user_id: this.props.match.params.usereditid,
    };
    // console.log("this.props.match", this.props.match);
    const userView_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/userDetail";
    await axios
      .post(userView_api_url, data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            names: data.data.name,
            email: data.data.email,
            nt_ticket_price: data.data.nt_ticket_price,
            // Marketplace:data.data.preferred_marketplace,
            // POS:data.data.preferred_pos,
            primaryLicense: data?.data && data?.data?.license_price && data?.data?.license_price?.primary,
            secondaryLicense: data?.data && data?.data?.license_price && data?.data?.license_price?.secondary,
            businessName: data?.data?.business_name,
            value: data?.data?.browser_charge_discount?.toString() + "%",
            businessEmail: data?.data?.business_email,
            ref_code: data.data.referral_code,
            ticket_price: data.data.ticket_price,
            access_browser: data.data.access_browser,
            axs_price: data.data.axs_link_price,
            testUser: data.data.isTestUser === false ? "No" : "Yes",
            selectedDate: new Date(data?.data?.browser_charge_date),
            package: {
              upcomingPackageId: data?.data?.upcomingPackageId,
              packageId: data?.data?.packageId
            },
            open: false,
            message: "",
            successError: "success",
          });
          // Pos data Iteration
          top100Films.map((data) => {
            if (data.title === response.data.data.preferred_pos) {
              this.setState({
                POS: data,
              });
            }
            return null;
          });
          // Marketplace data Iteration
          marketPlace.map((data) => {
            if (data.title === response.data.data.preferred_marketplace) {
              this.setState({
                Marketplace: data,
              });
            }
            return null;
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong please try again",
          successError: "error",
        });
      });

    if (!ValidatorForm.hasValidationRule("isPasswordMatch")) {
      ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
        if (value !== this.state.password) {
          return false;
        }
        return true;
      });
    }
    if (!ValidatorForm.hasValidationRule("PasswordStrength")) {
      ValidatorForm.addValidationRule("PasswordStrength", (value) => {
        // var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
        var re = /.{6,}/;
        // @".{6,}"
        return re.test(value);
      });
    }
  }
  componentWillUnmount() {
    if (ValidatorForm.hasValidationRule("isPasswordMatch")) {
      ValidatorForm.removeValidationRule("isPasswordMatch");
    }
    if (ValidatorForm.hasValidationRule("PasswordStrength")) {
      ValidatorForm.removeValidationRule("PasswordStrength");
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangePos = (e, newVal) => {
    this.setState({
      POS: newVal,
    });
  };
  handleChangeMarket = (e, newVal) => {
    this.setState({
      Marketplace: newVal,
    });
  };
  handleSubmit = async (e) => {
    const discount_rate = parseFloat(this.state?.value.replace('%', ''));
    this.setState({
      loader: true,
    });
    const data = {
      user_id: this.props.match.params.usereditid,
      name: this.state.names,
      email: this.state.email,
      business_name: this.state.businessName,
      business_email: this.state.businessEmail,
      referral_code: this.state.ref_code,
      preferred_pos: this.state.POS.title,
      preferred_marketplace: this.state.Marketplace.title,
      ticket_price: this.state.ticket_price,
      access_browser: this.state.access_browser,
      isTestUser: this.state.testUser === "Yes" ? true : false,
      nt_ticket_price: parseInt(this.state.nt_ticket_price),
      browser_charge_date: this.state?.selectedDate,
      password:
        this.state.showpassword === "changepassword" ? this.state.password : "",
      axs_link_price: this.state.axs_price,
      license_price: {
        primary: this.state.primaryLicense,
        secondary: this.state.secondaryLicense
      },
      upcomingPackageId: this.state.upcoming_package._id ? this.state.upcoming_package._id : null,
      browser_charge_discount: discount_rate
    };
    //    await this.props.signUpClick(data);

    const eddUser_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/updateUser";
    await axios
      .post(eddUser_api_url, data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "success",
          });
          this.props.history.push("/users-management");
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong, please try again",
          successError: "error",
        });
      });
  };

  getAllPackage = async () => {
    this.setState({
      loader: true,
    });
    try {
      const eddUser_api_url = process.env.REACT_APP_API_URL + "/api/package/list";
      const res = await axios.get(eddUser_api_url, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      });
      const { data } = res;
      if (data.error) {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong, please try again",
          successError: "error",
        });
      } else {
        data.data.unshift({
          name: "No Package",
          _id: null,
        });
        this.setState({
          allPackage: data.data,
          loader: false,
        });
      }
    } catch (error) {
      this.setState({
        loader: false,
        open: true,
        message: "Something went wrong, please try again",
        successError: "error",
      });
    }
  }

  handleClose = (event, reason) => {
    this.setState({
      open: false,
    });
  };

  handleChangeTestUser = (event) => {
    this.setState({ testUser: event.target.value });
  };


  setStartDate = async (dates) => {
    if (dates) {
      this.setState({ selectedDate: dates, crossCheck: false });
    } else {
      this.setState({ selectedDate: null, crossCheck: true });
    }
  };

  clearDate = () => {
    this.setState({ selectedDate: new Date('2024-01-01'), crossCheck: true });
  };

  handleChangeDiscount = (e) => {
    let inputValue = e.target.value.replace(/[^\d%]/g, '');
    if (inputValue.includes('%')) {
      inputValue = inputValue.replace('%', '');
    }
    if (inputValue.length > 1 && inputValue[0] === '0' && inputValue !== '0' && inputValue !== "") {
      inputValue = inputValue.replace(/^0+/, '');
    }
    if (
      inputValue === '' ||
      (/^\d{1,3}$/.test(inputValue) && parseInt(inputValue, 10) <= 100) && inputValue.length <= 3
    ) {
      if (inputValue === '') {
        this.setState({ value: '' });
      } else {
        this.setState({ value: inputValue + '%' }, this.handleCursorPositionDiscount(e));
      }
    }
  };

  handleBlurDiscount = () => {
    const { value } = this.state;
    if (value === '') {
      this.setState({ value: '0%' });
    } else if (!value.endsWith('%')) {
      this.setState({ value: value + '%' });
    }
  };

  handleCursorPositionDiscount = (e) => {
    const input = e.target;
    const position = input.selectionStart;
    const valueWithoutPercentage = this.state.value.replace('%', '');
    if (position >= valueWithoutPercentage.length) {
      input.setSelectionRange(valueWithoutPercentage.length, valueWithoutPercentage.length);
    }
  };

  handleOnChangePackage = (e, newValue) => {
    if (newValue) {
      this.setState({ upcoming_package: newValue, istrue: true });
    } else {
      this.setState({ upcoming_package: null, istrue: false });
    }
  };

  render() {
    const {
      names,
      email,
      ref_code,
      ticket_price,
      access_browser,
      password,
      c_password,
      loader,
      businessEmail,
      businessName,
      testUser,
      nt_ticket_price,
      allPackage,
      istrue
    } = this.state;

    if (istrue === false) {
      let upcomingPackage = this.state.allPackage?.find((item) => item._id === this.state.package.upcomingPackageId);
      let currentPackage = this.state.allPackage?.find((item) => item._id === this.state.package.packageId);
      this.state.current_package = currentPackage;
      upcomingPackage ? this.state.upcoming_package = upcomingPackage : this.state.upcoming_package = {
        name: "No Package",
        _id: null,
      }
    }
    
    if (!loader) {
      return (
        <>
          <Helmet>
            <title>Update User</title>
          </Helmet>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Update User"
                  action={
                    <Grid
                      container
                      spacing={{ xs: 1, md: 1, lg: 1 }}
                      columns={{ xs: 4, sm: 8, md: 8 }}
                      className="button-align"
                    >
                      <Grid item>
                        <Button
                          onClick={() => this.props.history.goBack()}
                          size="large"
                          variant="contained"
                          sx={{
                            backgroundColor: "#002986!important",
                            color: "white!important",
                            textTransform: "none!important",
                            fontWeight: "bold",
                            fontFamily: "Barlow!important",
                          }}
                          startIcon={<ArrowBack />}
                        >
                          Back
                        </Button>
                      </Grid>
                    </Grid>
                  }
                />
                <CardContent>
                  <ValidatorForm onSubmit={this.handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <label>Name</label>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange}
                          name="names"
                          value={names}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <label>Email address</label>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange}
                          name="email"
                          value={email}
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "This field is required",
                            "email is not valid",
                          ]}
                        />
                        {this.state.error.email && (
                          <div className="error-msg">
                            {this.state.error.email}
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item xs={6}>
                            <label>Business Name</label>
                            <TextValidator
                              variant="outlined"
                              fullWidth
                              onChange={this.handleChange}
                              type="text"
                              name="businessName"
                              value={businessName}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <label>Business Email</label>
                            <TextValidator
                              variant="outlined"
                              fullWidth
                              onChange={this.handleChange}
                              type="text"
                              name="businessEmail"
                              value={businessEmail}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <RadioGroup
                          aria-label="deliverymsg_time"
                          name="showpassword"
                          value={this.state.showpassword}
                          className="scan-condition-opts"
                          onChange={this.handleChange}
                        >
                          <FormControlLabel
                            value="changepassword"
                            control={<Radio color="primary" />}
                            label="Change Password"
                          />
                          <FormControlLabel
                            value="hidepassword"
                            control={<Radio color="primary" />}
                            label="Hide Password"
                          />
                        </RadioGroup>
                      </Grid>
                      {this.state.showpassword === "changepassword" && (
                        <>
                          <Grid item xs={12}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                              <Grid item xs={6}>
                                <label>Password</label>
                                <TextValidator
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.handleChange}
                                  type="password"
                                  name="password"
                                  value={password}
                                  validators={["required", "PasswordStrength"]}
                                  errorMessages={[
                                    "This field is required",
                                    "password must be at least 6 character",
                                  ]}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <label>Confirm Password</label>
                                <TextValidator
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.handleChange}
                                  type="password"
                                  name="c_password"
                                  value={c_password}
                                  validators={["required", "isPasswordMatch"]}
                                  errorMessages={[
                                    "This field is required",
                                    "password mismatch",
                                  ]}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item xs={6}>
                            <label>Referral code</label>
                            <TextValidator
                              variant="outlined"
                              fullWidth
                              onChange={this.handleChange}
                              name="ref_code"
                              value={ref_code}
                            // validators={["required"]}
                            // errorMessages={["Referal is required"]}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <label>Test User</label>
                            <Grid item xs={6}>
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={testUser}
                                  onChange={this.handleChangeTestUser}
                                >
                                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                  <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* <Col md="6">
                                                            <div className="form-group select-outer">
                                                                <label>Preffered  POS</label>
                                                                <Autocomplete
                                                                    onChange={this.handleChangePos}
                                                                    fullWidth
                                                                    id="combo-box-demo"
                                                                    name="POS"
                                                                    value={this.state.POS}
                                                                    options={top100Films}
                                                                    getOptionLabel={(option) => option.title}
                                                                    renderInput={(params) => <TextValidator 
                                                                        placeholder="Select POS" value={this.state.POS} 
                                                                        {...params} variant="outlined" 
                                                                        validators={['required']}
                                                                        errorMessages={('This field is required')}

                                                                        />}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md="6">
                                                            <div className="form-group select-outer">
                                                                <label>Preffered Marketplace</label>
                                                                <Autocomplete
                                                                    fullWidth
                                                                    onChange={this.handleChangeMarket}
                                                                    id="combo-box-demo"
                                                                    name="Marketplace"
                                                                    options={marketPlace}
                                                                    value={this.state.Marketplace}
                                                                    getOptionLabel={(option) => option.title}
                                                                    renderInput={(params) => <TextValidator
                                                                        value={this.state.Marketplace}
                                                                        placeholder="Select Marketplace"
                                                                        {...params} variant="outlined" 
                                                                        validators={['required']}
                                                                        errorMessages={('This field is required')}
                                                                        />}
                                                                />
                                                            </div>
                                                        </Col> */}
                      <Grid item xs={12}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item xs={6}>
                            <label>Ticket Price</label>
                            <TextValidator
                              variant="outlined"
                              fullWidth
                              onChange={this.handleChange}
                              type="text"
                              name="ticket_price"
                              value={ticket_price}
                              validators={["required"]}
                              errorMessages={["Ticket Price is required"]}
                            />
                            {this.state.error.ticket_price && (
                              <div className="error-msg">
                                {this.state.error.ticket_price}
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <label>Non-Transferable Ticket Price</label>
                            <TextValidator
                              variant="outlined"
                              fullWidth
                              onChange={this.handleChange}
                              type="text"
                              name="nt_ticket_price"
                              value={nt_ticket_price}
                              validators={["required"]}
                              errorMessages={["Non-Transferable Ticket Price is required"]}
                            />
                            {this.state.error.nt_ticket_price && (
                              <div className="error-msg">
                                {this.state.error.nt_ticket_price}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item xs={6}>
                            <label>Package</label>
                            <Autocomplete
                              fullWidth
                              disabled={true}
                              id="combo-box-demo"
                              defaultValue={(this.state.current_package ?? null)}
                              value={this.state.current_package ?? null}
                              options={allPackage ?? []}
                              getOptionLabel={(option) => option.name ?? ""}
                              renderInput={(params) =>
                                <TextValidator
                                  {...params}
                                  variant="outlined"
                                  placeholder="No Package"
                                />}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <label>Upcoming Package</label>
                            <Autocomplete
                              fullWidth
                              id="combo-box-demo"
                              name="upcoming_package"
                              options={allPackage ?? []}
                              onChange={this.handleOnChangePackage}
                              defaultValue={(this.state.upcoming_package) ?? null}
                              value={(this.state.upcoming_package) ?? null}
                              getOptionLabel={(option) => option.name ?? ""}
                              renderInput={(params) =>
                                <TextValidator
                                  value={(this.state.upcoming_package) ?? null}
                                  {...params} variant="outlined"
                                  validators={['required']}
                                  placeholder="No Package"
                                  errorMessages={('This field is required')}
                                />}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <span>Anonymous Browser Pricing</span>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item xs={6}>
                            <span>Primary</span> {" "}
                            <TextValidator
                              fullWidth
                              variant="outlined"
                              onChange={this.handleChange}
                              placeholder="Primary Price"
                              name="primaryLicense"
                              type="number"
                              value={this.state?.primaryLicense}
                              validators={["required"]}
                              errorMessages={["This field is required"]}
                              InputProps={{
                                startAdornment: <InputAdornment position="start" sx={{ marginRight: "-2px" }}>
                                  $
                                </InputAdornment>,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <span>Secondary</span>{' '}
                            <TextValidator
                              fullWidth
                              placeholder="Secondary Price"
                              variant="outlined"
                              onChange={this.handleChange}
                              name="secondaryLicense"
                              type="number"
                              value={this.state?.secondaryLicense}
                              validators={["required"]}
                              errorMessages={["This field is required"]}
                              InputProps={{
                                startAdornment: <InputAdornment position="start" sx={{ marginRight: "-2px" }}>
                                  $
                                </InputAdornment>,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2} >
                          <Grid item xs={6}>
                            <label>Access Browser</label>
                            <Select
                              style={{ width: "100%" }}
                              name="access_browser"
                              value={access_browser}
                              onChange={(e) => this.handleChange(e)}
                            >
                              <MenuItem
                                value="No"
                                selected={access_browser === "No"}
                              >
                                No
                              </MenuItem>
                              <MenuItem
                                value="Yes"
                                selected={access_browser === "Yes"}
                              >
                                Yes
                              </MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={4}>
                            <label>Browser Charge Date</label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={this.state.selectedDate}
                              customInput={
                                <TextValidator
                                  variant="outlined"
                                  className="filter-input"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        className="date-icon"
                                      >
                                        <div className="d-flex">
                                          {this.state.crossCheck ? (
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              edge="end"
                                            >
                                              <EventIcon className="text-dark" />
                                            </IconButton>
                                          ) : (
                                            <IconButton
                                              edge="end"
                                              onClick={() =>
                                                this.clearDate()
                                              }
                                            >
                                              <ClearIcon className="text-dark" />
                                            </IconButton>
                                          )}
                                        </div>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              }
                              onChange={(dates) =>
                                this.setStartDate(dates)
                              }
                            />
                          </Grid>
                          <Grid item xs={2} >
                            <label>Discount Rate</label>
                            <TextValidator
                              fullWidth
                              name="value"
                              value={this.state.value ?? ""}
                              onChange={this.handleChangeDiscount}
                              onBlur={this.handleBlurDiscount}
                              onKeyUp={this.handleCursorPositionDiscount}
                              onClick={this.handleCursorPositionDiscount}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={4} style={{ margin: "10px auto" }}>
                        <Button
                          variant="contained"
                          type="submit"
                          fullWidth
                          size="large"
                          // disabled={submitted}
                          sx={{ paddingY: "0.9rem" }}
                          className="theme-btn-submit"
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>

                  <AlertMessage
                    open={this.state.open}
                    message={this.state.message}
                    severity={this.state.successError}
                    closeCall={this.handleClose}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}

export default Useredit;
