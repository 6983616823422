
import React, { useEffect, useRef } from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

// react icon
import { HiOutlineSearch } from "react-icons/hi";

// mui components
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

// import components
import DataTable from "components/DataTable/DataTable";
import AlertMessage from "components/message/message";
import Pagination from "components/pagination/pagination";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import Usersidebar from "components/user-sidebar/user-sidebar";
import UserProfileAction from "redux/actions/UserProfielAction";
import { ContainerLoader } from "components/common/ContainerLoader";
import Cookies from "js-cookie";

const HistoryofLogs = () => {
  const dispatch = useDispatch();
  const limit = useSelector((state) => state.UserProfile?.data?.per_page_limit);
  const [loader, setLoader] = React.useState(false);
  const [filterData, setFilterData] = React.useState({
    status: "",
    user: "",
    vendor: "",
  });
  const [accountLogs, setAccountLogs] = React.useState([]);
  const [vendorList, setVendorList] = React.useState([]);
  const [vendorValue, setVendorValue] = React.useState(null);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [pageLimit, setPageLimit] = React.useState(limit);
  const [message, setMessage] = React.useState("");
  const [alertColor, setAlertColor] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [searchPage, setSearchPage] = React.useState(0);
  const [skip, setSkip] = React.useState(0);
  const prevFilterDataRef = useRef();


  const changePageLimit = (e) => {
    setSearchPage(1);
    setPageLimit(parseInt(e.target.value));
    updateProfile(e.target.value);
    getLogs(1, parseInt(e.target.value));
    setSearchPage(1);
  };

  const handleChange = (event) => {
    setFilterData({ ...filterData, [event.target.name]: event.target.value.trim() });
  };

  // Function to close the alert message
  const handleClose = () => {
    setOpen(false);
  };

  // disable Search
  const SearchDisable = (value) => {
    setSearchPage(value);
  };

  const onPageChanged = (data) => {
    const { currentPage } = data;
    setSkip((currentPage - 1) * pageLimit);
    getLogs((currentPage - 1) * pageLimit, pageLimit);
  };

  // handleSelect Vendor
  const handleSelectVendor = (e, newVal) => {
    console.log(newVal, 'newVal')
    if (newVal) {
      setFilterData({ ...filterData, vendor: newVal?.id });
      setVendorValue(newVal);
    } else {
      setFilterData({ ...filterData, vendor: null });
      setVendorValue(null);
    }
  };

  // Initialize the ref outside the component
  const handleSubmit = (event) => {
    event.preventDefault();
    setSearchPage(1);
    setLoader(true);
    if (
      prevFilterDataRef.current &&
      prevFilterDataRef.current === filterData
    ) {
      // Filter data has not changed, skip API call
      setLoader(false);
      return;
    }
    // Update the previous filterData value
    prevFilterDataRef.current = filterData;
    setSkip(0)
    getLogs(0, pageLimit);
  }

  const getLogs = async (pageskip = 0, limitpage) => {
    try {
      setLoader(true);
      const logsApiUrl = `${process.env.REACT_APP_API_URL}/api/account/historyOfLogs`;
      const response = await axios.post(
        logsApiUrl,
        {
          user: filterData.user,
          vendor: filterData.vendor,
          status: filterData.status,
          limit: limitpage,
          skip: pageskip,
        },
        {
          headers: {
            authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        });
      const { data } = response;
      setLoader(false);
      if (data.error) {
        setMessage(data?.message);
        setAlertColor("error");
        setOpen(true);
      } else {
        const logs = data?.data?.map((item) => ({
          ...item,
          user: item?.userId?.email,
          vendor: item?.accountId?.username,
          team: item?.accountId?.team,
        }));
        setAccountLogs(logs);
        setTotalRecords(data?.totalrecords);
      }
    } catch (error) {
      setMessage("Something went wrong");
      setAlertColor("error");
      setOpen(true);
      setLoader(false)
    }
  };

  const getAllAccountsList = async () => {
    try {
      setLoader(true)
      const vendorList_api_url = process.env.REACT_APP_API_URL + "/api/account/listAllAccounts";
      const response = await axios.post(vendorList_api_url,
        { type: "user" },
        {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        });
      const { data } = response;
      if (data.error) {
        setMessage(data.message);
        setAlertColor("error");
        setOpen(true);
      } else {
        const newdata = data?.data.map((val) => {
          return {
            label: `${val?.username} (${val?.team})`,
            id: val?._id,
          };
        }).sort((a, b) => a.label.localeCompare(b.label));
        const uniqueOptions = Array.from(new Set(newdata.map(option => option.id)))
          .map(id => newdata.find(option => option.id === id));
        setVendorList(uniqueOptions);
      }
    } catch (error) {
      setMessage("Something went wrong while accessing vendors list");
      setAlertColor("error");
      setOpen(true);
      setLoader(false)
    }
  }


  const updateProfile = async (perpageLimit) => {
    try {
      const updateDetail_api_url =
        process.env.REACT_APP_API_URL + "/api/user/updateUser";
      const response = await axios.post(
        updateDetail_api_url,
        { per_page_limit: perpageLimit },
        {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        }
      );
      const { data } = response;
      if (data.error) {
        setLoader(false);
        setOpen(true);
        setMessage(data.message);
        setAlertColor("error");
      } else {
        dispatch(UserProfileAction());
        setOpen(true);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllAccountsList()
    getLogs(skip, pageLimit)
  }, []);


  // DataTable fields configuration
  const fields = [
    {
      key: "vendor",
      label: "Vendor",
    },
    {
      key: "team",
      label: "Team",
    },

    {
      key: "status",
      label: "Status",
    },
    {
      key: "date",
      label: "Date/Time",
    },

  ];

  return (
    <>
      <div className="user-dashboard-wrapper">
        <Helmet>
          <title>History of Logs</title>
        </Helmet>
        <Header />
        <div className="c-app c-default-layout user-dashboard-body">
          <Usersidebar />
          <div className="c-wrapper">
            <div className="c-body">
              <main className="c-main">
                {/* {loader && <PageLoader />} */}
                <Grid container spacing={2}>
                  <Grid item xs="12" className="space-top-col">
                    <Card sx={{ p: 1 }}>
                      <CardHeader title="History of Logs" sx={{ px: 3 }} />
                      <CardContent>
                        <ValidatorForm
                          onSubmit={handleSubmit}
                          className="filter-form"
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                              <Autocomplete
                                fullWidth
                                className="filter-input"
                                disablePortal
                                id="combo-box-demo"
                                getOptionLabel={(option) => option.label}
                                options={vendorList}
                                onChange={handleSelectVendor}
                                renderOption={(props, option) => (
                                  <Box component="li" {...props} key={option.id}>
                                    {option.label}
                                  </Box>
                                )}
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                    value={vendorValue}
                                    placeholder="Vendor"
                                    variant="outlined" />}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                            >
                              <TextField
                                variant="outlined"
                                onChange={handleChange}
                                name="status"
                                value={filterData.status}
                                placeholder="Status"
                                className="filter-input"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              md={2}
                              lg={2}
                            >
                              <Button
                                variant="contained"
                                type="submit"
                                className="filter-btn"
                                startIcon={<HiOutlineSearch />}
                              >
                                Search
                              </Button>
                            </Grid>
                          </Grid>
                        </ValidatorForm>
                        {!loader ? (
                          <>
                            <DataTable
                              fields={fields}
                              items={accountLogs}
                              itemsPerPage={accountLogs.length}
                              pagination={false}
                              scopedSlots={{
                                status: (item) => <>

                                  <div className="container-tablecell">
                                    {item.status.length > 35 ? (
                                      <section>
                                        <div className="outer-wrapper-payment">
                                          <div className="inner-wrapper">
                                            {item.status}
                                          </div>
                                        </div>
                                      </section>
                                    ) : (
                                      <>
                                        {item.status}
                                      </>
                                    )}
                                  </div>
                                </>,
                                date: (item) => {
                                  const date = new Date(item.createdAt);
                                  const timeZone =
                                    Intl.DateTimeFormat().resolvedOptions()
                                      .timeZone;
                                  return (
                                    <>
                                      {moment(date)
                                        .tz(timeZone)
                                        .format("MM/DD/YYYY hh:mm A")}
                                    </>
                                  );
                                },
                              }}
                            />
                          </>
                        ) : (
                          <ContainerLoader />
                        )}
                        <AlertMessage
                          key="alertbtn"
                          open={open}
                          message={message}
                          severity={alertColor}
                          closeCall={handleClose}
                        />
                        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box>
                              {totalRecords > 0 && (
                                <Pagination
                                  key="paginationnew"
                                  searchPage={searchPage}
                                  SearchDisable={SearchDisable}
                                  totalRecords={totalRecords}
                                  pageLimit={pageLimit}
                                  onPageChanged={onPageChanged}
                                />
                              )}
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box>
                              <div
                                style={{
                                  textAlign: "right",
                                  display: "flex",
                                  justifyContent: "end",
                                  alignItems: "center",
                                }}
                              >
                                <label>Items per page :</label>
                                <FormControl
                                  sx={{
                                    m: 1,
                                    minWidth: 50,
                                  }}
                                  size="small"
                                >
                                  <Select
                                    defaultValue={pageLimit}
                                    value={pageLimit}
                                    name="limit"
                                    sx={{
                                      "& .MuiSelect-select": {
                                        padding: "4px 34px 4px 14px!important",
                                        fontFamily: "Barlow",
                                      },
                                    }}
                                    onChange={(e) => changePageLimit(e)}
                                  >
                                    <MenuItem value="10">10</MenuItem>
                                    <MenuItem value="20">20</MenuItem>
                                    <MenuItem value="50">50</MenuItem>
                                    <MenuItem value="100">100</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </main>
            </div>
          </div>
        </div>
        <Footer />
      </div >
    </>
  );
};

export default HistoryofLogs;
