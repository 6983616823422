import React, { Component } from "react";

// mui components
import {
    Typography,
    DialogActions,
    Select,
    DialogContentText,
    DialogTitle,
    Card,
    CardContent,
    CardHeader,
    TextField,
    Button,
    Autocomplete,
    FormControl,
    Checkbox,
    IconButton,
    Box,
    Grid,
    Dialog,
    AccordionSummary,
    InputAdornment,
    DialogContent,
    CircularProgress,
    AccordionDetails,
    LinearProgress,
    MenuItem,
    Accordion as MuiAccordion,
} from "@mui/material";

// mui and react icons 
import {
    TodayTwoTone,
    ExpandMore,
    Cancel,
    Event,
    Cached,
    Clear
} from "@mui/icons-material";
import { HiOutlineSearch } from "react-icons/hi";
import { AiOutlineUser } from 'react-icons/ai';

//  library's and packages  
import { ValidatorForm } from "react-material-ui-form-validator";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom"

// import component's
import Header from "components/header/header";
import Footer from "components/footer/footer";
import AlertMessage from "components/message/message";
import Usersidebar from "components/user-sidebar/user-sidebar";
import CalendarIcon from "images/user-dashboard/calendar-icon.svg";
import Pagination from "components/pagination/pagination";
import customDateFormat from "utils/customDateFormat";
import UserProfileAction from "redux/actions/UserProfielAction";
import "./filters.scss";
import loaderGif from "images/GIF-Loader-3.gif";
import { ProgressAction } from "redux/actions/ProgressAction";
import DataTable from "components/DataTable/DataTable";
import TimerButton from "utils/TimerButton";
import { ContainerLoader } from 'components/common/ContainerLoader'
import Cookies from "js-cookie";

const moment = extendMoment(originalMoment);

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress variant="determinate" value={props.value || 0} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    isNaN(props.value) ? 0 : props.value
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
class EventManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            events: [],
            totalRecords: 0,
            openedevent: null,
            vendorLabel: null,
            vendorVal: null,
            allvendors: [],
            selectedDate: null,
            filterEvents: [],
            fixedIndex: 0,
            nextRefreshTime: {},
            scanLimit: 0,
            selectedendDate: null,
            d: '',
            ds: '',
            accordionEvents: [],
            searchPage: 0,
            formData: {
                event_name: "",
                startDate: null,
                endDate: null,
                limit: 20,
                skip: 0,
                searchvendor: "",
                team: "",
                status: ''
            },
            crossCheck: true,
            allteamlist: [],
            monthsShown: 2,
            expanded: false,
            bulkActive: false,
            progressActive: false,
            progress: 0,
            todayActive: false,
            eventVendor: "",
            // saync vender user states
            isScanEventVisiableConfirmationPopup: false,
            syncshow: false,
            allsyncshow: false,
            scanAllVendorId: [],
            refreshshow: false,
            scanInventoryUser: [],
            scanInventoryUserButton: [],
            eventsDetail: [],
            messageopen: false,
            message: "",
            messagetype: "",
            selectedIndex: -1,
            axeState: true,
            syncCount: 0,
            fields: [

                {
                    key: "Select",
                    label: "Select"
                },

                {
                    key: "email",
                    label: "Email",
                },
                {
                    key: "available",
                    label: "Available",
                },
                {
                    key: "sold",
                    label: "Sold",
                },
                {
                    key: "status",
                    label: "Status",
                },
                {
                    key: "action",
                    label: "Action",
                }
            ]
        };
        this.eventSource = null;
        this.getEvents = this.getEvents.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClosemessage = this.handleClosemessage.bind(this);
        this.onPageChanged = this.onPageChanged.bind(this);
        this.handleSelectVendor = this.handleSelectVendor.bind(this);
        this.SearchDisable = this.SearchDisable.bind(this);
        this.handleSelectTeam = this.handleSelectTeam.bind(this);
        this.handleChangeAccordian = this.handleChangeAccordian.bind(this);
        this.handleCloseAllAccordian = this.handleCloseAllAccordian.bind(this);
        this.getTodayEvents = this.getTodayEvents.bind(this);
        this.toggleFields = this.toggleFields.bind(this);

        this.divRef = React.createRef();
    }

    // component's mounted  first render() 
    componentDidMount() {
        this.getTeamList();
        this.vendor_list("msg");
        this.geteventManagementScanLimit()
        if (window.innerWidth < 768) {
            this.setState({ monthsShown: 1 });
        } else if (window.innerWidth < 768) {
            this.setState({ monthsShown: 2 });
        }

        if (this.props.UserProfile.data !== undefined) {
            const { formData } = this.state;
            formData["limit"] = this.props.UserProfile.data?.per_page_limit;
            this.setState({ formData }, () => {
                this.getEvents();
            });
        }

    }

    geteventManagementScanLimit = async () => {
        const get_scan_limt = process.env.REACT_APP_API_URL + "/api/user/getEventManagementScanLimit";
        try {
            this.setState({ loader: true });
            const response = await axios.get(get_scan_limt, {
                headers: {
                    authorization: "Bearer " + Cookies.get("accessToken"),
                },
            });
            const { data } = response;
            if (data.error) {
                this.setState({
                    loader: false,
                    messageopen: true,
                    message: data.message,
                    messagetype: "error",
                });
            } else {
                this.setState({ scanLimit: data.scan_limit });
            }
        } catch (error) {
            this.setState({
                loader: false,
                messageopen: true,
                message: "Something went wrong",
                messagetype: "error",
            });
        }
    }



    /**
     * do not show toggle AXS events 
     * @param {string} vendor 
     */
    toggleFields = (vendor) => {
        if (vendor === "AXS") {
            this.setState({
                fields: [

                    {
                        key: "email",
                        label: "Email",
                    },
                    {
                        key: "available",
                        label: "Available",
                    },
                    {
                        key: "sold",
                        label: "Sold",
                    },
                    {
                        key: "status",
                        label: "Status",
                    },
                    {
                        key: "action",
                        label: "Action",
                    }
                ]
            })
        } else {
            this.setState({
                fields: [
                    {
                        key: "Select",
                        label: "Select"
                    },
                    {
                        key: "email",
                        label: "Email",
                    },
                    {
                        key: "available",
                        label: "Available",
                    },
                    {
                        key: "sold",
                        label: "Sold",
                    },
                    {
                        key: "status",
                        label: "Status",
                    },
                    {
                        key: "action",
                        label: "Action",
                    }
                ]
            })
        }
    }

    /**
     * get today events  date 
     * @param {object} reset 
     */
    getTodayEvents(reset) {
        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        this.setState({ loader: true });

        const { formData } = this.state;
        formData["startDate"] = reset ? null : startDate.toISOString();
        formData["endDate"] = null;
        if (reset) {
            this.setState({ todayActive: false, bulkActive: false });
        } else {
            this.setState({ todayActive: true });
        }
        this.setState({ formData }, () => {
            this.getEvents();
        });
    }

    // component unmount
    componentWillUnmount() {
        if (this.eventSource) {
            this.eventSource.close();
        }
    }

    handleClick = (index) => {
        this.setState({ selectedIndex: index });
    };

    //  close all Accordion
    handleCloseAllAccordian = () => {
        this.setState({ expanded: false, openedevent: null });
    };

    // open accordian 
    handleChangeAccordian = (panel) => (event, newExpanded) => {
        this.setState({ expanded: newExpanded ? panel : false });
        this.setState({ scanInventoryUser: [], scanInventoryUserButton: [] });
    };

    onHideSync = () => {
        this.setState({
            syncshow: false,
            allsyncshow: false,
            isScanEventVisiableConfirmationPopup: false
        });
    };

    onHideRefresh = () => {
        this.setState({
            refreshshow: false,
        });
    };

    handleSelectTeam(e, value) {
        if (value) {
            const { formData } = this.state;
            formData.team = value.name;
            this.setState({ formData });
        } else {
            const { formData } = this.state;
            formData.team = "";
            this.setState({ formData });
        }
        this.setState({ bulkActive: true });
    }

    SearchDisable = (value) => {
        this.setState({ searchPage: value });
    };

    /**
     * Function to select vendor from search
     */
    handleSelectVendor(e, val) {
        if (val) {
            this.setState({ vendorLabel: val.label, vendorVal: val.label });
            const { formData } = this.state;
            formData["searchvendor"] = val.label;
            this.setState({ formData });
        } else {
            this.setState({ vendorLabel: null, vendorVal: null });
            const { formData } = this.state;
            formData["searchvendor"] = null;
            this.setState({ formData });
        }
        this.setState({ bulkActive: true });
    }

    /**
     * get start date and end date in date picker 
     * @param {objects} dates 
     */
    setStartDate = (dates) => {
        const { formData } = this.state;
        const [start, end] = dates;
        // const getstart = start.valueOf()+hours+mins
        this.setState({ selectedendDate: end, selectedDate: start });
        // fomating date to isoString
        const datestart = moment(start).format("YYYY-MM-DDTHH:mm:ss");
        const dateend = moment(end).format("YYYY-MM-DDTHH:mm:ss");
        formData["startDate"] = datestart + ".000Z";
        if (end) {
            formData["endDate"] = dateend + ".000Z";
        }
        this.setState({ formData, crossCheck: false });
        this.setState({ bulkActive: true });
    };

    /**
     *  clear date in date picker 
     */
    clearDate = () => {
        const { formData } = this.state;
        this.setState({ selectedendDate: null, selectedDate: null });
        formData["startDate"] = null;
        formData["endDate"] = null;
        this.setState({ formData, crossCheck: true });
        this.setState({ bulkActive: true });
    };


    /**
     * get all vendor events 
     */
    async getEvents(msg, type = "success") {
        const event_api_url = process.env.REACT_APP_API_URL + "/api/user/getInteractiveAccounts";
        try {
            this.setState({ loader: true });
            const response = await axios.post(event_api_url, this.state.formData, {
                headers: {
                    authorization: "Bearer " + Cookies.get("accessToken"),
                },
            });
            const { data } = response;
            if (data.error) {
                this.setState({
                    loader: false,
                    messageopen: true,
                    message: "Something went wrong",
                    messagetype: "error",
                });
            } else {
                this.setState({ totalRecords: response.data.total });
                this.setState({
                    events: data.data,
                    messageopen: !msg ? false : true,
                    message: msg,
                    messagetype: type,
                });
                setTimeout(() => {
                    this.setState({ loader: false })
                }, 500)
                this.handleCloseAllAccordian()
            }
        } catch (error) {
            this.setState({
                loader: false,
                messageopen: true,
                message: "Something went wrong",
                messagetype: "error",
            });
        }
    }

    /**
     * get all team in vendor
     */
    async getTeamList() {
        const temalistapi = process.env.REACT_APP_API_URL + "/api/user/listAllTeams";
        try {
            const res = await axios.post(temalistapi, {},
                {
                    headers: {
                        authorization: "Bearer " + Cookies.get("accessToken"),
                    },
                }
            )
            const { data } = res;
            if (data.success === true) {

                const sorted = [...new Map(data.data.map(item => [item.name.toLowerCase(), item])).values()].sort((a, b) => {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                });

                this.setState({ allteamlist: sorted });
            }
        } catch (error) {
            this.setState({
                loader: false,
                messageopen: true,
                message: "Something went wrong",
                messagetype: "error",
            });
        }
    }

    /**
     * get vendor list api hitting
     * @param {string} msg 
     */
    async vendor_list(msg) {
        const vendorList_api_url = process.env.REACT_APP_API_URL + "/api/account/listAllAccounts";
        try {
            const response = await axios.post(
                vendorList_api_url,
                { type: "user" },
                {
                    headers: {
                        authorization: "Bearer " + Cookies.get("accessToken"),
                    },
                });
            const { data } = response;
            if (data.error) {
                this.setState({
                    loader: false,
                    messageopen: true,
                    message: "Something went wrong",
                    messagetype: "error",
                });
            } else {
                const newdata = data.data.map((val) => {
                    return {
                        label: val.username,
                        id: val._id,
                    };
                });

                const seen = new Set();
                const filteredArr = newdata.filter((el) => {
                    const duplicate = seen.has(el.label);
                    seen.add(el.label);
                    return !duplicate;
                });
                this.setState({
                    allvendors: filteredArr,
                });
            }
        } catch (error) {
            this.setState({
                loader: false,
                messageopen: true,
                message: "Something went wrong",
                messagetype: "error",
            });
        }
    }

    /**
     * scan single vendor api  hitting
     * @param {object} data 
     */
    scanAPiCall = async (data) => {
        const param = {
            id: data.account_id,
        };
        const vendorEventScan_api_url = process.env.REACT_APP_API_URL + "/api/account/scanVendorEvent";
        try {
            this.setState({ loader: true });
            await axios.post(vendorEventScan_api_url, param, {
                headers: {
                    authorization: "Bearer " + Cookies.get("accessToken"),
                },
            });
            const success_message = "Vendor Data has been updated";
            this.getEvents(success_message);
        } catch (error) {
            this.setState({
                loader: false,
                messageopen: true,
                message: "Something went wrong",
                messagetype: "error",
            });
        }
    }


    /**
     *  Selected Scan api  hitting  
     */
    scanVendorsSubmit = (type) => {
        const { scanInventoryUser, scanInventoryUserButton } = this.state;
        const data = {
            scanevents: scanInventoryUser.length === 0 ? scanInventoryUserButton : scanInventoryUser,
            path: '/event-management'
        };
        if (data.scanevents.length > 0) {
            this.setState({ loader: true, syncshow: false }, () => {
                const selectedVendorEventScan_api_url = process.env.REACT_APP_API_URL + "/api/account/scanAccountEvents";
                axios
                    .post(selectedVendorEventScan_api_url, data, {
                        headers: {
                            authorization: "Bearer " + Cookies.get("accessToken"),
                        },
                    })
                    .then((response) => {
                        const { data } = response;
                        if (data.error) {
                            this.setState({
                                loader: false,
                                messageopen: true,
                                message: data.message,
                                messagetype: "error",
                            });
                        } else {
                            const message =
                                data.total === 0
                                    ? "Accounts Scan Recently please try again after some time"
                                    : `${data.message}`;
                            let msgtype = data.total === 0 ? "error" : "success";
                            this.getEvents(message, msgtype);
                            this.handleCloseAllAccordian();
                            this.setState({ scanInventoryUser: [], scanInventoryUserButton: [] });
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            loader: false,
                            messageopen: true,
                            message: "Something went wrong",
                            messagetype: "error",
                        });
                    });
            });
        } else {
            this.setState({
                messageopen: true,
                message: "Please select event to scan",
                messagetype: "error",
            });
        }
    };


    /**
     * sync all vendors api  hitting
     */
    syncAllVendorsSubmit = async () => {
        const { scanAllVendorId } = this.state;
        try {
            this.setState({ loader: true, allsyncshow: false })
            const datas = {
                ids: scanAllVendorId,
            };
            const selectedVendorEventScan_api_url = process.env.REACT_APP_API_URL + "/api/account/scanMultipleVendorEvent";
            const response = await axios.post(selectedVendorEventScan_api_url, datas, {
                headers: {
                    authorization: "Bearer " + Cookies.get("accessToken"),
                },
            })
            const { data } = response;
            const message =
                data.total === 0
                    ? "All accounts are synced recently please try after some time"
                    : `Total ${data.total} ${data.message}`;

            var msgtype = data.total === 0 ? "error" : "success";
            this.getEvents(message, msgtype);
            this.setState({ scanAllVendorId: [] });
        } catch (error) {
            this.setState({
                loader: false,
                messageopen: true,
                message: "Something went wrong",
                messagetype: "error",
            });
        }
    }

    /**
     * update profile api
     */
    updateProfile = async () => {
        try {
            this.setState({ loader: true })
            const updateDetail_api_url = process.env.REACT_APP_API_URL + "/api/user/updateUser";
            const response = await axios.post(updateDetail_api_url,
                { per_page_limit: this.state.formData.limit },
                {
                    headers: {
                        authorization: "Bearer " + Cookies.get("accessToken"),
                    },
                })
            const { data } = response;
            if (data.error) {
                this.setState({
                    loader: false,
                    messageopen: true,
                    message: data.message,
                    successError: "error",
                });
            } else {
                this.props.getUserProfile();
            }
        } catch (error) {
            this.setState({
                loader: false,
                messageopen: true,
                message: "Something went wrong",
                messagetype: "error",
            });
        }
    }

    chooseEventForScan = (event, item, evt, index) => {
        const { scanInventoryUser, filterEvents, scanLimit } = this.state;
        const currentDate = new Date();
        this.setState({ selectedIndex: index });
        if (new Date(item.account_detail.nextRefresh) < currentDate) {
            // Check if nextRefresh is greater than current date
            if (evt.target.checked) {
                if (scanInventoryUser.length < scanLimit) {
                    const updatedEvents = [...scanInventoryUser, item._id];
                    const maxFilterEventsLength = scanLimit;
                    const updatedEventsSlice = Math.max(0, maxFilterEventsLength - filterEvents.length);
                    if (updatedEventsSlice === scanInventoryUser.length) {
                        this.setState({
                            messageopen: true,
                            message: `You have only ${parseInt(maxFilterEventsLength - filterEvents.length)} slot(s) left to scan!`,
                            messagetype: "error",
                        });
                    }
                    this.setState(
                        { scanInventoryUser: updatedEvents.slice(0, updatedEventsSlice), buttonHide: false },
                        () => this.handleFilterEvents(event)
                    );
                } else {
                    this.setState({
                        messageopen: true,
                        message: `You can select a maximum of ${this.state.scanLimit} events at a time`,
                        messagetype: "error",
                    });
                }
            } else {
                const updatedEvents = scanInventoryUser.filter((event) => event !== item._id);
                const maxFilterEventsLength = scanLimit;
                const updatedEventsSlice = Math.max(0, maxFilterEventsLength - filterEvents.length);
                this.setState(
                    { scanInventoryUser: updatedEvents.slice(0, updatedEventsSlice), buttonHide: false },
                    () => this.handleFilterEvents(event)
                );
            }
        } else {
            this.setState({
                messageopen: true,
                message: "The scan is in progress, please wait!",
                messagetype: "error",
            });
        }
    };



    ScanDisabledEventsShow = (id) => {
        this.setState({ scanInventoryUser: [] })
        const { scanInventoryUserButton } = this.state;
        scanInventoryUserButton.push(id);
        this.setState({ scanInventoryUserButton, isScanEventVisiableConfirmationPopup: false }, () => {
            this.scanVendorsSubmit();
        });
    };

    handleScanEventVisiableConfirmationPopup = () => {
        this.setState({ isScanEventVisiableConfirmationPopup: true });

    }

    handleFilterEvents = async (event, index) => {
        const { scanLimit } = this.state;
        const currentDate = new Date();
        this.setState({ ds: event._id.event_name })
        const futureEvents = await event.events.filter(event => new Date(event.account_detail.nextRefresh) > currentDate);
        futureEvents.length > 0 ? this.setState({ filterEvents: futureEvents, }) : this.setState({ filterEvents: [] })
        if (futureEvents.length > 0) {
            const shortestRefreshAccount = futureEvents.reduce((minAccount, event) => {
                const accountTime = new Date(event.account_detail.nextRefresh);
                if (accountTime > currentDate && (!minAccount || accountTime < new Date(minAccount.account_detail.nextRefresh))) {
                    return event;
                } else {
                    return minAccount;
                }
            }, null);
            const futureEventsLength = futureEvents.length;
            if (futureEventsLength >= scanLimit) {
                this.setState({
                    d: event._id.event_name,
                    nextRefreshTime: shortestRefreshAccount
                });
            }
        }
    };


    chooseAllEventsForScan = async (event, evtVal) => {
        const { filterEvents, scanLimit } = this.state;
        if (evtVal) {
            const selectedEvents = [];
            const filteredData = [];
            const scanFailedEvents = [];
            const scanCompletedEvents = [];
            document.querySelectorAll("tbody tr").forEach((row, index) => {
                const checkbox = row.querySelector("input[type='checkbox']");
                const status = JSON.parse(checkbox.getAttribute("name"))
                const nextRefreshDate = new Date(status.account_detail.nextRefresh)
                const id = checkbox.getAttribute("id");

                if (status && status.status === "Scan for Tickets" && id !== null && nextRefreshDate < new Date()) {
                    filteredData.push(status._id);
                } else if (
                    status &&
                    status.status !== "Scan for Tickets" &&
                    !status.status.includes("Scan Completed") &&
                    id !== null
                    && nextRefreshDate < new Date()
                ) {
                    scanFailedEvents.push(status._id);
                } else if (
                    status &&
                    status.status.includes("Scan Completed") &&
                    status.totalTickets === 0 &&
                    id !== null && nextRefreshDate < new Date()
                ) {
                    scanCompletedEvents.push(status._id);
                } else if (
                    status &&
                    status.status !== "Scan for Tickets" &&
                    id !== null
                    && nextRefreshDate < new Date()
                ) {
                    selectedEvents.push(status._id);
                }
            });
            const remainingRecords = Math.max(0, scanLimit - filteredData.length);
            const additionalEvents = selectedEvents.slice(0, remainingRecords);
            const updatedEvents = [
                ...filteredData,
                ...scanFailedEvents,
                ...scanCompletedEvents, // Prioritize Scan Completed with 0 total tickets
                ...additionalEvents,
            ];
            const maxFilterEventsLength = scanLimit;
            const updatedEventsSlice = Math.max(0, maxFilterEventsLength - filterEvents.length);
            this.setState(
                { scanInventoryUser: updatedEvents.slice(0, updatedEventsSlice) },
                () => this.handleFilterEvents(event)
            );
        } else {
            this.setState({ scanInventoryUser: [] });
        }
    };


    /**
     * handle submit getEvents function 
     */
    handleSubmit() {
        const { formData } = this.state;
        formData["skip"] = 0;
        this.setState({ bulkActive: false });
        this.setState({ searchPage: 1, formData, loader: true }, () => {
            this.getEvents(false);
        });
        this.handleCloseAllAccordian();
    }

    /**
     * on change move next  page handle click pagination
     */
    onPageChanged(data) {
        // const { currentPage, pageLimit } = data;
        const { currentPage } = data;
        const { formData } = this.state;
        formData["skip"] = formData.limit * (currentPage - 1);
        this.setState({ formData, loader: true }, () => {
            this.getEvents(false);
        });
    }
    /**
    * on change in input text 
    */
    handleChange(evt) {
        const { formData } = this.state;
        formData[evt.target.name] = evt.target.value;
        this.setState({ formData });
        this.setState({ bulkActive: true });
    }


    /**
     * set limit on pagination and cell the function getEvents()
     * @param {object} evt 
     */
    changepageLimit(event) {
        const { formData } = this.state;
        formData[event.target.name] = parseInt(event.target.value);
        formData["skip"] = 0;
        this.setState({ searchPage: 1, formData, loader: true }, () => {
            this.getEvents(false);
            this.updateProfile()
        });
    }

    handleSetData = (data) => {
        const userId = data.map(user => user.account_id);
        this.setState({ scanAllVendorId: userId }, () => {
            this.countSync(data);
        });
    }

    countSync = (data) => {
        const { scanAllVendorId } = this.state
        const selectedVendors = data.filter((item) => scanAllVendorId.includes(item.account_id));
        const compareTime = (nextSync) => {
            const nextSyncTime = new Date(nextSync).getTime();
            const currentTime = new Date().getTime();
            const diffSeconds = Math.round((nextSyncTime - currentTime) / 1000);
            return diffSeconds;
        };
        const counts = selectedVendors
            .map((item) => {
                const diffSeconds = compareTime(item.account_detail.nextScan);
                if (diffSeconds < 0) {
                    return item.account_id;
                }
                return null;
            })
            .filter((item) => item !== null);
        this.setState({ syncCount: counts.length });
    };


    handleClosemessage = () => {
        this.setState({ message: "", messageopen: false });
    };

    render() {
        const {
            loader,
            events,
            crossCheck,
            formData,
            totalRecords,
            scanInventoryUser,
            filterEvents,
            scanLimit
        } = this.state;


        var today = new Date();
        today.setDate(today.getDate() - 2);

        if (!this.state.progressActive) {

            const actionbutton = {
                backgroundColor: "#002986!important",
                color: "white!important",
                padding: "2px 5px!important",
                textTransform: "none!important",
                fontWeight: "bold",
                fontFamily: "Barlow!important",
                margin: "0 5px",
            };

            return (
                <React.Fragment>
                    <div className="user-dashboard-wrapper">
                        <Helmet>
                            <title>Event Management</title>
                        </Helmet>
                        <Header />
                        <div className="c-app c-default-layout user-dashboard-body">
                            <Usersidebar />
                            <div className="c-wrapper">
                                <div className="c-body">
                                    <main className="c-main">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Card
                                                    sx={{
                                                        p: 1,
                                                        width: "100%",
                                                        height: "100%",
                                                        minHeight: "100vh",
                                                    }}
                                                >
                                                    <CardHeader
                                                        title={
                                                            <Typography
                                                                sx={{
                                                                    textAlign: "left",
                                                                    color: (theme) => theme.palette.primary.main,
                                                                    fontFamily: "Barlow!important",
                                                                    fontSize: "26px!important",
                                                                    fontWeight: "600!important",
                                                                }}
                                                            >
                                                                Event Management
                                                            </Typography>
                                                        }
                                                        action={
                                                            <React.Fragment>
                                                                {this.state.todayActive && (
                                                                    <Button
                                                                        sx={{
                                                                            mr: 2,
                                                                        }}
                                                                        variant="outlined"
                                                                        startIcon={<Cached />}
                                                                        onClick={() => this.getTodayEvents(true)}
                                                                    >
                                                                        Reset
                                                                    </Button>
                                                                )}

                                                                <Button
                                                                    variant="outlined"
                                                                    startIcon={<TodayTwoTone />}
                                                                    onClick={() => this.getTodayEvents(false)}
                                                                >
                                                                    Today
                                                                </Button>
                                                            </React.Fragment>
                                                        }
                                                    />

                                                    <CardContent>
                                                        {/* Filters  */}
                                                        <ValidatorForm
                                                            onSubmit={this.handleSubmit}
                                                            className="filter-form mb-5"
                                                        >
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <Grid
                                                                    container
                                                                    spacing={{ xs: 2, md: 2, lg: 2 }}
                                                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                                                >
                                                                    <Grid item xs={12} sm={3}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            onChange={this.handleChange}
                                                                            name="event_name"
                                                                            placeholder="Search Event"
                                                                            value={formData.event_name || ''}
                                                                            className="filter-input"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={3} >
                                                                        <DatePicker
                                                                            placeholderText="MM/DD/YYYY"
                                                                            selected={this.state.selectedDate}
                                                                            startDate={this.state.selectedDate}
                                                                            endDate={this.state.selectedendDate}
                                                                            monthsShown={this.state.monthsShown}
                                                                            selectsRange
                                                                            isClearable={true}
                                                                            customInput={
                                                                                <TextField
                                                                                    variant="outlined"
                                                                                    className="filter-input"
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment
                                                                                                position="end"
                                                                                                className="date-icon"
                                                                                            >
                                                                                                <div className="d-flex">
                                                                                                    {crossCheck ? (
                                                                                                        <IconButton
                                                                                                            aria-label="toggle password visibility"
                                                                                                            edge="end"
                                                                                                        >
                                                                                                            <Event className="text-dark" />
                                                                                                        </IconButton>
                                                                                                    ) : (
                                                                                                        <IconButton
                                                                                                            edge="end"
                                                                                                            onClick={() =>
                                                                                                                this.clearDate()
                                                                                                            }
                                                                                                        >
                                                                                                            <Clear className="text-dark" />
                                                                                                        </IconButton>
                                                                                                    )}
                                                                                                </div>
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                />
                                                                            }
                                                                            onChange={(dates) =>
                                                                                this.setStartDate(dates)
                                                                            }
                                                                            filterDate={(date) => {
                                                                                var tdate = new Date();
                                                                                tdate.setDate(tdate.getDate() - 3);
                                                                                var dateString = tdate
                                                                                    .toISOString()
                                                                                    .split("T")[0];
                                                                                return date >= new Date(dateString);
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={3} >
                                                                        <Autocomplete
                                                                            fullWidth
                                                                            className="filter-input"
                                                                            name="filterstatusVal"
                                                                            options={this.state.allvendors}
                                                                            onChange={this.handleSelectVendor}
                                                                            getOptionLabel={(option) => option.label}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    value={this.state.vendorVal}
                                                                                    placeholder="Vendor Email"
                                                                                    {...params}
                                                                                    variant="outlined"
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        className="team"
                                                                        xs={12}
                                                                        sm={3}

                                                                    >
                                                                        <Autocomplete
                                                                            fullWidth
                                                                            className="filter-input"
                                                                            name="filterstatusVal"
                                                                            options={this.state.allteamlist}
                                                                            onChange={this.handleSelectTeam}
                                                                            getOptionLabel={(option) => option.name}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    value={this.state.vendorVal}
                                                                                    placeholder="Search Team"
                                                                                    {...params}
                                                                                    variant="outlined"
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        ite item
                                                                        className="team"
                                                                        xs={12}
                                                                        sm={3}
                                                                    >
                                                                        <FormControl fullWidth>
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                name="status"
                                                                                onChange={(e) => {
                                                                                    this.handleChange(e);
                                                                                }}
                                                                                placeholder="Status"
                                                                                size="small"
                                                                                defaultValue="all"
                                                                            >
                                                                                <MenuItem value="all">All</MenuItem>
                                                                                <MenuItem value="scan_tickets">
                                                                                    Scan for Tickets
                                                                                </MenuItem>
                                                                                <MenuItem value="scan_completed">
                                                                                    Scan Completed
                                                                                </MenuItem>
                                                                                <MenuItem value="others">Others</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4} md={2} lg={2}>
                                                                        <Button
                                                                            variant="contained"
                                                                            type="submit"
                                                                            className="filter-btn "
                                                                            startIcon={<HiOutlineSearch />}
                                                                        >
                                                                            Search
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </ValidatorForm>
                                                        {!loader ? (<>
                                                            {/* Bulk Generate   */}
                                                            {events.length === 0 && (
                                                                <div
                                                                    style={{ backgroundColor: "#ccc" }}
                                                                    className="text-center my-5"
                                                                >
                                                                    <h2>
                                                                        No items{" "}
                                                                        <svg
                                                                            width="30"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 512 512"
                                                                            className="c-icon c-icon-custom-size text-danger mb-2"
                                                                            role="img"
                                                                        >
                                                                            <path
                                                                                fill="var(--ci-primary-color, currentColor)"
                                                                                d="M425.706,86.294A240,240,0,0,0,86.294,425.705,240,240,0,0,0,425.706,86.294ZM256,48A207.1,207.1,0,0,1,391.528,98.345L98.345,391.528A207.1,207.1,0,0,1,48,256C48,141.309,141.309,48,256,48Zm0,416a207.084,207.084,0,0,1-134.986-49.887l293.1-293.1A207.084,207.084,0,0,1,464,256C464,370.691,370.691,464,256,464Z"
                                                                                className="ci-primary"
                                                                            ></path>
                                                                        </svg>
                                                                    </h2>
                                                                </div>
                                                            )}
                                                            {events.length > 0 &&
                                                                events.map((event, index) => (
                                                                    <MuiAccordion
                                                                        key={index}
                                                                        TransitionProps={{ unmountOnExit: true }}
                                                                        expanded={
                                                                            this.state.expanded === `event${index}`
                                                                        }
                                                                        onChange={this.handleChangeAccordian(
                                                                            `event${index}`
                                                                        )
                                                                        }
                                                                    >
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMore />}
                                                                            aria-controls="panel1bh-content"
                                                                            id="panel1bh-header"
                                                                            sx={{
                                                                                marginBottom: "4px!important",
                                                                            }}
                                                                            className="interactive-toggle"
                                                                            onClick={() => {
                                                                                this.handleSetData(event.events)
                                                                                this.handleFilterEvents(event)
                                                                                this.setState({ accordionEvents: event })
                                                                                // this.getTickets(index, event.events);
                                                                                this.setState({
                                                                                    eventVendor:
                                                                                        event.events[0].account_detail
                                                                                            ?.vendor === "AXS"
                                                                                            ? "AXS"
                                                                                            : event.events[0].account_detail
                                                                                                ?.team,

                                                                                })
                                                                                this.toggleFields(event.events[0].account_detail
                                                                                    ?.vendor === "AXS"
                                                                                    ? "AXS"
                                                                                    : event.events[0].account_detail
                                                                                        ?.team)
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                container
                                                                                spacing={2}
                                                                                className="align-items-center"
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                    sm={12}
                                                                                    md={4}
                                                                                    lg={5}
                                                                                    className="barinfo"
                                                                                >
                                                                                    <div className="text-left ">
                                                                                        {event._id.event_name}{" "}
                                                                                        {/* {event.events[0].event_name}{" "} */}
                                                                                        <span
                                                                                            style={{
                                                                                                textTransform: "capitalize",
                                                                                            }}
                                                                                        >
                                                                                            (
                                                                                            {event.events[0].account_detail
                                                                                                ?.vendor === "AXS"
                                                                                                ? "AXS"
                                                                                                : event.events[0].account_detail
                                                                                                    ?.team}
                                                                                            )
                                                                                        </span>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                    sm={12}
                                                                                    md={8}
                                                                                    lg={7}
                                                                                    className="barinfo"
                                                                                >
                                                                                    <Grid
                                                                                        container
                                                                                        direction="row"
                                                                                        justify="space-between"
                                                                                        alignItems="center"
                                                                                    >
                                                                                        <Grid
                                                                                            item
                                                                                            xs={8}
                                                                                            sm={4}
                                                                                            md={4}
                                                                                            lg={4}
                                                                                            className="barinfo"
                                                                                        >
                                                                                            <div
                                                                                                style={{
                                                                                                    textAlign: "left",
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    src={CalendarIcon}
                                                                                                    style={{ width: "20px" }}
                                                                                                    className="table-data-calendar-icon"
                                                                                                    alt="calendor-icon"
                                                                                                />
                                                                                                {customDateFormat(
                                                                                                    event._id.date
                                                                                                )}
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid
                                                                                            item
                                                                                            xs={2}
                                                                                            sm={4}
                                                                                            md={2}
                                                                                            lg={2}
                                                                                            className="barinfo"
                                                                                        >
                                                                                            <div
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    alignItems: "center"
                                                                                                }}
                                                                                            >
                                                                                                <AiOutlineUser
                                                                                                    style={{
                                                                                                        fontSize: "22px",
                                                                                                        marginRight: "4px"
                                                                                                    }}
                                                                                                />
                                                                                                <p style={{
                                                                                                    color: "white",
                                                                                                    margin: "0px",
                                                                                                }} >{event.events.length ?? 0}</p>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails
                                                                            sx={{
                                                                                padding: "0px!important",
                                                                            }}
                                                                        >
                                                                            {!event.events ? (
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        padding: "2rem",
                                                                                    }}
                                                                                >
                                                                                    <CircularProgress
                                                                                        sx={{
                                                                                            color: "#011C58",
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            ) : (
                                                                                <>
                                                                                    <Box
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            justifyContent: {
                                                                                                xs: "flex-start",
                                                                                                sm: "space-between"
                                                                                            },
                                                                                            marginBottom: "10px",
                                                                                            lineHeight: "38px",
                                                                                            alignItems: {
                                                                                                xs: 'start',
                                                                                                sm: "center"
                                                                                            },
                                                                                            paddingRight: "20px",
                                                                                            paddingLeft: {
                                                                                                xs: "10px",
                                                                                                sm: "0px"

                                                                                            },

                                                                                            flexDirection: {
                                                                                                xs: "column",
                                                                                                sm: "row",
                                                                                                md: "row",
                                                                                            },

                                                                                        }}
                                                                                        className="event-managment-"
                                                                                    >
                                                                                        {this.state.eventVendor !== "AXS" && (
                                                                                            <div
                                                                                                style={{
                                                                                                    padding: "4px 10px 10px 10px",
                                                                                                    borderRadius: "4px",
                                                                                                    width: "100%",
                                                                                                    maxWidth: "17rem",
                                                                                                    display: "flex",
                                                                                                    justifyContent: "end",
                                                                                                    alignItems: "center",
                                                                                                    flexDirection: "column",
                                                                                                    boxShadow:
                                                                                                        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                                                                                                }}
                                                                                            >


                                                                                                <div
                                                                                                    style={{
                                                                                                        textAlign: "left",
                                                                                                        width: "100%",
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        this.state.d === this.state.ds
                                                                                                            && filterEvents.length >= scanLimit ? <>
                                                                                                            <TimerButton
                                                                                                                apiCall={() => this.handleFilterEvents(event)}
                                                                                                                date={this?.state?.nextRefreshTime?.account_detail?.nextRefresh ?? null}
                                                                                                                id={`event-scan${this.state?.nextRefreshTime?._id ?? null}`}
                                                                                                                item={event}
                                                                                                                name="Scan Selected"
                                                                                                                time={0}
                                                                                                                timeinseconds={0}
                                                                                                                buttonType="button"
                                                                                                                variant="contained"
                                                                                                                size="medium"
                                                                                                                styles={{
                                                                                                                    actionbutton: {
                                                                                                                        backgroundColor: "#002986!important",
                                                                                                                        color: "white!important",
                                                                                                                        textTransform: "none!important",
                                                                                                                        fontFamily: "Barlow!important",
                                                                                                                        margin: "0 5px",
                                                                                                                    },
                                                                                                                    diabledbutton: {
                                                                                                                        backgroundColor: "#B60000!important",
                                                                                                                        textTransform: "none!important",
                                                                                                                        color: "white!important",
                                                                                                                        fontFamily: "Barlow!important",
                                                                                                                        margin: "0 5px",
                                                                                                                        fontSize: "14px",
                                                                                                                    },
                                                                                                                }}
                                                                                                            />
                                                                                                        </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <Button
                                                                                                                    variant="outlined"
                                                                                                                    sx={{
                                                                                                                        fontWeight: "semi-bold",
                                                                                                                        marginRight: "10px",
                                                                                                                    }}
                                                                                                                    onClick={() => {
                                                                                                                        this.chooseAllEventsForScan(event, scanInventoryUser.length > 0 && this.state.selectedIndex === index ? false : true)
                                                                                                                        this.handleClick(index, event.events)
                                                                                                                        this.setState({ fixedIndex: index })
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {this.state.selectedIndex === index && scanInventoryUser.length > 0
                                                                                                                        ? "Un-Select All"
                                                                                                                        : "Select All"}
                                                                                                                </Button>
                                                                                                            </>
                                                                                                    }
                                                                                                    {this.state.selectedIndex === index && scanInventoryUser.length > 0 && (
                                                                                                        <>
                                                                                                            <TimerButton
                                                                                                                apiCall={() => this.setState({ syncshow: true })}
                                                                                                                name="Scan Selected"
                                                                                                                time={0}
                                                                                                                timeinseconds={0}
                                                                                                                buttonType="button"
                                                                                                                variant="contained"
                                                                                                                size="medium"
                                                                                                                styles={{
                                                                                                                    actionbutton: {
                                                                                                                        backgroundColor: "#002986!important",
                                                                                                                        color: "white!important",
                                                                                                                        textTransform: "none!important",
                                                                                                                        fontFamily: "Barlow!important",
                                                                                                                        margin: "0 5px",
                                                                                                                    },
                                                                                                                    diabledbutton: {
                                                                                                                        backgroundColor: "#B60000!important",
                                                                                                                        textTransform: "none!important",
                                                                                                                        color: "white!important",
                                                                                                                        fontFamily: "Barlow!important",
                                                                                                                        margin: "0 5px",
                                                                                                                        fontSize: "14px",
                                                                                                                    },
                                                                                                                }}
                                                                                                            />
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                        <Grid className="grid-div filterBox" item sx={{ margin: "0 0 0 auto", }}>
                                                                                            <Button
                                                                                                className="button-sync-btn-all"
                                                                                                variant="outlined"
                                                                                                align="right"
                                                                                                onClick={() => {
                                                                                                    this.setState({ allsyncshow: true })
                                                                                                    this.countSync(event.events);
                                                                                                    this.handleSetData(event.events)
                                                                                                }}
                                                                                            >
                                                                                                Sync All
                                                                                            </Button>
                                                                                        </Grid>
                                                                                    </Box>

                                                                                    <DataTable
                                                                                        fields={this.state.fields}
                                                                                        itemsPerPage={event.events.length ?? 10}
                                                                                        items={event.events.sort((a, b) => {
                                                                                            const statusOrder = {
                                                                                                'Scan for Tickets': 0,
                                                                                                'loaded': 1,
                                                                                                'Change Password': 2,
                                                                                                'Scan in Progress': 3,
                                                                                                'Scan Failed': 4,
                                                                                                'Scan Completed': 5,
                                                                                            };
                                                                                            const aStatus = statusOrder[a.status.split(':')[0].trim()] || 0;
                                                                                            const bStatus = statusOrder[b.status.split(':')[0].trim()] || 0;
                                                                                            if (aStatus === 5 && bStatus === 5) {
                                                                                                const aTotalTickets = a.totalTickets || 0;
                                                                                                const bTotalTickets = b.totalTickets || 0;
                                                                                                return aTotalTickets - bTotalTickets;
                                                                                            }
                                                                                            return aStatus - bStatus;
                                                                                        }) || []}

                                                                                        scopedSlots={{
                                                                                            email: (item) => item.account_detail.username,
                                                                                            status: (item) => item.status,
                                                                                            available: (item) => {
                                                                                                const totalTickets = parseInt(item.totalTickets) || 0;
                                                                                                const soldTickets = parseInt(item.soldTickets) || 0;
                                                                                                return totalTickets - soldTickets;
                                                                                            },
                                                                                            sold: (item) => item.soldTickets ?? 0,
                                                                                            Select: (item) => {
                                                                                                return (
                                                                                                    <div className="flex">
                                                                                                        {
                                                                                                            this.state.d === this.state.ds
                                                                                                                && filterEvents.length >= scanLimit ?
                                                                                                                <>
                                                                                                                    <TimerButton
                                                                                                                        apiCall={() => this.setState({ syncshow: true })}
                                                                                                                        date={this?.state?.nextRefreshTime?.account_detail?.nextRefresh ?? ""}
                                                                                                                        id={`event-scan${this.state?.nextRefreshTime?._id ?? ""}`}
                                                                                                                        item={this.state?.nextRefreshTime}
                                                                                                                        time={0}
                                                                                                                        buttonType='Checkbox'
                                                                                                                        timeinseconds={0}
                                                                                                                    />
                                                                                                                </>
                                                                                                                :
                                                                                                                <Checkbox
                                                                                                                    ref={this.divRef}
                                                                                                                    size="small"
                                                                                                                    status={item.status}
                                                                                                                    id={
                                                                                                                        today > new Date(item.dateValid)
                                                                                                                            ? null
                                                                                                                            : `event${item._id}`
                                                                                                                    }
                                                                                                                    name={
                                                                                                                        today >= new Date(item.dateValid)
                                                                                                                            ? null
                                                                                                                            : JSON.stringify(item)
                                                                                                                    }
                                                                                                                    checked={scanInventoryUser.includes(item._id)}
                                                                                                                    onChange={(evt) =>
                                                                                                                        this.chooseEventForScan(event, item, evt, index)
                                                                                                                    }
                                                                                                                    inputProps={{
                                                                                                                        "aria-label": "primary checkbox",
                                                                                                                    }}
                                                                                                                />
                                                                                                        }

                                                                                                    </div>
                                                                                                );
                                                                                            },
                                                                                            action: (item, index) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            display: "flex",
                                                                                                            flexDirection: "row",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Button
                                                                                                            component={Link}
                                                                                                            size="small"
                                                                                                            variant="contained"
                                                                                                            sx={actionbutton}
                                                                                                            to={`/vendor-event-tickets/${item._id}`}
                                                                                                        >
                                                                                                            View
                                                                                                        </Button>
                                                                                                        <Button
                                                                                                            component={Link}
                                                                                                            size="small"
                                                                                                            variant="contained"
                                                                                                            sx={actionbutton}
                                                                                                            to={`/edit-vendor/${item.account_id}`}
                                                                                                        >
                                                                                                            Edit
                                                                                                        </Button>
                                                                                                        {this.state.d === this.state.ds
                                                                                                            && filterEvents.length >= scanLimit ?
                                                                                                            <>
                                                                                                                {filterEvents.length >= scanLimit && new Date(item.account_detail.nextRefresh) > new Date() ?
                                                                                                                    <TimerButton
                                                                                                                        apiCall={() => this.ScanDisabledEventsShow(item._id)}
                                                                                                                        date={item.account_detail.nextRefresh ?? ""}
                                                                                                                        item={item}
                                                                                                                        id={`event-scan${item._id}`}
                                                                                                                        name="Scan"
                                                                                                                        buttonType="Scan"
                                                                                                                        time={0}
                                                                                                                        timeinseconds={0}
                                                                                                                    /> :
                                                                                                                    <TimerButton
                                                                                                                        apiCall={() => this.setState({ syncshow: true })}
                                                                                                                        date={this?.state?.nextRefreshTime?.account_detail?.nextRefresh ?? ""}
                                                                                                                        id={`event-scan${this.state?.nextRefreshTime?._id ?? ""}`}
                                                                                                                        item={this.state?.nextRefreshTime}
                                                                                                                        name="Scan"
                                                                                                                        time={0}
                                                                                                                        buttonType="Scan"
                                                                                                                        timeinseconds={0}
                                                                                                                    />
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <TimerButton
                                                                                                                    apiCall={() => this.ScanDisabledEventsShow(item._id)}
                                                                                                                    date={item.account_detail.nextRefresh ?? ""}
                                                                                                                    item={item}
                                                                                                                    id={`event-scan${item._id}`}
                                                                                                                    name="Scan"
                                                                                                                    time={0}
                                                                                                                    timeinseconds={0}
                                                                                                                />
                                                                                                            </>
                                                                                                        }
                                                                                                        <TimerButton
                                                                                                            apiCall={this.scanAPiCall}
                                                                                                            date={item.account_detail.nextScan ?? null}
                                                                                                            item={item}
                                                                                                            id={`scan-${item.account_id}`}
                                                                                                            name="Sync"
                                                                                                            time={0}
                                                                                                            timeinseconds={0}
                                                                                                        />

                                                                                                    </div>
                                                                                                );
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                            )
                                                                            }
                                                                        </AccordionDetails>
                                                                    </MuiAccordion>
                                                                ))}
                                                        </>) : (
                                                            <ContainerLoader />
                                                        )}
                                                        {totalRecords > 0 && (
                                                            <Pagination
                                                                key="paginationnew"
                                                                searchPage={this.state.searchPage}
                                                                SearchDisable={this.SearchDisable}
                                                                totalRecords={totalRecords}
                                                                pageLimit={this.state.formData.limit}
                                                                onPageChanged={this.onPageChanged}
                                                            />
                                                        )}

                                                        {totalRecords > 0 && (
                                                            <div
                                                                style={{
                                                                    textAlign: "right",
                                                                    display: "flex",
                                                                    justifyContent: "end",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <label>Items per page :</label>
                                                                <FormControl
                                                                    sx={{
                                                                        m: 1,
                                                                        minWidth: 50,
                                                                    }}
                                                                    size="small"
                                                                >
                                                                    <Select
                                                                        defaultValue={formData.limit}
                                                                        value={formData.limit}
                                                                        name="limit"
                                                                        sx={{
                                                                            "& .MuiSelect-select": {
                                                                                padding: "4px 14px",
                                                                                fontFamily: "Barlow",
                                                                            },
                                                                        }}
                                                                        onChange={(e) => this.changepageLimit(e)}
                                                                    >
                                                                        <MenuItem value="10">10</MenuItem>
                                                                        <MenuItem value="20">20</MenuItem>
                                                                        <MenuItem value="50">50</MenuItem>
                                                                        <MenuItem value="100">100</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        )}
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </main>
                                </div>
                            </div>
                        </div>
                        <Footer />
                        <Dialog
                            maxWidth="sm"
                            open={
                                this.state.allsyncshow === true ?
                                    this.state.allsyncshow
                                    :
                                    this.state.syncshow
                            }
                            onClose={this.onHideSync}
                            sx={{
                                "& .MuiDialog-paper": {
                                    width: "100%",
                                },
                            }}
                        >
                            <DialogTitle>
                                {this.state.allsyncshow === true ? (
                                    "Sync"
                                ) : (
                                    "Scan"
                                )}
                            </DialogTitle>
                            <DialogContent>
                                <IconButton
                                    aria-label="close"
                                    onClick={this.onHideSync}
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        color: (theme) => theme.palette.grey[500],
                                        p: 1,
                                    }}
                                >
                                    <Cancel />
                                </IconButton>
                                <DialogContentText>
                                    {this.state.allsyncshow === true ? (
                                        `Are you sure you want to sync${" "} ${this.state.syncCount}`
                                    ) : (
                                        `Are you sure you want to scan${" "} ${this.state.scanInventoryUser.length}`
                                    )} accounts ?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={this.onHideSync}
                                    sx={{
                                        backgroundColor: "#002986!important",
                                        color: "white!important",
                                        fontWeight: "bold",
                                        fontFamily: "Barlow",
                                        textTransform: "none",
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() =>
                                        this.state.allsyncshow === true ?
                                            this.syncAllVendorsSubmit('allSync')
                                            :
                                            this.scanVendorsSubmit('multiple')
                                    }
                                    sx={{
                                        backgroundColor: "#002986!important",
                                        color: "white!important",
                                        fontWeight: "bold",
                                        fontFamily: "Barlow",
                                        textTransform: "none",
                                    }}
                                >
                                    {this.state.allsyncshow === true ? (
                                        "Sync"
                                    ) : (
                                        "Scan"
                                    )}
                                </Button>
                            </DialogActions>
                        </Dialog>


                        <Dialog
                            maxWidth="sm"
                            open={this.state.isScanEventVisiableConfirmationPopup}
                            onClose={this.onHideSync}
                            sx={{
                                "& .MuiDialog-paper": {
                                    width: "100%",
                                },
                            }}
                        >
                            <DialogTitle>Scan</DialogTitle>
                            <DialogContent>
                                <IconButton
                                    aria-label="close"
                                    onClick={this.onHideSync}
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        color: (theme) => theme.palette.grey[500],
                                        p: 1,
                                    }}
                                >
                                    <Cancel />
                                </IconButton>
                                <DialogContentText
                                    sx={{
                                        fontFamily: "Barlow",
                                        fontWeight: "500",
                                        fontSize: "18px",
                                        color: "black",
                                    }}
                                >
                                    Are you sure you want to Scan ?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={this.onHideSync}
                                    sx={{
                                        backgroundColor: "#002986!important",
                                        color: "white!important",
                                        fontWeight: "bold",
                                        fontFamily: "Barlow",
                                        textTransform: "none",
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => this.ScanDisabledEventsShow()}
                                    sx={{
                                        backgroundColor: "#002986!important",
                                        color: "white!important",
                                        fontWeight: "bold",
                                        fontFamily: "Barlow",
                                        textTransform: "none",
                                    }}
                                >
                                    Scan
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <AlertMessage
                            open={this.state.messageopen}
                            message={this.state.message}
                            severity={this.state.messagetype}
                            closeCall={this.handleClosemessage}
                        />
                    </div>
                </React.Fragment >
            );
        }

        return (
            <React.Fragment>
                <div className="user-dashboard-wrapper">
                    <Helmet>
                        <title>Event Management</title>
                    </Helmet>
                    <Header />
                    <div className="c-app c-default-layout user-dashboard-body">
                        <Usersidebar />
                        <div className="c-wrapper">
                            <div className="c-body">
                                <main className="c-main">
                                    {/* {loader && <PageLoader />} */}
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Card
                                                sx={{
                                                    padding: "30px",
                                                    width: "100%",
                                                    height: "100%",
                                                    minHeight: "100vh",
                                                }}
                                            >
                                                <CardHeader
                                                    title={
                                                        <Typography
                                                            sx={{
                                                                textAlign: "left",
                                                                color: (theme) => theme.palette.primary.main,
                                                                fontFamily: "Barlow!important",
                                                                fontSize: "26px!important",
                                                                fontWeight: "600!important",
                                                            }}
                                                        >
                                                            Event Management
                                                        </Typography>
                                                    }
                                                />
                                                <CardContent>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Box sx={{ width: "100%" }}>
                                                                <Typography
                                                                    variant="body2"
                                                                    color="text.secondary"
                                                                    sx={{ display: "inline" }}
                                                                >
                                                                    Task in progress, please wait
                                                                </Typography>
                                                                <LinearProgressWithLabel
                                                                    value={this.state.progress}
                                                                />
                                                                <div
                                                                    style={{
                                                                        marginTop: "3rem",
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={loaderGif}
                                                                        alt="loader"
                                                                        style={{
                                                                            width: "170px",
                                                                            height: "170px",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </main>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        UserProfile: state.UserProfile,
        limit: state.Page.vendorList,
        ProgressId: state.Progress,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getUserProfile: () => dispatch(UserProfileAction()),
        addId: (id) => dispatch(ProgressAction(id)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventManagement);
