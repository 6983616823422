import React, { Component } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import Moment from "moment";
import { connect } from "react-redux";

// mui components
import {
  RadioGroup,
  Radio,
  Typography,
  DialogActions,
  Fade,
  Select,
  DialogContentText,
  DialogTitle,
  Link,
  Card,
  CardContent,
  Collapse,
  Alert,
  CardHeader,
  Fab,
  FormControlLabel,
  TextField,
  Autocomplete,
  Button,
  FormControl,
  Checkbox,
  IconButton,
  InputAdornment,
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  DialogContent,
  CircularProgress,
  Dialog,
  Box,
  Grid,
  Link as MUiLink,
  Modal,
  MenuItem,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Paper
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

// mui icons and svg icons
import CachedIcon from "@mui/icons-material/Cached";
import CalendarIcon from "images/user-dashboard/calendar-icon.svg";
import TicketsIcon from "images/user-dashboard/ticket-icon.svg";
import SoldTicketsIcon from "images/user-dashboard/sold-ticket.svg";
import CloseIcon from "@mui/icons-material/Close";
import { HiOutlineSearch } from "react-icons/hi";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import CancelIcon from "@mui/icons-material/Cancel";
import { grey, indigo } from "@mui/material/colors";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import loaderGif from "images/GIF-Loader-3.gif";
import TodayTwoToneIcon from "@mui/icons-material/TodayTwoTone";
import Cookies from "js-cookie";
// scss file
import "./filters.scss";

// import components
import detailTimeFormat from "utils/detailTimeForm";
import { ProgressAction } from "redux/actions/ProgressAction";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import AlertMessage from "components/message/message";
import Usersidebar from "components/user-sidebar/user-sidebar";
import Pagination from "components/pagination/pagination";
import customDateFormat from "utils/customDateFormat";
import UserProfileAction from "redux/actions/UserProfielAction";
import { ContainerLoader } from 'components/common/ContainerLoader'
import { Table } from "react-bootstrap";

// datatable css 
const StyledTableCells = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary["dark"],
    color: theme.palette.common.white,
    fontFamily: "Barlow !important",
    fontWeight: "bold",
    textAlign: "left",
    position: "relative",
    // width: "100%",
    minWidth: "max-content",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Barlow !important",
    textAlign: "left",
    color: "#3C4E6A",
    borderBottom: "1px solid #CCCCCC",
    width: "auto",
    padding: "12px 15px",
  },
}));

/// model tabel css
const StyledTableRows = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3858A0",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontFamily: "Barlow !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const moment = extendMoment(originalMoment);

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" value={props.value || 0} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          isNaN(props.value) ? 0 : props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};
class InteractiveInventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      user_deliverymsg_time: "immediate",
      events: [],
      totalRecords: 0,
      currentVendor: "",
      openedevent: null,
      vendorLabel: null,
      vendorVal: null,
      allvendors: [],
      selectedDate: null,
      selectedendDate: null,
      searchPage: 0,
      formData: {
        event_names: "",
        section: "",
        row: "",
        seat: "",
        startDate: null,
        endDate: null,
        limit: 20,
        skip: 0,
        pathname: "inventory",
        searchvendor: "",
        team: "",
      },
      generateTicketMsg: "",
      generateTicketMsgAlertColor: "success",
      generateTicketMsgOpen: false,
      submttingticketform: false,
      generateTicketEvent: null,
      generateTicketData: {
        customerDisplayName: "",
        performer_name: "",
        invoiceExternalRef: "",
        uploadtovivid: false,
        custfromvivid: false,
        invoiceDate: "",
        customerName: "",
        customerEmail: "",
        customerPhone: "",
        deliverymsg_time: "immediate",
        ticketData: [],
        ticketIds: [],
        // skipExistCondition: false,
      },
      generateTicketModal: false,
      copiedTicketUrl: false,
      generatedTicketUrl: null,
      generatedTicketDetailModal: false,
      crossCheck: true,
      messageopen: false,
      message: "",
      messagetype: "",
      soldtickets: 0,
      allteamlist: [],
      monthsShown: 2,
      generateopen: false,
      generateresponsemsg: "",
      expanded: false,
      errorInFulfillingOrder: false,
      posType: null,
      generateTicketModalTV: false,
      filterOnGenerate: true,
      bulkConfirm: false,
      generateCount: null,
      generatedModal: false,
      bulkGeneratedData: [],
      copiedIndex: null,
      copiedUrl: false,
      bulkActive: false,
      generateTV: false,
      progressActive: false,
      progress: 0,
      todayActive: false,
      axsSuccess: false,
      axsLink: "",
      eventVendor: "",
      checkbox: true
    };
    this.eventSource = null;
    this.getEvents = this.getEvents.bind(this);
    this.getTickets = this.getTickets.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.generateModal = this.generateModal.bind(this);
    this.submitGenerateTicket = this.submitGenerateTicket.bind(this);
    this.handleClosemessage = this.handleClosemessage.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.handleSelectVendor = this.handleSelectVendor.bind(this);
    this.SearchDisable = this.SearchDisable.bind(this);
    this.getTeamList = this.getTeamList.bind(this);
    this.handleSelectTeam = this.handleSelectTeam.bind(this);
    this.handleToggleGenerate = this.handleToggleGenerate.bind(this);
    // this.handleRegenerateTicket = this.handleRegenerateTicket.bind(this);
    this.handleChangeAccordian = this.handleChangeAccordian.bind(this);
    this.handleCloseAllAccordian = this.handleCloseAllAccordian.bind(this);
    this.generatedDetailModalHideTV =
      this.generatedDetailModalHideTV.bind(this);
    this.bulkGenerate = this.bulkGenerate.bind(this);
    this.onbulkConfirmHide = this.onbulkConfirmHide.bind(this);
    this.copyMutipleUrl = this.copyMutipleUrl.bind(this);
    this.generateBulkHide = this.generateBulkHide.bind(this);
    this.convertToCSV = this.convertToCSV.bind(this);
    this.exportToCSV = this.exportToCSV.bind(this);
    this.submitGenerateTicketTV = this.submitGenerateTicketTV.bind(this);
    this.submitGenerateTicketAXS = this.submitGenerateTicketAXS.bind(this);
    this.serverSideProgress = this.serverSideProgress.bind(this);
    this.deleteProgressTask = this.deleteProgressTask.bind(this);
    this.getTodayEvents = this.getTodayEvents.bind(this);
    this.sortTickets = this.sortTickets.bind(this);
    this.calculateTotalAvailableTickets =
      this.calculateTotalAvailableTickets.bind(this);
    this.divRef = React.createRef();
    this.chooseticket = this.chooseticket.bind(this);
  }
  // calculateTotalAvailableTickets(events) {
  //   // Check if the vendor for the first event is "AXS"

  //   if (events[0]?.account_detail?.vendor === "AXS") {
  //     // Filter the events to only those with 0 sold tickets, then calculate the total available tickets
  //     return events
  //       .filter((event) => event.soldtickets_total === 0)
  //       .reduce((total, event) => total + event.availtickets.length, 0);
  //   } else {
  //     // Calculate the total available tickets for all events
  //     return events.reduce(
  //       (total, event) => total + event.availtickets.length,
  //       0
  //     );
  //   }
  // }
  calculateTotalAvailableTickets(events) {
    // Check if the vendor for the first event is "AXS"
    if (events[0]?.account_detail?.vendor === "AXS") {
      // Filter the events to only those with 0 sold tickets, then calculate the total available tickets
      return events
        .filter((event) => event.soldtickets_total ? event.soldtickets_total === 0 : event)
        .reduce((total, event) => total + (event.alltickets_total - (event.soldtickets_total ?? 0)), 0);
    } else {
      // Calculate the total available tickets for all events
      return events.reduce(
        (total, event) => total + (event.alltickets_total - (event.soldtickets_total ?? 0)),
        0
      );
    }
  }

  componentDidMount() {
    this.getTeamList();
    this.vendor_list("msg");
    if (window.innerWidth < 768) {
      this.setState({ monthsShown: 1 });
    } else if (window.innerWidth < 768) {
      this.setState({ monthsShown: 2 });
    }

    if (this.props.UserProfile.data !== undefined) {
      const { formData } = this.state;
      formData["limit"] = this.props.UserProfile.data?.per_page_limit;
      this.setState({ formData }, () => {
        this.getEvents();
      });

      if (this.props.UserProfile?.data?.deliverymsg_time) {
        const { generateTicketData } = this.state;
        generateTicketData.deliverymsg_time =
          this.props.UserProfile?.data?.deliverymsg_time;
        this.setState({ generateTicketData });
      }
    }
  }
  // Extract the sorting logic into a separate function
  sortTickets(a, b) {
    const sectionComparison = a.section.localeCompare(b.section);
    const rowComparison = parseInt(a.row) - parseInt(b.row);
    const seatComparison = parseInt(a.seat) - parseInt(b.seat);
    if (sectionComparison !== 0) {
      return sectionComparison;
    } else if (rowComparison !== 0) {
      return rowComparison;
    } else if (seatComparison !== 0) {
      return seatComparison;
    } else {
      return 0;
    }
  }
  getTodayEvents(reset) {
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    this.setState({ loader: true });
    const { formData } = this.state;
    formData["startDate"] = reset ? null : startDate.toISOString();
    formData["endDate"] = null;
    if (reset) {
      this.setState({ todayActive: false, bulkActive: false });
    } else {
      this.setState({ todayActive: true });
    }
    this.setState({ formData }, () => {
      this.getEvents();
    });
  }

  componentWillUnmount() {
    if (this.eventSource) {
      this.eventSource.close(); // close the EventSource instance before unmounting
    }
  }

  deleteProgressTask = (id) => {
    const deleteTaskApi = `${process.env.REACT_APP_API_URL}/api/user/deleteTask`;
    axios
      .post(
        deleteTaskApi,
        { _id: id },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('accessToken')}`,
          },
        }
      )
      .then((res) => {
        this.getEvents();
      })
      .catch((err) => {
        console.log("deleteTask", err);
      });
  };

  serverSideProgress = (id, generateType) => {
    this.eventSource.onmessage = async (event) => {
      const data = JSON.parse(event.data);
      const totalEntries = data.data.totalEntries;
      const completedEntries = data.data.completedEntries;
      const progress = Math.round((completedEntries / totalEntries) * 100);
      this.setState({ progress });
      if (data.data.status === "completed" && this.state.progressActive) {
        const updatedData = data?.data?.response?.data.map((item) => ({
          event_name: item.event_name,
          date: item.date,
          game_id: item?.game_id,
          barcode_id: item?.barcode_id,
          order_id: item.order_id,
          location: item?.location,
          account: item.account_detail?.username[0],
          vendor: item.vendor,
          section: item.section,
          row: item.row,
          seat: item.seat,
          ticketUrl: item.ticketUrl,
          team: item.team,
        }));
        this.setState(
          {
            progressActive: false,
            bulkGeneratedData:
              generateType === "singleGenerate"
                ? updatedData
                : data?.data?.response?.data,
          },
          () => {
            this.eventSource.close();
            this.setState({
              bulkConfirm: false,
              generatedModal: true,
              loader: false,
              progress: 0,
            });

            this.deleteProgressTask(id);
          }
        );
      } else if (data.data.status === "failed" && this.state.progressActive) {
        this.setState({ progressActive: false });
        if (data.data.response.alreadyExists) {
          console.log("AlreadyExists");
          this.setState({
            generateTicketModalTV: false,
            generateresponsemsg: data.data.response.message,
            generateopen: true,
            loader: false,
          });

        } else {
          this.setState({
            messageopen: true,
            message: data.data.response.message,
            messagetype: "error",
            progress: 0,
          });
        }
        this.eventSource.close();
        this.getEvents();
      } else if (data.data.message === "Error in getting progress!") {
        this.setState({
          messageopen: true,
          message: "Something went wrong!",
          messagetype: "error",
          progress: 0,
        });
        this.eventSource.close();
        this.getEvents();
      }
    };
  };

  handleCloseAllAccordian = () => {
    this.setState({ expanded: false, openedevent: null });
  };
  handleChangeAccordian = (panel) => (event, newExpanded) => {
    this.setState({ expanded: newExpanded ? panel : false });
  };
  onbulkConfirmHide = () => {
    this.setState({ bulkConfirm: false });
  };

  handleToggleGenerate = () => {
    this.setState({ generateopen: !this.state.generateopen });
  };
  getTeamList() {
    const temalistapi =
      process.env.REACT_APP_API_URL + "/api/user/listAllTeams";
    axios
      .post(
        temalistapi,
        {},
        {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          const { data } = res;
          const sorted = [...new Map(data.data.map(item => [item.name.toLowerCase(), item])).values()].sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
          this.setState({ allteamlist: sorted });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  generateBulkHide = () => {
    this.setState({
      generatedModal: false,
      copiedUrl: false,
      copiedIndex: null,
    });
  };

  convertToCSV = (data) => {
    const dataFiltered = data.map((item, index) => {
      const rowValue = typeof item.row === 'number' ? item.row ?? "" : item.row ?? "";
      const seatNumbers = typeof item.seat === 'number' ? item.seat ?? "" : item.seat ?? "";
      const section = typeof item.section === 'number' ? item.section ?? "" : item.section ?? "";
      return {
        "Performer Name":
          item.account === "Ticketmaster Account Manager" ||
            item.vendor === "Ticketmaster Account Manager"
            ? item.team.replace(/[^a-zA-Z ]/g, "")
            : item.event_name.replace(/[^a-zA-Z ]/g, "") ?? "",
        EventDateTime: " " + detailTimeFormat(item.date) + "",
        Section: '\t' + section,
        Row: '\t' + rowValue,
        Seat: '\t' + seatNumbers,
        Barcode: "",
        "Ticket Url": item.ticketUrl,
      }
    });
    const csv = Papa.unparse(dataFiltered);
    return csv;
  };

  exportToCSV = (data) => {
    const csv = this.convertToCSV(data);
    const file = new Blob([csv], { type: "text/csv" }); // file type
    saveAs(file, "bulkgeneratedlinks.csv");
  };

  validationOftheformatValue = (value) => {
    return typeof value === ('number' || 'string') ? value ?? "" : value ?? "";
  }

  convertToCSVACTA = (data) => {
    const dataFiltered = data.map((item, index) => {
      const date = item && item.date ? " " + detailTimeFormat(item?.date) + " " : "";
      const rowValue = this.validationOftheformatValue(item?.row);
      const seatNumbers = this.validationOftheformatValue(item?.seat);
      const section = this.validationOftheformatValue(item?.section);
      const orderId = this.validationOftheformatValue(item?.order_id);
      const barcode_id = this.validationOftheformatValue(item?.barcode_id);
      return {
        "Vendor eMail": item?.account ?? "",
        "Performer Name": item?.team ?? "",
        "Game Title": item.event_name.replace(/[^a-zA-Z ]/g, "") ?? "",
        "Game ID": (item.account === "Ticketmaster" ||
          item.vendor === "Ticketmaster") ? '\t' + orderId ?? "" : '\t' + item?.game_id ?? "",
        "Venue": item?.location ?? "",
        "Event Date": '\t' + Moment(date).format("MM-DD-YYYY") ?? "",
        "Event Time": '\t' + Moment(date).format("HH:mm:ss") ?? "",
        "Section": '\t' + section ?? "",
        "Row": '\t' + rowValue ?? "",
        "Seat": '\t' + seatNumbers ?? "",
        "Barcode": '\t' + barcode_id ?? "",
        "Status": "Available",
        "Ticket Url": item.ticketUrl ?? "",
      };
    });
    return Papa.unparse(dataFiltered);
  }

  exportToCSVACTA = (data) => {
    const csv = this.convertToCSVACTA(data);
    const file = new Blob([csv], { type: "text/csv" });
    saveAs(file, "bulkgeneratedlinks.csv");
  };
  bulkGenerate = async (bulk) => {
    if (!bulk) {
      this.setState({ progressActive: true });
    }
    const data = {
      ...this.state.formData,
      count: bulk,
      customerDisplayName: this.state.posType,
      totalEntries: !bulk ? this.state.generateCount : 0,
    };
    const bulkgenerateApi =
      process.env.REACT_APP_API_URL + "/api/user/bulkGenerate";
    axios
      .post(bulkgenerateApi, this.state.filterOnGenerate && data, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((res) => {
        if (bulk) {
          this.setState({
            generateCount: res.data.data,
            bulkConfirm: true,
          });
        } else {
          if (
            res.data.taskId !== "" &&
            res.data.taskId !== undefined &&
            res.data.taskId !== null &&
            res.data.taskInProgress
          ) {
            this.setState({ progressActive: true });
            this.props.addId(res.data.taskId);

            this.eventSource = new EventSource(
              process.env.REACT_APP_API_URL +
              `/api/user/bulkGenerate/status/${res.data.taskId}`
            );
            this.serverSideProgress(res.data.taskId, "bulkGenerate");
          }

          if (res.data.data !== undefined) {
            this.setState({
              bulkConfirm: false,
              bulkGeneratedData: res.data.data,
              generatedModal: true,
              loader: false,
            });
          }
          this.setState({ loader: false, bulkConfirm: false });
          // this.getEvents();
        }
      })
      .catch((err) => {
        this.setState({
          bulkConfirm: false,
          loader: false,
          message: "Something went wrong, please try again later.",
          messageopen: true,
          messagetype: "error",
        });
      });
  };

  handleSelectTeam(e, value) {
    if (value) {
      const { formData } = this.state;
      formData.team = value.name;
      this.setState({ formData });
    } else {
      const { formData } = this.state;
      formData.team = "";
      this.setState({ formData });
    }
    this.setState({ bulkActive: true });
  }

  // handleRegenerateTicket = () => {
  //   const { generateTicketData } = this.state;
  //   generateTicketData.skipExistCondition = true;
  //   this.setState({ generateTicketData, generateopen: false }, () => {
  //     this.state.posType && this.state.currentVendor !== "AXS"
  //       ? this.submitGenerateTicketTV()
  //       : this.state.posType && this.state.currentVendor === "AXS"
  //         ? this.submitGenerateTicketAXS()
  //         : this.submitGenerateTicket();
  //   });
  // };

  SearchDisable = (value) => {
    this.setState({ searchPage: value });
  };
  vendor_list(msg) {
    const vendorList_api_url =
      process.env.REACT_APP_API_URL + "/api/account/listAllAccounts";
    axios
      .post(
        vendorList_api_url,
        { type: "user" },
        {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        }
      )
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          const newdata = data.data.map((val) => {
            return {
              label: val.username,
              id: val._id,
            };
          });

          const seen = new Set();
          const filteredArr = newdata.filter((el) => {
            const duplicate = seen.has(el.label);
            seen.add(el.label);
            return !duplicate;
          });
          this.setState({
            allvendors: filteredArr,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  }

  //Function to select vendor from search
  handleSelectVendor(e, val) {
    if (val) {
      this.setState({ vendorLabel: val.label, vendorVal: val.label });
      const { formData } = this.state;
      formData["searchvendor"] = val.label;
      this.setState({ formData });
    } else {
      this.setState({ vendorLabel: null, vendorVal: null });
      const { formData } = this.state;
      formData["searchvendor"] = null;
      this.setState({ formData });
    }
    this.setState({ bulkActive: true });
  }
  updateProfile() {
    const updateDetail_api_url =
      process.env.REACT_APP_API_URL + "/api/user/updateUser";
    axios
      .post(
        updateDetail_api_url,
        { per_page_limit: this.state.formData.limit },
        {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        }
      )
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({ loader: false, message: data.message });
        } else {
          this.props.getUserProfile();
          this.setState({
            open: true,
            message: data.message,
            successError: "success",
          });
        }
      })
      .catch((error) => {
        this.setState({ loader: false });
      });
  }

  handleChange(evt) {
    const { formData } = this.state;
    formData[evt.target.name] = evt.target.value;
    this.setState({ formData });
    this.setState({ bulkActive: true });
  }

  changepageLimit(evt) {
    const { formData } = this.state;

    formData[evt.target.name] = parseInt(evt.target.value);
    formData["skip"] = 0;
    this.setState({ searchPage: 1, formData, loader: true }, () => {
      this.updateProfile();
      this.getEvents(false);
    });
  }

  setStartDate = (dates) => {
    const { formData } = this.state;
    const [start, end] = dates;
    // const getstart = start.valueOf()+hours+mins
    this.setState({ selectedendDate: end, selectedDate: start });
    // fomating date to isoString
    const datestart = moment(start).format("YYYY-MM-DDTHH:mm:ss");
    const dateend = moment(end).format("YYYY-MM-DDTHH:mm:ss");
    formData["startDate"] = datestart + ".000Z";
    if (end) {
      formData["endDate"] = dateend + ".000Z";
    }
    this.setState({ formData, crossCheck: false });
    this.setState({ bulkActive: true });
  };

  clearDate = () => {
    const { formData } = this.state;
    this.setState({ selectedendDate: null, selectedDate: null });
    formData["startDate"] = null;
    formData["endDate"] = null;
    this.setState({ formData, crossCheck: true });
    this.setState({ bulkActive: true });
  };

  getEvents() {
    // this.setState({ loader: true });
    const event_api_url =
      process.env.REACT_APP_API_URL + "/api/user/getUserInteractiveEvents";
    axios
      .post(event_api_url, this.state.formData, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.taskId) {
          this.setState({ progressActive: true, loader: false });
          this.props.addId(data.taskId);
          this.eventSource = new EventSource(
            process.env.REACT_APP_API_URL +
            `/api/user/bulkGenerate/status/${data.taskId}`
          );
          this.serverSideProgress(data.taskId, "getEvents");
        } else {
          if (data.success) {
            this.setState({ totalRecords: data.total });
            if (data.data !== undefined && data.data.length === 0) {
              this.setState({ bulkActive: true });
            }
            this.setState({
              posType: data.pos,
              events: data.data,
              loader: false,
            });
            /*if(fetchticket) {
                    var index = this.state.openedevent;
                    var event_id = data.data[index].id;
                    var event_date = data.data[index].date;
                    var account_id = data.data[index].account_id;
                    this.getTickets(index, event_id, event_date, account_id);
                }*/
          }
        }
      });
  }

  getTickets(index, event_ids) {
    const { events, generateTicketData } = this.state;
    var openedevent = null;
    if (this.state.openedevent !== index) {
      openedevent = index;
    }
    generateTicketData.ticketData = [];
    generateTicketData.ticketIds = [];
    this.setState({ openedevent, generateTicketData });
    if (!("tickets" in events[index])) {
      var event_only_ids = event_ids.map((ev) => {
        return ev._id;
      });
      const params = { event_id: event_only_ids };
      if (this.state.formData.section !== "") {
        params.section = this.state.formData.section;
      }
      if (this.state.formData.row !== "") {
        params.row = this.state.formData.row;
      }
      if (this.state.formData.seat !== "") {
        params.seat = this.state.formData.seat;
      }
      const event_api_url =
        process.env.REACT_APP_API_URL +
        "/api/user/getUserInteractiveEventsTickets";
      axios
        .post(event_api_url, params, {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.success) {
            events[index].tickets = data.data;
          } else {
            events[index].tickets = [];
          }
          this.setState({ events });
        });
    }
  }

  handleSubmit() {
    const { formData } = this.state;
    formData["skip"] = 0;
    this.setState({ bulkActive: false });
    this.setState({ searchPage: 1, formData, loader: true }, () => {
      this.getEvents(false);
    });
    this.handleCloseAllAccordian();
  }

  onPageChanged(data) {
    const { currentPage } = data;
    const { formData } = this.state;
    formData["skip"] = formData.limit * (currentPage - 1);
    this.setState({ formData, loader: true }, () => {
      this.getEvents(false);
    });
  }

  getBadge = (status) => {
    switch (status) {
      case "Active":
        return "success";
      case "Inactive":
        return "secondary";
      case "Pending":
        return "warning";
      case "Banned":
        return "danger";
      default:
        return "primary";
    }
  };


  chooseticket = (t, evt, event) => {
    const { generateTicketData, posType } = this.state;
    const vendor = event?.events[0]?.account_detail?.vendor;
    if (generateTicketData?.ticketData.length > 0 && !posType) {
      const { section, row, account_id } = generateTicketData.ticketData[0];
      if (
        section !== t.section ||
        row !== t.row ||
        account_id !== t.account_id
      ) {
        this.setState({
          message: "You must select a ticket from the same row and section",
          messageopen: true,
          messagetype: "error",
        });
        return;
      }
    }
    if (
      generateTicketData.ticketData.length > 0 &&
      posType &&
      vendor === "AXS"
    ) {
      const { row } = generateTicketData.ticketData[0];
      if (row !== t.row) {
        this.setState({
          message: "You must select a ticket from the same row",
          messageopen: true,
          messagetype: "error",
        });
        return;
      }
    }
    const { ticketIds, ticketData } = generateTicketData;
    const index = ticketIds.indexOf(t.id);
    if (evt.target.checked) {
      if (index === -1) {
        // if (posType && ticketIds.length > 0) {
        //   this.setState({
        //     message: "You can only select one ticket",
        //     messageopen: true,
        //     messagetype: "error",
        //   });

        //   return;
        // }
        generateTicketData.ticketIds = [...ticketIds, t.id];
        generateTicketData.ticketData = [...ticketData, t];
        this.setState({ generateTicketData });
      }
    } else {
      if (index !== -1) {
        ticketIds.splice(index, 1);
        ticketData.splice(index, 1);
        generateTicketData.ticketIds = ticketIds;
        generateTicketData.ticketData = ticketData.length ? ticketData : [];
        this.setState({ generateTicketData });
      }
    }
  }


  generateModal = (val, event) => {
    this.setState(
      {
        generatedTicketUrl: null,
        copiedTicketUrl: false,
        generateTicketEvent: event,
        currentVendor: event.events[0]?.account_detail?.vendor ?? null,
      },
      () => {
        if (this.state.posType === "TV" || this.state.posType === "TA") {
          this.setState({ generateTicketModalTV: val });
        } else {
          this.setState({ generateTicketModal: val });
        }
      }
    );
  };

  generatedDetailModalHide = () => {
    var generateTicketData = {
      invoiceExternalRef: "",
      invoiceDate: "",
      customerEmail: "",
      customerPhone: "",
      customerName: "",
      uploadtovivid: false,
      custfromvivid: false,
      ticketData: [],
      ticketIds: [],
      deliverymsg_time: this.state.user_deliverymsg_time,
    };

    this.setState({
      expanded: false,
      openedevent: null,
      generateTicketData,
      generateTicketModal: false,
      generatedTicketUrl: null,
      errorInFulfillingOrder: false,
      copiedTicketUrl: false,
      generateTicketEvent: null,
      generatedTicketDetailModal: false,
      generateTicketMsg: null,
      generateTicketMsgOpen: false,
    });
  };
  generatedDetailModalHideTV = () => {
    var generateTicketData = {
      invoiceExternalRef: "",
      invoiceDate: "",
      customerEmail: "",
      customerPhone: "",
      customerName: "",
      uploadtovivid: false,
      custfromvivid: false,
      ticketData: [],
      ticketIds: [],
      deliverymsg_time: this.state.user_deliverymsg_time,
    };

    this.setState({
      generateTicketModalTV: false,
      expanded: false,
      axsSuccess: false,
      axsLink: "",
      openedevent: null,
      generateTicketData,
      generateTicketModal: false,
      generatedTicketUrl: null,
      errorInFulfillingOrder: false,
      copiedTicketUrl: false,
      generateTicketEvent: null,
      generateTicketMsg: null,
      generateTicketMsgOpen: false,
    });
  };

  submitGenerateTicket = () => {
    this.setState({ submttingticketform: true });
    const ticket_api_url =
      process.env.REACT_APP_API_URL + "/api/user/generateTicketUrl";
    axios
      .post(ticket_api_url, this.state.generateTicketData, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        var { data } = response;
        if (data.error && !data.alreadyExists) {
          console.log("ErrorExists");
          this.setState({
            // skipExistCondition: false,
            generateTicketMsg: data.message
              ? data.message
              : "There is issue in generating ticket",
            generateTicketMsgAlertColor: "error",
            generateTicketMsgOpen: true,
            submttingticketform: false,
          });
        } else if (data.error && data.alreadyExists) {
          console.log("AlreadyExists");
          this.setState({
            submttingticketform: false,
            generateresponsemsg: data.message,
            generateopen: true,
            loader: false,
          });
        } else {
          this.setState(
            {
              submttingticketform: false,
              // skipExistCondition: false,
              loader: true,
            },
            () => {
              this.getEvents(true);
            }
          );
          this.handleCloseAllAccordian();
          if (data.errorInFulfillingOrder) {
            this.setState({ errorInFulfillingOrder: true });
          } else {
            this.setState({ errorInFulfillingOrder: false });
          }
          this.setState({
            generatedTicketDetailModal: true,
            submttingticketform: false,
            generatedTicketUrl: data.ticketUrl,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  submitGenerateTicketAXS = async () => {
    this.setState({ submttingticketform: true });
    var vendorData = { ...this.state.generateTicketData };
    // vendorData.customerDisplayName =
    //   this.state.currentVendor === "AXS"
    //     ? this.state.posType
    //     : vendorData.customerDisplayName;
    vendorData.customerDisplayName =
      (this.state.vendor === "AXS" || vendorData.customerDisplayName === "")
        ? this.state.posType
        : vendorData.customerDisplayName;

    vendorData.ticketData = await this.state.generateTicketData.ticketData.map(
      (ticket) => ({
        ...ticket,
        event_name:
          this.state.generateTicketEvent?.events &&
          this.state.generateTicketEvent.events[0].event_name,
        date:
          this.state.generateTicketEvent?.events &&
          this.state.generateTicketEvent.events[0].date,
      })
    );
    const ticket_api_url =
      process.env.REACT_APP_API_URL + "/api/user/generateTicketUrl";
    axios
      .post(ticket_api_url, vendorData, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        var { data } = response;
        if (data.error && !data.alreadyExists) {
          console.log("ErrorExists");
          this.setState({
            // skipExistCondition: false,
            generateTicketMsg: data.message
              ? data.message
              : "There is issue in generating ticket",
            generateTicketMsgAlertColor: "error",
            generateTicketMsgOpen: true,
            submttingticketform: false,
          });
        } else if (data.error && data.alreadyExists) {
          console.log("AlreadyExists");
          this.setState({
            submttingticketform: false,
            generateresponsemsg: data.message,
            generateopen: true,
            loader: false,
          });
        } else {
          this.setState(
            {
              submttingticketform: false,
              // skipExistCondition: false,
              loader: true,
              axsSuccess: true,
              axsLink: data.ticketUrl,
            },
            () => {
              this.getEvents(true);
            }
          );
          this.handleCloseAllAccordian();
          if (data.errorInFulfillingOrder) {
            this.setState({ errorInFulfillingOrder: true });
          } else {
            this.setState({ errorInFulfillingOrder: false });
          }
          this.setState({
            generatedTicketDetailModal: true,
            submttingticketform: false,
            generatedTicketUrl: data.ticketUrl,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  submitGenerateTicketTV = async () => {
    this.setState({ submttingticketform: true });
    var vendorData = { ...this.state.generateTicketData };
    // vendorData.customerDisplayName =
    //   this.state.currentVendor === "AXS"
    //     ? this.state.posType
    //     : vendorData.customerDisplayName;
    vendorData.customerDisplayName =
      (this.state.vendor === "AXS" || vendorData.customerDisplayName === "")
        ? this.state.posType
        : vendorData.customerDisplayName;
    vendorData.ticketData = await this.state.generateTicketData.ticketData.map(
      (ticket) => ({
        ...ticket,
        event_name:
          this.state.generateTicketEvent?.events &&
          this.state.generateTicketEvent.events[0].event_name,
        date:
          this.state.generateTicketEvent?.events &&
          this.state.generateTicketEvent.events[0].date,
        game_id:
          this.state.generateTicketEvent?.events &&
          this.state.generateTicketEvent.events[0].game_id,
        order_id:
          this.state.generateTicketEvent?.events &&
          this.state.generateTicketEvent.events[0].order_id,
        location:
          this.state.generateTicketEvent?.events &&
          this.state.generateTicketEvent.events[0].location
      })
    );
    const ticket_api_url =
      process.env.REACT_APP_API_URL + "/api/user/generateTicketUrlTVuser";
    axios
      .post(ticket_api_url, vendorData, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        var { data } = response;
        if (data.data !== undefined) {
          if (data.error && !data.alreadyExists) {
            console.log("ErrorExists");
            this.handleCloseAllAccordian();
            this.setState({
              submttingticketform: false,
              message: data.message,
              messageopen: true,
              messagetype: "error",
            });
            this.setState({
              loader: false,
              // skipExistCondition: false,
              generateTicketMsg: data.message
                ? data.message
                : "There is issue in generating ticket",
              generateTicketMsgAlertColor: "error",
              generateTicketMsgOpen: true,
              generateTicketModalTV: false,
            });
          } else if (data.error && data.alreadyExists) {
            console.log("AlreadyExists");
            this.setState({
              generateTicketModalTV: false,
              generateresponsemsg: data.message,
              generateopen: true,
              loader: false,
            });
          } else {
            const updatedData = data.data.map((item) => ({
              event_name: item.event_name,
              date: item.date,
              account: item.account_detail?.username[0],
              vendor: item.vendor,
              section: item.section,
              row: item.row,
              seat: item.seat,
              game_id: item?.game_id,
              barcode_id: item?.barcode_id,
              order_id: item.order_id,
              location: item?.location,
              ticketUrl: item.ticketUrl,
              team: item.team,
            }));
            this.setState({
              bulkGeneratedData: updatedData,
              generatedModal: true,
              generateTV: true,
            });

            this.setState(
              {
                generateTicketModalTV: false,
                // skipExistCondition: false,
                loader: true,
              },
              () => {
                this.getEvents(true);
              }
            );

            this.handleCloseAllAccordian();
          }
        } else if (
          response.data.taskId !== "" &&
          response.data.taskId !== undefined &&
          response.data.taskId !== null &&
          response.data.taskInProgress
        ) {
          this.setState({
            generateTicketModalTV: false,
            progressActive: true,
            submttingticketform: false,
          });
          this.props.addId(response.data.taskId);

          this.eventSource = new EventSource(
            process.env.REACT_APP_API_URL +
            `/api/user/bulkGenerate/status/${response.data.taskId}`
          );
          this.serverSideProgress(response.data.taskId, "singleGenerate");
          this.handleCloseAllAccordian();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleModelChange = (e) => {
    const { generateTicketData } = this.state;
    generateTicketData[e.target.name] = e.target.value;
    this.setState({ generateTicketData });
  };

  handleModelChangeCheckbox = (e) => {
    const { generateTicketData } = this.state;
    generateTicketData[e.target.name] = e.target.checked;
    this.setState({ generateTicketData });
  };

  handleModelChangeSelect = (evt, val, name) => {
    const { generateTicketData } = this.state;
    if (val) {
      generateTicketData[name] = val.title;
    } else {
      generateTicketData[name] = null;
      generateTicketData.uploadtovivid = false;
      generateTicketData.custfromvivid = false;
    }
    this.setState({ generateTicketData });
  };

  handleClosemessage = () => {
    this.setState({ message: "", messageopen: false });
  };

  copyUrl = (axsLink) => {
    this.setState({ copiedTicketUrl: true });
    if (axsLink) {
      navigator.clipboard.writeText(axsLink);
    }
    navigator.clipboard.writeText(this.state.generatedTicketUrl);
  };
  copyMutipleUrl = (text, index) => {
    navigator.clipboard.writeText(text);
    this.setState({ copiedUrl: true, copiedIndex: index });
  };

  render() {
    const {
      loader,
      events,
      crossCheck,
      formData,
      totalRecords,
      generateTicketData,
    } = this.state;

    const marketplace = [
      { title: "VividSeats" },
      { title: "SeatGeek" },
      { title: "Gametime" },
      { title: "Ticket Evolution" },
      { title: "Ticket Network" },
      { title: "TickPick" },
      { title: "StubHub" },
      { title: "Manual/None" },
      { title: "Other" },
    ];

    var externalRefProps = {};
    if (
      generateTicketData.customerDisplayName === "VividSeats" ||
      generateTicketData.customerDisplayName === "TickPick" ||
      generateTicketData.customerDisplayName === "SeatGeek" ||
      generateTicketData.customerDisplayName === "Gametime"
    ) {
      if (
        generateTicketData.uploadtovivid ||
        generateTicketData.custfromvivid
      ) {
        externalRefProps = {
          validators: ["required"],
          errorMessages: ["this field is required"],
        };
      }
    }

    const modalstyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      maxWidth: 600,
      minWidth: 100,
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 3,
    };

    const generateButtondesign = this.state.bulkActive
      ? {
        margin: "0 10px",
        backgroundColor: "#1b3675c9!important",
      }
      : {
        margin: "0 10px",
      };
    if (!this.state.progressActive) {
      return (
        <React.Fragment>
          <div className="user-dashboard-wrapper">
            <Helmet>
              <title>Interactive Inventory</title>
            </Helmet>
            <Header />
            <div className="c-app c-default-layout user-dashboard-body">
              <Usersidebar />
              <div className="c-wrapper">
                <div className="c-body">
                  <main className="c-main">
                    {/* {loader && <PageLoader />} */}
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Card
                          sx={{
                            p: 1,
                            width: "100%",
                            height: "100%",
                            minHeight: "100vh",
                          }}
                        >
                          <CardHeader
                            title={
                              <Typography
                                sx={{
                                  textAlign: "left",
                                  color: (theme) => theme.palette.primary.main,
                                  fontFamily: "Barlow!important",
                                  fontSize: "26px!important",
                                  fontWeight: "600!important",
                                }}
                              >
                                Interactive Inventory
                              </Typography>
                            }
                            action={
                              <React.Fragment>
                                {this.state.todayActive && (
                                  <Button
                                    sx={{
                                      mr: 2,
                                    }}
                                    variant="outlined"
                                    startIcon={<CachedIcon />}
                                    onClick={() => this.getTodayEvents(true)}
                                  >
                                    Reset
                                  </Button>
                                )}

                                <Button
                                  variant="outlined"
                                  startIcon={<TodayTwoToneIcon />}
                                  onClick={() => this.getTodayEvents(false)}
                                >
                                  Today
                                </Button>
                              </React.Fragment>
                            }
                          />

                          <CardContent>
                            {/* Filters  */}
                            <ValidatorForm
                              onSubmit={this.handleSubmit}
                              className="filter-form mb-5"
                            >
                              <Box sx={{ flexGrow: 1 }}>
                                <Grid
                                  container
                                  spacing={{ xs: 2, md: 2, lg: 2 }}
                                  columns={{ xs: 4, sm: 8, md: 12 }}
                                >
                                  <Grid item xs={12} sm={4} md={3} lg={3}>
                                    <TextField
                                      variant="outlined"
                                      onChange={this.handleChange}
                                      name="event_names"
                                      placeholder="Search Event"
                                      value={formData.event_names}
                                      className="filter-input"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={3} lg={3}>
                                    <DatePicker
                                      placeholderText="MM/DD/YYYY"
                                      selected={this.state.selectedDate}
                                      startDate={this.state.selectedDate}
                                      endDate={this.state.selectedendDate}
                                      monthsShown={this.state.monthsShown}
                                      selectsRange
                                      isClearable={true}
                                      customInput={
                                        <TextField
                                          variant="outlined"
                                          className="filter-input"
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment
                                                position="end"
                                                className="date-icon"
                                              >
                                                <div className="d-flex">
                                                  {crossCheck ? (
                                                    <IconButton
                                                      aria-label="toggle password visibility"
                                                      edge="end"
                                                    >
                                                      <EventIcon className="text-dark" />
                                                    </IconButton>
                                                  ) : (
                                                    <IconButton
                                                      edge="end"
                                                      onClick={() =>
                                                        this.clearDate()
                                                      }
                                                    >
                                                      <ClearIcon className="text-dark" />
                                                    </IconButton>
                                                  )}
                                                </div>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      }
                                      onChange={(dates) =>
                                        this.setStartDate(dates)
                                      }
                                      filterDate={(date) => {
                                        var tdate = new Date();
                                        tdate.setDate(tdate.getDate() - 3);
                                        var dateString = tdate
                                          .toISOString()
                                          .split("T")[0];
                                        return date >= new Date(dateString);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={3} lg={3}>
                                    <Autocomplete
                                      fullWidth
                                      className="filter-input"
                                      name="filterstatusVal"
                                      options={this.state.allvendors}
                                      onChange={this.handleSelectVendor}
                                      getOptionLabel={(option) => option.label}
                                      renderInput={(params) => (
                                        <TextField
                                          value={this.state.vendorVal}
                                          placeholder="Vendor Email"
                                          {...params}
                                          variant="outlined"
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    className="team"
                                    xs={12}
                                    sm={4}
                                    md={2.3}
                                    lg={2.3}
                                  >
                                    <Autocomplete
                                      fullWidth
                                      className="filter-input"
                                      name="filterstatusVal"
                                      options={this.state.allteamlist}
                                      onChange={this.handleSelectTeam}
                                      getOptionLabel={(option) => option.name}
                                      renderInput={(params) => (
                                        <TextField
                                          value={this.state.vendorVal}
                                          placeholder="Search Team"
                                          {...params}
                                          variant="outlined"
                                        />
                                      )}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={4} md={2} lg={2}>
                                    <TextValidator
                                      variant="outlined"
                                      className="filter-input"
                                      onChange={this.handleChange}
                                      name="section"
                                      placeholder="Search Section"
                                      value={formData.section}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={2} lg={2}>
                                    <TextValidator
                                      variant="outlined"
                                      onChange={this.handleChange}
                                      className="filter-input"
                                      name="row"
                                      placeholder="Search Row"
                                      value={formData.row}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={2} lg={2}>
                                    <TextValidator
                                      className="filter-input"
                                      variant="outlined"
                                      onChange={this.handleChange}
                                      name="seat"
                                      placeholder="Search Seat"
                                      value={formData.seat}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={4} md={2} lg={2}>
                                    <Button
                                      variant="contained"
                                      type="submit"
                                      className="filter-btn "
                                      startIcon={<HiOutlineSearch />}
                                    >
                                      Search
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            </ValidatorForm>
                            {/* Bulk Generate   */}
                            {!loader ? (
                              <>
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <Grid item className="text-right" xs={12}>
                                    {this.state.posType === "TV" && (
                                      <Button
                                        variant="contained"
                                        type="button"
                                        size="large"
                                        onClick={() => this.bulkGenerate(true)}
                                        className="theme-btn-submit"
                                        sx={generateButtondesign}
                                        disabled={this.state.bulkActive}
                                      >
                                        Bulk Generate
                                      </Button>
                                    )}
                                    {this.state.posType === "TA" && (
                                      <Button
                                        variant="contained"
                                        type="button"
                                        size="large"
                                        onClick={() => this.bulkGenerate(true)}
                                        className="theme-btn-submit"
                                        sx={generateButtondesign}
                                        disabled={this.state.bulkActive}
                                      >
                                        Bulk Generate
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                                {events.length === 0 && (
                                  <div
                                    style={{ backgroundColor: "#ccc" }}
                                    className="text-center my-5"
                                  >
                                    <h2>
                                      No items{" "}
                                      <svg
                                        width="30"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        className="c-icon c-icon-custom-size text-danger mb-2"
                                        role="img"
                                      >
                                        <path
                                          fill="var(--ci-primary-color, currentColor)"
                                          d="M425.706,86.294A240,240,0,0,0,86.294,425.705,240,240,0,0,0,425.706,86.294ZM256,48A207.1,207.1,0,0,1,391.528,98.345L98.345,391.528A207.1,207.1,0,0,1,48,256C48,141.309,141.309,48,256,48Zm0,416a207.084,207.084,0,0,1-134.986-49.887l293.1-293.1A207.084,207.084,0,0,1,464,256C464,370.691,370.691,464,256,464Z"
                                          className="ci-primary"
                                        ></path>
                                      </svg>
                                    </h2>
                                  </div>
                                )}
                                {events.length > 0 &&
                                  events.map((event, index) => (
                                    <MuiAccordion
                                      key={index}
                                      TransitionProps={{ unmountOnExit: true }}
                                      expanded={
                                        this.state.expanded === `event${index}`
                                      }
                                      onChange={this.handleChangeAccordian(
                                        `event${index}`
                                      )}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        sx={{
                                          marginBottom: "4px!important",
                                        }}
                                        className="interactive-toggle"
                                        onClick={() => {
                                          this.getTickets(index, event.events);
                                          this.setState({
                                            eventVendor:
                                              event.events[0].account_detail
                                                ?.vendor === "AXS"
                                                ? "AXS"
                                                : event.events[0].account_detail
                                                  ?.team,
                                          });
                                        }}
                                      >
                                        <Grid
                                          container
                                          spacing={2}
                                          className="align-items-center"
                                        >
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={4}
                                            lg={5}
                                            className="barinfo"
                                          >
                                            <div className="text-left ">
                                              {event.events[0].event_name}{" "}
                                              <span
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                (
                                                {event.events[0].account_detail
                                                  ?.vendor === "AXS"
                                                  ? "AXS"
                                                  : event.events[0].account_detail
                                                    ?.team}
                                                )
                                              </span>
                                            </div>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={8}
                                            lg={7}
                                            className="barinfo"
                                          >
                                            <Grid
                                              container
                                              direction="row"
                                              justify="space-between"
                                              alignItems="center"
                                            >
                                              <Grid
                                                item
                                                xs={8}
                                                sm={4}
                                                md={4}
                                                lg={4}
                                                className="barinfo"
                                              >
                                                <div
                                                  style={{
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  <img
                                                    src={CalendarIcon}
                                                    style={{ width: "20px" }}
                                                    className="table-data-calendar-icon"
                                                    alt="calendor-icon"
                                                  />
                                                  {customDateFormat(
                                                    event.events[0].date
                                                  )}
                                                </div>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={2}
                                                sm={4}
                                                md={2}
                                                lg={2}
                                                className="barinfo"
                                              >
                                                <div
                                                  style={{
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  <img
                                                    src={TicketsIcon}
                                                    className="table-data-ticket-icon"
                                                    alt="ticket-icon"
                                                  />
                                                  {this.calculateTotalAvailableTickets(
                                                    event.events
                                                  )}
                                                </div>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={2}
                                                sm={4}
                                                md={2}
                                                lg={2}
                                                className="barinfo"
                                              >
                                                <div
                                                  style={{
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  <img
                                                    style={{
                                                      width: "24px",
                                                      marginRight: "5px",
                                                    }}
                                                    src={SoldTicketsIcon}
                                                    alt="location-icon"
                                                  />

                                                  {event.events.reduce(function (
                                                    a,
                                                    b
                                                  ) {
                                                    return a + (b.soldtickets_total ?? 0);
                                                    // return a + b.soldtickets_total;
                                                  },
                                                    0)}
                                                </div>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </AccordionSummary>
                                      <AccordionDetails
                                        sx={{
                                          padding: "0px!important",
                                        }}
                                      >
                                        {!event.tickets ? (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "center",
                                              padding: "2rem",
                                            }}
                                          >
                                            <CircularProgress
                                              sx={{
                                                color: "#011C58",
                                              }}
                                            />
                                          </Box>
                                        ) : (
                                          <>
                                            {this.state.openedevent === index && (
                                              <div className="d-none d-md-block generate-desktop-button">
                                                {generateTicketData.ticketIds
                                                  .length > 0 && (
                                                    <>
                                                      <Button
                                                        onClick={() =>
                                                          this.generateModal(
                                                            true,
                                                            event
                                                          )
                                                        }
                                                        sx={{
                                                          float: "left",
                                                          marginRight: "5px",
                                                          marginTop: "10px!important",
                                                          position: "absolute",
                                                          top: "0px",
                                                          right: "50px",
                                                          backgroundColor:
                                                            "#b60000!important",
                                                          border:
                                                            "1px solid #b60000!important",
                                                          color: "white!important",
                                                          textTransform:
                                                            "none!important",
                                                          fontSize: " 14px!important",
                                                          fontFamily:
                                                            "Barlow!important",
                                                          "&hover": {
                                                            backgroundColor:
                                                              "#b60000!important",
                                                            border:
                                                              "1px solid #b60000!important",
                                                            color: "white!important",
                                                            textTransform:
                                                              "none!important",
                                                            fontSize: " 14px!important",
                                                            fontFamily:
                                                              "Barlow!important",
                                                          },
                                                        }}
                                                      >
                                                        Generate
                                                      </Button>
                                                    </>
                                                  )}
                                              </div>
                                            )}

                                            {this.state.openedevent === index && (
                                              <>
                                                {generateTicketData.ticketIds
                                                  .length > 0 && (
                                                    <>
                                                      <div className="fix-bottom-button">
                                                        <Fab variant="extended"
                                                          onClick={() =>
                                                            this.generateModal(
                                                              true,
                                                              event
                                                            )
                                                          }
                                                          sx={{
                                                            backgroundColor:
                                                              "#b60000!important",
                                                            height: "49px",
                                                            color: "white",
                                                            fontSize: '19px'
                                                          }}
                                                        >
                                                          Generate
                                                        </Fab>
                                                      </div>
                                                    </>
                                                  )}
                                              </>
                                            )}

                                            {this.state.openedevent === index && (
                                              <div className="d-block d-md-none">
                                                {generateTicketData.ticketIds
                                                  .length > 0 && (
                                                    <>
                                                      <Button
                                                        sx={{
                                                          marginRight: "5px",
                                                          marginTop: "10px!important",
                                                          marginBottom:
                                                            "10px!important",
                                                          float: "right",
                                                          backgroundColor:
                                                            "#b60000!important",
                                                          border:
                                                            "1px solid #b60000!important",
                                                          color: "white!important",
                                                          textTransform:
                                                            "none!important",
                                                          fontSize: " 14px!important",
                                                          fontFamily:
                                                            "Barlow!important",
                                                          "&hover": {
                                                            backgroundColor:
                                                              "#b60000!important",
                                                            border:
                                                              "1px solid #b60000!important",
                                                            color: "white!important",
                                                            textTransform:
                                                              "none!important",
                                                            fontSize: " 14px!important",
                                                            fontFamily:
                                                              "Barlow!important",
                                                          },
                                                        }}
                                                        onClick={() =>
                                                          this.generateModal(
                                                            true,
                                                            event
                                                          )
                                                        }
                                                      >
                                                        Generate
                                                      </Button>
                                                    </>
                                                  )}
                                              </div>
                                            )}

                                            {event.tickets.map((ticket) => {
                                              const sorteddata = ticket.tickets
                                                .sort(
                                                  (a, b) =>
                                                    a.section.localeCompare(
                                                      b.section
                                                    ) ||
                                                    parseInt(a.row) -
                                                    parseInt(b.row) ||
                                                    a.row.localeCompare(b.row) ||
                                                    parseInt(a.seat) -
                                                    parseInt(b.seat)
                                                )
                                                .map((ticket) => {
                                                  return {
                                                    ...ticket,
                                                    status: ticket.delivery_revoked
                                                      ? "Revoked/Available"
                                                      : ticket.is_verified
                                                        ? ticket.soldinventory
                                                          ? "Sold"
                                                          : "Available"
                                                        : "Not Verified",
                                                    ticketsurl:
                                                      ticket.ticketurl.length > 0 &&
                                                      ticket.ticketurl[0],
                                                    vendor:
                                                      ticket?.account_detail
                                                        ?.vendor[0],
                                                    username:
                                                      ticket?.account_detail
                                                        ?.username,
                                                  };
                                                });

                                              // Use reduce() to group the tickets by username and row
                                              const groupedTickets =
                                                sorteddata.reduce((acc, ticket) => {
                                                  const key =
                                                    ticket.username +
                                                    "-" +
                                                    ticket.row;
                                                  if (!acc[key]) {
                                                    acc[key] = [];
                                                  }
                                                  acc[key].push(ticket);
                                                  return acc;
                                                }, {});

                                              // Create pairs of rows based on matching username and row values
                                              const pairs = [];
                                              Object.values(groupedTickets).forEach(
                                                (userRowTickets) => {
                                                  const row = userRowTickets[0].row;
                                                  const matchingUserRowTickets =
                                                    Object.values(
                                                      groupedTickets
                                                    ).find(
                                                      (otherUserRowTickets) => {
                                                        return (
                                                          otherUserRowTickets[0]
                                                            .username !==
                                                          userRowTickets[0]
                                                            .username &&
                                                          otherUserRowTickets[0]
                                                            .row === row
                                                        );
                                                      }
                                                    );
                                                  if (matchingUserRowTickets) {
                                                    pairs.push([
                                                      userRowTickets,
                                                      matchingUserRowTickets,
                                                    ]);
                                                  }
                                                }
                                              );

                                              // Check each row for both sold and available tickets with vendor Axs
                                              pairs.forEach(
                                                ([tickets1, tickets2]) => {
                                                  const axsSoldTickets = tickets1
                                                    .concat(tickets2)
                                                    .filter((ticket) => {
                                                      return (
                                                        ticket.vendor === "AXS" &&
                                                        ticket.status === "Sold"
                                                      );
                                                    });
                                                  const axsAvailableTickets =
                                                    tickets1
                                                      .concat(tickets2)
                                                      .filter((ticket) => {
                                                        return (
                                                          ticket.vendor === "AXS" &&
                                                          ticket.status ===
                                                          "Available"
                                                        );
                                                      });
                                                  if (
                                                    axsSoldTickets.length > 0 &&
                                                    axsAvailableTickets.length > 0
                                                  ) {
                                                    axsSoldTickets.forEach(
                                                      (ticket) => {
                                                        if (
                                                          ticket.vendor === "AXS"
                                                        ) {
                                                          ticket.soldstatus = true;
                                                        }
                                                      }
                                                    );
                                                    axsAvailableTickets.forEach(
                                                      (ticket) => {
                                                        if (
                                                          ticket.vendor === "AXS"
                                                        ) {
                                                          ticket.soldstatus = true;
                                                        }
                                                      }
                                                    );
                                                  }
                                                }
                                              );
                                              // Check each row for sold tickets with vendor Axs
                                              Object.values(groupedTickets).forEach(
                                                (rowTickets) => {
                                                  const hasSold = rowTickets.some(
                                                    (ticket) =>
                                                      ticket.status === "Sold" &&
                                                      ticket.vendor === "AXS"
                                                  );

                                                  if (hasSold) {
                                                    rowTickets.forEach((ticket) => {
                                                      if (
                                                        ticket.vendor === "AXS" &&
                                                        ticket.status !== "Sold"
                                                      ) {
                                                        ticket.soldstatus = true;
                                                        ticket.status =
                                                          "Unavailable";
                                                      }
                                                    });
                                                  }
                                                }
                                              );
                                              const finalData = sorteddata.sort(
                                                (a, b) => {
                                                  const statusOrder = {
                                                    Available: 1,
                                                    Unavailable: 2,
                                                    Sold: 3,
                                                  };
                                                  const aStatus =
                                                    statusOrder[a.status] || 0;
                                                  const bStatus =
                                                    statusOrder[b.status] || 0;

                                                  if (aStatus !== bStatus) {
                                                    return aStatus - bStatus;
                                                  } else {
                                                    return 0;
                                                  }
                                                }
                                              );
                                              return (
                                                <>
                                                  <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                      <TableHead>
                                                        <TableRow>
                                                          <StyledTableCells>
                                                            Email
                                                          </StyledTableCells>
                                                          <StyledTableCells>
                                                            Section
                                                          </StyledTableCells>
                                                          <StyledTableCells>
                                                            Row
                                                          </StyledTableCells>
                                                          <StyledTableCells>
                                                            Seat
                                                          </StyledTableCells>
                                                          <StyledTableCells>
                                                            Status
                                                          </StyledTableCells>
                                                          <StyledTableCells>
                                                            Checked
                                                          </StyledTableCells>
                                                          <StyledTableCells>
                                                            <div style={{ width: "100%", minWidth: "max-content", }}>
                                                              View
                                                            </div>
                                                          </StyledTableCells>
                                                        </TableRow>
                                                      </TableHead>
                                                      <TableBody>
                                                        {finalData && finalData?.map((item, index) => (

                                                          <StyledTableRows
                                                            key={index}
                                                          >
                                                            <StyledTableCells component="td" scope="row">
                                                              {item?.username}
                                                            </StyledTableCells>
                                                            <StyledTableCells component="td" scope="row">
                                                              {item?.section}
                                                            </StyledTableCells>
                                                            <StyledTableCells component="td" scope="row">
                                                              {item?.row}
                                                            </StyledTableCells>
                                                            <StyledTableCells component="td" scope="row">
                                                              {item?.seat}
                                                            </StyledTableCells>
                                                            <StyledTableCells component="th" scope="row">
                                                              {item?.status}
                                                            </StyledTableCells>
                                                            <StyledTableCells component="td" scope="row">
                                                              <input
                                                                ref={this.divRef}
                                                                type="checkbox"
                                                                id={`ticket${item?.id}`}
                                                                size="small"
                                                                name="choosetickets"
                                                                className={item?.soldinventory ||
                                                                  (item?.is_verified &&
                                                                    item?.soldstatus !==
                                                                    undefined &&
                                                                    item?.soldstatus) ? 'checked_button_disabled' : "checked_button_enable"}
                                                                disabled={
                                                                  item?.soldinventory ||
                                                                  (item?.is_verified &&
                                                                    item?.soldstatus !==
                                                                    undefined &&
                                                                    item?.soldstatus)
                                                                }
                                                                checked={generateTicketData?.ticketIds?.includes(item.id)}
                                                                onChange={(evt) => this.chooseticket(item, evt, event)}
                                                              />
                                                            </StyledTableCells>
                                                            <StyledTableCells component="td" scope="row">
                                                              <div style={{ width: "100%", minWidth: "max-content", }}>
                                                                {item?.ticketsurl && (
                                                                  <>
                                                                    <Button
                                                                      component="a"
                                                                      size="small"
                                                                      variant="contained"
                                                                      sx={{
                                                                        backgroundColor:
                                                                          "#002986!important",
                                                                        color:
                                                                          "white!important",
                                                                        padding:
                                                                          "2px 5px!important",
                                                                        textTransform:
                                                                          "none!important",
                                                                        fontWeight: "bold",
                                                                        fontFamily:
                                                                          "Barlow!important",
                                                                        marginRight: "5px",
                                                                      }}
                                                                      href={item?.ticketsurl}
                                                                      target="_blank"
                                                                      rel="noreferrer"
                                                                    >
                                                                      Ticket
                                                                    </Button>

                                                                    <Button
                                                                      component="a"
                                                                      size="small"
                                                                      variant="contained"
                                                                      sx={{
                                                                        backgroundColor:
                                                                          "#002986!important",
                                                                        color:
                                                                          "white!important",
                                                                        padding:
                                                                          "2px 5px!important",
                                                                        textTransform:
                                                                          "none!important",
                                                                        fontWeight: "bold",
                                                                        fontFamily:
                                                                          "Barlow!important",
                                                                      }}
                                                                      href={`/sold-inventory?refId=${item?.soldinventory}`}
                                                                      target="_blank"
                                                                      rel="noreferrer"
                                                                    >
                                                                      Sale
                                                                    </Button>
                                                                  </>
                                                                )}
                                                              </div>
                                                            </StyledTableCells>
                                                          </StyledTableRows>
                                                        ))}
                                                      </TableBody>
                                                    </Table>
                                                  </TableContainer>
                                                </>
                                              );
                                            })}
                                          </>
                                        )}
                                      </AccordionDetails>
                                    </MuiAccordion>
                                  ))}
                              </>) : (
                              <ContainerLoader />
                            )}
                            {totalRecords > 0 && (
                              <Pagination
                                key="paginationnew"
                                searchPage={this.state.searchPage}
                                SearchDisable={this.SearchDisable}
                                totalRecords={totalRecords}
                                pageLimit={this.state.formData.limit}
                                onPageChanged={this.onPageChanged}
                              />
                            )}

                            {totalRecords > 0 && (
                              <div
                                style={{
                                  textAlign: "right",
                                  display: "flex",
                                  justifyContent: "end",
                                  alignItems: "center",
                                }}
                              >
                                <label>Items per page :</label>
                                <FormControl
                                  sx={{
                                    m: 1,
                                    minWidth: 50,
                                  }}
                                  size="small"
                                >
                                  <Select
                                    defaultValue={formData.limit}
                                    value={formData.limit}
                                    name="limit"
                                    sx={{
                                      "& .MuiSelect-select": {
                                        padding: "4px 14px",
                                        fontFamily: "Barlow",
                                      },
                                    }}
                                    onChange={(e) => this.changepageLimit(e)}
                                  >
                                    <MenuItem value="10">10</MenuItem>
                                    <MenuItem value="20">20</MenuItem>
                                    <MenuItem value="50">50</MenuItem>
                                    <MenuItem value="100">100</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            )}
                          </CardContent>

                          <Dialog
                            fullWidth={true}
                            scroll="body"
                            maxWidth="lg"
                            open={this.state.generatedModal}
                            onClose={this.generateBulkHide}
                          >
                            <DialogTitle
                              sx={{
                                fontSize: "1.2rem!important",
                              }}
                            >
                              Generated Data
                            </DialogTitle>
                            <DialogContent>
                              <IconButton
                                aria-label="close"
                                onClick={() => this.generateBulkHide()}
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  color: (theme) => theme.palette.grey[500],
                                  p: 1,
                                }}
                              >
                                <CancelIcon />
                              </IconButton>
                              <div
                                style={{
                                  textAlign: "right",
                                  padding: "10px 0px",
                                }}
                              >
                                {this.state.posType === "TA" ? (
                                  <Button
                                    variant="contained"
                                    onClick={() =>
                                      this.exportToCSVACTA(
                                        this.state.bulkGeneratedData
                                      )
                                    }
                                    sx={{
                                      backgroundColor: "#011C58!important",
                                      color: "white!important",
                                      textTransform: "none",
                                      fontWeight: "bold!important",
                                      fontSize: "0.9rem",
                                    }}
                                    startIcon={<FileDownloadIcon />}
                                  >
                                    Download As Csv
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    onClick={() =>
                                      this.exportToCSV(
                                        this.state.bulkGeneratedData
                                      )
                                    }
                                    sx={{
                                      backgroundColor: "#011C58!important",
                                      color: "white!important",
                                      textTransform: "none",
                                      fontWeight: "bold!important",
                                      fontSize: "0.9rem",
                                    }}
                                    startIcon={<FileDownloadIcon />}
                                  >
                                    Download As Csv
                                  </Button>
                                )}
                              </div>

                              <TableContainer component={Paper}>
                                <MuiTable
                                  sx={{ minWidth: 700 }}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell>
                                        Event Name
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        Date
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        Vendor
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        Section
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        Row
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        Seat
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        Ticket Url
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        Copy Url
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.bulkGeneratedData.map(
                                      (row, index) => (
                                        <StyledTableRow key={index}>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            {row.event_name}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {customDateFormat(row.date)}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {row.account}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {row.section}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {row.row}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {row.seat}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            <Link
                                              href={row.ticketUrl}
                                              target="_blank"
                                              underline="none"
                                              sx={{ color: "#202020" }}
                                            >
                                              {row.ticketUrl}
                                            </Link>
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {this.state.copiedUrl &&
                                              this.state.copiedIndex === index ? (
                                              <Button
                                                variant="contained"
                                                sx={{
                                                  backgroundColor:
                                                    "#919191C2!important",
                                                  color: "white!important",
                                                  textTransform: "none",
                                                  fontWeight: "bold!important",
                                                  padding: "6px 8px",
                                                  fontSize: "14px!important",
                                                  ".css-y6rp3m-MuiButton-startIcon":
                                                  {
                                                    marginRight: "3px",
                                                    marginLeft: "3px",
                                                  },
                                                  ".css-y6rp3m-MuiButton-startIcon > :nth-of-type(1)":
                                                  {
                                                    fontSize: "20px",
                                                  },
                                                }}
                                                size="small"
                                                startIcon={
                                                  <LibraryAddCheckIcon />
                                                }
                                              >
                                                Copied
                                              </Button>
                                            ) : (
                                              <Button
                                                variant="contained"
                                                sx={{
                                                  backgroundColor:
                                                    "#011C58!important",
                                                  color: "white!important",
                                                  textTransform: "none",
                                                  fontWeight: "bold!important",
                                                }}
                                                onClick={() =>
                                                  this.copyMutipleUrl(
                                                    row.ticketUrl,
                                                    index
                                                  )
                                                }
                                                startIcon={<ContentCopyIcon />}
                                              >
                                                Copy
                                              </Button>
                                            )}
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      )
                                    )}
                                  </TableBody>
                                </MuiTable>
                              </TableContainer>
                            </DialogContent>
                          </Dialog>
                        </Card>
                      </Grid>
                    </Grid>
                  </main>
                </div>
              </div>
            </div>
            <Footer />

            <Dialog
              scroll="body"
              aria-labelledby="contained-modal-title-vcenter"
              centered="true"
              open={this.state.generateTicketModal}
              onClose={() => this.generatedDetailModalHide()}
            >
              <DialogContent
                sx={{
                  width: "100%",
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={() => this.generatedDetailModalHide()}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: (theme) => theme.palette.grey[500],
                    p: 1,
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <div className="user-info">
                  <ValidatorForm onSubmit={this.submitGenerateTicket}>
                    <div className="custom-pop-over-row">
                      <div className="form-group login-wrapper">
                        {this.state.generateTicketEvent?.events && (
                          <div>
                            <strong>Event Title: </strong>
                            {
                              this.state.generateTicketEvent.events[0]
                                .event_name
                            }
                          </div>
                        )}

                        {this.state.generateTicketEvent?.events && (
                          <div>
                            <strong>Date: </strong>
                            {customDateFormat(
                              this.state.generateTicketEvent.events[0].date
                            )}
                          </div>
                        )}

                        <div>
                          <strong>Tickets: </strong>
                          {generateTicketData.ticketData.map((t, index) => (
                            <div>
                              <span
                                style={{
                                  paddingRight: "15px",
                                  paddingLeft: "5px",
                                }}
                              >
                                Section: {t.section}
                              </span>
                              <span style={{ paddingRight: "15px" }}>
                                Row: {t.row}
                              </span>
                              Seat: {t.seat}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className="custom-pop-over-row">
                          <div className="form-group select-outer Ticket_input">
                            <label>Marketplace</label>
                            <Autocomplete
                              className="Ticket_input_wrap"
                              onChange={(evt, val) =>
                                this.handleModelChangeSelect(
                                  evt,
                                  val,
                                  "customerDisplayName"
                                )
                              }
                              fullWidth
                              name="customerDisplayName"
                              disabled={this.state.generatedTicketDetailModal}
                              options={marketplace}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextValidator
                                  value={generateTicketData.customerDisplayName}
                                  placeholder="Select Marketplace"
                                  {...params}
                                  variant="outlined"
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className="custom-pop-over-row">
                          <div className="form-group login-wrapper">
                            <label>External Reference</label>
                            <TextValidator
                              className="Ticket_input"
                              variant="outlined"
                              onChange={this.handleModelChange}
                              name="invoiceExternalRef"
                              disabled={this.state.generatedTicketDetailModal}
                              value={generateTicketData.invoiceExternalRef}
                              {...externalRefProps}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    {(generateTicketData.customerDisplayName === "VividSeats" ||
                      generateTicketData.customerDisplayName === "TickPick" ||
                      generateTicketData.customerDisplayName === "SeatGeek" ||
                      generateTicketData.customerDisplayName ===
                      "Gametime") && (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={this.handleModelChangeCheckbox}
                                  name="uploadtovivid"
                                  color="primary"
                                />
                              }
                              label={`Upload the link to ${generateTicketData.customerDisplayName}`}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={this.handleModelChangeCheckbox}
                                  name="custfromvivid"
                                  color="primary"
                                />
                              }
                              label={`Get customer details from ${generateTicketData.customerDisplayName}`}
                            />
                          </Grid>
                        </Grid>
                      )}
                    {!generateTicketData.custfromvivid && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <div className="custom-pop-over-row">
                            <div className="form-group login-wrapper">
                              <label>Customer Name</label>
                              <TextValidator
                                className="Ticket_input"
                                variant="outlined"
                                onChange={this.handleModelChange}
                                name="customerName"
                                disabled={this.state.generatedTicketDetailModal}
                                value={generateTicketData.customerName}
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <div className="custom-pop-over-row">
                            <div className="form-group login-wrapper">
                              <label>Customer Email</label>
                              <TextValidator
                                className="Ticket_input"
                                variant="outlined"
                                onChange={this.handleModelChange}
                                name="customerEmail"
                                disabled={this.state.generatedTicketDetailModal}
                                value={generateTicketData.customerEmail}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    )}
                    {!generateTicketData.custfromvivid && (
                      <>
                        {generateTicketData.customerDisplayName !==
                          "Ticket Evolution" && (
                            <div className="custom-pop-over-row">
                              <div className="form-group login-wrapper">
                                <label>Customer Phone</label>
                                <TextValidator
                                  className="Ticket_input"
                                  variant="outlined"
                                  onChange={this.handleModelChange}
                                  name="customerPhone"
                                  disabled={this.state.generatedTicketDetailModal}
                                  value={generateTicketData.customerPhone}
                                />
                              </div>
                            </div>
                          )}
                      </>
                    )}
                    {!generateTicketData.uploadtovivid && (
                      <div className="custom-pop-over-row">
                        <div className="form-group login-wrapper">
                          <label>Send Delivery message</label>
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-form-control-label-placement"
                              name="deliverymsg_time"
                              defaultValue="immediate"
                              onChange={this.handleModelChange}
                            >
                              <FormControlLabel
                                value="immediate"
                                control={<Radio />}
                                label="Immediate"
                              />
                              {/* <FormControlLabel value="48 hours before" control={<Radio color="primary" disabled={true} />} label="48 Hours Before" /> */}
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    )}
                    {this.state.eventVendor === "AXS" &&
                      !this.state.generatedTicketUrl ? (
                      <Alert
                        sx={{
                          marginTop: "26px",
                        }}
                        severity="warning"
                        variant="outlined"
                      >
                        <strong>AXS</strong> links are currently in beta,
                        deliver at your own risk
                      </Alert>
                    ) : null}
                    <Grid container spacing={2} className="custom-pop-over-row">
                      <Grid item xs={12}>
                        {this.state.generatedTicketUrl && (
                          <p
                            className={
                              this.state.errorInFulfillingOrder
                                ? "alert alert-danger"
                                : "alert alert-success "
                            }
                          >
                            <strong>
                              {this.state.errorInFulfillingOrder
                                ? "Sale successfully generated but failed to upload link to marketplace and ticket Url is"
                                : "Sale successfully generated and ticket Url is "}{" "}
                            </strong>
                            <br />
                            <MUiLink
                              component="a"
                              href={this.state.generatedTicketUrl}
                            >
                              {this.state.generatedTicketUrl}
                            </MUiLink>
                            &nbsp;&nbsp;
                            <Button
                              variant="contained"
                              startIcon={<ContentCopyIcon />}
                              sx={{
                                backgroundColor: "#011C58!important",
                                color: "white!important",
                                textTransform: "none",
                                fontWeight: "bold!important",
                                borderRadius: "2px!important",
                                fontFamily: "Barlow!important",
                                marginTop: "5px",
                              }}
                              type="button"
                              onClick={() => this.copyUrl()}
                            >
                              {this.state.copiedTicketUrl ? "Copied" : "Copy"}
                            </Button>
                          </p>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <DialogActions
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                        item
                      >
                        {this.state.generatedTicketDetailModal ? (null) : (
                          <>
                            {this.state.submttingticketform ? (
                              <Button
                                type="button"
                                disabled="disabled"
                                variant="contained"
                                size="large"
                                sx={{
                                  fontSize: "1.1rem!important",
                                  fontWeight: "bold",
                                  color: "black",
                                  bgcolor: grey[400],
                                  textTransform: "capitalize",
                                  padding: "0.6rem 2rem!important",
                                  width: "100%",
                                  maxWidth: "11rem",
                                  "&:hover": {
                                    bgcolor: grey[400],
                                    color: "black",
                                  },

                                  fontFamily: "Barlow  , sans-serif!important",
                                }}
                                autoFocus
                              >
                                Updating..
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{
                                  fontSize: "1.1rem!important",
                                  fontWeight: "bold",
                                  color: "#fff",
                                  bgcolor: "#002986",
                                  textTransform: "capitalize",
                                  padding: "0.6rem 2rem!important",
                                  width: "100%",
                                  maxWidth: "11rem",
                                  fontFamily: "Barlow  , sans-serif!important",
                                  "&:hover": {
                                    bgcolor: "#002986",
                                    color: "#fff",
                                  },
                                }}
                                autoFocus
                              >
                                Update
                              </Button>
                            )}
                          </>
                        )}

                        {this.state.generatedTicketDetailModal ? (
                          <>
                            <Button
                              size="large"
                              sx={{
                                fontSize: "1.1rem!important",
                                fontWeight: "bold",
                                color: "#fff",
                                bgcolor: "#002986",
                                padding: "0.6rem 2rem!important",
                                width: "100%",
                                maxWidth: "11rem",
                                position: 'relative',
                                left: '161px',
                                textTransform: "capitalize",
                                "&:hover": {
                                  bgcolor: "#002986",
                                  color: "#fff",
                                },

                                fontFamily: "Barlow  , sans-serif!important",
                              }}
                              onClick={() => this.generatedDetailModalHide()}
                              autoFocus
                            >
                              Exit
                            </Button>
                          </>
                        ) : (
                          <Button
                            size="large"
                            sx={{
                              fontSize: "1.1rem!important",
                              fontWeight: "bold",
                              color: "#fff",
                              bgcolor: "#002986",
                              padding: "0.6rem 2rem!important",
                              width: "100%",
                              maxWidth: "11rem",
                              marginLeft: "1rem!important",
                              textTransform: "capitalize",
                              "&:hover": {
                                bgcolor: "#002986",
                                color: "#fff",
                              },

                              fontFamily: "Barlow  , sans-serif!important",
                            }}
                            onClick={() => this.generatedDetailModalHide()}
                            autoFocus
                          >
                            Cancel
                          </Button>)}
                      </DialogActions>
                    </Grid>
                  </ValidatorForm>
                  <Collapse in={this.state.generateTicketMsgOpen}>
                    <Alert
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            this.setState({ generateTicketMsgOpen: false });
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      severity={this.state.generateTicketMsgAlertColor}
                      sx={{ mb: 2 }}
                    >
                      {this.state.generateTicketMsg}
                    </Alert>
                  </Collapse>
                </div>
              </DialogContent>
            </Dialog>
            <Dialog
              scroll="body"
              fullWidth={true}
              maxWidth="md"
              centered="true"
              open={this.state.generateTicketModalTV}
              onClose={() => this.generatedDetailModalHideTV()}
            >
              <DialogContent
                sx={{
                  width: "100%",
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={() => this.generatedDetailModalHideTV()}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: (theme) => theme.palette.grey[500],
                    p: 1,
                  }}
                >
                  <CancelIcon />
                </IconButton>

                <div>
                  <ValidatorForm
                    onSubmit={
                      this.state.currentVendor === "AXS"
                        ? this.submitGenerateTicketAXS
                        : this.submitGenerateTicketTV
                    }
                  >
                    <div className="custom-pop-over-row">
                      <div className="form-group login-wrapper">
                        {this.state.generateTicketEvent?.events && (
                          <div>
                            <strong>Event Title: </strong>
                            {
                              this.state.generateTicketEvent.events[0]
                                .event_name
                            }
                          </div>
                        )}

                        {this.state.generateTicketEvent?.events && (
                          <div>
                            <strong>Date: </strong>
                            {customDateFormat(
                              this.state.generateTicketEvent.events[0].date
                            )}
                          </div>
                        )}

                        <div>
                          <strong>Tickets: </strong>
                          <TableContainer
                            component={Paper}
                            sx={{ marginTop: "10px" }}
                          >
                            <MuiTable
                              sx={{ minWidth: 700 }}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Email</StyledTableCell>
                                  <StyledTableCell align="left">
                                    Section
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    Row
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    Seat
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {generateTicketData.ticketData.map(
                                  (t, index) => (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell
                                        component="th"
                                        scope="row"
                                      >
                                        {t.account_detail?.username[0]}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {t.section}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {t.row}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {t.seat}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  )
                                )}
                              </TableBody>
                            </MuiTable>
                          </TableContainer>
                        </div>
                      </div>
                    </div>

                    {this.state.axsSuccess && (
                      <p className="alert alert-success">
                        <strong>
                          "Sale successfully generated and ticket Url is
                        </strong>
                        <br />
                        <MUiLink component="a" href={this.state.axsLink}>
                          {this.state.axsLink}
                        </MUiLink>
                        <Button
                          variant="contained"
                          startIcon={<ContentCopyIcon />}
                          sx={{
                            backgroundColor: "#011C58!important",
                            color: "white!important",
                            textTransform: "none",
                            fontWeight: "bold!important",
                            borderRadius: "2px!important",
                            fontFamily: "Barlow!important",
                            marginTop: "5px",
                            marginLeft: "10px",
                          }}
                          type="button"
                          onClick={() => this.copyUrl()}
                        >
                          {this.state.copiedTicketUrl ? "Copied" : "Copy"}
                        </Button>
                      </p>
                    )}
                    {this.state.eventVendor === "AXS" &&
                      !this.state.axsSuccess ? (
                      <Alert
                        sx={{
                          marginTop: "26px",
                        }}
                        severity="warning"
                        variant="outlined"
                      >
                        <strong>AXS</strong> links are currently in beta,
                        deliver at your own risk
                      </Alert>
                    ) : null}

                    <DialogActions
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      {!this.state.axsSuccess && (
                        <Button
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{
                            fontSize: "0.9rem!important",
                            fontWeight: "bold",
                            color: "#fff",
                            bgcolor: this.state.submttingticketform
                              ? grey[400]
                              : "#002986",
                            textTransform: "capitalize",
                            fontFamily: "Barlow, sans-serif!important",
                            "&:hover": {
                              bgcolor: this.state.submttingticketform
                                ? grey[400]
                                : "#002986",
                              color: "#fff",
                            },
                          }}
                          disabled={
                            this.state.submttingticketform ? true : false
                          }
                          autoFocus
                        >
                          {this.state.submttingticketform
                            ? "Updating.."
                            : "Update"}
                        </Button>
                      )}

                      <Button
                        type="button"
                        size="large"
                        sx={{
                          fontSize: "0.9rem!important",
                          fontWeight: "bold",
                          color: "#fff",
                          bgcolor: "#002986",
                          textTransform: "capitalize",
                          fontFamily: "Barlow  , sans-serif!important",
                          "&:hover": {
                            bgcolor: "#002986",
                            color: "#fff",
                          },
                        }}
                        onClick={() => this.generatedDetailModalHideTV()}
                        autoFocus
                      >
                        {this.state.generatedTicketDetailModal
                          ? "Exit"
                          : "Cancel"}
                      </Button>
                    </DialogActions>
                  </ValidatorForm>
                </div>
              </DialogContent>
            </Dialog>
            <Dialog
              fullWidth={true}
              scroll="body"
              maxWidth="sm"
              open={this.state.bulkConfirm}
              onClose={this.onbulkConfirmHide}
            >
              <DialogTitle>Bulk Generate</DialogTitle>
              <DialogContent>
                <IconButton
                  aria-label="close"
                  onClick={() => this.onbulkConfirmHide()}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: (theme) => theme.palette.grey[500],
                    p: 1,
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <DialogContentText>
                  {this.state.generateCount} links will be generated. Click ok
                  to continue and cancel to exit .
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.onbulkConfirmHide}
                  sx={{
                    backgroundColor: "#002986!important",
                    color: "white!important",
                    fontWeight: "bold",
                    fontFamily: "Barlow",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => this.bulkGenerate(false)}
                  sx={{
                    backgroundColor: "#002986!important",
                    color: "white!important",
                    fontWeight: "bold",
                    fontFamily: "Barlow",
                    textTransform: "none",
                  }}
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={this.state.generateopen}
              onClose={() => this.handleToggleGenerate()}
              closeAfterTransition
            >
              <Fade in={this.state.generateopen}>
                <Box sx={modalstyle}>
                  <IconButton
                    aria-label="close"
                    onClick={() => this.handleToggleGenerate()}
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      color: (theme) => theme.palette.grey[500],
                      p: 1,
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                  <Typography
                    id="transition-modal-title"
                    sx={{
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      color: "#1A237E",
                      textAlign: "left",
                      mb: 2,
                      fontFamily: "Barlow  , sans-serif!important",
                    }}
                    variant="h6"
                    component="h2"
                  >
                    Link Already Generated
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography
                        variant="body1"
                        component="p"
                        sx={{
                          fontSize: "1.1rem",
                          textAlign: "left",
                          fontFamily: "Barlow  , sans-serif!important",
                        }}
                      >
                        {this.state.generateresponsemsg}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <DialogActions>
                        {/* <Button
                          sx={{
                            fontSize: "0.9rem!important",
                            fontWeight: "bold",
                            color: "#fff",
                            bgcolor: indigo[900],
                            textTransform: "capitalize",
                            "&:hover": {
                              bgcolor: indigo[900],
                              color: "#fff",
                            },

                            fontFamily: "Barlow  , sans-serif!important",
                          }}
                          autoFocus
                          onClick={() => this.handleRegenerateTicket()}
                        >
                          Yes
                        </Button> */}
                        <Button
                          sx={{
                            fontSize: "0.9rem!important",
                            fontWeight: "bold",
                            color: "#fff",
                            bgcolor: indigo[900],
                            textTransform: "capitalize",
                            "&:hover": {
                              bgcolor: indigo[900],
                              color: "#fff",
                            },

                            fontFamily: "Barlow  , sans-serif!important",
                          }}
                          onClick={() => {
                            this.handleToggleGenerate();
                            this.generatedDetailModalHide();
                            this.getEvents();
                            this.generatedDetailModalHideTV();
                          }}
                          autoFocus
                        >
                          Exit
                          {/* No */}
                        </Button>
                      </DialogActions>
                    </Grid>
                  </Grid>
                </Box>
              </Fade>
            </Modal>
            <AlertMessage
              open={this.state.messageopen}
              message={this.state.message}
              severity={this.state.messagetype}
              closeCall={this.handleClosemessage}
            />
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="user-dashboard-wrapper">
          <Helmet>
            <title>Interactive Inventory</title>
          </Helmet>
          <Header />
          <div className="c-app c-default-layout user-dashboard-body">
            <Usersidebar />
            <div className="c-wrapper">
              <div className="c-body">
                <main className="c-main">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Card
                        sx={{
                          padding: "30px",
                          width: "100%",
                          height: "100%",
                          minHeight: "100vh",
                        }}
                      >
                        <CardHeader
                          title={
                            <Typography
                              sx={{
                                textAlign: "left",
                                color: (theme) => theme.palette.primary.main,
                                fontFamily: "Barlow!important",
                                fontSize: "26px!important",
                                fontWeight: "600!important",
                              }}
                            >
                              Interactive Inventory
                            </Typography>
                          }
                        />
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Box sx={{ width: "100%" }}>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  sx={{ display: "inline" }}
                                >
                                  Task in progress, please wait
                                </Typography>
                                <LinearProgressWithLabel
                                  value={this.state.progress}
                                />
                                <div
                                  style={{
                                    marginTop: "3rem",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    src={loaderGif}
                                    alt="loader"
                                    style={{
                                      width: "170px",
                                      height: "170px",
                                    }}
                                  />
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </main>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserProfile: state.UserProfile,
    ProgressId: state.Progress,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(UserProfileAction()),
    addId: (id) => dispatch(ProgressAction(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InteractiveInventory);