import React, { Component } from "react";
import "App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "pages/Login/Login";
import UserProfileAction from "redux/actions/UserProfielAction";
import { connect } from "react-redux"
// import SignUp from 'pages/SignUp/SignUp';
import Subscription from "pages/subscription/subscription";
import SubscriptionList from "pages/subscription/subscriptionList";
import CreditPackages from "pages/subscription/creditPackages";
import Billing from "pages/subscription/billing/billing";
import Home from "pages/Home/Home";
import Welcome from "pages/welcome/welcome";
import Forgetpassword from "pages/Forget/Forget";
import Changepass from "pages/Changepassword/Changepass";
import Setuserpassword from "pages/Setuserpassword/Setuserpassword";
import Contact from "pages/contact/contact";
import Whatwedo from "pages/whatWeDo/whatWeDo";
import Success from "pages/subscription/success";
import Thanks from "pages/subscription/thanks";
import Cancel from "pages/subscription/cancel";
// import Alertmessage from 'components/message/message';
// import Header from 'components/header/header';
import Logout from "pages/logOut";
import Profile from "pages/profile/profile";
import BillingInfo from "pages/profile/billinginfo";
import InstructionGuide from "pages/instructionGuide/instructionGuide";
// import history from "./history";
import DashboardLayout from "containers/DashboardLayout";
import CommunicationLayout from "containers/CommunicationLayout";
import TheLayouts from "containers/TheLayout";
import Approute from "components/Approute";
import AdminRoute from "components/Adminroute";
import CommuinicationRoute from "components/CommuinicationRoute";
import Privateroute from "components/Privateroute";
import Dashboard from "containers/dashboard/Dashboard";
import Usermanage from "containers/usermanagement/usermanagement";
import Adduser from "containers/usermanagement/useradd";
import Userview from "containers/usermanagement/userview";
import Useredit from "containers/usermanagement/useredit";
import Contactlist from "containers/contactmanage/contactlist";
import Contactview from "containers/contactmanage/contactview";
import Changepassadmin from "containers/changepassword/changepassword";
import Inventorylist from "containers/interactiveinvent/inventorylist";
// import Inventoryview from 'containers/interactiveinvent/inventoryview';
import Soldinventlist from "containers/soldinvent/soldinventlist";
import Creditpackage from "containers/creditpackages/creditpackages";
import Addcreditpackage from "containers/creditpackages/addcreditpackages";
import Addcustomcredit from "containers/customcredit/addcustomcredit";
import Removecustomcredit from "containers/customcredit/removecustomcredit";
import CreditPackageView from "containers/creditpackages/creditpackageview";
import CreditPackageEdit from "containers/creditpackages/creditpackagesedit";
import Vendor from "containers/terms-management/vendor";
import Teams from "containers/terms-management/teams";
import League from "containers/terms-management/league";
import InternalNotes from "containers/terms-management/internalnotes";
import BlacklistedNumber from "containers/terms-management/blacklistednumbers";
import Notification from "pages/userdashboard/notification";

import AddCommunicationUser from "containers/communicationusers/add";
import EditCommunicationUser from "containers/communicationusers/edit";
import ListCommunicationUser from "containers/communicationusers/list";

import VbdOrigAccounts from "containers/vbdaccounts/vbdaccounts";
import AddVbdAccount from "containers/vbdaccounts/add";
import VbdAccounts from "containers/vbdaccounts/lists";
import EditVbdAccount from "containers/vbdaccounts/edit";

import AdminVendorLists from "containers/uservendors/lists";
import AdminVendorEventslist from "containers/uservendors/vendorevents";
import AdminVendorEventTickets from "containers/uservendors/vendoreventtickets";

import Messageslist from "containers/messages/lists";
import IncomingMessageslist from "containers/messages/IncomingMessagesList";
import SendMessage from "containers/messages/add";

import AutoMessagingFormat from "containers/auto-messaging-format";
import AutoMessagingMailFormat from "containers/auto-messaging-mail-format";
import VbdTicketPrice from "containers/vbd-ticket-price";
import ManageScanLimit from "containers/ManageScanLimit/ManageScanLimit";

import Adminlogin from "containers/login/Adminlogin";
import Logoutadmin from "containers/Logoutadmin";
import Userdashboard from "pages/userdashboard/userdashboard";
import Vendorlist from "pages/userdashboard/accounts/vendor/vendorlist";
import VendorEventslist from "pages/userdashboard/accounts/vendor/vendorevents";
import VendorEventTickets from "pages/userdashboard/accounts/vendor/vendoreventtickets";
import MarketPoslist from "pages/userdashboard/accounts/market&pos/marketposlist";
import SkyboxScanJobs from "pages/userdashboard/accounts/market&pos/skyboxscanjobs";
import Addaccount from "pages/userdashboard/accounts/addaccount/addaccount";
import Addvendor from "pages/userdashboard/accounts/vendor/addVendor";
import Editvendor from "pages/userdashboard/accounts/vendor/editVendor";
import EditMarketPos from "pages/userdashboard/accounts/market&pos/editMarketPos";
import Inventory from "pages/userdashboard/interactiveinventory";
import EventManagement from "pages/userdashboard/EventManagement"
import InventoryPast from "pages/userdashboard/interactiveinventorypast";
import Soldinventory from "pages/userdashboard/soldinventory";
import Addcredit from "pages/userdashboard/addcredit";
import Historypayment from "pages/userdashboard/historypayment";
import Historyusage from "pages/userdashboard/historyusage";
import HistoryAnonymousTransfer from "pages/userdashboard/HistoryAnonymousTransfer";
import UserMessageslist from "pages/userdashboard/usermessageslist";
import Term from "pages/termcondition/term";
import Privacypolicy from "pages/termcondition/privacypolicy";

import Communicationlogin from "containers/login/Communicationlogin";
import CommunicationPanel from "containers/dashboard/CommunicationPanel";
import LogoutCommunication from "containers/LogoutCommunication";
import Message from "containers/dashboard/chatPanel/Message";
import "scss/style.scss";
import TextRecoginition from "containers/messages/TextRecognition";
import ChangeComPassword from "containers/changepassword/changeCompassword";
import ProxyManagement from "containers/browser/proxyManagement";
import Profiles from "containers/browser/profiles";
import AddProxy from "containers/browser/addProxy";
import EditProxy from "containers/browser/editProxy";
import Fingerprint from "containers/browser/fingerprint";
import UserAgent from "containers/browser/useragent";
// instructions
import InstructionSection from "containers/instructionguide/InstructionSection";
//for user
import BrowserLoginDetail from "pages/browser/browserLoginDetail";
import Announcement from "containers/Announcement/Announcement";
import Attendance from "pages/userdashboard/Attendance";
import AttendanceDetails from "pages/userdashboard/attendanceDetails";
import InstructionPages from "containers/instructionguide/InstructionsPages";
import CreditAdded from "containers/tvTab/ManageLinks/CreditAdded";
import CreditUsage from "containers/tvTab/ManageLinks/CreditUsage";
import verifiedTickets from "pages/verifiedTickets/verifiedTickets";
import splitLinks from "containers/splitLinks/splitLinks";
import historyofTickets from "pages/historyofTickets/historyofTickets";
import EditVendorEvents from "pages/userdashboard/accounts/vendor/editVendorEvents";
import FontManagement from "containers/browser/fontManagement";
import AddFont from "containers/browser/addFont";
import EditFont from "containers/browser/editFont";
import AccountLogs from "pages/userdashboard/accounts/vendor/AccountLogs"
import ManageLogs from "containers/uservendors/ManageLogs"
import HistoryofLogs from "pages/userdashboard/HistoryofLogs"
import Historyofpayment from "containers/history/HistoryofPayment"
import ReleaseNotes from "pages/userdashboard/browser/releaseNotes/ReleaseNotes";
import AddReleaseNotes from "containers/browser/releaseNotes";

// TMPasswordManager
import TMPasswordManager from "pages/userdashboard/accounts/tmPasswordManager/TMPasswordManager"
import ManageLicenses from "containers/browser/managelicenses/ManageLicenses";
import LinksUsed from "containers/tvTab/ManageLinks/LinksUsed";
import LinksDelivered from "containers/tvTab/ManageLinks/LinksDelivered";
import LinksDeliveredDetail from "containers/tvTab/ManageLinks/LinksDeliveredDetail";
import LinksUsedDetail from "containers/tvTab/ManageLinks/LinksUsedDetail";
import NewLicensesSummary from "pages/userdashboard/browser/licensesSummary/NewLicensesSummary";
import PurchaseCredit from "pages/userdashboard/browser/purchaseCredit/PurchaseCredit";
import AdminLicensesLogs from "containers/browser/managelicenses/adminlicenseslog/LicensesLogs";
import BrowserLicensesPricing from "containers/browser/manageLicensesPricing/BrowserLicensesPricing";
import HistoryofNotification from "pages/userdashboard/HistoryofNotification";
import ManageBrowser from "containers/browser/manageBrowser/ManageBrowser";
import ManageBrowserDetail from "containers/browser/manageBrowser/ManageBrowserDetail";
import EndUserLicenseAgreement from "containers/browser/endUserLicenseAgreement/EndUserLicenseAgreement";
import EndUserLicenseAgreementUserPanel from "pages/userdashboard/browser/endUserLicenseAgreement/EndUserLicenseAgreementUserPanel";

import WhatsNew from "containers/browser/what'snew/whatsnew";
import Affiliates from "pages/userdashboard/affiliates/Affiliates";
import ManageAffiliates from "containers/manageAffiliates/ManageAffiliates";
import TermsConditions from "containers/Terms-Conditions/TermsConditions";
//Package 
import UsersPackages from "containers/managePackage/UsersPackages";
import PackageManage from "containers/managePackage/PackageManage";
import Packages from "pages/userdashboard/browser/buyPackages/Packages";
import BrowserSessions from "containers/browser/browserSessions/BrowserSessions";

const NotPageFound = () => {
  return (
    <div className="text-center mt-top">
      <h1>Page not found</h1>
    </div>
  );
};
class App extends Component {
  componentDidMount() {
    this.props.getUserProfile();
  }
  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/" component={Home} layout={TheLayouts} />
            <Route
              exact
              path="/verifiedticket"
              component={verifiedTickets}
              layout={TheLayouts}
            />
            <Route
              exact
              path="/historyofTickets"
              component={historyofTickets}
              layout={TheLayouts}
            />

            <Route exact path="/login" component={Login} />
            {/* <Route exact path="/signup" component={SignUp}/> */}
            <Privateroute
              exact
              path="/subscription/:id"
              component={Subscription}
            />

            <Privateroute
              exact
              path="/account-logs/:id"
              component={AccountLogs}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/welcome"
              component={Welcome}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/history-logs"
              component={HistoryofLogs}
              layout={TheLayouts}
            />
            {/* {browser Tab router in the user-panel} */}
            <Privateroute
              exact
              path="/release-notes"
              component={ReleaseNotes}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/packages"
              component={Packages}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/licenses-summary"
              component={NewLicensesSummary}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/purchase-credit"
              component={PurchaseCredit}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/end-user-license-agreement"
              component={EndUserLicenseAgreementUserPanel}
              layout={TheLayouts}
            />
            <Route exact path="/forgetPassword" component={Forgetpassword} />
            <Route
              exact
              path="/changePassword/:userId"
              component={Changepass}
            />
            <Privateroute
              exact
              path="/change-user-password"
              component={Setuserpassword}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/subscription-list"
              component={SubscriptionList}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/add-credit"
              component={CreditPackages}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/billing"
              component={Billing}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/success"
              component={Success}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/thanks"
              component={Thanks}
              layout={TheLayouts}
            />
            <Route exact path="/cancel" component={Cancel} />
            <Route
              exact
              path="/contact"
              component={Contact}
            />
            <Privateroute
              exact
              path="/term-and-condition"
              component={Term}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/privacy-policy"
              component={Privacypolicy}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/contact/request"
              component={Contact}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/what-we-do"
              component={Whatwedo}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/edit-event/:id"
              component={EditVendorEvents}
              layout={TheLayouts}
            />
            <Route exact path="/logout" component={Logout} />
            <Privateroute
              exact
              path="/profile"
              component={Profile}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/tm-password-manager"
              component={TMPasswordManager}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/billing-info"
              component={BillingInfo}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/instruction-guide/:id"
              component={InstructionGuide}
              layout={TheLayouts}
            />
            <AdminRoute
              exact
              path="/manage-browser"
              component={ManageBrowser}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/manage-browser-detail/:month/:year"
              component={ManageBrowserDetail}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/links-used"
              component={LinksUsed}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/links-used-detail/:month/:year"
              component={LinksUsedDetail}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/links-delivered"
              component={LinksDelivered}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/links-delivered-detail/:month/:year"
              component={LinksDeliveredDetail}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/users-packages"
              component={UsersPackages}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/manage-affiliates"
              component={ManageAffiliates}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/split-links"
              component={splitLinks}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/package-management"
              component={PackageManage}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/credit-usage-manage-links"
              component={CreditUsage}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/credit-added-manage-links"
              component={CreditAdded}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/users-management"
              component={Usermanage}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/instruction-management"
              component={InstructionSection}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/instruction-pages"
              component={InstructionPages}
              layout={DashboardLayout}
            />

            <AdminRoute
              exact
              path="/history-of-payment"
              component={Historyofpayment}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/add-user"
              component={Adduser}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/user-management-view/:userid"
              component={Userview}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/user-management-edit/:usereditid"
              component={Useredit}
              layout={DashboardLayout}
            />

            <AdminRoute
              exact
              path="/add-communication-user"
              component={AddCommunicationUser}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/edit-communication-user/:id"
              component={EditCommunicationUser}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/communication-users"
              component={ListCommunicationUser}
              layout={DashboardLayout}
            />

            <AdminRoute
              exact
              path="/add-proxy"
              component={AddProxy}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/edit-proxy/:id"
              component={EditProxy}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/proxy-management"
              component={ProxyManagement}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/font-management"
              component={FontManagement}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/add-font"
              component={AddFont}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/edit-font/:id"
              component={EditFont}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/view-profiles"
              component={Profiles}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/add-release-notes"
              component={AddReleaseNotes}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/add-enduser-license-agreement"
              component={EndUserLicenseAgreement}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/manage-licenses"
              component={ManageLicenses}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/browser-pricing"
              component={BrowserLicensesPricing}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/admin-licenses-logs"
              component={AdminLicensesLogs}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/browser-sessions"
              component={BrowserSessions}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/fingerprint-detail"
              component={Fingerprint}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/useragent-detail"
              component={UserAgent}
              layout={DashboardLayout}
            />

            {/* <AdminRoute exact path="/admin-dashboard" component={Dashboard} layout={DashboardLayout}/> */}
            <AdminRoute
              exact
              path="/admin"
              component={Dashboard}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/contact-management"
              component={Contactlist}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/contact-management-view/:id"
              component={Contactview}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/changepass"
              component={Changepassadmin}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/manage-logs"
              component={ManageLogs}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/interactive-management"
              component={Inventorylist}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/sold-inventory-management"
              component={Soldinventlist}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/credit-packages"
              component={Creditpackage}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/add-credit-packages"
              component={Addcreditpackage}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/add-custom-credit/:id"
              component={Addcustomcredit}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/remove-custom-credit/:id"
              component={Removecustomcredit}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/add-custom-credit"
              component={Addcustomcredit}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/remove-custom-credit"
              component={Removecustomcredit}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/credit-package-view/:id"
              component={CreditPackageView}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/credit-package-edit/:id"
              component={CreditPackageEdit}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/vbdaccounts/accounts"
              component={VbdOrigAccounts}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/vbdaccounts/add"
              component={AddVbdAccount}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/vbdaccounts/lists"
              component={VbdAccounts}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/vbdaccounts/edit/:id"
              component={EditVbdAccount}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/auto-messaging-format"
              component={AutoMessagingFormat}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/auto-messaging-mail-format"
              component={AutoMessagingMailFormat}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/vbd-ticket-price"
              component={VbdTicketPrice}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/manage_scan_limit"
              component={ManageScanLimit}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/messages/list"
              component={Messageslist}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/messages/Incoming-list"
              component={IncomingMessageslist}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/message/send"
              component={SendMessage}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/vendor"
              component={Vendor}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/league"
              component={League}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/team"
              component={Teams}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/internal-notes"
              component={InternalNotes}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/blacklisted-numbers"
              component={BlacklistedNumber}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/announcement"
              component={Announcement}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/what's-new"
              component={WhatsNew}
              layout={DashboardLayout}
            />
            {/* <AdminRoute
              exact
              path="/terms_conditions"
              component={TermsConditions}
              layout={DashboardLayout}
            /> */}
            <AdminRoute
              exact
              path="/terms_conditions"
              component={TermsConditions}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/messages/text-recognition"
              component={TextRecoginition}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/admin-vendor-lists"
              component={AdminVendorLists}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/admin-vendor-events/:id"
              component={AdminVendorEventslist}
              layout={DashboardLayout}
            />
            <AdminRoute
              exact
              path="/admin-vendor-event-tickets/:id"
              component={AdminVendorEventTickets}
              layout={DashboardLayout}
            />

            <Route
              exact
              path="/admin-login"
              component={Adminlogin}
              layout={TheLayouts}
            />
            <AdminRoute
              exact
              path="/adminlogout"
              component={Logoutadmin}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/user-dashboard"
              component={Userdashboard}
              layout={TheLayouts}
            />
            {/*browser Detail for user*/}
            <Privateroute
              exact
              path="/user-browser-login-detail"
              component={BrowserLoginDetail}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/vendor-list"
              component={Vendorlist}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/vendor-events/:id"
              component={VendorEventslist}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/vendor-event-tickets/:id"
              component={VendorEventTickets}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/vendor-list"
              component={Vendorlist}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/market-pos-list"
              component={MarketPoslist}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/skybox-scan-jobs/:id"
              component={SkyboxScanJobs}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/add-account"
              component={Addaccount}
              layout={TheLayouts}
            />
            {/* <Privateroute exact path="/add-account/vendoradd" component={Addaccount} layout={TheLayouts}/> */}
            <Privateroute
              exact
              path="/add-vendor"
              component={Addvendor}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/edit-vendor/:id"
              component={Editvendor}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/edit-market-pos/:id"
              component={EditMarketPos}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/inventory"
              component={Inventory}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/event-management"
              component={EventManagement}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/inventory-past"
              component={InventoryPast}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/sold-inventory"
              component={Soldinventory}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/attendance"
              component={Attendance}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/affiliates"
              component={Affiliates}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/attendance/:id"
              component={AttendanceDetails}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/addcredit"
              component={Addcredit}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/history-payment"
              component={Historypayment}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/history-usage"
              component={Historyusage}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/history-anonymous-transfer"
              component={HistoryAnonymousTransfer}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/notification"
              component={Notification}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/history-notifications"
              component={HistoryofNotification}
              layout={TheLayouts}
            />
            <Privateroute
              exact
              path="/messages-list"
              component={UserMessageslist}
              layout={TheLayouts}
            />

            <Route
              exact
              path="/communication-login"
              component={Communicationlogin}
              layout={TheLayouts}
            />
            <CommuinicationRoute
              exact
              path="/communication-panel"
              component={CommunicationPanel}
              layout={CommunicationLayout}
            />
            <CommuinicationRoute
              exact
              path="/communication-panel-order/:id"
              component={Message}
              layout={CommunicationLayout}
            />
            <CommuinicationRoute
              exact
              path="/communicationlogout"
              component={LogoutCommunication}
              layout={TheLayouts}
            />
            <CommuinicationRoute
              exact
              path="/change-pass"
              component={ChangeComPassword}
              layout={CommunicationLayout}
            />

            <Approute component={NotPageFound} />
          </Switch>
        </Router>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(UserProfileAction()),
  };
};

// export default App;
export default connect(null, mapDispatchToProps)(App);
