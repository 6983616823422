import React from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { jsPDF } from "jspdf";
import { connect } from "react-redux";
import Papa from "papaparse";
import { saveAs } from "file-saver";

// mui icons and svg icons
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { HiOutlineArrowNarrowDown, HiOutlineSearch } from "react-icons/hi";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ArchiveIcon from "@mui/icons-material/Archive";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled, alpha } from "@mui/material/styles";
import ReplayIcon from "@mui/icons-material/Replay";
import CancelIcon from "@mui/icons-material/Cancel";
import { indigo } from "@mui/material/colors";
import CachedIcon from "@mui/icons-material/Cached";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import LoadingButton from "@mui/lab/LoadingButton";
import { BiPlusCircle } from "react-icons/bi";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";

// scss file
import "./proof.scss";
import "./filters.scss";
import "react-datepicker/dist/react-datepicker.css";

// mui components
import { Table as CustomTable } from "react-bootstrap";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  TextField,
  Button,
  Autocomplete,
  Radio,
  RadioGroup,
  Checkbox,
  FormControlLabel,
  Backdrop,
  Box,
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  TableSortLabel,
  InputLabel,
  OutlinedInput,
  IconButton,
  Dialog,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

// import components
import detailTimeFormat from "utils/detailTimeForm";
import getStatus from "utils/getStatus";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import Usersidebar from "components/user-sidebar/user-sidebar";
import Pagination from "components/pagination/pagination";
import PageLoader from "components/PageLoader/PageLoader";
import AlertMessage from "components/message/message";
import customDateFormat from "utils/customDateFormat";
import customDateFormatLocal from "utils/customDateFormatLocal";
import invoiceDateFormat from "utils/invoiceDateFormat";
import UserProfileAction from "redux/actions/UserProfielAction";
import { ContainerLoader } from "components/common/ContainerLoader";
import Cookies from "js-cookie";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const getStyles = (label, filterstatusVal, theme) => {
  return {
    fontWeight:
      filterstatusVal?.indexOf(label) === -1
        ? theme?.typography?.fontWeightRegular
        : theme?.typography?.fontWeightMedium,
  };
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,
        "& .MuiAvatar-root": {
          width: 32,
          height: 32,
          ml: -0.5,
          mr: 1,
        },
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          right: 14,
          width: 10,
          height: 10,
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
      },
    }}
    transformOrigin={{ horizontal: "right", vertical: "top" }}
    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary["dark"],
    color: theme.palette.common.white,
    fontFamily: "Barlow !important",
    fontWeight: "bold",
    textAlign: "left",
    position: "relative",
    width: "100%",
    minWidth: "max-content",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Barlow !important",
    textAlign: "left",
    color: "#3C4E6A",
    borderLeft: "1px solid #CCCCCC",
    borderBottom: "1px solid #CCCCCC",
    width: "auto",
    padding: "5px 14px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const CustomRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
}));

const convertToCSV = (data) => {
  const dataFiltered = data.map((item, index) => {
    const rowValue = typeof item.row === 'number' ? item.row : item.row;
    const seatNumbers = typeof item.seatNumbers === 'number' ? item.seatNumbers : item.seatNumbers;
    const section = typeof item.section === 'number' ? item.section : item.section;
    return {
      "Performer Name": item.account_detail[0].vendor === "Ticketmaster Account Manager"
        ? item.account_detail[0].team
        : item.event && item.event[0].name
          ? item.event[0].name
          : "",
      "Event Title": item.event && item.event[0].name ? item.event[0].name : "",
      EventDateTime: item.event && item.event[0].date ? " " + detailTimeFormat(item.event[0].date) + " " : "",
      Section: '\t' + section,
      Row: '\t' + rowValue,
      Seat: '\t' + seatNumbers,
      Barcode: "",
      "Ticket Url": item.ticketurl,
    };
  });

  return Papa.unparse(dataFiltered);
};

const exportToCSV = (data) => {
  const dataFiltered = data.filter(
    (item) => item.delivery_status !== 11 && item.delivery_status !== 12
  );
  const csv = convertToCSV(dataFiltered);
  const file = new Blob([csv], { type: "text/csv" });
  saveAs(file, "data.csv");
};

const validationOftheformatValue = (value) => {
  return typeof value === ('number' || 'string') ? value ?? "" : value ?? "";
}

const convertToCSVACTA = (data) => {
  const dataFiltered = data.map((item, index) => {
    const date = item.event && item.event[0].date ? " " + detailTimeFormat(item.event[0].date) + " " : "";
    const rowValue = validationOftheformatValue(item?.row);
    const seatNumbers = validationOftheformatValue(item?.seatNumbers);
    const section = validationOftheformatValue(item?.section);
    const game_id = validationOftheformatValue(item.event_detail && item.event_detail[0]?.game_id);
    const barcode = item?.ticket_data
      ? item?.ticket_data?.length === 1
        ? item?.ticket_data?.map((item) => (item.barcode_id ? item.barcode_id + "," : null)).join("")
        : item?.ticket_data?.map((item) => (item.barcode_id ? item.barcode_id + "," : null)).join("")
      : "";
    return {
      "Vendor Email": (item?.account_detail && item?.account_detail[0]?.username) ?? "",
      "Performer Name": item.event ? (item.event[0]?.performer?.name ?? "") : "",
      "Game Title": item.event && item.event[0].name ? item.event[0].name : "",
      "Game ID": item.account_detail && item.account_detail[0].vendor === "Ticketmaster" ? (item.event_detail && item.event_detail[0]?.order_id) ?? "" : '\t' + game_id ?? "",
      "Venue": (item.event && item.event[0]?.venue?.name) ?? "",
      "Event Date": '\t' + Moment(date).format("MM-DD-YYYY") ?? "",
      "Event Time": '\t' + Moment(date).format("HH:mm:ss") ?? "",
      "Section": '\t' + section ?? "",
      "Row": '\t' + rowValue ?? "",
      "Seat": '\t' + seatNumbers ?? "",
      "Barcode": '\t' + barcode?.slice(0, -1),
      "Status": "Available",
      "Ticket Url": item.ticketurl ?? "",
    };
  });
  return Papa.unparse(dataFiltered);
}


const exportToCSVACTA = (data) => {
  const dataFiltered = data.filter(
    (item) => item.delivery_status !== 11 && item.delivery_status !== 12
  );
  const csv = convertToCSVACTA(dataFiltered);
  const file = new Blob([csv], { type: "text/csv" });
  saveAs(file, "data.csv");
};
class Soldinventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      successError: "",
      totalCountries: "",
      open: false,
      message: "",
      soldList: [],
      loader: false,
      dataloader: false,
      allCountries: [],
      currentCountries: [],
      currentPage: 1,
      totalPages: 200,
      pageLimit: 20,
      total: "",
      index: 0,
      event: "",
      section: "",
      row: "",
      seat: "",
      dateVal: "",
      startDate: null,
      endDate: null,
      startDate1: null,
      endDate1: null,
      refId: null,
      filterstatus: [],
      filterstatusVal: [],
      filterref: null,
      filterphone: null,
      sortBy: null,
      sortByOrder: null,
      crossCheck: true,
      endcrossCheck: true,
      modalShow: false,
      userData: "",
      email: "",
      phone: "",
      modelError: "",
      modelSuccess: "",
      id: "",
      ticketurl: null,
      twiliomessages: [],
      tmModel: false,
      tm1Model: false,
      proofModal: false,
      timezone: null,
      currentRow: null,
      pos: [],
      invLogs: [],
      loadLogs: false,
      modalShow1: false,
      modalArchiveShow: false,
      archiveId: null,
      modalConfirmShow: false,
      confirmId: null,
      confirmStatus: null,
      confirmStatusLabel: null,
      searchPage: 0,
      currentColumn: null,
      formData: {
        customerName: "",
        customerEmail: "",
        ticketURL: "",
        customerPhone: "",
        invoiceDate: "",
        team: "",
        section: "",
        rows: "",
        seat: "",
        marketPlace: "",
        uploadtovivid: false,
        custfromvivid: false,
        invoiceExternalRef: "",
        event_name: "",
        eventDate: "",
        tickets: "",
        deliverymsg_time: "immediate",
        fromDate: "",
        toDate: "",
      },
      monthsShown: 2,
      openmenu: false,
      anchorEl: null,
      errorInFulfillingOrder: false,
      confirmFulfill: false,
      fulFillId: null,
      posType: null,
      exportLoader: false,
      exportAllFilter: false,
      direction: "asc",
      fromDate: null,
      toDate: null,
      crosslinkCheck: true,
      exportLoaderTA: false
    };

    this.getTimezone = this.getTimezone.bind(this);
    this.getLists = this.getLists.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.handleChangeEvent = this.handleChangeEvent.bind(this);
    this.handleSearchSection = this.handleSearchSection.bind(this);
    this.handleSearchRow = this.handleSearchRow.bind(this);
    this.handleSeat = this.handleSeat.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.handleCustomerPhone = this.handleCustomerPhone.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setStartDate1 = this.setStartDate1.bind(this);
    this.clearDate = this.clearDate.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleSubmitModal = this.handleSubmitModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStatusFilter = this.handleStatusFilter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onArchivePopupHide = this.onArchivePopupHide.bind(this);
    this.onArchivePopupShow = this.onArchivePopupShow.bind(this);
    this.archiveInventory = this.archiveInventory.bind(this);
    this.onStatusChangePopupHide = this.onStatusChangePopupHide.bind(this);
    this.onStatusChangePopupShow = this.onStatusChangePopupShow.bind(this);
    this.statusChangeInventory = this.statusChangeInventory.bind(this);
    this.downloadPdf = this.downloadPdf.bind(this);
    this.downloadPdf2 = this.downloadPdf2.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleClickMenu = this.handleClickMenu.bind(this);
    this.handleRotateIcon = this.handleRotateIcon.bind(this);
    this.handleToggleFulFill = this.handleToggleFulFill.bind(this);
    this.handlefullFillOrderManually =
      this.handlefullFillOrderManually.bind(this);
    this.handleExportAll = this.handleExportAll.bind(this);
    this.handleExportAllTA = this.handleExportAllTA.bind(this);
    this.copyUrl = this.copyUrl.bind(this);
    this.linkDate = this.linkDate.bind(this);
    this.clearlinkDate = this.clearlinkDate.bind(this);
  }

  componentDidMount() {
    if (window.innerWidth < 768) {
      this.setState({ monthsShown: 1 });
    } else if (window.innerWidth < 768) {
      this.setState({ monthsShown: 2 });
    }
    const search_params = this.props.location.search;
    const url_search_params = new URLSearchParams(search_params);
    const refId = url_search_params.get("refId");
    this.setState({ loader: true, refId: refId }, () => {
      this.getTimezone();
    });
  }

  copyUrl = (url) => {
    navigator.clipboard.writeText(url);
    this.setState({
      open: true,
      message: "URL copied to clipboard",
      successError: "success",
    });
  };

  clearlinkDate = () => {
    if (this.state.fromDate != null) {
      this.setState({ fromDate: null, toDate: null, crosslinkCheck: true });
    } else {
      this.setState({ crosslinkCheck: true });
    }
  };

  handleCloseMenu = () => {
    this.setState({ openmenu: false });
  };

  handleClickMenu = (event, index) => {
    this.setState({ openmenu: index, anchorEl: event.currentTarget });
  };
  getTimezone = () => {
    if (this.props.UserProfile.data) {
      const { formData } = this.state;
      if (this.props.UserProfile.data.deliverymsg_time) {
        formData.deliverymsg_time =
          this.props.UserProfile.data.deliverymsg_time;
      }
      this.setState(
        {
          pageLimit: this.props.UserProfile.data.per_page_limit,
          timezone: this.props.UserProfile.data.timezone,
          formData,
        },
        () => {
          this.getLists();
        }
      );
    }
  };

  handleChange(evt) {
    this.setState({ exportAllFilter: true });
    const { formData } = this.state;
    formData[evt.target.name] = evt.target.value;
    this.setState({ formData });
  }

  SearchDisable = (value) => {
    this.setState({ searchPage: value });
  };

  linkDate = async (dates) => {
    const [start, end] = dates;
    console.log(dates);
    const { formData } = this.state;
    formData.fromDate = dates[0];
    this.setState({
      exportAllFilter: true,
      fromDate: start,
      formData,
      toDate: end,
      crosslinkCheck: false,
    });
  };
  getLists = () => {
    const params = {
      index: this.state.index,
      totalRecord: this.state.pageLimit,
      event_name: this.state.event,
      section: this.state.section,
      row: this.state.row,
      seat: this.state.seat,
      filterref: this.state.filterref,
      filterphone: this.state.filterphone,
      exportCsvTA: false,
      startDate:
        this.state.startDate != null
          ? Moment(this.state.startDate).format("YYYY-MM-DD")
          : "",
      endDate:
        this.state.endDate != null
          ? Moment(this.state.endDate).format("YYYY-MM-DD")
          : "",
      fromDate:
        this.state.fromDate != null
          ? Moment(this.state.fromDate).format("YYYY-MM-DD")
          : "",
      toDate:
        this.state.toDate != null
          ? Moment(this.state.toDate).format("YYYY-MM-DD")
          : "",
      refId: this.state.refId,
      status: this.state.filterstatus,
      sortBy: this.state.sortBy,
      sortByOrder: this.state.sortByOrder,
    };

    const inventoryList_api_url =
      process.env.REACT_APP_API_URL + "/api/inventory/getSoldInventoryUser";
    axios
      .post(inventoryList_api_url, params, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            dataloader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          this.setState({
            totalCountries: data.total,
            posType: data.pos,
          });

          this.setState({
            soldList: data.data,
            loader: false,
            dataloader: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  handleToggleFulFill(id) {
    this.setState({
      confirmFulfill: !this.state.confirmFulfill,
      fulFillId: id,
    });
  }

  async handleRotateIcon(columnname) {
    if (this.state.currentColumn === null) {
      await this.setState({ currentColumn: columnname });
    }
    if (this.state.currentColumn !== columnname) {
      await this.setState({
        activeasc: false,
        activedesc: false,
        currentColumn: columnname,
        styleInactive: false,
        direction: "",
      });
    }
    if (this.state.currentColumn === columnname) {
      if (
        !this.state.styleInactive &&
        !this.state.activeasc &&
        !this.state.activedesc &&
        this.state.currentColumn === columnname
      ) {
        this.customSorting({ column: columnname, asc: true });
        this.setState({
          styleInactive: true,
          currentColumn: columnname,
          direction: "asc",
        });
        console.log("1");
      }
      if (
        this.state.styleInactive &&
        !this.state.activeasc &&
        !this.state.activedesc &&
        this.state.currentColumn === columnname
      ) {
        this.customSorting({ column: columnname, asc: false });
        await this.setState({
          styleInactive: true,
          activeasc: true,
          activedesc: false,
          direction: "desc",
        });
        console.log("2");
      } else if (
        this.state.styleInactive &&
        this.state.activeasc &&
        !this.state.activedesc &&
        this.state.currentColumn === columnname
      ) {
        this.customSorting({ column: columnname, asc: true });
        await this.setState({
          currentColumn: columnname,
          activeasc: false,
          activedesc: false,
          direction: "asc",
        });
        console.log("3");
      }
    }
  }

  onPageChanged = async (data) => {
    const { currentPage, pageLimit } = data;
    this.setState(
      { dataloader: true, index: (currentPage - 1) * pageLimit },
      () => {
        this.getLists();
      }
    );
  };

  handleChangeEvent = (data) => {
    this.setState({ exportAllFilter: true, event: data.target.value });
  };

  handleSearchSection = (data) => {
    this.setState({ exportAllFilter: true, section: data.target.value });
  };

  handleSearchRow = (data) => {
    this.setState({ exportAllFilter: true, row: data.target.value });
  };

  handleSeat = (data) => {
    this.setState({ exportAllFilter: true, seat: data.target.value });
  };

  handleCustomerPhone = (data) => {
    this.setState({ exportAllFilter: true, filterphone: data.target.value });
  };

  handleRef = (data) => {
    this.setState({ exportAllFilter: true, filterref: data.target.value });
  };

  handleChangeDate = (data) => {
    this.setState({ exportAllFilter: true, dateVal: data.target.value });
  };

  setStartDate = async (dates) => {
    const [start, end] = dates;
    const { formData } = this.state;
    formData.invoiceDate = dates[0];
    this.setState({
      exportAllFilter: true,
      startDate: start,
      formData,
      endDate: end,
      crossCheck: false,
    });
  };

  setStartDate1 = async (date) => {
    const { formData } = this.state;
    formData.eventDate = date;
    this.setState({ formData, crossCheck: false });
    /*
        const [start, end] = dates;
        console.log(dates)
        this.state.formData.eventDate=dates[0]
        this.setState({ startDate1: start, formData:this.state.formData, endDate1: end, crossCheck: false })
        */
  };

  clearDate = () => {
    if (this.state.startDate != null) {
      this.setState({ startDate: null, endDate: null, crossCheck: true });
    } else {
      this.setState({ crossCheck: true });
    }
  };

  handleModelChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  openModal = (data) => {
    this.setState({
      modalShow: true,
      userData: data,
      modelSuccess: "",
      modalError: "",
      email: typeof data !== "undefined" ? data.customerEmail : "",
      phone: typeof data !== "undefined" ? data.customerPhone : "",
      id: typeof data !== "undefined" ? data._id : "",
      ticketurl: data.ticketurl ? data.ticketurl : null,
    });
  };

  handleSubmitModal = async (data) => {
    const param = {
      id: this.state.id,
      customer_email: this.state.email,
      customer_phone: this.state.phone,
      ticketurl: this.state.ticketurl,
    };
    const updateSoldInventory_api_url =
      process.env.REACT_APP_API_URL +
      "/api/inventory/updateSoldInventoryCustomerInfo";
    await axios
      .post(updateSoldInventory_api_url, param, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: response.data.message,
            successError: "error",
            modelError: response.data.message,
          });
        } else {
          if (data.success) {
            this.getLists();
            this.onHide();
          }
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  onHide = () => {
    this.setState({ modalShow: false });
  };

  handleClose = (event, reason) => {
    this.setState({
      open: false,
    });
  };

  handleSubmitTicket = () => {
    this.setState({ loader: true }, () => {
      // if(this.state.formData.invoiceDate==="" || this.state.formData.eventDate==="" || this.state.formData.marketPlace===""){
      //     this.setState({ loader: false})
      // }else{
      const addInventory =
        process.env.REACT_APP_API_URL + "/api/user/addInventory";
      axios
        .post(addInventory, this.state.formData, {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.error) {
            console.log(data);
            this.setState({
              loader: false,
              open: true,
              message: data.msg,
              successError: "error",
            });
          } else {
            console.log(data);
            this.setState({
              loader: false,
              open: true,
              message: data.msg,
              successError: "success",
            });
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
        });
      // }
    });
  };

  handleModelChangeCheckbox = (e) => {
    const { formData } = this.state;
    formData[e.target.name] = e.target.checked;
    this.setState({ formData });
  };

  handleStatusFilter(event) {
    const { value } = event.target;
    if (value) {
      this.setState({
        filterstatus: value,
        filterstatusVal: typeof value === "string" ? value.split(",") : value,
        exportAllFilter: true,
      });
    } else {
      this.setState({
        filterstatus: [],
        filterstatusVal: [],
        exportAllFilter: true,
      });
    }
  }

  handleSubmit() {
    const { formData } = this.state;
    formData["skip"] = 0;
    this.setState(
      { index: 0, searchPage: 1, formData, dataloader: true, refId: null },
      () => {
        this.getLists();
        this.setState({ exportAllFilter: false });
      }
    );
  }

  updateProfile() {
    this.setState({ loader: true }, () => {
      const updateDetail_api_url =
        process.env.REACT_APP_API_URL + "/api/user/updateUser";
      axios
        .post(
          updateDetail_api_url,
          { per_page_limit: this.state.pageLimit },
          {
            headers: {
              authorization: "Bearer " + Cookies.get("accessToken"),
            },
          }
        )
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "error",
            });
          } else {
            this.props.getUserProfile();
            this.setState({
              open: true,
              message: data.message,
              successError: "success",
            });
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
        });
    });
  }

  changePageLimit = (e) => {
    this.setState(
      { searchPage: 1, pageLimit: parseInt(e.target.value) },
      () => {
        this.handleSubmit();
        this.updateProfile();
      }
    );
  };

  opentmModel = (data) => {
    const twiliomessagedownload = data.twiliomessages
      .sort((a, b) => new Date(b.sentat) - new Date(a.sentat))
      .map((tm) => {
        var ret = {};
        if (tm.sms_type) {
          ret.Status =
            tm.sms_type.charAt(0).toUpperCase() + tm.sms_type.slice(1);
        } else {
          ret.Status = "Delivered";
        }
        if (tm.sms_type === "received") {
          ret.PhoneNumber = tm.sentby;
        } else {
          ret.PhoneNumber = tm.phone_number;
        }
        ret.Message = tm.message_body;
        ret.SentDate = customDateFormat(tm.sentat);
        return ret;
      });
    this.setState({
      tmModel: true,
      twiliomessages: data.twiliomessages,
      twiliomessagedownload,
    });
  };

  getLogsByInventory(id) {
    this.setState({ tm1Model: true, loadLogs: true });
    const msg_by_twelio_api_url =
      process.env.REACT_APP_API_URL + "/api/inventory/getInventoryStatuses";
    axios
      .post(
        msg_by_twelio_api_url,
        { inventory_id: id._id },
        {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        }
      )
      .then((response) => {
        if (response.data) {
          this.setState({
            invLogs: response.data.lists,
            loadLogs: false,
            currentRow: id,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleExportAll = () => {
    this.setState({ exportLoader: true });
    const params = {
      exportAll: true,
      index: this.state.index,
      totalRecord: this.state.pageLimit,
      event_name: this.state.event,
      section: this.state.section,
      row: this.state.row,
      seat: this.state.seat,
      filterref: this.state.filterref,
      filterphone: this.state.filterphone,
      exportCsvTA: false,
      startDate:
        this.state.startDate != null
          ? Moment(this.state.startDate).format("YYYY-MM-DD")
          : "",
      endDate:
        this.state.endDate != null
          ? Moment(this.state.endDate).format("YYYY-MM-DD")
          : "",
      fromDate:
        this.state.fromDate != null
          ? Moment(this.state.fromDate).format("YYYY-MM-DD")
          : "",
      toDate:
        this.state.toDate != null
          ? Moment(this.state.toDate).format("YYYY-MM-DD")
          : "",
      refId: this.state.refId,
      status: this.state.filterstatus,
      sortBy: this.state.sortBy,
      sortByOrder: this.state.sortByOrder,
    };

    const inventoryList_api_url =
      process.env.REACT_APP_API_URL + "/api/inventory/getSoldInventoryUser";
    axios
      .post(inventoryList_api_url, params, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            dataloader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          console.log(data);
          exportToCSV(data.data);
          this.setState({ exportLoader: false });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  handleExportAllTA = () => {
    this.setState({ exportLoader: true });
    const params = {
      index: this.state.index,
      totalRecord: this.state.pageLimit,
      event_name: this.state.event,
      section: this.state.section,
      row: this.state.row,
      seat: this.state.seat,
      filterref: this.state.filterref,
      filterphone: this.state.filterphone,
      exportCsvTA: true,
      startDate:
        this.state.startDate != null
          ? Moment(this.state.startDate).format("YYYY-MM-DD")
          : "",
      endDate:
        this.state.endDate != null
          ? Moment(this.state.endDate).format("YYYY-MM-DD")
          : "",
      fromDate:
        this.state.fromDate != null
          ? Moment(this.state.fromDate).format("YYYY-MM-DD")
          : "",
      toDate:
        this.state.toDate != null
          ? Moment(this.state.toDate).format("YYYY-MM-DD")
          : "",
      refId: this.state.refId,
      status: this.state.filterstatus,
      sortBy: this.state.sortBy,
      sortByOrder: this.state.sortByOrder,
    };

    const inventoryList_api_url =
      process.env.REACT_APP_API_URL + "/api/inventory/getSoldInventoryUser";
    axios
      .post(inventoryList_api_url, params, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            dataloader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          exportToCSVACTA(data.data);
          this.setState({ exportLoader: false });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };
  // Function to get Twilio Messages
  getTwilioMessages = (data, modalName) => {
    const ct = data;
    this.setState({ currentRow: ct });
    const id = data._id;
    const getTwiliomsg =
      process.env.REACT_APP_API_URL + "/api/inventory/getTiwilioMsgSoldInv";
    axios
      .post(
        getTwiliomsg,
        { _id: id },
        {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        }
      )
      .then((response) => {
        if (response.data) {
          if (modalName === "opentmModel") {
            this.opentmModel(response.data.data);
          } else if (modalName === "proofModal") {
            this.proofModal(response.data.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  proofModal(data) {
    const twiliomessagedownload = data.twiliomessages
      .sort((a, b) => new Date(b.sentat) - new Date(a.sentat))
      .map((tm) => {
        var ret = {};
        if (tm.sms_type) {
          ret.Status =
            tm.sms_type.charAt(0).toUpperCase() + tm.sms_type.slice(1);
        } else {
          ret.Status = "Delivered";
        }
        if (tm.sms_type === "received") {
          ret.PhoneNumber = tm.sentby;
        } else {
          ret.PhoneNumber = tm.phone_number;
        }
        ret.Message = tm.message_body;
        ret.SentDate = customDateFormat(tm.sentat);
        return ret;
      });
    this.setState({
      proofModal: true,
      twiliomessages: data.twiliomessages,
      twiliomessagedownload,
    });
  }

  onArchivePopupShow(inv) {
    this.setState({ archiveId: inv._id, modalArchiveShow: true });
  }

  onArchivePopupHide() {
    this.setState({ archiveId: null, modalArchiveShow: false });
  }

  archiveInventory(inv) {
    this.setState({ loader: true }, () => {
      const archive_api_url =
        process.env.REACT_APP_API_URL + "/api/inventory/archiveInventory";
      axios
        .post(
          archive_api_url,
          { inventory_id: this.state.archiveId },
          {
            headers: {
              authorization: "Bearer " + Cookies.get("accessToken"),
            },
          }
        )
        .then((response) => {
          this.setState({ archiveId: null, modalArchiveShow: false });
          this.getLists();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  onStatusChangePopupShow(inv, newStatus, statusLabel) {
    this.setState({
      confirmId: inv._id,
      modalConfirmShow: true,
      confirmStatus: newStatus,
      confirmStatusLabel: statusLabel,
    });
  }

  onStatusChangePopupHide() {
    this.setState({
      confirmId: null,
      modalConfirmShow: false,
      confirmStatus: null,
      confirmStatusLabel: null,
    });
  }
  statusChangeInventory() {
    this.setState({ loader: true }, () => {
      const archive_api_url =
        process.env.REACT_APP_API_URL + "/api/inventory/updateDeliveryStatus";
      axios
        .post(
          archive_api_url,
          {
            inventory_id: this.state.confirmId,
            status: this.state.confirmStatus,
          },
          {
            headers: {
              authorization: "Bearer " + Cookies.get("accessToken"),
            },
          }
        )
        .then((response) => {
          this.setState({
            confirmId: null,
            modalConfirmShow: false,
            confirmStatus: null,
            confirmStatusLabel: null,
          });
          this.getLists();
        })
        .catch((err) => {
          this.setState({
            confirmId: null,
            modalConfirmShow: false,
            confirmStatus: null,
            confirmStatusLabel: null,
          });
          console.log(err);
        });
    });
  }

  handlefullFillOrderManually = () => {
    this.setState({ loader: true }, () => {
      const fulfill_api_url =
        process.env.REACT_APP_API_URL + "/api/inventory/manually_fulfil";
      axios
        .post(
          fulfill_api_url,
          { _id: this.state.fulFillId },
          {
            headers: {
              authorization: "Bearer " + Cookies.get("accessToken"),
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.setState({
            fulFillId: null,
            confirmFulfill: false,
            open: true,
            message: response.data.message,
            successError: response.data.error ? "error" : "success",
          });
          if (!response.data.error) {
            this.getLists();
          } else {
            this.setState({ loader: false });
          }
        })
        .catch((error) => {
          console.log("errrr", error.toJSON());
          this.setState({
            loader: false,
            fulFillId: null,
            confirmFulfill: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        });
    });
  };

  tmModelonHide = () => {
    this.setState({ tmModel: false, twiliomessages: [] });
  };

  proofModalHide = () => {
    this.setState({ proofModal: false, twiliomessages: [] });
  };

  tm1ModelonHide = () => {
    this.setState({ tm1Model: false, twiliomessages: [] });
  };

  add = () => {
    this.setState({ modalShow1: true });
  };

  onHide1 = () => {
    this.setState({ modalShow1: false });
  };

  handleModelChangeSelect = (evt, val, name) => {
    const { formData } = this.state;
    if (val) {
      formData["marketPlace"] = val.title;
    } else {
      formData["marketPlace"] = null;
    }
    formData.uploadtovivid = false;
    formData.custfromvivid = false;
    this.setState({ formData });
  };

  downloadAsPdf = () => {
    const headers = [
      {
        id: "Status",
        name: "Status",
        prompt: "Status",
        width: 45,
        align: "center",
        padding: 0,
      },
      {
        id: "Message",
        name: "Message",
        prompt: "Message",
        width: 220,
        align: "center",
        padding: 0,
      },
      {
        id: "SentDate",
        name: "SentDate",
        prompt: "Sent Date",
        width: 65,
        align: "center",
        padding: 0,
      },
    ];

    /*
            { id: "PhoneNumber", name: "PhoneNumber", prompt: "Phone Number", width: 55, align: "center", padding: 0 },
        */

    var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape" });

    doc.setFontSize(12);

    var phone_number =
      this.state.twiliomessages[0].sms_type === "received"
        ? this.state.twiliomessages[0].sentby
        : this.state.twiliomessages[0].phone_number;

    doc.setFont("courier", "bold");
    doc.text("Event: ", 10, 20);
    doc.setFont("courier", "normal");
    doc.text(this.state.currentRow.event[0].name, 27, 20);

    doc.setFont("courier", "bold");
    doc.text("Event Date: ", 10, 26);
    doc.setFont("courier", "normal");
    doc.text(customDateFormat(this.state.currentRow.event[0].date), 39, 26);

    doc.setFont("courier", "bold");
    doc.text("Marketplace: ", 10, 32);
    doc.setFont("courier", "normal");
    doc.text(this.state.currentRow.customerDisplayName, 42, 32);

    doc.setFont("courier", "bold");
    doc.text("External Ref: ", 10, 38);
    doc.setFont("courier", "normal");
    doc.text(this.state.currentRow.invoiceExternalRef, 45, 38);

    doc.setFont("courier", "bold");
    doc.text("Date: ", 10, 44);
    doc.setFont("courier", "normal");
    doc.text(customDateFormat(this.state.currentRow.invoiceDate), 25, 44);

    doc.setFont("courier", "bold");
    doc.text("Team: ", 10, 50);
    doc.setFont("courier", "normal");
    doc.text(this.state.currentRow.event[0].name, 32, 50);

    doc.setFont("courier", "bold");
    doc.text("Section: ", 10, 55);
    doc.setFont("courier", "normal");
    doc.text(this.state.currentRow.section, 32, 55);

    doc.setFont("courier", "bold");
    doc.text("Row: ", 55, 55);
    doc.setFont("courier", "normal");
    doc.text(this.state.currentRow.row, 67, 55);

    doc.setFont("courier", "bold");
    doc.text("Seat: ", 95, 55);
    doc.setFont("courier", "normal");
    doc.text(this.state.currentRow.seatNumbers, 109, 55);

    doc.setFont("courier", "bold");
    doc.text("Phone Number: ", 10, 60);
    doc.setFont("courier", "normal");
    doc.text(phone_number, 45, 60);
    console.log("this.state.twiliomessages", this.state.twiliomessages);

    doc.table(10, 66, this.state.twiliomessagedownload, headers, {
      autoSize: false,
    });

    doc.save("send-messages.pdf");
  };
  // download Proof as pdf

  downloadPdf() {
    var doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "tabloid",
      putOnlyUsedFonts: true,
    });
    doc.setFontSize(35);
    doc.setDrawColor(212, 211, 211);
    doc.rect(4, 4, 424, 60); // empty square
    doc.setFont("Helvetica", "bold");
    doc.text(this.state.currentRow.event[0].name, 10, 25);

    doc.setFont("Helvetica", "normal");
    doc.setFontSize(20);
    doc.setTextColor(71, 71, 71);

    doc.text(
      customDateFormat(this.state.currentRow.event[0].dateformatted),
      10,
      44
    );
    doc.text(document.getElementById("adress").innerText, 10, 54);
    doc.rect(4, 64, 424, 25);

    doc.setFont("Helvetica", "bold");
    doc.text("Section: ", 10, 79);
    doc.setFont("Helvetica", "bold");
    doc.text(this.state.currentRow.section, 39, 79);

    doc.setFont("Helvetica", "bold");
    doc.text("Row: ", 70, 79);
    doc.setFont("Helvetica", "bold");
    doc.text(this.state.currentRow.row, 88, 79);


    doc.setFont("Helvetica", "bold");
    doc.text("Seat: ", 110, 79);
    doc.setFont("Helvetica", "bold");
    doc.text(this.state.currentRow.seatNumbers, 130, 79);

    doc.setFont("Helvetica", "bold");
    doc.text("Transfer Status: ", 6, 98);
    doc.setFont("Helvetica", "bold");
    doc.text(document.getElementById("tm").innerText, 63, 98);

    doc.setTextColor(71, 71, 71);
    doc.setFont("Helvetica", "normal");
    doc.text(document.getElementById("ticket-text").innerText, 6, 110);

    doc.save(
      this.state.currentRow?.invoiceExternalRef
        ? this.state.currentRow?.invoiceExternalRef + ".pdf"
        : "proof.pdf"
    );
  }

  downloadPdf2() {
    var doc = new jsPDF({
      orientation: "landscape",

      unit: "mm",
      format: "tabloid",
      putOnlyUsedFonts: true,
    });

    doc.setFontSize(35);
    doc.setDrawColor(212, 211, 211);
    doc.rect(4, 4, 424, 140); // empty square
    doc.setFont("Helvetica", "bold");
    doc.text(this.state.currentRow.event[0].name, 20, 25);

    var imgCalendor = require("images/user-dashboard/calendor-icon.jpg");
    var imgLocation = require("images/user-dashboard/location.jpg");

    doc.setFont("Helvetica", "normal");
    doc.setFontSize(20);
    doc.setTextColor(71, 71, 71);
    doc.addImage(imgCalendor, "JPG", 20, 37, 7, 8);

    doc.setTextColor(0, 0, 0);
    doc.text(document.getElementById("icon-date").innerText, 30, 44);
    doc.addImage(imgLocation, "JPG", 20, 50, 7, 8);

    doc.text(document.getElementById("adress2").innerHTML, 30, 56);

    doc.setDrawColor(97, 97, 97);
    doc.setLineWidth(1);

    doc.line(20, 70, 412, 70);
    doc.setFontSize(28);
    doc.setTextColor(97, 97, 97);
    doc.setFont("Helvetica", "bold");
    doc.text("TRANSFERRED TICKETS", 21, 87);
    doc.line(20, 96, 412, 96);

    doc.setFontSize(20);
    var imgPdfIcon = require("images/user-dashboard/pdfIcon.jpg");
    doc.addImage(imgPdfIcon, "JPG", 18, 100, 10, 13);
    doc.setTextColor(0, 0, 0);
    doc.setFont("Helvetica", "bold");
    doc.text("Section: ", 33, 108);
    doc.setFont("Helvetica", "bold");
    doc.text(this.state.currentRow.section, 61, 108);

    doc.setFont("Helvetica", "bold");
    doc.text("Row: ", 92, 108);
    doc.setFont("Helvetica", "bold");
    doc.text(this.state.currentRow.row, 110, 108);

    doc.setFont("Helvetica", "bold");
    doc.text("Seat: ", 128, 108);
    doc.setFont("Helvetica", "bold");
    doc.text(this.state.currentRow.seatNumbers, 146, 108);

    doc.setFont("Helvetica", "bold");
    doc.text(document.getElementById("trf-ticket").innerText, 33, 118);

    doc.line(20, 125, 412, 125);

    doc.save(
      this.state.currentRow?.invoiceExternalRef
        ? this.state.currentRow?.invoiceExternalRef + ".pdf"
        : "proof.pdf"
    );
  }
  customSorting = (obj) => {
    console.log(obj);
    if (obj) {
      if (obj.column) {
        const { formData } = this.state;
        formData["skip"] = 0;
        this.setState(
          {
            formData,
            dataloader: true,
            sortBy: obj.column,
            sortByOrder: obj.asc ? "asc" : "desc",
          },
          () => {
            this.getLists();
          }
        );
      }
    }
  };

  handleClearSelection = () => {
    this.setState({ filterstatusVal: [], filterstatus: [] });
  };

  handleExportToCSVTA = () => {
    this.setState({ exportLoaderTA: true });
    const params = {
      index: this.state.index,
      totalRecord: this.state.pageLimit,
      event_name: this.state.event,
      section: this.state.section,
      row: this.state.row,
      seat: this.state.seat,
      filterref: this.state.filterref,
      filterphone: this.state.filterphone,
      exportCsvTA: true,
      startDate:
        this.state.startDate != null
          ? Moment(this.state.startDate).format("YYYY-MM-DD")
          : "",
      endDate:
        this.state.endDate != null
          ? Moment(this.state.endDate).format("YYYY-MM-DD")
          : "",
      fromDate:
        this.state.fromDate != null
          ? Moment(this.state.fromDate).format("YYYY-MM-DD")
          : "",
      toDate:
        this.state.toDate != null
          ? Moment(this.state.toDate).format("YYYY-MM-DD")
          : "",
      refId: this.state.refId,
      status: this.state.filterstatus,
      sortBy: this.state.sortBy,
      sortByOrder: this.state.sortByOrder,
    };

    const inventoryList_api_url =
      process.env.REACT_APP_API_URL + "/api/inventory/getSoldInventoryUser";
    axios
      .post(inventoryList_api_url, params, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            dataloader: false,
            exportLoaderTA: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          exportToCSVACTA(data.data);
          this.setState({ exportLoaderTA: false });
        }
      })
      .catch((error) => {
        this.setState({
          exportLoaderTA: false,
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };


  render() {
    const { filterstatusVal } = this.state;
    const marketplace = [
      { title: "VividSeats" },
      { title: "TickPick" },
      { title: "SeatGeek" },
      { title: "Gametime" },
      { title: "Ticket Evolution" },
      { title: "Ticket Network" },
      { title: "StubHub" },
      { title: "Manual/None" },
      { title: "Other" },
    ];
    const { loader, dataloader, totalCountries, pageLimit, modalArchiveShow } =
      this.state;

    // const sorterFields = { invoiceDate: true, team: true, section: true, invoiceExternalRef: true, eventDate: true }

    const filterstatus = [
      { value: 0, label: "No message sent" },
      { value: 1, label: "Sold message sent" },
      { value: 2, label: "Delivery message sent" },
      { value: 3, label: "Confirmation message sent" },
      { value: 4, label: "Order Confirmed" },
      { value: 5, label: "Problem in Order" },
      { value: 7, label: "Need Manual Review" },
      { value: 8, label: "Phone number Blacklisted" },
      { value: 9, label: "Phone number Invalid" },
      { value: 11, label: "Manually Transferred" },
      { value: 12, label: "Delivery revoked" },
      { value: 13, label: "Ticket Uploaded" },
      { value: 14, label: "Link Generated" },
      { value: 15, label: "Event Reminder" },
      { value: 16, label: "Ticket Not Uploaded" },
      { value: 17, label: "Account Deleted" },
    ];
    const linkdesign = {
      color: "#002986!important",

      fontFamily: "Barlow",
      fontWeight: "500",
      svg: {
        color: "#002986!important",
        fontSize: "1.3rem!important",
      },
      ":hover": {
        color: "#b60000!important",
        svg: {
          color: "#b60000!important",
        },
      },
    };

    return (
      <div className="user-dashboard-wrapper">
        <Helmet>
          <title>Sold Inventory</title>
        </Helmet>
        <Header />
        <div className="c-app c-default-layout user-dashboard-body">
          <Usersidebar />
          <div className="c-wrapper">
            <div className="c-body">
              <main className="c-main">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader title="Sold Inventory" />
                      <CardContent className="plan-tbl sold-invent-outer">
                        <ValidatorForm
                          // ref="form"
                          onSubmit={this.handleSubmit}
                          className="filter-form mb-2"
                        >
                          <Box sx={{ flexGrow: 1 }}>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 2, lg: 2 }}
                              columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                              <Grid item xs={12} sm={4} md={2} lg={2}>
                                <TextField
                                  variant="outlined"
                                  onChange={this.handleChangeEvent}
                                  name="event_name"
                                  value={this.state.event}
                                  placeholder="Search Event"
                                  className="filter-input"
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={3}>
                                <DatePicker
                                  placeholderText="Search  Date"
                                  selected={this.state.fromDate}
                                  startDate={this.state.fromDate}
                                  endDate={this.state.toDate}
                                  monthsShown={this.state.monthsShown}
                                  selectsRange
                                  isClearable={true}
                                  customInput={
                                    <TextField
                                      variant="outlined"
                                      className="filter-input"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            position="end"
                                            className="date-icon"
                                          >
                                            <div className="d-flex">
                                              {this.state.crosslinkCheck ? (
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  edge="end"
                                                >
                                                  <EventIcon className="text-dark" />
                                                </IconButton>
                                              ) : (
                                                <IconButton
                                                  edge="end"
                                                  onClick={() =>
                                                    this.clearlinkDate()
                                                  }
                                                >
                                                  <ClearIcon className="text-dark" />
                                                </IconButton>
                                              )}
                                            </div>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  }
                                  onChange={(dates) => this.linkDate(dates)}
                                // filterDate={(date) => {
                                //   var tdate = new Date();
                                //   tdate.setDate(tdate.getDate() - 1);
                                //   var dateString = tdate
                                //     .toISOString()
                                //     .split("T")[0];

                                //   return date >= new Date(dateString);
                                // }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={3}>
                                <DatePicker
                                  placeholderText="Search Event Date"
                                  selected={this.state.startDate}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  monthsShown={this.state.monthsShown}
                                  selectsRange
                                  isClearable={true}
                                  customInput={
                                    <TextField
                                      variant="outlined"
                                      className="filter-input"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            position="end"
                                            className="date-icon"
                                          >
                                            <div className="d-flex">
                                              {this.state.crossCheck ? (
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  edge="end"
                                                >
                                                  <EventIcon className="text-dark" />
                                                </IconButton>
                                              ) : (
                                                <IconButton
                                                  edge="end"
                                                  onClick={() =>
                                                    this.clearDate()
                                                  }
                                                >
                                                  <ClearIcon className="text-dark" />
                                                </IconButton>
                                              )}
                                            </div>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  }
                                  onChange={(dates) =>
                                    this.setStartDate(dates)
                                  }
                                // filterDate={(date) => {
                                //   var tdate = new Date();
                                //   tdate.setDate(tdate.getDate() - 1);
                                //   var dateString = tdate
                                //     .toISOString()
                                //     .split("T")[0];

                                //   return date >= new Date(dateString);
                                // }}
                                />
                              </Grid>

                              <Grid item xs={12} sm={4} md={2} lg={2}>
                                <TextValidator
                                  variant="outlined"
                                  className="filter-input"
                                  onChange={this.handleSearchSection}
                                  name="section"
                                  placeholder="Search Section"
                                  value={this.state.section}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={2} lg={2}>
                                <TextValidator
                                  variant="outlined"
                                  onChange={this.handleSearchRow}
                                  className="filter-input"
                                  name="rows"
                                  placeholder="Search Row"
                                  value={this.state.row}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={2} lg={2}>
                                <TextValidator
                                  className="filter-input"
                                  variant="outlined"
                                  onChange={this.handleSeat}
                                  name="seat"
                                  placeholder="Search Seat"
                                  value={this.state.seat}
                                />
                              </Grid>
                              <Grid
                                item
                                className="team"
                                xs={12} sm={4} md={3} lg={3}
                              >
                                <TextValidator
                                  className="filter-input"
                                  variant="outlined"
                                  onChange={this.handleRef}
                                  name="filterref"
                                  value={this.state.filterref}
                                  placeholder="Search Ref."
                                />
                              </Grid>

                              <Grid item xs={12} sm={4} md={3} lg={3}>
                                <FormControl sx={{ width: '100%' }}>
                                  <InputLabel id="demo-multiple-name-label" sx={{ zIndex: 'inherit' }}>
                                    Select Status
                                  </InputLabel>
                                  <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    multiple
                                    inputProps={{
                                      IconComponent: () =>
                                        filterstatusVal.length > 0 && (
                                          <ClearIcon
                                            onClick={this.handleClearSelection}
                                            sx={{
                                              cursor: 'pointer',
                                              position: 'relative',
                                              right: '10px',
                                            }}
                                          />
                                        ),
                                    }}
                                    fullWidth
                                    value={filterstatusVal}
                                    onChange={this.handleStatusFilter}
                                    input={<OutlinedInput label="Select Status" />}
                                    MenuProps={MenuProps}
                                  >
                                    {filterstatus.map((name) => (
                                      <MenuItem
                                        key={name.value}
                                        value={name.value}
                                        style={getStyles(name.label, filterstatusVal, this.props.theme)}
                                        sx={{
                                          fontWeight:
                                            filterstatusVal.indexOf(name.value) === -1
                                              ? 'normal'
                                              : 'bold',
                                        }}
                                      >
                                        {name.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                className="team"
                                xs={12}
                                sm={4}
                                md={2}
                                lg={2}
                              >
                                <TextValidator
                                  className="filter-input"
                                  variant="outlined"
                                  onChange={this.handleCustomerPhone}
                                  name="filterphone"
                                  placeholder="Search Phone"
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={2} lg={2}>
                                <Button
                                  variant="contained"
                                  type="submit"
                                  className="filter-btn "
                                  startIcon={<HiOutlineSearch />}
                                >
                                  Search
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                          <Grid
                            contaier
                            spacing={1}
                            className="usage-tbl-header  mt-3"
                          >
                            <Grid
                              item
                              xs={12}
                              className="usage-tbl-header text-right"
                            >
                              {this.state.posType === "TV" && (
                                <>
                                  <LoadingButton
                                    onClick={() => this.handleExportAll()}
                                    size="large"
                                    endIcon={<SaveAltIcon />}
                                    loading={this.state.exportLoader}
                                    disabled={
                                      !this.state.exportAllFilter &&
                                        this.state.soldList.length > 0
                                        ? false
                                        : true
                                    }
                                    loadingPosition="end"
                                    sx={{
                                      fontFamily: "Bevan, cursive !important",
                                    }}
                                    variant="contained"
                                  >
                                    <span>Export All</span>
                                  </LoadingButton>
                                  <LoadingButton
                                    onClick={() =>
                                      exportToCSV(this.state.soldList)
                                    }
                                    size="large"
                                    endIcon={<SaveAltIcon />}
                                    loading={false}
                                    disabled={
                                      !this.state.exportAllFilter &&
                                        this.state.soldList.length > 0
                                        ? false
                                        : true
                                    }
                                    loadingPosition="end"
                                    sx={{
                                      fontFamily: "Bevan, cursive !important",
                                      margin: "0 10px",
                                    }}
                                    variant="contained"
                                  >
                                    <span>Export to CSV</span>
                                  </LoadingButton>
                                </>
                              )}

                              {this.state.posType === "TA" && (
                                <>
                                  <LoadingButton
                                    onClick={() => this.handleExportAllTA()}
                                    size="large"
                                    endIcon={<SaveAltIcon />}
                                    loading={this.state.exportLoader}
                                    disabled={
                                      !this.state.exportAllFilter &&
                                        this.state.soldList.length > 0
                                        ? false
                                        : true
                                    }
                                    loadingPosition="end"
                                    sx={{
                                      fontFamily: "Bevan, cursive !important",
                                    }}
                                    variant="contained"
                                  >
                                    <span>Export All</span>
                                  </LoadingButton>
                                  <LoadingButton
                                    onClick={() =>
                                      this.handleExportToCSVTA()
                                    }
                                    size="large"
                                    endIcon={<SaveAltIcon />}
                                    loading={this.state.exportLoaderTA}
                                    disabled={
                                      !this.state.exportAllFilter &&
                                        this.state.soldList.length > 0
                                        ? false
                                        : true
                                    }
                                    loadingPosition="end"
                                    sx={{
                                      fontFamily: "Bevan, cursive !important",
                                      margin: "0 10px",
                                    }}
                                    variant="contained"
                                  >
                                    <span>Export to CSV</span>
                                  </LoadingButton>
                                </>
                              )}
                              <Button
                                variant="contained"
                                size="large"
                                type="button"
                                onClick={this.add}
                                className="theme-btn-submit"
                                startIcon={<BiPlusCircle />}
                              >
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                        </ValidatorForm>
                        {!dataloader && !loader ? (
                          <>
                            {this.state.soldList.length > 0 ? (
                              <TableContainer
                                component={Paper}
                                sx={{
                                  marginBottom: "20px",
                                  paddingBottom: "12px",
                                }}
                              >
                                <Table
                                  sx={{ minWidth: 700 }}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell sx={{ cursor: "pointer" }}>
                                        <TableSortLabel
                                          IconComponent={HiOutlineArrowNarrowDown}
                                          active={
                                            this.state.currentColumn ===
                                            "invoiceDate"
                                          }
                                          direction={
                                            this.state.currentColumn ===
                                              "invoiceDate"
                                              ? this.state.direction
                                              : "asc"
                                          }
                                          onClick={() =>
                                            this.handleRotateIcon("invoiceDate")
                                          }
                                        >
                                          Date
                                        </TableSortLabel>
                                      </StyledTableCell>
                                      <StyledTableCell>Team</StyledTableCell>
                                      <StyledTableCell> Vendor</StyledTableCell>
                                      <StyledTableCell
                                        sx={{
                                          cursor: "pointer",
                                          width: "4rem!important",
                                        }}
                                      >
                                        <TableSortLabel
                                          IconComponent={HiOutlineArrowNarrowDown}
                                          active={
                                            this.state.currentColumn === "section"
                                          }
                                          direction={
                                            this.state.currentColumn === "section"
                                              ? this.state.direction
                                              : "asc"
                                          }
                                          onClick={() =>
                                            this.handleRotateIcon("section")
                                          }
                                        >
                                          Section
                                        </TableSortLabel>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        sx={{
                                          cursor: "pointer",
                                          width: "4rem!important",
                                        }}
                                      >
                                        <TableSortLabel
                                          IconComponent={HiOutlineArrowNarrowDown}
                                          active={
                                            this.state.currentColumn === "row"
                                          }
                                          direction={
                                            this.state.currentColumn === "row"
                                              ? this.state.direction
                                              : "asc"
                                          }
                                          onClick={() =>
                                            this.handleRotateIcon("row")
                                          }
                                        >
                                          Row
                                        </TableSortLabel>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        sx={{
                                          cursor: "pointer",
                                          width: "4rem!important",
                                        }}
                                      >
                                        <TableSortLabel
                                          IconComponent={HiOutlineArrowNarrowDown}
                                          active={
                                            this.state.currentColumn ===
                                            "seatNumbers"
                                          }
                                          direction={
                                            this.state.currentColumn ===
                                              "seatNumbers"
                                              ? this.state.direction
                                              : "asc"
                                          }
                                          onClick={() =>
                                            this.handleRotateIcon("seatNumbers")
                                          }
                                        >
                                          Seat
                                        </TableSortLabel>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        sx={{
                                          cursor: "pointer",
                                          width: "4rem!important",
                                        }}
                                      >
                                        <TableSortLabel
                                          IconComponent={HiOutlineArrowNarrowDown}
                                          active={
                                            this.state.currentColumn ===
                                            "customerDisplayName"
                                          }
                                          direction={
                                            this.state.currentColumn ===
                                              "customerDisplayName"
                                              ? this.state.direction
                                              : "asc"
                                          }
                                          onClick={() =>
                                            this.handleRotateIcon(
                                              "customerDisplayName"
                                            )
                                          }
                                        >
                                          Marketplace
                                        </TableSortLabel>
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <div
                                          style={{
                                            width: "100%",
                                            minWidth: "max-content",
                                          }}
                                        >
                                          External Ref
                                        </div>
                                      </StyledTableCell>
                                      <StyledTableCell>Event</StyledTableCell>
                                      <StyledTableCell>
                                        <div
                                          style={{
                                            width: "100%",
                                            minWidth: "max-content",
                                          }}
                                        >
                                          Event Date
                                        </div>
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <div
                                          style={{
                                            width: "100%",
                                            minWidth: "max-content",
                                          }}
                                        >
                                          Internal Notes
                                        </div>
                                      </StyledTableCell>
                                      <StyledTableCell>Status</StyledTableCell>
                                      <StyledTableCell>Action</StyledTableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody sx={{ position: "sticky" }}>
                                    <Backdrop
                                      sx={{
                                        color: "#fff",
                                        backgroundColor:
                                          "rgba(255, 255, 255, 0.72)",
                                        position: "absolute!important",
                                        zIndex: (theme) =>
                                          theme.zIndex.drawer + 1,
                                      }}
                                      open={dataloader}
                                    ></Backdrop>
                                    {this.state.soldList.map((row, index) => (
                                      <StyledTableRow key={index}>
                                        <StyledTableCell
                                          component="td"
                                          scope="row"
                                          onClick={() => this.openModal(row)}
                                        >
                                          {invoiceDateFormat(row.invoiceDate)}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() => this.openModal(row)}
                                          component="td"
                                          scope="row"
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              minWidth: "max-content",
                                            }}
                                          >
                                            {row.event !== "undefined"
                                              ? row.event[0].performer?.name
                                              : ""}
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="td"
                                          scope="row"
                                          onClick={() => this.openModal(row)}
                                        >
                                          {row.account_detail.length >= 1 &&
                                            row.account_detail[0].username}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="td"
                                          scope="row"
                                        >
                                          {row.section}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() => this.openModal(row)}
                                          component="th"
                                          scope="row"
                                        >
                                          {row.row}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() => this.openModal(row)}
                                          component="td"
                                          scope="row"
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              minWidth: "max-content",
                                            }}
                                          >
                                            {row.seatNumbers}
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() => this.openModal(row)}
                                          component="td"
                                          scope="row"
                                        >
                                          {row.customerDisplayName}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() => this.openModal(row)}
                                          component="td"
                                          scope="row"
                                        >
                                          {row.invoiceExternalRef}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="td"
                                          scope="row"
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              minWidth: "max-content",
                                            }}
                                          >
                                            {row.event !== "undefined"
                                              ? row.event[0].name
                                              : ""}
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="td"
                                          scope="row"
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              minWidth: "max-content",
                                            }}
                                          >
                                            {row.event[0].date !== "undefined"
                                              ? customDateFormat(
                                                row.event[0].date
                                              )
                                              : ""}
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="td"
                                          scope="row"
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              minWidth: "max-content",
                                            }}
                                          >
                                            {" "}
                                            {row.notes ? row.notes : ""}
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="td"
                                          scope="row"
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              minWidth: "max-content",
                                            }}
                                          >
                                            {!row?.delivery_status ? (
                                              <div>
                                                No message sent
                                                <br />
                                              </div>
                                            ) : (
                                              <div>
                                                {getStatus(row?.delivery_status)}
                                              </div>
                                            )}
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="td"
                                          scope="row"
                                        >
                                          <td className="py-2 icon-outer-font">
                                            <Tooltip title="Action List">
                                              <IconButton
                                                key={index}
                                                id={`demo-customized-button-${index}`}
                                                aria-controls={`demo-customized-button-${index}`}
                                                aria-haspopup="true"
                                                variant="contained"
                                                disableelevation
                                                onClick={(event) =>
                                                  this.handleClickMenu(
                                                    event,
                                                    index
                                                  )
                                                }
                                              >
                                                <MoreVertIcon />
                                              </IconButton>
                                            </Tooltip>
                                            <StyledMenu
                                              key={index + 1}
                                              id={`demo-customized-button-${index}`}
                                              anchorEl={this.state.anchorEl}
                                              keepMounted
                                              open={this.state.openmenu === index}
                                              onClose={this.handleCloseMenu}
                                            >
                                              {row.ticketurl ===
                                                (undefined || null) ? (
                                                ""
                                              ) : (
                                                <MenuItem
                                                  sx={linkdesign}
                                                  onClick={() => {
                                                    this.copyUrl(row.ticketurl);

                                                    this.handleCloseMenu();
                                                  }}
                                                  disableRipple
                                                >
                                                  <ContentCopyIcon />
                                                  Copy link
                                                </MenuItem>
                                              )}

                                              <MenuItem
                                                sx={linkdesign}
                                                onClick={() => {
                                                  this.getTwilioMessages(
                                                    row,
                                                    "opentmModel"
                                                  );

                                                  this.handleCloseMenu();
                                                }}
                                                disableRipple
                                              >
                                                <VisibilityIcon />
                                                View
                                              </MenuItem>
                                              <MenuItem
                                                sx={linkdesign}
                                                onClick={() => {
                                                  this.getLogsByInventory(row);
                                                  this.handleCloseMenu();
                                                }}
                                                disableRipple
                                              >
                                                <ReceiptLongIcon />
                                                View Log
                                              </MenuItem>
                                              <MenuItem
                                                sx={linkdesign}
                                                onClick={() => {
                                                  this.getTwilioMessages(
                                                    row,
                                                    "proofModal"
                                                  );
                                                  this.handleCloseMenu();
                                                }}
                                                disableRipple
                                              >
                                                <InsertDriveFileIcon />
                                                View Proof
                                              </MenuItem>
                                              {row?.delivery_status !== 4 &&
                                                row?.delivery_status !== 6 && (
                                                  <MenuItem
                                                    sx={linkdesign}
                                                    onClick={() => {
                                                      this.onStatusChangePopupShow(
                                                        row,
                                                        4,
                                                        "Confirm Manually"
                                                      );
                                                      this.handleCloseMenu();
                                                    }}
                                                    disableRipple
                                                  >
                                                    <TouchAppIcon />
                                                    Manually Confirmed
                                                  </MenuItem>
                                                )}
                                              {row?.delivery_status !== 11 && (
                                                <MenuItem
                                                  sx={linkdesign}
                                                  onClick={() => {
                                                    this.onStatusChangePopupShow(
                                                      row,
                                                      11,
                                                      "Transfer Manually"
                                                    );
                                                    this.handleCloseMenu();
                                                  }}
                                                  disableRipple
                                                >
                                                  <TouchAppIcon />
                                                  Manually Transferred
                                                </MenuItem>
                                              )}
                                              {row?.delivery_status !== 12 && (
                                                <MenuItem
                                                  sx={linkdesign}
                                                  onClick={() => {
                                                    this.onStatusChangePopupShow(
                                                      row,
                                                      12,
                                                      "Revoke Tickets"
                                                    );
                                                    this.handleCloseMenu();
                                                  }}
                                                  disableRipple
                                                >
                                                  <ReplayIcon />
                                                  Revoke Tickets
                                                </MenuItem>
                                              )}

                                              {row?.delivery_status === 16 && (
                                                <MenuItem
                                                  sx={linkdesign}
                                                  onClick={() => {
                                                    this.handleToggleFulFill(
                                                      row._id
                                                    );
                                                    this.handleCloseMenu();
                                                  }}
                                                  disableRipple
                                                >
                                                  <CachedIcon />
                                                  Fulfill Order Manually
                                                </MenuItem>
                                              )}

                                              <MenuItem
                                                sx={linkdesign}
                                                onClick={() => {
                                                  this.onArchivePopupShow(row);
                                                  this.handleCloseMenu();
                                                }}
                                                disableRipple
                                              >
                                                <ArchiveIcon />
                                                Archive
                                              </MenuItem>
                                            </StyledMenu>
                                          </td>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            ) : (
                              <>
                                <TableContainer
                                  component={Paper}
                                  sx={{
                                    marginBottom: "20px",
                                    paddingBottom: "12px",
                                  }}
                                >
                                  <Table
                                    sx={{ minWidth: 700 }}
                                    aria-label="customized table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell>Date</StyledTableCell>
                                        <StyledTableCell>Team</StyledTableCell>
                                        <StyledTableCell>Vendor</StyledTableCell>
                                        <StyledTableCell>Section</StyledTableCell>
                                        <StyledTableCell>Row</StyledTableCell>
                                        <StyledTableCell>Seat</StyledTableCell>
                                        <StyledTableCell>
                                          Marketplace
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          <div
                                            style={{
                                              width: "100%",
                                              minWidth: "max-content",
                                            }}
                                          >
                                            External Ref
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell>Event</StyledTableCell>
                                        <StyledTableCell>
                                          Event Date
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          Internal Notes
                                        </StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                        <StyledTableCell>Action</StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <StyledTableRow>
                                        <td
                                          colSpan={13}
                                          style={{ padding: "2.6rem 1rem" }}
                                        >
                                          <Typography
                                            variant="h6"
                                            sx={{
                                              fontSize: "1.6rem",
                                              textAlign: "center",
                                            }}
                                          >
                                            No Records Found
                                          </Typography>
                                        </td>
                                      </StyledTableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </>
                            )}
                          </>
                        ) : (
                          <ContainerLoader />
                        )}
                        {totalCountries > 0 && (
                          <Pagination
                            totalRecords={totalCountries}
                            searchPage={this.state.searchPage}
                            SearchDisable={this.SearchDisable}
                            pageLimit={pageLimit}
                            onPageChanged={this.onPageChanged}
                          />
                        )}
                        <div
                          style={{
                            textAlign: "right",
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                          }}
                        >
                          <label>Items per page :</label>
                          <FormControl
                            sx={{
                              m: 1,
                              minWidth: 50,
                            }}
                            size="small"
                          >
                            <Select
                              defaultValue={pageLimit}
                              value={pageLimit}
                              name="limit"
                              sx={{
                                "& .MuiSelect-select": {
                                  padding: "4px 14px",
                                  fontFamily: "Barlow",
                                },
                              }}
                              onChange={(e) => this.changePageLimit(e)}
                            >
                              <MenuItem value="10">10</MenuItem>
                              <MenuItem value="20">20</MenuItem>
                              <MenuItem value="50">50</MenuItem>
                              <MenuItem value="100">100</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <Dialog
                          fullWidth={true}
                          scroll="body"
                          maxWidth="sm"
                          open={modalArchiveShow}
                          onClose={this.onArchivePopupHide}
                        >
                          <DialogTitle>Archive</DialogTitle>
                          <DialogContent>
                            <IconButton
                              aria-label="close"
                              onClick={() => this.onArchivePopupHide()}
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                color: (theme) => theme.palette.grey[500],
                                p: 1,
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                            <DialogContentText>
                              Are you sure you want to archive this action can
                              not be revert ?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={this.onArchivePopupHide}
                              sx={{
                                backgroundColor: "#002986!important",
                                color: "white!important",
                                fontWeight: "bold",
                                fontFamily: "Barlow",
                                textTransform: "none",
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={this.archiveInventory}
                              sx={{
                                backgroundColor: "#002986!important",
                                color: "white!important",
                                fontWeight: "bold",
                                fontFamily: "Barlow",
                                textTransform: "none",
                              }}
                            >
                              Archive
                            </Button>
                          </DialogActions>
                        </Dialog>

                        {/* Revoke Modal  */}
                        <Dialog
                          fullWidth={true}
                          scroll="body"
                          maxWidth="sm"
                          open={this.state.modalConfirmShow}
                          onClose={this.onStatusChangePopupHide}
                        >
                          <DialogTitle>Revoke </DialogTitle>
                          <DialogContent>
                            <IconButton
                              aria-label="close"
                              onClick={() => this.onStatusChangePopupHide()}
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                color: (theme) => theme.palette.grey[500],
                                p: 1,
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                            <DialogContentText>
                              Are you sure you want to{" "}
                              {this.state.confirmStatusLabel}?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={this.onStatusChangePopupHide}
                              sx={{
                                backgroundColor: "#002986!important",
                                color: "white!important",
                                fontWeight: "bold",
                                fontFamily: "Barlow",
                                textTransform: "none",
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={this.statusChangeInventory}
                              sx={{
                                backgroundColor: "#002986!important",
                                color: "white!important",
                                fontWeight: "bold",
                                fontFamily: "Barlow",
                                textTransform: "none",
                              }}
                            >
                              {this.state.confirmStatusLabel}
                            </Button>
                          </DialogActions>
                        </Dialog>

                        {/* Confirm fullfill Modal  */}
                        <Dialog
                          fullWidth={true}
                          scroll="body"
                          maxWidth="sm"
                          open={this.state.confirmFulfill}
                          onClose={() => this.handleToggleFulFill(null)}
                        >
                          <DialogTitle>Fulfill Order </DialogTitle>
                          <DialogContent>
                            <IconButton
                              aria-label="close"
                              onClick={() => this.handleToggleFulFill(null)}
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                color: (theme) => theme.palette.grey[500],
                                p: 1,
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                            <DialogContentText>
                              Are you sure you want to Fulfill this order
                              manually?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={() => this.handleToggleFulFill(null)}
                              sx={{
                                backgroundColor: "#002986!important",
                                color: "white!important",
                                fontWeight: "bold",
                                fontFamily: "Barlow",
                                textTransform: "none",
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={this.handlefullFillOrderManually}
                              sx={{
                                backgroundColor: "#002986!important",
                                color: "white!important",
                                fontWeight: "bold",
                                fontFamily: "Barlow",
                                textTransform: "none",
                              }}
                            >
                              Yes{" "}
                            </Button>
                          </DialogActions>
                        </Dialog>

                        <Dialog
                          size="lg"
                          open={this.state.modalShow}
                          scroll="body"
                          onClose={this.onHide}
                        >
                          <DialogTitle>Update</DialogTitle>
                          <DialogContent>
                            <IconButton
                              aria-label="close"
                              onClick={() => this.onHide()}
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                color: (theme) => theme.palette.grey[500],
                                p: 1,
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                            <div>
                              <div style={{ color: "red", fontSize: "16px" }}>
                                {this.state.modelError}
                              </div>
                              <div
                                style={{
                                  color: "green",
                                  fontSize: "16px",
                                }}
                              >
                                {this.state.modelSuccess}
                              </div>

                              <ValidatorForm
                                // ref="form"
                                onSubmit={this.handleSubmitModal}
                              >
                                <Box sx={{ flexGrow: 1 }}>
                                  <Grid
                                    container
                                    spacing={{ xs: 2, md: 2, lg: 2 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                    >
                                      <label>Ticket:</label>
                                      <TextValidator
                                        variant="outlined"
                                        fullWidth
                                        onChange={this.handleModelChange}
                                        name="ticketurl"
                                        value={this.state.ticketurl}
                                      />
                                      {this.state.ticketurl && (
                                        <a
                                          href={this.state.ticketurl}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          click to view ticket
                                        </a>
                                      )}
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                    >
                                      <label>Email</label>
                                      <TextValidator
                                        variant="outlined"
                                        fullWidth
                                        onChange={this.handleModelChange}
                                        name="email"
                                        value={this.state.email}
                                      />
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                    >
                                      <label>Phone</label>
                                      <TextValidator
                                        variant="outlined"
                                        fullWidth
                                        onChange={this.handleModelChange}
                                        name="phone"
                                        value={this.state.phone}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                      <Button
                                        variant="contained"
                                        type="submit"
                                        size="large"
                                        sx={{
                                          minWidth: "12rem!important",
                                          backgroundColor:
                                            "#011C58!important",
                                          color: "white!important",
                                          fontFamily: "Barlow!important",
                                          textTransform: "none!important",
                                          fontSize: "1.1rem",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Submit
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                      {/* <div onClick={this.onHide} className="cancelbillingModal">Cancel</div> */}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </ValidatorForm>
                            </div>
                          </DialogContent>
                          {/* <Modal.Footer>
                                                        <div onClick={this.onHide} className="cancelbillingModal">Cancel</div> */}
                          {/* <div onClick={()=>this.onUpdate(this.state.userData)} className="cancelbillingModal">Update</div> */}
                          {/* </Modal.Footer> */}
                        </Dialog>

                        <Dialog
                          maxWidth="md"
                          fullWidth={true}
                          scroll="body"
                          open={this.state.tmModel}
                          className="Modal_Box custom-pop-over"
                          onClose={this.tmModelonHide}
                        >
                          <DialogContent>
                            <IconButton
                              aria-label="close"
                              onClick={() => this.tmModelonHide()}
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,

                                color: (theme) => theme.palette.grey[500],
                                p: 1,
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                            {this.state.twiliomessages.length > 0 ? (
                              <>
                                <Button
                                  className="theme-btn float-right"
                                  style={{
                                    marginRight: 10,
                                    marginTop: 15,
                                    textTransform: "none!important",
                                  }}
                                  onClick={() => this.downloadAsPdf()}
                                >
                                  Download as PDF
                                </Button>
                                <br />
                                <br />
                                <div style={{ padding: "10px" }}>
                                  <strong>Event: </strong>{" "}
                                  {this.state.currentRow?.event[0].name}
                                  <br />
                                  <strong>Event Date: </strong>{" "}
                                  {customDateFormat(
                                    this.state.currentRow.event[0].date
                                  )}
                                  <br />
                                  <strong>Marketplace: </strong>{" "}
                                  {this.state.currentRow.customerDisplayName}{" "}
                                  &nbsp; &nbsp;
                                  <strong>External Ref: </strong>{" "}
                                  {this.state.currentRow.invoiceExternalRef}
                                  <br />
                                  <strong>Team: </strong>{" "}
                                  {this.state.currentRow.event[0].name} &nbsp;
                                  &nbsp;
                                  <strong>Date: </strong>{" "}
                                  {this.state.currentRow.invoiceDate}
                                  <br />
                                  <strong>Section: </strong>{" "}
                                  {this.state.currentRow.section} &nbsp;
                                  &nbsp;
                                  <strong>Row: </strong>{" "}
                                  {this.state.currentRow.row} &nbsp; &nbsp;
                                  <strong>Seat: </strong>{" "}
                                  {this.state.currentRow.seatNumbers}
                                  <br />
                                  {this.state.currentRow.customerName && (
                                    <>
                                      <strong>Customer Name: </strong>
                                      {this.state.currentRow.customerName}
                                      <br />
                                    </>
                                  )}
                                  <strong>Phone Number: </strong>{" "}
                                  {this.state.twiliomessages[0].sms_type ===
                                    "received"
                                    ? this.state.twiliomessages[0].sentby
                                    : this.state.twiliomessages[0]
                                      .phone_number}
                                </div>
                                <CustomTable>
                                  <thead>
                                    <tr>
                                      <th>Status</th>
                                      {/*<th>Phone Number</th>*/}
                                      <th>Message</th>
                                      <th>Sent Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.twiliomessages
                                      .sort(
                                        (a, b) =>
                                          new Date(b.sentat) -
                                          new Date(a.sentat)
                                      )
                                      .map((tm) => (
                                        <>
                                          <td
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {tm.sms_type
                                              ? tm.sms_type
                                              : "delivered"}
                                          </td>
                                          {/*<td>{tm.sms_type === "received" ? tm.sentby : tm.phone_number}</td>*/}
                                          <td style={{ width: "50%" }}>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  tm.message_body.replace(
                                                    "\n",
                                                    "<br />"
                                                  ),
                                              }}
                                            />
                                          </td>

                                          <td>
                                            {customDateFormatLocal(
                                              tm.sentat,
                                              this.state.timezone
                                            )}
                                          </td>
                                        </>
                                      ))}
                                  </tbody>
                                </CustomTable>
                              </>
                            ) : (
                              <>
                                {this.state.currentRow && (
                                  <>
                                    <div style={{ padding: "10px" }}>
                                      <strong>Event: </strong>{" "}
                                      {this.state.currentRow?.event[0].name}
                                      <br />
                                      <strong>Event Date: </strong>{" "}
                                      {customDateFormat(
                                        this.state.currentRow.event[0].date
                                      )}
                                      <br />
                                      <strong>Marketplace: </strong>{" "}
                                      {
                                        this.state.currentRow
                                          .customerDisplayName
                                      }{" "}
                                      &nbsp; &nbsp;
                                      <strong>External Ref: </strong>{" "}
                                      {
                                        this.state.currentRow
                                          .invoiceExternalRef
                                      }
                                      <br />
                                      <strong>Team: </strong>{" "}
                                      {this.state.currentRow.event[0].name}{" "}
                                      &nbsp; &nbsp;
                                      <strong>Date: </strong>{" "}
                                      {this.state.currentRow.invoiceDate}
                                      <br />
                                      <strong>Section: </strong>{" "}
                                      {this.state.currentRow.section} &nbsp;
                                      &nbsp;
                                      <strong>Row: </strong>{" "}
                                      {this.state.currentRow.row} &nbsp;
                                      &nbsp;
                                      <strong>Seat: </strong>{" "}
                                      {this.state.currentRow.seatNumbers}
                                      <br />
                                      {this.state.currentRow.customerName && (
                                        <>
                                          <strong>Customer Name: </strong>
                                          {this.state.currentRow.customerName}
                                          <br />
                                        </>
                                      )}
                                      <strong>Phone Number: </strong>{" "}
                                      {this.state.currentRow.customerPhone !==
                                        ""
                                        ? this.state.currentRow.customerPhone
                                        : "Not Available"}
                                    </div>
                                  </>
                                )}

                                <CustomTable>
                                  <thead>
                                    <tr>
                                      <th>Status</th>
                                      {/*<th>Phone Number</th>*/}
                                      <th>Message</th>
                                      <th>Sent Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <CustomRow>
                                      <td
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        No Messages Yet
                                      </td>
                                      <td></td>
                                      <td></td>
                                    </CustomRow>
                                  </tbody>
                                </CustomTable>
                              </>
                            )}
                          </DialogContent>
                        </Dialog>

                        {/* Proof of transfer */}
                        <Dialog
                          fullWidth={true}
                          maxWidth="md"
                          scroll="body"
                          open={this.state.proofModal}
                          className="Modal_Box custom-pop-over proof-modal"
                          onClose={this.proofModalHide}
                        >
                          {this.state.currentRow?.event[0]?.performer.name ===
                            "ticketmaster" ? (
                            <>
                              <DialogContent>
                                <Button
                                  varaint="contained"
                                  className="theme-btn float-right"
                                  onClick={() => this.downloadPdf()}
                                >
                                  Download as PDF
                                </Button>
                                <br />
                                <br />
                                <div
                                  className="mainSec"
                                  id="content-22"
                                  style={{ marginTop: "12px" }}
                                >
                                  <div className="boxSec">
                                    <div className="boxText">
                                      <h3
                                        style={{
                                          fontWeight: "bold",
                                          fontFamily: "sans-serif",
                                        }}
                                      >
                                        {this.state.currentRow.event[0].name}
                                      </h3>
                                      <p>
                                        {customDateFormat(
                                          this.state.currentRow.event[0]
                                            .dateformatted
                                        )}
                                      </p>
                                      <p id="adress">
                                        {this.state.currentRow.event[0]?.venue
                                          .name && (
                                            <>
                                              {
                                                this.state.currentRow.event[0]
                                                  ?.venue.name
                                              }
                                            </>
                                          )}
                                        {this.state.currentRow.event[0]?.venue
                                          .city && (
                                            <>
                                              ,
                                              {" " +
                                                this.state.currentRow.event[0]
                                                  ?.venue.city}
                                            </>
                                          )}
                                        {this.state.currentRow.event[0]?.venue
                                          .state && (
                                            <>
                                              ,
                                              {" " +
                                                this.state.currentRow.event[0]
                                                  ?.venue.state}
                                            </>
                                          )}
                                        {this.state.currentRow.event[0]?.venue
                                          .country && (
                                            <>
                                              ,
                                              {" " +
                                                this.state.currentRow.event[0]
                                                  ?.venue.country}
                                            </>
                                          )}
                                      </p>
                                    </div>

                                    <div className="boxText border-top">
                                      <h4>
                                        Section{" "}
                                        {this.state.currentRow?.section}, Row{" "}
                                        {this.state.currentRow?.row}, Seat{" "}
                                        {this.state.currentRow?.seatNumbers}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="p-top">
                                    <h4>
                                      Transfer Status:{" "}
                                      <span id="tm">Sent</span>
                                    </h4>
                                    <p id="ticket-text">
                                      Your ticket transfer is in the works.
                                      There are now{" "}
                                      {
                                        this.state.currentRow.seatNumbers.split(
                                          ","
                                        ).length
                                      }{" "}
                                      {this.state.currentRow.seatNumbers.split(
                                        ","
                                      ).length > 1 ? (
                                        <>ticket(s)</>
                                      ) : (
                                        <>ticket</>
                                      )}
                                      &nbsp;heading{" "}
                                      {this.state.currentRow.customerName && (
                                        <>
                                          to{" "}
                                          {this.state.currentRow.customerName}
                                        </>
                                      )}{" "}
                                      {this.state.currentRow.customerEmail ? (
                                        <>
                                          at{" "}
                                          <a
                                            href={
                                              this.state.currentRow
                                                .customerEmail
                                            }
                                          >
                                            {
                                              this.state.currentRow
                                                .customerEmail
                                            }
                                          </a>
                                        </>
                                      ) : (
                                        this.state.currentRow
                                          .customerPhone && (
                                          <>
                                            {" "}
                                            at{" "}
                                            {
                                              this.state.currentRow
                                                .customerPhone
                                            }
                                          </>
                                        )
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </DialogContent>
                            </>
                          ) : (
                            <>
                              <DialogContent>
                                <Button
                                  className="theme-btn float-right"
                                  onClick={() => this.downloadPdf2()}
                                >
                                  Download as PDF
                                </Button>
                                <br />
                                <br />
                                <div
                                  className="mainSec"
                                  id="content-22"
                                  style={{ marginTop: "12px" }}
                                >
                                  <div className="boxSec">
                                    <div className="boxText box-ticket">
                                      <h3 className="heading-font">
                                        {this.state.currentRow?.event[0].name}
                                      </h3>

                                      <div
                                        className="d-flex justify-content-start mt-4"
                                        id="icon-date"
                                      >
                                        <span
                                          className="m-0"
                                          id="calendor-icon"
                                        >
                                          <TodayRoundedIcon className="calendar-icon" />
                                        </span>
                                        <p className="margin-left">
                                          {customDateFormat(
                                            this.state.currentRow?.event[0]
                                              .dateformatted
                                          )}
                                        </p>
                                      </div>
                                      <div className="d-flex justify-content-start mt-1">
                                        <LocationOnOutlinedIcon className="location-icon" />
                                        <p id="adress2">
                                          {this.state.currentRow?.event[0]
                                            ?.venue.name && (
                                              <>
                                                {
                                                  this.state.currentRow
                                                    ?.event[0]?.venue.name
                                                }
                                              </>
                                            )}
                                          {this.state.currentRow?.event[0]
                                            ?.venue.city && (
                                              <>
                                                ,
                                                {" " +
                                                  this.state.currentRow
                                                    ?.event[0]?.venue.city}
                                              </>
                                            )}
                                          {this.state.currentRow?.event[0]
                                            ?.venue.state && (
                                              <>
                                                ,
                                                {" " +
                                                  this.state.currentRow
                                                    ?.event[0]?.venue.state}
                                              </>
                                            )}
                                          {this.state.currentRow?.event[0]
                                            ?.venue.country && (
                                              <>
                                                ,
                                                {" " +
                                                  this.state.currentRow
                                                    ?.event[0]?.venue.country}
                                              </>
                                            )}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="ticket-heading">
                                      <h1>TRANSFERRED TICKETS</h1>
                                    </div>
                                    <div className="ticket-text d-flex justify-content-lg-start border-bt">
                                      <svg
                                        width="17"
                                        height="28"
                                        viewBox="0 0 22 24"
                                      >
                                        <path
                                          className="primary"
                                          fill="#262626"
                                          fill-opacity=".65"
                                          fill-rule="evenodd"
                                          d="M18.65 6.375A3.544 3.544 0 0 0 20.086 3.5c0-1.933-1.499-3.5-3.348-3.5-1.849 0-3.348 1.567-3.348 3.5 0 1.19.569 2.242 1.437 2.874-1.96.799-3.35 2.791-3.35 5.126v3.895c0 .28.205.558.463.624l1.45.373.957 7.608h4.782l.957-7.608 1.45-.373c.256-.066.463-.344.463-.624V11.5c0-2.332-1.392-4.325-3.35-5.125zM15.208 23l-.87-6.74a.985.985 0 0 0-.72-.84l-1.184-.304V11.5c0-2.487 1.926-4.5 4.304-4.5 2.374 0 4.304 2.017 4.304 4.5v3.616l-1.185.305a.985.985 0 0 0-.72.839L18.27 23h-3.061zm1.53-17c-1.32 0-2.391-1.12-2.391-2.5S15.418 1 16.739 1c1.32 0 2.391 1.12 2.391 2.5S18.06 6 16.74 6zm-11 1v3c0 .433.49.661.798.372l3.73-3.5a.515.515 0 0 0 0-.744l-3.73-3.5c-.308-.289-.798-.06-.798.372v3H.48A.49.49 0 0 0 0 6.5c0 .276.214.5.478.5H5.74zm.957 0V4.12L9.232 6.5 6.696 8.88V7z"
                                        ></path>
                                      </svg>
                                      <div className="ml-2">
                                        <h4>
                                          Sec {this.state.currentRow?.section}
                                          , Row {this.state.currentRow?.row},
                                          Seat{" "}
                                          {this.state.currentRow?.seatNumbers}
                                        </h4>
                                        <h4 id="trf-ticket">
                                          Transferred:{" "}
                                          <span id="tm">
                                            {Moment(
                                              this.state.currentRow
                                                ?.invoiceDate
                                            ).format("dddd . MMMM DD,YYYY ")}
                                          </span>{" "}
                                          {this.state.currentRow
                                            ?.customerEmail ? (
                                            <>
                                              to{" "}
                                              {
                                                this.state.currentRow
                                                  ?.customerEmail
                                              }
                                            </>
                                          ) : (
                                            this.state.currentRow
                                              ?.customerPhone && (
                                              <>
                                                to{" "}
                                                {
                                                  this.state.currentRow
                                                    ?.customerPhone
                                                }
                                              </>
                                            )
                                          )}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </DialogContent>
                            </>
                          )}
                        </Dialog>
                        {/*  */}

                        <Dialog
                          scroll="body"
                          fullWidth={true}
                          maxWidth="sm"
                          open={this.state.tm1Model}
                          className="Modal_Box custom-pop-over"
                          onClose={this.tm1ModelonHide}
                        >
                          <DialogContent>
                            <IconButton
                              aria-label="close"
                              onClick={() => this.tm1ModelonHide()}
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                color: (theme) => theme.palette.grey[500],
                                p: 1,
                              }}
                            >
                              <CancelIcon />
                            </IconButton>

                            {this.state.loadLogs ? (
                              <div
                                style={{
                                  height: "12rem",
                                  width: "35rem",
                                }}
                              >
                                <PageLoader />
                              </div>
                            ) : (
                              <>
                                {this.state.invLogs.length > 0 ? (
                                  <>
                                    <div style={{ padding: "10px" }}>
                                      <strong>Event: </strong>{" "}
                                      {this.state.currentRow?.event[0].name}
                                      <br />
                                      <strong>Event Date: </strong>{" "}
                                      {customDateFormat(
                                        this.state.currentRow?.event[0].date
                                      )}
                                      <br />
                                      <strong>Marketplace: </strong>{" "}
                                      {
                                        this.state.currentRow
                                          ?.customerDisplayName
                                      }{" "}
                                      &nbsp; &nbsp;
                                      <strong>External Ref: </strong>{" "}
                                      {
                                        this.state.currentRow
                                          ?.invoiceExternalRef
                                      }
                                      <br />
                                      <strong>Team: </strong>{" "}
                                      {this.state.currentRow?.event[0]?.name}{" "}
                                      &nbsp; &nbsp;
                                      <strong>Date: </strong>{" "}
                                      {this.state.currentRow?.invoiceDate}
                                      <br />
                                      <strong>Section: </strong>{" "}
                                      {this.state.currentRow?.section} &nbsp;
                                      &nbsp;
                                      <strong>Row: </strong>{" "}
                                      {this.state.currentRow?.row} &nbsp;
                                      &nbsp;
                                      <strong>Seat: </strong>{" "}
                                      {this.state.currentRow?.seatNumbers}
                                    </div>

                                    <CustomTable>
                                      <thead>
                                        <tr>
                                          <th>Status</th>
                                          <th>Date</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.invLogs.map((invlog) => (
                                          <CustomRow>
                                            <td
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              <div className="icon-space-left">
                                                {getStatus(invlog.status)}
                                                <br />
                                              </div>
                                            </td>
                                            <td>
                                              {customDateFormatLocal(
                                                invlog.created,
                                                this.state.timezone
                                              )}
                                            </td>
                                          </CustomRow>
                                        ))}
                                      </tbody>
                                    </CustomTable>
                                  </>
                                ) : (
                                  <Typography
                                    variant="p"
                                    sx={{ textAlign: "center" }}
                                  >
                                    No log yet!
                                  </Typography>
                                )}
                              </>
                            )}
                          </DialogContent>
                        </Dialog>

                        <Dialog
                          size="lg"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                          scroll="body"
                          open={this.state.modalShow1}
                          className="Modal_Box custom-pop-over"
                          onClose={this.onHide1}
                        >
                          <DialogTitle>Add Record</DialogTitle>
                          <DialogContent>
                            <IconButton
                              aria-label="close"
                              onClick={() => this.onHide1()}
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                color: (theme) => theme.palette.grey[500],
                                p: 1,
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                            <ValidatorForm onSubmit={this.handleSubmitTicket}>
                              <Box sx={{ flexGrow: 1 }}>
                                <Grid
                                  container
                                  spacing={{ xs: 2, md: 2, lg: 2 }}
                                  columns={{ xs: 4, sm: 8, md: 12 }}
                                >
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <label className="Ticket_info">
                                      Marketplace
                                    </label>
                                    <Autocomplete
                                      className="input_size autocomplete_select"
                                      onChange={(evt, val) =>
                                        this.handleModelChangeSelect(
                                          evt,
                                          val,
                                          "customerDisplayName"
                                        )
                                      }
                                      fullWidth
                                      name="marketplace"
                                      options={marketplace}
                                      getOptionLabel={(option) =>
                                        option.title
                                      }
                                      renderInput={(params) => (
                                        <TextValidator
                                          value={
                                            this.state.formData.marketplace
                                          }
                                          placeholder="Select Marketplace"
                                          {...params}
                                          variant="outlined"
                                        />
                                      )}
                                      validators={["required"]}
                                      errorMessages={[
                                        "This field is required",
                                      ]}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <label className="Ticket_info">
                                      External Ref
                                    </label>
                                    <TextValidator
                                      variant="outlined"
                                      fullWidth
                                      onChange={this.handleChange}
                                      name="invoiceExternalRef"
                                      placeholder="External Ref"
                                      sx={{
                                        input: {
                                          padding: "14px 12px",
                                        },
                                      }}
                                      value={
                                        this.state.formData.invoiceExternalRef
                                      }
                                      validators={["required"]}
                                      errorMessages={[
                                        "This field is required",
                                      ]}
                                    />
                                  </Grid>
                                  {(this.state.formData.marketPlace ===
                                    "VividSeats" ||
                                    this.state.formData.marketPlace ===
                                    "TickPick" ||
                                    this.state.formData.marketPlace ===
                                    "SeatGeek") && (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              onChange={
                                                this.handleModelChangeCheckbox
                                              }
                                              name="uploadtovivid"
                                              sx={{
                                                color: indigo[900],
                                                "&.Mui-checked": {
                                                  color: indigo[600],
                                                },
                                              }}
                                            />
                                          }
                                          label={`Upload the link to ${this.state.formData.marketPlace}`}
                                        />
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              onChange={
                                                this.handleModelChangeCheckbox
                                              }
                                              name="custfromvivid"
                                              sx={{
                                                color: indigo[900],
                                                "&.Mui-checked": {
                                                  color: indigo[600],
                                                },
                                              }}
                                            />
                                          }
                                          label={`Get customer details from  ${this.state.formData.marketPlace}`}
                                        />
                                      </Grid>
                                    )}
                                  {!this.state.formData.custfromvivid && (
                                    <>
                                      <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <label className="Ticket_info">
                                          Customer Name
                                        </label>
                                        <TextValidator
                                          className="Ticket_input"
                                          variant="outlined"
                                          fullWidth
                                          onChange={this.handleChange}
                                          name="customerName"
                                          placeholder="Customer Name"
                                          sx={{
                                            input: {
                                              padding: "14px 12px",
                                            },
                                          }}
                                          value={
                                            this.state.formData.customerName
                                          }
                                          validators={["required"]}
                                          errorMessages={[
                                            "This field is required",
                                          ]}
                                        />
                                      </Grid>

                                      <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <label className="Ticket_info">
                                          Customer Email
                                        </label>
                                        <TextValidator
                                          className="Ticket_input"
                                          variant="outlined"
                                          fullWidth
                                          onChange={this.handleChange}
                                          name="customerEmail"
                                          sx={{
                                            input: {
                                              padding: "14px 12px",
                                            },
                                          }}
                                          placeholder="Customer Email"
                                          value={
                                            this.state.formData.customerEmail
                                          }
                                          validators={["required"]}
                                          errorMessages={[
                                            "This field is required",
                                          ]}
                                        />
                                      </Grid>
                                    </>
                                  )}
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <label className="Ticket_info">
                                      Ticket URL
                                    </label>
                                    <TextField
                                      className="Ticket_input"
                                      variant="outlined"
                                      fullWidth
                                      onChange={this.handleChange}
                                      name="ticketURL"
                                      placeholder="Ticket URL"
                                      sx={{
                                        input: {
                                          padding: "14px 12px",
                                        },
                                      }}
                                      value={this.state.formData.ticketURL}
                                      validators={["required"]}
                                      errorMessages={[
                                        "This field is required",
                                      ]}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <label className="Ticket_info">
                                      Tickets
                                    </label>
                                    <TextField
                                      className="Ticket_input"
                                      variant="outlined"
                                      onChange={this.handleChange}
                                      name="tickets"
                                      fullWidth
                                      sx={{
                                        input: {
                                          padding: "14px 12px",
                                        },
                                      }}
                                      placeholder="Tickets"
                                      value={this.state.formData.tickets}
                                      validators={["required"]}
                                      errorMessages={[
                                        "This field is required",
                                      ]}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <label className="Ticket_info">
                                      Date
                                    </label>
                                    <DatePicker
                                      className="Ticket_input_wrap"
                                      selectsRange
                                      selected={this.state.startDate}
                                      placeholderText="Select Date"
                                      startDate={this.state.startDate}
                                      endDate={this.state.endDate}
                                      onChange={(dates) =>
                                        this.setStartDate(dates)
                                      }
                                      validators={["required"]}
                                      customInput={
                                        <TextValidator
                                          variant="outlined"
                                          sx={{
                                            input: {
                                              padding: "14px 12px",
                                            },
                                          }}
                                          className="filter-input"
                                        />
                                      }
                                      errorMessages={[
                                        "This field is required",
                                      ]}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <label className="Ticket_info">
                                      Team
                                    </label>
                                    <TextValidator
                                      className="Ticket_input"
                                      variant="outlined"
                                      fullWidth
                                      sx={{
                                        input: {
                                          padding: "14px 12px",
                                        },
                                      }}
                                      onChange={this.handleChange}
                                      name="team"
                                      placeholder="Team"
                                      value={this.state.formData.team}
                                      validators={["required"]}
                                      errorMessages={[
                                        "This field is required",
                                      ]}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={4} md={4} lg={4}>
                                    <label className="Ticket_info">
                                      Section
                                    </label>
                                    <TextValidator
                                      className="Ticket_input"
                                      variant="outlined"
                                      fullWidth
                                      sx={{
                                        input: {
                                          padding: "14px 12px",
                                        },
                                      }}
                                      onChange={this.handleChange}
                                      name="section"
                                      placeholder="Section"
                                      value={this.state.formData.section}
                                      validators={["required"]}
                                      errorMessages={[
                                        "This field is required",
                                      ]}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={4} lg={4}>
                                    <label className="Ticket_info">Row</label>
                                    <TextValidator
                                      className="Ticket_input"
                                      variant="outlined"
                                      fullWidth
                                      onChange={this.handleChange}
                                      name="rows"
                                      sx={{
                                        input: {
                                          padding: "14px 12px",
                                        },
                                      }}
                                      placeholder="Row"
                                      value={this.state.formData.rows}
                                      validators={["required"]}
                                      errorMessages={[
                                        "This field is required",
                                      ]}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={4} lg={4}>
                                    <label className="Ticket_info">
                                      Seat
                                    </label>
                                    <TextValidator
                                      className="Ticket_input"
                                      variant="outlined"
                                      fullWidth
                                      onChange={this.handleChange}
                                      name="seat"
                                      sx={{
                                        input: {
                                          padding: "14px 12px",
                                        },
                                      }}
                                      placeholder="Seat"
                                      value={this.state.formData.seat}
                                      validators={["required"]}
                                      errorMessages={[
                                        "This field is required",
                                      ]}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <label className="Ticket_info">
                                      Event
                                    </label>
                                    <TextValidator
                                      className="Ticket_input"
                                      onChange={this.handleChange}
                                      fullWidth
                                      sx={{
                                        input: {
                                          padding: "14px 12px",
                                        },
                                      }}
                                      name="eventName"
                                      placeholder="Event"
                                      value={this.state.formData.eventName}
                                      validators={["required"]}
                                      errorMessages={[
                                        "This field is required",
                                      ]}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <label className="Ticket_info">
                                      Event Date
                                    </label>
                                    <DatePicker
                                      showTimeSelect
                                      selected={this.state.formData.eventDate}
                                      placeholderText="Select Date"
                                      onChange={(date) =>
                                        this.setStartDate1(date)
                                      }
                                      validators={["required"]}
                                      errorMessages={[
                                        "This field is required",
                                      ]}
                                      dateFormat="Pp"
                                      customInput={
                                        <TextValidator
                                          variant="outlined"
                                          sx={{
                                            input: {
                                              padding: "14px 12px",
                                            },
                                          }}
                                          className="filter-input"
                                        />
                                      }
                                    />
                                  </Grid>
                                  {!this.state.formData.custfromvivid && (
                                    <Grid item xs={12}>
                                      <label className="Ticket_info">
                                        Phone
                                      </label>
                                      <TextValidator
                                        className="Ticket_input"
                                        variant="outlined"
                                        fullWidth
                                        onChange={this.handleChange}
                                        sx={{
                                          input: {
                                            padding: "14px 12px",
                                          },
                                        }}
                                        name="customerPhone"
                                        placeholder="Customer Phone"
                                        value={
                                          this.state.formData.customerPhone
                                        }
                                      />
                                    </Grid>
                                  )}
                                  <Grid item xs={12}>
                                    <label>Send Delivery message</label>
                                    <RadioGroup
                                      aria-label="deliverymsg_time"
                                      defaultValue={
                                        this.state.formData.deliverymsg_time
                                      }
                                      name="deliverymsg_time"
                                      className="scan-condition-opts"
                                      onChange={this.handleChange}
                                    >
                                      <FormControlLabel
                                        value="immediate"
                                        control={<Radio color="primary" />}
                                        label="Immediate"
                                      />
                                      {/* <FormControlLabel value="48 hours before" control={<Radio color="primary" disabled={true}/>} label="48 Hours Before" /> */}
                                    </RadioGroup>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      type="submit"
                                      size="large"
                                      sx={{
                                        minWidth: "17rem!important",
                                        backgroundColor: "#011C58!important",
                                        color: "white!important",
                                        fontFamily: "Barlow!important",
                                        textTransform: "none!important",
                                        fontSize: "1.1rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Submit
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            </ValidatorForm>
                          </DialogContent>
                        </Dialog>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </main>
              <AlertMessage
                open={this.state.open}
                message={this.state.message}
                severity={this.state.successError}
                closeCall={this.handleClose}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserProfile: state.UserProfile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(UserProfileAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Soldinventory);