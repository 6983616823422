import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import TimeAgo from "react-timeago";
import Image from "images/Licenses Summary.png";

// scss file
import "./userdashboard.scss";

// import components
import Header from "components/header/header";
import Footer from "components/footer/footer";
import axios from "utils/axiosInterceptor";
import Usersidebar from "components/user-sidebar/user-sidebar";
import { ContainerLoader } from "components/common/ContainerLoader";
import AlertMessage from "components/message/message";

// mui components
import {
  Grid,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Box,
  FormControlLabel,
  InputAdornment,
  Link as MuiLink,
  Switch,
  TextField,
  Tooltip as MuiToolTip,
  Card,
  CardContent,
  Paper,
  Button,
  Stack,
} from "@mui/material";

// mui icons and svg icon
import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import TableRow from "@mui/material/TableRow";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import Credit from "images/Credit.svg";
import Pending from "images/Pending.svg";
import { styled } from "@mui/material/styles";

// chart js
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Cookies from "js-cookie";
import UserProfileAction from "redux/actions/UserProfielAction";
import CountdownRealTime from "utils/countdownRealTime";
import { LinearProgressBar } from "utils/linearProgressBar";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  height: "100%",
  textAlign: "center",
  // marginTop:"10px",
  color: theme.palette.text.secondary,
}));

const moment = extendMoment(originalMoment);

const MonthSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

class Userdashboard extends React.Component {
  constructor(props, context) {
    super(props, context);
    const today = moment();
    this.state = {
      moweek: "",
      fromDate: "2021-01-01",
      toDate: "2021-01-10",
      isOpenToggle: false,
      loader: true,
      value: moment.range(today.clone().subtract(0, "days"), today.clone()),
      textDate: "Select date",
      userDetail: [],
      startDate: new Date(),
      endDate: new Date(),
      dateErrorMsg: "",
      dateValue: null,
      open: false,
      message: "",
      successError: "",
      notificationList: [],
      crossCheck: true,
      dtLabels: [],
      transferTickets: 0,
      creditBalance: null,
      totalTicketsDelivered: 0,
      totalAvailableInventory: 0,
      totalactiveLicenses: 0,
      graphData: [],
      graphDt: [],
      toggleDate: true,
      monthDate: new Date(),
      costPerTicket: 0,
      totalAmount: 0,
      monthEndDate: new Date(),
      monthStartDate: new Date(),
      packageData: null,
    };
  }

  getNotifications = async () => {
    this.setState({ loader: true });
    //Notification List api
    const userNotifications_api =
      process.env.REACT_APP_API_URL + "/api/user/userNotifications";
    await axios
      .post(userNotifications_api, "", {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          const today = new Date();
          // Filter the array and update notifications with expired titles
          const updatedNotificationList = data?.data?.map((item) => {
            if (item.expireAt) {
              const expiryDate = new Date(item.expireAt);
              if (expiryDate < today) {
                // If the event has expired, extend the duration by 2 days
                expiryDate.setDate(expiryDate.getDate() + 2);
                // Remove the item from the array if it's expired for more than 2 days
                if (expiryDate < today) {
                  return null;
                }
                item.expireAt = expiryDate.toISOString();
              }
            }
            return item;
          });
          // Filter out null (expired) items from the array
          const extendDurationEventNotificationList =
            updatedNotificationList.filter((item) => item !== null);
          this.setState({
            notificationList: extendDurationEventNotificationList,
            open: false,
            message: "",
            successError: "success",
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  async componentDidMount() {
    this.setState({
      loader: true,
    });
    this.getNotifications();
    var date = new Date();
    var dateString = date.toISOString().split("T")[0];
    const OneYearBack = moment(dateString).subtract(1, "years");
    this.handleDate([new Date(OneYearBack), new Date(dateString)]);
    // this.getUserDetails(this.state.startDate, this.state.endDate);
  }

  handleChange = (e) => {
    this.setState({
      moweek: e.target.innerText,
    });
  };

  getUserDetails = async (value, start, end) => {
    const startDate = moment(start).format("YYYY-MM-DDTHH:mm:ss") + ".000Z";
    const endDate = moment(end).format("YYYY-MM-DDTHH:mm:ss") + ".000Z";
    //User package detail api
    const user_detail_api_url =
      process.env.REACT_APP_API_URL + "/api/user/userDetail";
    await axios
      .post(
        user_detail_api_url,
        {
          graphStart: startDate,
          graphEnd: endDate,
          type: value ? "monthly" : "daywise",
        },
        {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        }
      )
      .then((response) => {
        const { data } = response;
        this.setState({
          loader: false,
        });

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else if (data.graphData !== undefined) {
          var getnewData = [];
          if (data.graphData.length > 0) {
            const totalGraphData = data.graphData.reduce(
              (a, b) => a + Number(b.ticketsDelivered),
              0
            );
            // count total tickets delivered
            this.setState({
              totalAmount: totalGraphData,
            });
            if (this.state.toggleDate) {
              getnewData = data?.graphData?.map((item) => {
                return item.ticketsDelivered;
              });
            } else {
              const datesf = this.enumerateDate(
                this.state.startDate,
                this.state.endDate
              );
              getnewData = datesf.map((item) => {
                const nw = data.graphData.map((row) => {
                  return item === row.data ? row.ticketsDelivered : 0;
                });
                const bt = nw.reduce(function (a, b) {
                  return a + b;
                });
                return bt;
              });
            }
          } else {
            this.setState({
              totalAmount: 0,
            });
          }
          this.setState({
            packageData: data?.userPackagePurchased,
          });
          this.setState({
            totalAvailableInventory: data?.totalAvailableInventory,
          });
          this.setState({
            totalactiveLicenses: data?.totalActiveLicense ?? 0,
          });
          this.setState({
            creditBalance: data.credits.balance,
            // totalAvailableInventory: data.totalAvailableInventory,
            noOfTicketTransfers: data.noOfTicketTransfers,
            graphData: data.graphData,
            userDetail: data,
            open: false,
            message: "",
            successError: "success",
            graphDt: getnewData,
            loader: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });
      });
  };

  handleMonthlyCheck = (e) => {
    const start = this.state.startDate;
    const end = this.state.endDate;
    const value = e.target.checked
    this.setState({
      toggleDate: value,
    })
    if (start && end) {
      if (value) {
        const dt = this.enumrateMonth(start, end);
        this.setState({ dtLabels: dt });
      } else {
        this.setState({ dtLabels: this.enumerateDaysBetweenDates(start, end) });
      }
      this.getUserDetails(value, start, end);
    }
  }

  setStartDate = (dates) => {
    const [start, end] = dates;
    this.setState({ startDate: start, endDate: end });
    if (start && end) {
      if (this.state.toggleDate) {
        const dt = this.enumrateMonth(start, end);
        this.setState({ dtLabels: dt });
      } else {
        this.setState({ dtLabels: this.enumerateDaysBetweenDates(start, end) });
      }
      this.getUserDetails(this.state.toggleDate, start, end);
    }
  };

  handleDate = (date) => {
    this.setState({
      monthStartDate: date[0],
      monthEndDate: date[1],
      startDate: date[0],
      endDate: date[1],
    });
    this.setState({ monthDate: date[0] });
    if (date[1] !== null) {
      const currentMonth = date[0].getMonth();
      const currentYear = date[0].getFullYear();
      const firstDay = new Date(currentYear, currentMonth, 1);
      const endMonth = date[1].getMonth();
      const endYear = date[1].getFullYear();
      const lastDay = new Date(endYear, endMonth + 1, 0);
      this.setStartDate([firstDay, lastDay]);
    }
  };

  setEndDate = (date) => {
    this.setState({ startDate: this.state.startDate, endDate: date });
  };

  handleMarkAllReadNotification = async () => {
    const idsArray = this.state.notificationList.map((ids) => ids._id);
    try {
      const params = {
        ids: idsArray,
      };
      const notificationDelete_api_url =
        process.env.REACT_APP_API_URL + "/api/user/readNotification";
      const response = await axios.post(notificationDelete_api_url, params, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      });
      const { data } = response;
      if (data.error) {
        this.setState({
          loader: false,
          open: true,
          message: data.data.message,
          successError: "error",
        });
      } else {
        this.getNotifications();
        this.setState({
          loader: false,
          open: false,
          message: "Mark All Notification Successfully",
          successError: "success",
        });
      }
    } catch (error) {
      this.setState({
        loader: false,
        open: true,
        message: "Something went wrong",
        successError: "error",
      });
    }
  };

  clearDate = async (datas) => {
    const data = {
      ids: [datas._id],
    };
    const notificationDelete_api_url =
      process.env.REACT_APP_API_URL + "/api/user/readNotification";
    await axios
      .post(notificationDelete_api_url, data, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.data.message,
            successError: "error",
          });
        } else {
          if (data.success) {
            //Get All Notification List api
            const userNotifications_api =
              process.env.REACT_APP_API_URL + "/api/user/userNotifications";
            axios
              .post(userNotifications_api, "", {
                headers: {
                  authorization: "Bearer " + Cookies.get("accessToken"),
                },
              })
              .then((response) => {
                const { data } = response;
                if (data.error) {
                  this.setState({
                    loader: false,
                    open: true,
                    message: "Something went wrong",
                    successError: "error",
                  });
                } else {
                  this.setState({
                    loader: false,
                    notificationList: data.data,
                    open: false,
                    message: "",
                    successError: "success",
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  loader: false,
                });
              });
          } else {
            this.setState({
              loader: false,
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });
      });
  };

  enumrateMonth = (startDate, endDate) => {
    var startDates = moment(startDate).subtract(1, "days");
    var endDates = moment(endDate).add(1, "days");
    var dates = [];
    var currDate = moment(startDates).startOf("day");
    var lastDate = moment(endDates).startOf("day");
    // get the range of months
    while (currDate.add(1, "months").diff(lastDate) < 0) {
      dates.push(currDate.format("MMM/YY"));
    }
    return dates;
  };

  enumerateDaysBetweenDates = (startDate, endDate) => {
    var startDates = moment(startDate).subtract(1, "days");
    var endDates = moment(endDate).add(1, "days");
    var dates = [];
    var currDate = moment(startDates).startOf("day");
    var lastDate = moment(endDates).startOf("day");
    while (currDate.add(1, "days").diff(lastDate) < 0) {
      dates.push(currDate.format("DD MMM"));
    }
    return dates;
  };

  enumerateDate = (startDate, endDate) => {
    var startDates = moment(startDate).subtract(1, "days");
    var endDates = moment(endDate).add(1, "days");
    var dates = [];
    var currDate = moment(startDates).startOf("day");
    var lastDate = moment(endDates).startOf("day");
    while (currDate.add(1, "days").diff(lastDate) < 0) {
      dates.push(currDate.format("YYYY-MM-DD"));
    }
    return dates;
  };

  calculateDays = (endDate, startDate) => {
    if (!endDate || !startDate) return null;
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
    const daysDifference = Math.floor(
      differenceInMilliseconds / millisecondsPerDay
    );
    return daysDifference + 1;
  };

  render() {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "",
        },
      },
    };

    const datatickets = {
      labels: this.state.dtLabels,
      datasets: [
        {
          label: "Tickets Delivered",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          data: this.state.graphDt.length > 0 ? this.state.graphDt : [0, 0, 0],
        },
      ],
    };
    const { notificationList } = this.state;
    const isAdd_credit_btn_status =
      this.props?.UserProfile?.add_credit_btn_status ?? false;
    const accountCreatedDate = new Date(this.props?.UserProfile?.data?.created);
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate - accountCreatedDate;
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

    return (
      <>
        <div className="user-dashboard-wrapper">
          <Helmet>
            <title>User Dashboard</title>
          </Helmet>
          <Header />
          <div className="c-app c-default-layout user-dashboard-body">
            <Usersidebar />
            <div className="c-wrapper">
              <div className="c-body">
                {!this.state.loader ? (
                  <main className="c-main">
                    {/* if browser accses and vbd acctteached the show this panel */}
                    {this.props?.UserProfile?.data?.access_browser === "Yes" &&
                      this.props?.UserProfile?.vbdaccattached === true ? (
                      <>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                                <Card className="card-custom">
                                  <CardContent className="card-custom-inner">
                                    <div className="credit-left-img">
                                      <Grid container spacing={0}>
                                        <Grid
                                          item
                                          xs="12"
                                          sm="3"
                                          md="3"
                                          lg="3"
                                          spacing={0}
                                          className="icon_size"
                                          sx={{
                                            display: "flex",
                                            alignItems: "baseline",
                                            justifyContent: {
                                              xs: "center",
                                              sm: "center",
                                              md: "flex-start",
                                            },
                                          }}
                                        >
                                          <img src={Credit} alt="no images" />
                                        </Grid>
                                        <Grid
                                          item
                                          xs="12"
                                          sm="6"
                                          md="6"
                                          lg="6"
                                          sx={{
                                            padding: "0px 15px!important",
                                            display: "flex",
                                            alignItems: "flex-end",
                                            justifyContent: "flex-start",
                                          }}
                                        >
                                          <div className="plan-prices">
                                            $
                                            {
                                              <NumberFormat
                                                value={
                                                  this.state.creditBalance ?? 0
                                                }
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                              />
                                            }
                                            <h2
                                              style={{
                                                marginTop: "5px",
                                              }}
                                            >
                                              {" "}
                                              Credit Remaining{" "}
                                            </h2>
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs="12"
                                          sm="6"
                                          md="3"
                                          lg="3"
                                          className="icon_size  add_credit_grid"
                                          sx={{
                                            display: "flex",
                                            alignItems: "baseline",
                                            justifyContent: {
                                              xs: "center",
                                              sm: "center",
                                              md: "flex-start",
                                            },
                                          }}
                                        >
                                          <Button
                                            component={Link}
                                            to="/purchase-credit"
                                            size="small"
                                            variant="contained"
                                            className="add-credit-button"
                                            disabled={
                                              isAdd_credit_btn_status ===
                                                false || differenceInHours < 48
                                                ? true
                                                : false
                                            }
                                            sx={{
                                              backgroundColor:
                                                isAdd_credit_btn_status ===
                                                  false ||
                                                  differenceInHours < 48
                                                  ? "#E0E0E0!important"
                                                  : "#002986!important",
                                              color:
                                                isAdd_credit_btn_status ===
                                                  false ||
                                                  differenceInHours < 48
                                                  ? "#A6A6A6 !important"
                                                  : "white!important",
                                              textTransform: "none!important",
                                              fontWeight: "bold",
                                              fontFamily: "Barlow!important",
                                              marginTop: "-21px",
                                              minWidth: "90px",
                                            }}
                                          >
                                            Add Credit
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </CardContent>
                                </Card>
                              </Grid>
                              {/* Package lic*/}
                              {this.props?.UserProfile?.data?.access_browser ===
                                "Yes" && (
                                  <>
                                    {this.state?.packageData?.name ? (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={3}
                                      >
                                        <Card
                                          className="card-custom"
                                          sx={{
                                            paddingBottom: {
                                              xl: "0px",
                                              lg: "0px",
                                              md: "0px",
                                              sm: "42px",
                                              xs: "0px",
                                            },
                                          }}
                                        >
                                          <CardContent
                                            className="card-custom-inner"
                                            sx={{
                                              paddingTop: "14px !important",
                                              paddingLeft: "2px !important",
                                              paddingRight: "2px!important",
                                              paddingBottom: "7px !important",
                                            }}
                                          >
                                            <div className="account-have-img">
                                              <Grid container spacing={0}>
                                                <Grid
                                                  item
                                                  xs="12"
                                                  sm="12"
                                                  md="12"
                                                  lg="12"
                                                  sx={{
                                                    padding: "0px 15px!important",
                                                    display: "flex",
                                                    alignItems: "flex-end",
                                                    justifyContent: "flex-start",
                                                  }}
                                                >
                                                  <Stack
                                                    direction="row"
                                                    spacing={2}
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      width: "100%",
                                                    }}
                                                  >
                                                    <Box>
                                                      <h2
                                                        style={{
                                                          color: "#002583",
                                                          fontWeight: "bold",
                                                          lineHeight: "13px",
                                                        }}
                                                      >
                                                        Package Details
                                                      </h2>
                                                    </Box>
                                                    <Box>
                                                      <Stack direction="row">
                                                        <Box>
                                                          <h2
                                                            style={{
                                                              lineHeight: "13px",
                                                            }}
                                                          >
                                                            Auto-Renew:{" "}
                                                          </h2>
                                                        </Box>
                                                        <Box>
                                                          <h2
                                                            style={{
                                                              lineHeight: "13px",
                                                            }}
                                                          >
                                                            {this.state
                                                              .packageData
                                                              ?.userAutoRenewal ===
                                                              "on"
                                                              ? "ON"
                                                              : "OFF"}
                                                          </h2>
                                                        </Box>
                                                      </Stack>
                                                    </Box>
                                                  </Stack>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs="12"
                                                  sm="12"
                                                  md="12"
                                                  lg="12"
                                                  sx={{
                                                    padding: "0px 15px!important",
                                                    display: "flex",
                                                    alignItems: "flex-end",
                                                    justifyContent: "flex-start",
                                                  }}
                                                >
                                                  <h2
                                                    style={{ lineHeight: "13px" }}
                                                  >
                                                    Name:{" "}
                                                    {this.state.packageData
                                                      ?.name ?? "No Package"}
                                                  </h2>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs="12"
                                                  sm="12"
                                                  md="12"
                                                  lg="12"
                                                  sx={{
                                                    padding: "0px 15px!important",
                                                    display: "flex",
                                                    alignItems: "flex-end",
                                                    justifyContent: "flex-start",
                                                  }}
                                                >
                                                  <h2
                                                    style={{ lineHeight: "13px" }}
                                                  >
                                                    Time Left:{" "}
                                                    {this?.state?.packageData
                                                      ?.userPackageValidUpto ? (
                                                      <CountdownRealTime
                                                        timeZone="America/New_York"
                                                        targetDate={
                                                          this?.state?.packageData
                                                            ?.userPackageValidUpto
                                                        }
                                                      />
                                                    ) : null}
                                                  </h2>
                                                </Grid>
                                                <Grid
                                                  direction="column"
                                                  xs="12"
                                                  sm="12"
                                                  md="12"
                                                  lg="12"
                                                  className="icon_size"
                                                  sx={{
                                                    width: "100% !important",
                                                    display: "flex",
                                                    alignItems: "baseline",
                                                    padding: "0px 15px!important",
                                                    justifyContent: {
                                                      xs: "center",
                                                      sm: "center",
                                                      md: "flex-start",
                                                    },
                                                  }}
                                                >
                                                  <Box>
                                                    <h2
                                                      style={{
                                                        lineHeight: "13px",
                                                      }}
                                                    >
                                                      Tickets Delivered:
                                                    </h2>
                                                  </Box>
                                                  <LinearProgressBar
                                                    data={this.state.packageData}
                                                  />
                                                </Grid>
                                              </Grid>
                                            </div>
                                          </CardContent>
                                        </Card>
                                      </Grid>
                                    ) : (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={3}
                                      >
                                        <Card
                                          className="card-custom"
                                          sx={{
                                            paddingBottom: {
                                              xl: "89px",
                                              lg: "89px",
                                              md: "67px",
                                              sm: "63px",
                                              xs: "63px",
                                            },
                                            paddingTop: {
                                              xl: "0px",
                                              lg: "0px",
                                              md: "0px",
                                              sm: "30px",
                                              xs: "50px",
                                            },
                                          }}
                                        >
                                          <CardContent
                                            className="card-custom-inner card-custom-nopackage"
                                            sx={{
                                              paddingTop: {
                                                xl: "15px",
                                                lg: "8px",
                                                md: "8px",
                                                sm: "8px",
                                                xs: "8px",
                                              },
                                              paddingBottom: "7px !important",
                                            }}
                                          >
                                            <div className="account-have-img">
                                              <Grid
                                                container
                                                spacing={0}
                                                sx={{
                                                  gap: {
                                                    xl: 0,
                                                    lg: 0,
                                                    md: 0,
                                                    sm: "10px",
                                                    xs: "10px",
                                                  },
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs="12"
                                                  sm="12"
                                                  md="5"
                                                  lg="5"
                                                  xl="5"
                                                  sx={{
                                                    padding: "0px 15px!important",
                                                    display: "flex",
                                                    alignItems: "flex-end",
                                                    justifyContent: {
                                                      xl: "flex-start",
                                                      lg: "flex-start",
                                                      md: "flex-start",
                                                      sm: "flex-start",
                                                      xs: "space-evenly",
                                                    },
                                                  }}
                                                >
                                                  <h2
                                                    style={{
                                                      color: "#002583",
                                                      fontWeight: "bold",
                                                      lineHeight: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    No Package
                                                  </h2>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs="12"
                                                  sm="12"
                                                  md="7"
                                                  lg="7"
                                                  xl="7"
                                                  sx={{
                                                    padding: {
                                                      xl: "0px 0 0 15px!important",
                                                      lg: "0px 15px!important",
                                                      md: "7px 15px!important",
                                                      sm: "0px 15px!important",
                                                      xs: "0px 15px!important",
                                                    },
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                    justifyContent: {
                                                      xl: "flex-end",
                                                      lg: "flex-end",
                                                      md: "flex-end",
                                                      sm: "flex-start",
                                                      xs: "space-evenly",
                                                    },
                                                  }}
                                                >
                                                  <Button
                                                    component={Link}
                                                    to="/packages"
                                                    size="small"
                                                    variant="contained"
                                                    sx={{
                                                      backgroundColor:
                                                        "#002986!important",
                                                      color: "white!important",
                                                      textTransform:
                                                        "none!important",
                                                      fontWeight: "bold",
                                                      fontFamily:
                                                        "Barlow!important",
                                                    }}
                                                  >
                                                    Purchase Package
                                                  </Button>
                                                </Grid>
                                              </Grid>
                                            </div>
                                          </CardContent>
                                        </Card>
                                      </Grid>
                                    )}
                                  </>
                                )}
                              {/* total lic*/}
                              {this.props?.UserProfile?.data?.access_browser ===
                                "Yes" && (
                                  <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                                    <Card className="card-custom">
                                      <CardContent className="card-custom-inner">
                                        <div className="account-have-img">
                                          <Grid container spacing={0}>
                                            <Grid
                                              item
                                              xs="12"
                                              sm="3"
                                              md="3"
                                              lg="3"
                                              className="icon_size"
                                              sx={{
                                                display: "flex",
                                                alignItems: "baseline",
                                                justifyContent: {
                                                  xs: "center",
                                                  sm: "center",
                                                  md: "flex-start",
                                                },
                                              }}
                                            >
                                              <img
                                                src={Image}
                                                alt="no images"
                                                width={54}
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              xs="12"
                                              sm="8"
                                              md="8"
                                              lg="8"
                                              sx={{
                                                padding: "0px 15px!important",
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "flex-start",
                                              }}
                                            >
                                              <div className="plan-prices">
                                                {
                                                  <NumberFormat
                                                    value={
                                                      this.state
                                                        .totalactiveLicenses
                                                    }
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    decimalScale={0}
                                                    fixedDecimalScale={true}
                                                  />
                                                }
                                                <h2
                                                  style={{
                                                    marginTop: "5px",
                                                  }}
                                                >
                                                  Total Licenses
                                                </h2>
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </div>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                )}
                              {/* total available inventary*/}
                              {this.props?.UserProfile?.data?.access_browser ===
                                "Yes" &&
                                this.props?.UserProfile?.vbdaccattached ===
                                true && (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={3}
                                  >
                                    <Card className="card-custom">
                                      <CardContent className="card-custom-inner">
                                        <div className="account-have-img">
                                          <Grid
                                            container
                                            spacing={0}
                                            sx={{
                                              paddingBottom: {
                                                xl: 0,
                                                lg: 0,
                                                md: 0,
                                                sm: 0,
                                                xs: 0,
                                              },
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs="12"
                                              sm="3"
                                              md="3"
                                              lg="3"
                                              className="icon_size"
                                              sx={{
                                                display: "flex",
                                                alignItems: "baseline",
                                                justifyContent: {
                                                  xs: "center",
                                                  sm: "center",
                                                  md: "flex-start",
                                                },
                                              }}
                                            >
                                              <img
                                                src={Pending}
                                                alt="no images"
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              xs="12"
                                              sm="8"
                                              md="8"
                                              lg="8"
                                              sx={{
                                                padding: "0px 15px!important",
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "flex-start",
                                              }}
                                            >
                                              <div className="plan-prices">
                                                {
                                                  <NumberFormat
                                                    value={
                                                      this.state
                                                        .totalAvailableInventory
                                                    }
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    decimalScale={0}
                                                    fixedDecimalScale={true}
                                                  />
                                                }
                                                <h2
                                                  style={{
                                                    marginTop: "5px",
                                                  }}
                                                >
                                                  Total Available Inventory
                                                </h2>
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </div>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12} md={12} lg={8}>
                            {/* chart js */}
                            <Grid
                              container
                              spacing={2}
                              sx={{ marginTop: "-2px" }}
                              className="graph-card"
                            >
                              <Grid item xs={12} sm={12} lg={12} md={12}>
                                <Item sx={{ padding: "1rem 1rem" }}>
                                  <Grid
                                    container
                                    spacing={{ xs: 2, md: 2, lg: 2 }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          sx={{
                                            fontSize: "18px",
                                            fontFamily: "Barlow!important",
                                            textAlign: "left",
                                            color: "#002986",
                                            fontWeight: "bold",
                                            padding: "4px 7px",
                                          }}
                                        >
                                          Tickets Delivered
                                        </Typography>

                                        <Typography
                                          sx={{
                                            fontSize: "18px",
                                            fontFamily: "Barlow!important",
                                            textAlign: "left!important",
                                            fontWeight: "800",
                                            color: "black",
                                            padding: "0px 10px",
                                            lineHeight: "1.0",
                                          }}
                                        >
                                          {
                                            <NumberFormat
                                              value={
                                                this.state.totalAmount || 0
                                              }
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              decimalScale={0}
                                              fixedDecimalScale={true}
                                            />
                                          }
                                        </Typography>
                                      </div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={2}
                                      lg={2}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "end",
                                      }}
                                    >
                                      <FormControlLabel
                                        control={<MonthSwitch defaultChecked />}
                                        onChange={(e) => this.handleMonthlyCheck(e)}
                                        label="Monthly"
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={4}
                                      lg={4}
                                      className="datepicker-style"
                                    >
                                      {this.state.toggleDate ? (
                                        <DatePicker
                                          showMonthYearPicker
                                          selected={this.state.monthDate}
                                          startDate={this.state.monthStartDate}
                                          endDate={this.state.monthEndDate}
                                          onChange={(date) =>
                                            this.handleDate(date)
                                          }
                                          dateFormat="MMMM/yyyy"
                                          selectsRange
                                          customInput={
                                            <TextField
                                              variant="outlined"
                                              className="filter-input"
                                              size="small"
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment
                                                    position="end"
                                                    className="date-icon"
                                                  >
                                                    <div className="d-flex">
                                                      {this.state.crossCheck ? (
                                                        <IconButton
                                                          aria-label="toggle password visibility"
                                                          edge="end"
                                                        >
                                                          <EventIcon className="text-dark" />
                                                        </IconButton>
                                                      ) : (
                                                        <IconButton
                                                          edge="end"
                                                          onClick={() => this.clearDate()}
                                                        >
                                                          <ClearIcon className="text-dark" />
                                                        </IconButton>
                                                      )}
                                                    </div>
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          }
                                        />
                                      ) : (
                                        <DatePicker
                                          placeholderText="Search Date"
                                          selected={this.state.startDate}
                                          startDate={this.state.startDate}
                                          endDate={this.state.endDate}
                                          monthsShown={this.state.monthsShown}
                                          selectsRange
                                          isClearable={true}
                                          customInput={
                                            <TextField
                                              variant="outlined"
                                              className="filter-input"
                                              size="small"
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment
                                                    position="end"
                                                    className="date-icon"
                                                  >
                                                    <div className="d-flex">
                                                      {this.state.crossCheck ? (
                                                        <IconButton
                                                          aria-label="toggle password visibility"
                                                          edge="end"
                                                        >
                                                          <EventIcon className="text-dark" />
                                                        </IconButton>
                                                      ) : (
                                                        <IconButton
                                                          edge="end"
                                                          onClick={() =>
                                                            this.clearDate()
                                                          }
                                                        >
                                                          <ClearIcon className="text-dark" />
                                                        </IconButton>
                                                      )}
                                                    </div>
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          }
                                          onChange={(dates) =>
                                            this.setStartDate(dates)
                                          }
                                        />
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Bar options={options} data={datatickets} />
                                </Item>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={4}
                            sx={{ paddingTop: "22px!important" }}
                          >
                            <Paper
                              sx={{
                                backgroundColor: "#fff",
                                padding: "1rem 0.3rem",
                                height: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "0 1rem",
                                  // marginBottom: "0.6rem",
                                }}
                              >
                                <h6 className="notifaction_tittle">
                                  Notifications
                                </h6>
                                <MuiLink
                                  to="/notification"
                                  underline="none"
                                  component={Link}
                                  sx={{
                                    textDecoration: "none!important",
                                    color: "#002986!important",
                                    fontWeight: "500",
                                    fontSize: "13px",
                                    fontFamily: "Barlow!important",
                                  }}
                                >
                                  View All
                                </MuiLink>
                              </div>
                              {notificationList.length > 0 && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    px: 2.5,
                                  }}
                                >
                                  <Box sx={{ flexGrow: 1 }}>
                                    <Typography variant="body2">
                                      You have{" "}
                                      {`${notificationList.length !== 0 &&
                                        notificationList.length
                                        } unread notification${notificationList.length > 0 && "s"
                                        }`}
                                    </Typography>{" "}
                                  </Box>
                                  {notificationList.length > 0 && (
                                    <MuiToolTip title=" Mark all as read">
                                      <IconButton
                                        color="primary"
                                        onClick={() =>
                                          this.handleMarkAllReadNotification()
                                        }
                                      >
                                        <DoneAllIcon />
                                      </IconButton>
                                    </MuiToolTip>
                                  )}
                                </Box>
                              )}
                              <TableContainer
                                sx={{ maxHeight: "20rem", padding: "10px" }}
                              >
                                <Table stickyHeader aria-label="sticky table">
                                  <TableBody>
                                    {notificationList.length > 0 ? (
                                      notificationList.map((data) => {
                                        return (
                                          <TableRow
                                            className="notification-row"
                                            sx={{ position: "relative" }}
                                          >
                                            <IconButton
                                              aria-label="close"
                                              onClick={() =>
                                                this.clearDate(data)
                                              }
                                              sx={{
                                                position: "absolute",
                                                top: 0,
                                                right: 0,
                                                color: (theme) =>
                                                  theme.palette.grey[500],
                                                p: 1,
                                              }}
                                            >
                                              <CloseIcon
                                                sx={{ fontSize: "14px" }}
                                              />
                                            </IconButton>
                                            <TableCell className="text-left notification-data">
                                              {data.refId ? (
                                                <a
                                                  href={`${data.link}`}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  {data.title}
                                                </a>
                                              ) : (
                                                <>
                                                  {data.title ===
                                                    "Credit balance is low" ? (
                                                    <Link to="/purchase-credit">
                                                      {data.title}
                                                    </Link>
                                                  ) : (
                                                    <span>{data.title}</span>
                                                  )}
                                                </>
                                              )}
                                              <Typography
                                                sx={{
                                                  color: "#888",
                                                  fontSize: "13px",
                                                  fontFamily:
                                                    "Barlow!important",
                                                  textAlign: "left",
                                                }}
                                              >
                                                {data.description}
                                              </Typography>
                                            </TableCell>
                                            <TimeAgo
                                              date={new Date(data.created)}
                                              style={{
                                                fontSize: "13px",
                                                position: "absolute",
                                                right: "24px",
                                                top: "17px",
                                                color: "#888",
                                              }}
                                            />
                                          </TableRow>
                                        );
                                      })
                                    ) : (
                                      <div
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "bolder",
                                          textAlign: "center",
                                        }}
                                      >
                                        No Notifications
                                      </div>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Paper>
                          </Grid>
                          {/* Notification pannel  */}
                        </Grid>
                      </>
                    ) : //  {/* if browser accses "no" and vbd acctteached "true" the show this panel */}
                      this.props?.UserProfile?.data?.access_browser === "No" &&
                        this.props?.UserProfile?.vbdaccattached === true ? (
                        <>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={4}>
                                  <Card className="card-custom">
                                    <CardContent>
                                      <div className="credit-left-img">
                                        <Grid container spacing={0}>
                                          <Grid
                                            item
                                            xs="12"
                                            sm="3"
                                            md="3"
                                            lg="3"
                                            className="icon_size"
                                            sx={{
                                              display: "flex",
                                              alignItems: "baseline",
                                              justifyContent: {
                                                xs: "center",
                                                sm: "center",
                                                md: "flex-start",
                                              },
                                            }}
                                          >
                                            <img src={Credit} alt="no images" />
                                          </Grid>
                                          <Grid
                                            item
                                            xs="12"
                                            sm="6"
                                            md="6"
                                            lg="6"
                                            sx={{
                                              padding: "0px 15px!important",
                                              display: "flex",
                                              alignItems: "flex-end",
                                              justifyContent: "flex-start",
                                            }}
                                          >
                                            <div className="plan-prices card-textAlign">
                                              $
                                              {
                                                <NumberFormat
                                                  value={
                                                    this.state.creditBalance ?? 0
                                                  }
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                />
                                              }
                                              <h2
                                                style={{
                                                  marginTop: "10px",
                                                  marginBottom: "20px"
                                                }}
                                              >
                                                {" "}
                                                Credit Remaining{" "}
                                              </h2>
                                            </div>
                                          </Grid>
                                          <Grid
                                            item
                                            xs="12"
                                            sm="6"
                                            md="3"
                                            lg="3"
                                            className="icon_size"
                                            sx={{
                                              display: "flex",
                                              alignItems: "baseline",
                                              justifyContent: {
                                                xs: "center",
                                                sm: "center",
                                                md: "flex-start",
                                              },
                                            }}
                                          >
                                            <Button
                                              component={Link}
                                              to="/purchase-credit"
                                              size="small"
                                              variant="contained"
                                              className="add-credit-button"
                                              disabled={
                                                isAdd_credit_btn_status ===
                                                  false || differenceInHours < 48
                                                  ? true
                                                  : false
                                              }
                                              sx={{
                                                backgroundColor:
                                                  isAdd_credit_btn_status ===
                                                    false ||
                                                    differenceInHours < 48
                                                    ? "#E0E0E0!important"
                                                    : "#002986!important",
                                                color:
                                                  isAdd_credit_btn_status ===
                                                    false ||
                                                    differenceInHours < 48
                                                    ? "#A6A6A6 !important"
                                                    : "white!important",
                                                textTransform: "none!important",
                                                fontWeight: "bold",
                                                fontFamily: "Barlow!important",
                                                marginTop: "-21px",
                                                minWidth: "90px",
                                              }}
                                            >
                                              Add Credit
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </CardContent>
                                  </Card>
                                </Grid>
                                {/* {ticket count} */}
                                <Grid item xs={12} sm={6} md={6} lg={4}>
                                  <Card className="card-custom">
                                    <CardContent>
                                      <div className="account-have-img">
                                        <Grid
                                          container
                                          spacing={0}
                                          sx={{
                                            paddingBottom: {
                                              xl: 0,
                                              lg: 0,
                                              md: 0,
                                              sm: "34px",
                                              xs: 0,
                                            },
                                          }}
                                        >
                                          <Grid
                                            item
                                            xs="12"
                                            sm="3"
                                            md="3"
                                            lg="3"
                                            className="icon_size"
                                            sx={{
                                              display: "flex",
                                              alignItems: "baseline",
                                              justifyContent: {
                                                xs: "center",
                                                sm: "center",
                                                md: "flex-start",
                                              },
                                            }}
                                          >
                                            <img src={Pending} alt="no images" />
                                          </Grid>
                                          <Grid
                                            item
                                            xs="12"
                                            sm="8"
                                            md="8"
                                            lg="8"
                                            sx={{
                                              padding: "0px 15px!important",
                                              display: "flex",
                                              alignItems: "flex-end",
                                              justifyContent: "flex-start",
                                            }}
                                          >
                                            <div className="plan-prices card-textAlign">
                                              {
                                                <NumberFormat
                                                  value={
                                                    this.state
                                                      .totalAvailableInventory
                                                  }
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  decimalScale={0}
                                                  fixedDecimalScale={true}
                                                />
                                              }
                                              <h2
                                                style={{
                                                  marginTop: "10px",
                                                }}
                                              >
                                                Total Available Inventory
                                              </h2>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </CardContent>
                                  </Card>
                                </Grid>
                                {/* packages ticket */}
                                {this.state?.packageData?.name ? (
                                  <Grid item xs={12} sm={6} md={6} lg={4}>
                                    <Card className="card-custom">
                                      <CardContent
                                        className="card-custom-inner"
                                        sx={{
                                          paddingTop: "14px !important",
                                          paddingLeft: "2px !important",
                                          paddingRight: "2px!important",
                                          paddingBottom: "7px !important",
                                        }}
                                      >
                                        <div className="account-have-img">
                                          <Grid container spacing={0}>
                                            <Grid
                                              item
                                              xs="12"
                                              sm="12"
                                              md="12"
                                              lg="12"
                                              sx={{
                                                padding: "0px 15px!important",
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "flex-start",
                                              }}
                                            >
                                              <Stack
                                                direction="row"
                                                spacing={2}
                                                sx={{
                                                  display: "flex",
                                                  justifyContent: "space-between",
                                                  width: "100%",
                                                }}
                                              >
                                                <Box>
                                                  <h2
                                                    style={{
                                                      color: "#002583",
                                                      fontWeight: "bold",
                                                      lineHeight: "13px",
                                                    }}
                                                  >
                                                    Package Details
                                                  </h2>
                                                </Box>
                                                <Box>
                                                  <Stack direction="row">
                                                    <Box>
                                                      <h2
                                                        style={{
                                                          lineHeight: "13px",
                                                        }}
                                                      >
                                                        Auto-Renew:{" "}
                                                      </h2>
                                                    </Box>
                                                    <Box>
                                                      <h2
                                                        style={{
                                                          lineHeight: "13px",
                                                        }}
                                                      >
                                                        {this.state.packageData
                                                          ?.userAutoRenewal ===
                                                          "on"
                                                          ? "ON"
                                                          : "OFF"}
                                                      </h2>
                                                    </Box>
                                                  </Stack>
                                                </Box>
                                              </Stack>
                                            </Grid>
                                            <Grid
                                              item
                                              xs="12"
                                              sm="12"
                                              md="12"
                                              lg="12"
                                              sx={{
                                                padding: "0px 15px!important",
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "flex-start",
                                              }}
                                            >
                                              <h2 style={{ lineHeight: "13px" }}>
                                                Name:{" "}
                                                {this.state.packageData?.name ??
                                                  "No Package"}
                                              </h2>
                                            </Grid>
                                            <Grid
                                              item
                                              xs="12"
                                              sm="12"
                                              md="12"
                                              lg="12"
                                              sx={{
                                                padding: "0px 15px!important",
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "flex-start",
                                              }}
                                            >
                                              <h2 style={{ lineHeight: "13px" }}>
                                                Time Left:{" "}
                                                {this?.state?.packageData
                                                  ?.userPackageValidUpto ? (
                                                  <CountdownRealTime
                                                    timeZone="America/New_York"
                                                    targetDate={
                                                      this?.state?.packageData
                                                        ?.userPackageValidUpto
                                                    }
                                                  />
                                                ) : null}
                                              </h2>
                                            </Grid>
                                            <Grid
                                              direction="column"
                                              xs="12"
                                              sm="12"
                                              md="12"
                                              lg="12"
                                              className="icon_size"
                                              sx={{
                                                width: "100% !important",
                                                display: "flex",
                                                alignItems: "baseline",
                                                padding: "0px 15px!important",
                                                justifyContent: {
                                                  xs: "center",
                                                  sm: "center",
                                                  md: "flex-start",
                                                },
                                              }}
                                            >
                                              <Box>
                                                <h2
                                                  style={{ lineHeight: "13px" }}
                                                >
                                                  Tickets Delivered:
                                                </h2>
                                              </Box>
                                              <LinearProgressBar
                                                data={this.state.packageData}
                                              />
                                            </Grid>
                                          </Grid>
                                        </div>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                ) : (
                                  <Grid item xs={12} sm={6} md={6} lg={4}>
                                    <Card
                                      className="card-custom"
                                      sx={{
                                        paddingBottom: {
                                          xl: "89px",
                                          lg: "89px",
                                          md: "67px",
                                          sm: "63px",
                                          xs: "63px",
                                        },
                                        paddingTop: {
                                          xl: "0px",
                                          lg: "0px",
                                          md: "0px",
                                          sm: "30px",
                                          xs: "50px",
                                        },
                                      }}
                                    >
                                      <CardContent
                                        className="card-custom-inner"
                                        sx={{
                                          paddingTop: {
                                            xl: "15px",
                                            lg: "8px",
                                            md: "8px",
                                            sm: "8px",
                                            xs: "8px",
                                          },
                                          paddingBottom: "7px !important",
                                        }}
                                      >
                                        <div className="account-have-img">
                                          <Grid
                                            container
                                            spacing={0}
                                            sx={{
                                              gap: {
                                                xl: 0,
                                                lg: 0,
                                                md: 0,
                                                sm: "10px",
                                                xs: "10px",
                                              },
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs="12"
                                              sm="12"
                                              md="5"
                                              lg="5"
                                              xl="5"
                                              sx={{
                                                padding: "0px 15px!important",
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: {
                                                  xl: "flex-start",
                                                  lg: "flex-start",
                                                  md: "flex-start",
                                                  sm: "flex-start",
                                                  xs: "space-evenly",
                                                },
                                              }}
                                            >
                                              <h2
                                                style={{
                                                  color: "#002583",
                                                  fontWeight: "bold",
                                                  lineHeight: "13px",
                                                }}
                                              >
                                                {" "}
                                                No Package
                                              </h2>
                                            </Grid>
                                            <Grid
                                              item
                                              xs="12"
                                              sm="12"
                                              md="7"
                                              lg="7"
                                              xl="7"
                                              sx={{
                                                padding: {
                                                  xl: "0px 0 0 15px!important",
                                                  lg: "0px 15px!important",
                                                  md: "7px 15px!important",
                                                  sm: "0px 15px!important",
                                                  xs: "0px 15px!important",
                                                },
                                                display: "flex",
                                                alignItems: "flex-start",
                                                justifyContent: {
                                                  xl: "flex-end",
                                                  lg: "flex-end",
                                                  md: "flex-end",
                                                  sm: "flex-start",
                                                  xs: "space-evenly",
                                                },
                                              }}
                                            >
                                              <Button
                                                component={Link}
                                                to="/packages"
                                                size="small"
                                                variant="contained"
                                                sx={{
                                                  backgroundColor:
                                                    "#002986!important",
                                                  color: "white!important",
                                                  textTransform: "none!important",
                                                  fontWeight: "bold",
                                                  fontFamily: "Barlow!important",
                                                }}
                                              >
                                                Purchase Package
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </div>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          {/*  */}
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={8}>
                              {/* chart js */}
                              <Grid
                                container
                                spacing={2}
                                sx={{ marginTop: "-2px" }}
                                className="graph-card"
                              >
                                <Grid item xs={12} sm={12} lg={12} md={12}>
                                  <Item sx={{ padding: "1rem 1rem" }}>
                                    <Grid
                                      container
                                      spacing={{ xs: 2, md: 2, lg: 2 }}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>
                                          <Typography
                                            sx={{
                                              fontSize: "18px",
                                              fontFamily: "Barlow!important",
                                              textAlign: "left",
                                              color: "#002986",
                                              fontWeight: "bold",
                                              padding: "4px 7px",
                                            }}
                                          >
                                            Tickets Delivered
                                          </Typography>

                                          <Typography
                                            sx={{
                                              fontSize: "18px",
                                              fontFamily: "Barlow!important",
                                              textAlign: "left!important",
                                              fontWeight: "800",
                                              color: "black",
                                              padding: "0px 10px",
                                              lineHeight: "1.0",
                                            }}
                                          >
                                            {
                                              <NumberFormat
                                                value={
                                                  this.state.totalAmount || 0
                                                }
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                decimalScale={0}
                                                fixedDecimalScale={true}
                                              />
                                            }
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={2}
                                        lg={2}
                                        sx={{
                                          display: "flex",
                                          justifyContent: "end",
                                        }}
                                      >
                                        <FormControlLabel
                                          control={<MonthSwitch defaultChecked />}
                                          onChange={(e) =>this.handleMonthlyCheck(e)}
                                          label="Monthly"
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={4}
                                        lg={4}
                                        className="datepicker-style"
                                      >
                                        {this.state.toggleDate ? (
                                          <DatePicker
                                            showMonthYearPicker
                                            selected={this.state.monthDate}
                                            startDate={this.state.monthStartDate}
                                            endDate={this.state.monthEndDate}
                                            onChange={(date) =>
                                              this.handleDate(date)
                                            }
                                            dateFormat="MMMM/yyyy"
                                            selectsRange
                                            customInput={
                                              <TextField
                                                variant="outlined"
                                                className="filter-input"
                                                size="small"
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment
                                                      position="end"
                                                      className="date-icon"
                                                    >
                                                      <div className="d-flex">
                                                        {this.state.crossCheck ? (
                                                          <IconButton
                                                            aria-label="toggle password visibility"
                                                            edge="end"
                                                          >
                                                            <EventIcon className="text-dark" />
                                                          </IconButton>
                                                        ) : (
                                                          <IconButton
                                                            edge="end"
                                                            onClick={() => this.clearDate()}
                                                          >
                                                            <ClearIcon className="text-dark" />
                                                          </IconButton>
                                                        )}
                                                      </div>
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            }
                                          />
                                        ) : (
                                          <DatePicker
                                            placeholderText="Search Date"
                                            selected={this.state.startDate}
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            monthsShown={this.state.monthsShown}
                                            selectsRange
                                            isClearable={true}
                                            customInput={
                                              <TextField
                                                variant="outlined"
                                                className="filter-input"
                                                size="small"
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment
                                                      position="end"
                                                      className="date-icon"
                                                    >
                                                      <div className="d-flex">
                                                        {this.state.crossCheck ? (
                                                          <IconButton
                                                            aria-label="toggle password visibility"
                                                            edge="end"
                                                          >
                                                            <EventIcon className="text-dark" />
                                                          </IconButton>
                                                        ) : (
                                                          <IconButton
                                                            edge="end"
                                                            onClick={() =>
                                                              this.clearDate()
                                                            }
                                                          >
                                                            <ClearIcon className="text-dark" />
                                                          </IconButton>
                                                        )}
                                                      </div>
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            }
                                            onChange={(dates) =>
                                              this.setStartDate(dates)
                                            }
                                          />
                                        )}
                                      </Grid>
                                    </Grid>

                                    <Bar options={options} data={datatickets} />
                                  </Item>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={4}
                              sx={{ paddingTop: "22px!important" }}
                            >
                              <Paper
                                sx={{
                                  backgroundColor: "#fff",
                                  padding: "1rem 0.3rem",
                                  height: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "0 1rem",
                                    // marginBottom: "0.6rem",
                                  }}
                                >
                                  <h6 className="notifaction_tittle">
                                    Notifications
                                  </h6>
                                  <MuiLink
                                    to="/notification"
                                    underline="none"
                                    component={Link}
                                    sx={{
                                      textDecoration: "none!important",
                                      color: "#002986!important",
                                      fontWeight: "500",
                                      fontSize: "13px",
                                      fontFamily: "Barlow!important",
                                    }}
                                  >
                                    View All
                                  </MuiLink>
                                </div>
                                {notificationList.length > 0 && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      px: 2.5,
                                    }}
                                  >
                                    <Box sx={{ flexGrow: 1 }}>
                                      <Typography variant="body2">
                                        You have{" "}
                                        {`${notificationList.length !== 0 &&
                                          notificationList.length
                                          } unread notification${notificationList.length > 0 && "s"
                                          }`}
                                      </Typography>{" "}
                                    </Box>
                                    {notificationList.length > 0 && (
                                      <MuiToolTip title=" Mark all as read">
                                        <IconButton
                                          color="primary"
                                          onClick={() =>
                                            this.handleMarkAllReadNotification()
                                          }
                                        >
                                          <DoneAllIcon />
                                        </IconButton>
                                      </MuiToolTip>
                                    )}
                                  </Box>
                                )}
                                <TableContainer
                                  sx={{ maxHeight: "20rem", padding: "10px" }}
                                >
                                  <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                      {notificationList.length > 0 ? (
                                        notificationList.map((data) => {
                                          return (
                                            <TableRow
                                              className="notification-row"
                                              sx={{ position: "relative" }}
                                            >
                                              <IconButton
                                                aria-label="close"
                                                onClick={() =>
                                                  this.clearDate(data)
                                                }
                                                sx={{
                                                  position: "absolute",
                                                  top: 0,
                                                  right: 0,
                                                  color: (theme) =>
                                                    theme.palette.grey[500],
                                                  p: 1,
                                                }}
                                              >
                                                <CloseIcon
                                                  sx={{ fontSize: "14px" }}
                                                />
                                              </IconButton>
                                              <TableCell className="text-left notification-data">
                                                {data.refId ? (
                                                  <a
                                                    href={`${data.link}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                  >
                                                    {data.title}
                                                  </a>
                                                ) : (
                                                  <>
                                                    {data.title ===
                                                      "Credit balance is low" ? (
                                                      <Link to="/purchase-credit">
                                                        {data.title}
                                                      </Link>
                                                    ) : (
                                                      <span>{data.title}</span>
                                                    )}
                                                  </>
                                                )}
                                                <Typography
                                                  sx={{
                                                    color: "#888",
                                                    fontSize: "13px",
                                                    fontFamily:
                                                      "Barlow!important",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {data.description}
                                                </Typography>
                                              </TableCell>
                                              <TimeAgo
                                                date={new Date(data.created)}
                                                style={{
                                                  fontSize: "13px",
                                                  position: "absolute",
                                                  right: "24px",
                                                  top: "17px",
                                                  color: "#888",
                                                }}
                                              />
                                            </TableRow>
                                          );
                                        })
                                      ) : (
                                        <div
                                          style={{
                                            fontSize: "20px",
                                            fontWeight: "bolder",
                                            textAlign: "center",
                                          }}
                                        >
                                          No Notifications
                                        </div>
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Paper>
                            </Grid>
                            {/* Notification pannel  */}
                          </Grid>
                        </>
                      ) : //  {/* if browser accses "Yes" and vbd acctteached "false" then show this panel */}
                        this.props?.UserProfile?.data?.access_browser === "Yes" &&
                          this.props?.UserProfile?.vbdaccattached === false ? (
                          <>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                container
                                direction="column"
                                xs={12}
                                sm={6}
                                spacing={2}
                              >
                                <Grid item>
                                  <Paper>
                                    <Card className="card-custom">
                                      <CardContent>
                                        <div className="credit-left-img">
                                          <Grid container spacing={0}>
                                            <Grid
                                              item
                                              xs="12"
                                              sm="3"
                                              md="3"
                                              lg="3"
                                              className="icon_size"
                                              sx={{
                                                display: "flex",
                                                alignItems: "baseline",
                                                justifyContent: {
                                                  xs: "center",
                                                  sm: "center",
                                                  md: "flex-start",
                                                },
                                              }}
                                            >
                                              <img src={Credit} alt="no images" />
                                            </Grid>
                                            <Grid
                                              item
                                              xs="12"
                                              sm="6"
                                              md="6"
                                              lg="6"
                                              sx={{
                                                padding: "0px 15px!important",
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "flex-start",
                                              }}
                                            >
                                              <div className="plan-prices card-textAlign">
                                                $
                                                {
                                                  <NumberFormat
                                                    value={
                                                      this.state.creditBalance ?? 0
                                                    }
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                  />
                                                }
                                                <h2
                                                  style={{
                                                    marginTop: "10px",
                                                  }}
                                                >
                                                  {" "}
                                                  Credit Remaining{" "}
                                                </h2>
                                              </div>
                                            </Grid>
                                            <Grid
                                              item
                                              xs="12"
                                              sm="6"
                                              md="3"
                                              lg="3"
                                              className="icon_size"
                                              sx={{
                                                display: "flex",
                                                alignItems: "baseline",
                                                justifyContent: {
                                                  xs: "center",
                                                  sm: "center",
                                                  md: "flex-start",
                                                },
                                              }}
                                            >
                                              <Button
                                                component={Link}
                                                to="/purchase-credit"
                                                size="small"
                                                variant="contained"
                                                className="add-credit-button add-credit-btn"
                                                disabled={
                                                  isAdd_credit_btn_status ===
                                                    false || differenceInHours < 48
                                                    ? true
                                                    : false
                                                }
                                                sx={{
                                                  backgroundColor:
                                                    isAdd_credit_btn_status ===
                                                      false ||
                                                      differenceInHours < 48
                                                      ? "#E0E0E0!important"
                                                      : "#002986!important",
                                                  color:
                                                    isAdd_credit_btn_status ===
                                                      false ||
                                                      differenceInHours < 48
                                                      ? "#A6A6A6 !important"
                                                      : "white!important",
                                                  textTransform: "none!important",
                                                  fontWeight: "bold",
                                                  fontFamily: "Barlow!important",
                                                  marginTop: "-21px",
                                                  minWidth: "90px",
                                                  marginLeft: "49px",
                                                }}
                                              >
                                                Add Credit
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </div>
                                      </CardContent>
                                    </Card>
                                  </Paper>
                                </Grid>
                                <Grid item>
                                  <Paper>
                                    <Card className="card-custom">
                                      <CardContent>
                                        <div className="account-have-img">
                                          <Grid container spacing={0}>
                                            <Grid
                                              item
                                              xs="12"
                                              sm="3"
                                              md="3"
                                              lg="3"
                                              className="icon_size"
                                              sx={{
                                                display: "flex",
                                                alignItems: "baseline",
                                                justifyContent: {
                                                  xs: "center",
                                                  sm: "center",
                                                  md: "flex-start",
                                                },
                                              }}
                                            >
                                              <img src={Pending} alt="no images" />
                                            </Grid>
                                            <Grid
                                              item
                                              xs="12"
                                              sm="8"
                                              md="8"
                                              lg="8"
                                              sx={{
                                                padding: "0px 15px!important",
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "flex-start",
                                              }}
                                            >
                                              <div className="plan-prices card-textAlign">
                                                {
                                                  <NumberFormat
                                                    value={
                                                      this.state
                                                        .totalactiveLicenses ?? 0
                                                    }
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    decimalScale={0}
                                                    fixedDecimalScale={true}
                                                  />
                                                }
                                                <h2
                                                  style={{
                                                    marginTop: "10px",
                                                  }}
                                                >
                                                  Total Licenses
                                                </h2>
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </div>
                                      </CardContent>
                                    </Card>
                                  </Paper>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                container
                                direction="column"
                                xs={12}
                                sm={6}
                                spacing={2}
                              >
                                <Grid item>
                                  <Paper
                                    // sx={{  height: "35vh"}}
                                    className="browser-accses-userdashboard"
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "0 1rem",
                                        marginBottom: "0.6rem",
                                      }}
                                    >
                                      <h6 className="notifaction_tittle">
                                        Notifications
                                      </h6>
                                      <MuiLink
                                        to="/notification"
                                        underline="none"
                                        component={Link}
                                        sx={{
                                          textDecoration: "none!important",
                                          color: "#002986!important",
                                          fontWeight: "500",
                                          fontSize: "13px",
                                          fontFamily: "Barlow!important",
                                        }}
                                      >
                                        View All
                                      </MuiLink>
                                    </div>
                                    {notificationList.length > 0 && (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          px: 2.5,
                                        }}
                                      >
                                        <Box sx={{ flexGrow: 1 }}>
                                          <Typography variant="body2">
                                            You have{" "}
                                            {`${notificationList.length !== 0 &&
                                              notificationList.length
                                              } unread notification${notificationList.length > 0 && "s"
                                              }`}
                                          </Typography>{" "}
                                        </Box>
                                        {notificationList.length > 0 && (
                                          <MuiToolTip title=" Mark all as read">
                                            <IconButton
                                              color="primary"
                                              onClick={() =>
                                                this.handleMarkAllReadNotification()
                                              }
                                            >
                                              <DoneAllIcon />
                                            </IconButton>
                                          </MuiToolTip>
                                        )}
                                      </Box>
                                    )}
                                    <TableContainer
                                      sx={{
                                        maxHeight: "31rem",
                                        padding:
                                          notificationList.length > 0
                                            ? "10px"
                                            : "86px",
                                      }}
                                    >
                                      <Table stickyHeader aria-label="sticky table">
                                        <TableBody>
                                          {notificationList.length > 0 ? (
                                            notificationList.map((data) => {
                                              return (
                                                <TableRow
                                                  className="notification-row"
                                                  sx={{ position: "relative" }}
                                                >
                                                  <IconButton
                                                    aria-label="close"
                                                    onClick={() =>
                                                      this.clearDate(data)
                                                    }
                                                    sx={{
                                                      position: "absolute",
                                                      top: 0,
                                                      right: 0,
                                                      color: (theme) =>
                                                        theme.palette.grey[500],
                                                      p: 1,
                                                    }}
                                                  >
                                                    <CloseIcon
                                                      sx={{ fontSize: "14px" }}
                                                    />
                                                  </IconButton>
                                                  <TableCell className="text-left notification-data">
                                                    {data.refId ? (
                                                      <a
                                                        href={`${data.link}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        {data.title}
                                                      </a>
                                                    ) : (
                                                      <>
                                                        {data.title ===
                                                          "Credit balance is low" ? (
                                                          <Link to="/purchase-credit">
                                                            {data.title}
                                                          </Link>
                                                        ) : (
                                                          <span>{data.title}</span>
                                                        )}
                                                      </>
                                                    )}
                                                    <Typography
                                                      sx={{
                                                        color: "#888",
                                                        fontSize: "13px",
                                                        fontFamily:
                                                          "Barlow!important",
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      {data.description}
                                                    </Typography>
                                                  </TableCell>
                                                  <TimeAgo
                                                    date={new Date(data.created)}
                                                    style={{
                                                      fontSize: "13px",
                                                      position: "absolute",
                                                      right: "24px",
                                                      top: "17px",
                                                      color: "#888",
                                                    }}
                                                  />
                                                </TableRow>
                                              );
                                            })
                                          ) : (
                                            <div
                                              style={{
                                                fontSize: "20px",
                                                fontWeight: "bolder",
                                                textAlign: "center",
                                                // paddingTop:" 0 0 0s 0px"
                                              }}
                                            >
                                              No Notifications
                                            </div>
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Paper>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          // if  access browser and vbd accatted both  false then  show this panel
                          this.props?.UserProfile?.data?.access_browser === "No" &&
                          this.props?.UserProfile?.vbdaccattached === false && (
                            <>
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12} lg={8}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                      <Card className="card-custom">
                                        <CardContent>
                                          <div className="credit-left-img">
                                            <Grid container spacing={0}>
                                              <Grid
                                                item
                                                xs="12"
                                                sm="3"
                                                md="3"
                                                lg="3"
                                                className="icon_size"
                                                sx={{
                                                  display: "flex",
                                                  alignItems: "baseline",
                                                  justifyContent: {
                                                    xs: "center",
                                                    sm: "center",
                                                    md: "flex-start",
                                                  },
                                                }}
                                              >
                                                <img src={Credit} alt="no images" />
                                              </Grid>
                                              <Grid
                                                item
                                                xs="12"
                                                sm="6"
                                                md="6"
                                                lg="6"
                                                sx={{
                                                  padding: "0px 15px!important",
                                                  display: "flex",
                                                  alignItems: "flex-end",
                                                  justifyContent: "flex-start",
                                                }}
                                              >
                                                <div className="plan-prices card-textAlign">
                                                  $
                                                  {
                                                    <NumberFormat
                                                      value={"0.00"}
                                                      displayType={"text"}
                                                      thousandSeparator={true}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                    />
                                                  }
                                                  <h2
                                                    style={{
                                                      marginTop: "10px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Credit Remaining{" "}
                                                  </h2>
                                                </div>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                      <Card className="card-custom">
                                        <CardContent>
                                          <div className="account-have-img">
                                            <Grid container spacing={0}>
                                              <Grid
                                                item
                                                xs="12"
                                                sm="3"
                                                md="3"
                                                lg="3"
                                                className="icon_size"
                                                sx={{
                                                  display: "flex",
                                                  alignItems: "baseline",
                                                  justifyContent: {
                                                    xs: "center",
                                                    sm: "center",
                                                    md: "flex-start",
                                                  },
                                                }}
                                              >
                                                <img
                                                  src={Pending}
                                                  alt="no images"
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                xs="12"
                                                sm="8"
                                                md="8"
                                                lg="8"
                                                sx={{
                                                  padding: "0px 15px!important",
                                                  display: "flex",
                                                  alignItems: "flex-end",
                                                  justifyContent: "flex-start",
                                                }}
                                              >
                                                <div className="plan-prices card-textAlign">
                                                  {
                                                    <NumberFormat
                                                      value={"0.00"}
                                                      displayType={"text"}
                                                      thousandSeparator={true}
                                                      decimalScale={0}
                                                      fixedDecimalScale={true}
                                                    />
                                                  }
                                                  <h2
                                                    style={{
                                                      marginTop: "10px",
                                                    }}
                                                  >
                                                    Total Available Inventory
                                                  </h2>
                                                </div>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    spacing={2}
                                    sx={{ marginTop: "10px" }}
                                    className="graph-card"
                                  >
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                      <Item sx={{ padding: "1rem 1rem" }}>
                                        <Grid
                                          container
                                          spacing={{ xs: 2, md: 2, lg: 2 }}
                                        >
                                          <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div>
                                              <Typography
                                                sx={{
                                                  fontSize: "18px",
                                                  fontFamily: "Barlow!important",
                                                  textAlign: "left",
                                                  color: "#002986",
                                                  fontWeight: "bold",
                                                  padding: "4px 7px",
                                                }}
                                              >
                                                Tickets Delivered
                                              </Typography>

                                              <Typography
                                                sx={{
                                                  fontSize: "18px",
                                                  fontFamily: "Barlow!important",
                                                  textAlign: "left!important",
                                                  fontWeight: "800",
                                                  color: "black",
                                                  padding: "0px 10px",
                                                  lineHeight: "1.0",
                                                }}
                                              >
                                                {
                                                  <NumberFormat
                                                    value={"0.00"}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    decimalScale={0}
                                                    fixedDecimalScale={true}
                                                  />
                                                }
                                              </Typography>
                                            </div>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={2}
                                            lg={2}
                                            sx={{
                                              display: "flex",
                                              justifyContent: "end",
                                            }}
                                          >
                                            <FormControlLabel
                                              control={
                                                <MonthSwitch defaultChecked />
                                              }
                                              onChange={(e) =>this.handleMonthlyCheck(e)}
                                              label="Monthly"
                                            />
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={4}
                                            lg={4}
                                            className="datepicker-style"
                                          >
                                            {this?.state?.toggleDate ? (
                                              <DatePicker
                                                showMonthYearPicker
                                                selected={this?.state?.monthDate}
                                                startDate={
                                                  this?.state?.monthStartDate
                                                }
                                                endDate={this?.state?.monthEndDate}
                                                onChange={(date) =>
                                                  this?.handleDate(date)
                                                }
                                                dateFormat="MMMM/yyyy"
                                                selectsRange
                                                customInput={
                                                  <TextField
                                                    variant="outlined"
                                                    className="filter-input"
                                                    size="small"
                                                    InputProps={{
                                                      endAdornment: (
                                                        <InputAdornment
                                                          position="end"
                                                          className="date-icon"
                                                        >
                                                          <div className="d-flex">
                                                            {this?.state
                                                              ?.crossCheck ? (
                                                              <IconButton
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                              >
                                                                <EventIcon className="text-dark" />
                                                              </IconButton>
                                                            ) : (
                                                              <IconButton
                                                                edge="end"
                                                                onClick={() => this.clearDate()}
                                                              >
                                                                <ClearIcon className="text-dark" />
                                                              </IconButton>
                                                            )}
                                                          </div>
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                  />
                                                }
                                              />
                                            ) : (
                                              <DatePicker
                                                placeholderText="Search Date"
                                                selected={this?.state?.startDate}
                                                startDate={this?.state?.startDate}
                                                endDate={this?.state?.endDate}
                                                monthsShown={
                                                  this?.state?.monthsShown
                                                }
                                                selectsRange
                                                isClearable={true}
                                                customInput={
                                                  <TextField
                                                    variant="outlined"
                                                    className="filter-input"
                                                    size="small"
                                                    InputProps={{
                                                      endAdornment: (
                                                        <InputAdornment
                                                          position="end"
                                                          className="date-icon"
                                                        >
                                                          <div className="d-flex">
                                                            {this?.state
                                                              ?.crossCheck ? (
                                                              <IconButton
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                              >
                                                                <EventIcon className="text-dark" />
                                                              </IconButton>
                                                            ) : (
                                                              <IconButton
                                                                edge="end"
                                                                onClick={() =>
                                                                  this.clearDate()
                                                                }
                                                              >
                                                                <ClearIcon className="text-dark" />
                                                              </IconButton>
                                                            )}
                                                          </div>
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                  />
                                                }
                                                onChange={(dates) =>
                                                  this.setStartDate(dates)
                                                }
                                              />
                                            )}
                                          </Grid>
                                        </Grid>
                                        <Bar
                                          options={options ?? null}
                                          data={datatickets ?? []}
                                        />
                                      </Item>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {/* Notification pannel  */}
                                <Grid item xs={12} sm={12} md={12} lg={4}>
                                  <Paper
                                    sx={{
                                      backgroundColor: "#fff",
                                      padding: "1rem 0.3rem",
                                      height: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "0 1rem",
                                        marginBottom: "0.6rem",
                                      }}
                                    >
                                      <h6 className="notifaction_tittle">
                                        Notifications
                                      </h6>
                                      <MuiLink
                                        to="/notification"
                                        underline="none"
                                        component={Link}
                                        sx={{
                                          textDecoration: "none!important",
                                          color: "#002986!important",
                                          fontWeight: "500",
                                          fontSize: "13px",
                                          fontFamily: "Barlow!important",
                                        }}
                                      >
                                        View All
                                      </MuiLink>
                                    </div>
                                    {notificationList &&
                                      notificationList?.length > 0 && (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            px: 2.5,
                                          }}
                                        >
                                          <Box sx={{ flexGrow: 1 }}>
                                            <Typography variant="body2">
                                              You have{" "}
                                              {`${notificationList?.length !== 0 &&
                                                notificationList?.length
                                                } unread notification${notificationList?.length > 0 && "s"
                                                }`}
                                            </Typography>{" "}
                                          </Box>
                                          {notificationList &&
                                            notificationList?.length > 0 && (
                                              <MuiToolTip title=" Mark all as read">
                                                <IconButton
                                                  color="primary"
                                                  onClick={() =>
                                                    this.handleMarkAllReadNotification()
                                                  }
                                                >
                                                  <DoneAllIcon />
                                                </IconButton>
                                              </MuiToolTip>
                                            )}
                                        </Box>
                                      )}
                                    <TableContainer
                                      sx={{ maxHeight: "31rem", padding: "10px" }}
                                    >
                                      <Table stickyHeader aria-label="sticky table">
                                        <TableBody>
                                          {notificationList &&
                                            notificationList?.length > 0 ? (
                                            notificationList &&
                                            notificationList?.map((data) => {
                                              return (
                                                <TableRow
                                                  className="notification-row"
                                                  sx={{ position: "relative" }}
                                                >
                                                  <IconButton
                                                    aria-label="close"
                                                    onClick={() =>
                                                      this?.clearDate(data)
                                                    }
                                                    sx={{
                                                      position: "absolute",
                                                      top: 0,
                                                      right: 0,
                                                      color: (theme) =>
                                                        theme?.palette?.grey[500],
                                                      p: 1,
                                                    }}
                                                  >
                                                    <CloseIcon
                                                      sx={{ fontSize: "14px" }}
                                                    />
                                                  </IconButton>
                                                  <TableCell className="text-left notification-data">
                                                    {data?.refId ? (
                                                      <a
                                                        href={`${data.link}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        {data?.title ?? ""}
                                                      </a>
                                                    ) : (
                                                      <>
                                                        {data.title ===
                                                          "Credit balance is low" ? (
                                                          <Link to="/purchase-credit">
                                                            {data?.title ?? ""}
                                                          </Link>
                                                        ) : (
                                                          <span>
                                                            {data?.title ?? ""}
                                                          </span>
                                                        )}
                                                      </>
                                                    )}
                                                    <Typography
                                                      sx={{
                                                        color: "#888",
                                                        fontSize: "13px",
                                                        fontFamily:
                                                          "Barlow!important",
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      {data?.description ?? ""}
                                                    </Typography>
                                                  </TableCell>
                                                  <TimeAgo
                                                    date={
                                                      new Date(data?.created) ??
                                                      new Date()
                                                    }
                                                    style={{
                                                      fontSize: "13px",
                                                      position: "absolute",
                                                      right: "24px",
                                                      top: "17px",
                                                      color: "#888",
                                                    }}
                                                  />
                                                </TableRow>
                                              );
                                            })
                                          ) : (
                                            <div
                                              style={{
                                                fontSize: "20px",
                                                fontWeight: "bolder",
                                                textAlign: "center",
                                              }}
                                            >
                                              No Notifications
                                            </div>
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Paper>
                                </Grid>
                              </Grid>
                            </>
                          )
                        )}
                  </main>
                ) : (
                  <ContainerLoader />
                )}
              </div>
              <AlertMessage
                open={this.state.open}
                message={this.state.message}
                severity={this.state.successError}
                closeCall={this.handleClose}
              />
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserProfile: state.UserProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetName: (name) => dispatch({ type: "setusername", username: name }),
    getUserProfile: () => dispatch(UserProfileAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Userdashboard);
