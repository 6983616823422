import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axios from "axios";

// mui icons and svg icon
import AddIcon from "@mui/icons-material/Add";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Warning from "images/user-dashboard/warning-icon.svg";
import Warningyellow from "images/user-dashboard/warning-yellow.svg";
import Tickicon from "images/user-dashboard/tick-icon.svg";
import LoadPassword from "images/load_password.svg";
import { HiDotsHorizontal } from "react-icons/hi";
import CancelIcon from "@mui/icons-material/Cancel";

// import components
import Header from "components/header/header";
import Footer from "components/footer/footer";
import Usersidebar from "components/user-sidebar/user-sidebar";
import AlertMessage from "components/message/message";
import DataTable from "components/DataTable/DataTable";
import { ContainerLoader } from "components/common/ContainerLoader";

// import scss file
import "pages/userdashboard/userdashboard.scss";

//  mui components
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton
} from "@mui/material";
import Cookies from "js-cookie";

const fields = [
  {
    key: "Source",
    label: "Source",
    sort: true,
  },
  {
    key: "UserName",
    label: "UserName",
    sort: true,
  },
  {
    key: "Password",
    label: "Password",
    sort: false,
  },
  {
    key: "Status",
    label: "Status",
    sort: false,
  },
  {
    key: "Actions",

    label: "Actions",
    sort: false,
  },
];

class MarketPoslist extends React.Component {
  state = {
    hidepassword: false,
    loadpass: false,
    vendorList: [],
    loader: false,
    usersData: [
      {
        Source: "Marketplace",
        UserName: "Daniel_123",
        Password: "daniel*#&2v!",
      },
      { Source: "POS", UserName: "ticket@256", Password: "daniel*#&2v!" },
      { Source: "POS", UserName: "admin852", Password: "daniel*#&2v!" },
      {
        Source: "Marketplace",
        UserName: "globe5263",
        Password: "daniel*#&2v!",
      },
      {
        Source: "Marketplace",
        UserName: "daniel7412",
        Password: "daniel*#&2v!",
      },
      { Source: "POS", UserName: "liberty_ticket", Password: "daniel*#&2v!" },
      {
        Source: "Marketplace",
        UserName: "accounts523",
        Password: "acc$*56@#5",
      },
    ],
    indexNum: "",
    modalShow: false,
    deleteId: "",
  };
  async componentDidMount() {
    this.setState({
      loader: true,
    });
    const vendorList_api_url =
      process.env.REACT_APP_API_URL + "/api/account/getAllAccounts";
    await axios
      .post(
        vendorList_api_url,
        { account_type: ["Marketplace", "POS"] },
        {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        }
      )
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            vendorList: data,
            open: false,
            message: "",
            successError: "success",
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  }
  hiddenPassword = (itemval) => {
    if (this.state.hidepassword) {
      this.setState({ hidepassword: false, indexNum: "", loadpass: false });
    } else {
      this.setState(
        { hidepassword: true, loadpass: true, indexNum: itemval.id },
        () => {
          const pass_api_url =
            process.env.REACT_APP_API_URL +
            "/api/account/accountDetailWithPlainPass";
          axios
            .post(
              pass_api_url,
              { id: itemval.id },
              {
                headers: {
                  authorization:
                    "Bearer " + Cookies.get("accessToken"),
                },
              }
            )
            .then((response) => {
              const { data } = response;
              if (data.error) {
                this.setState({
                  hidepassword: false,
                  indexNum: "",
                  loadpass: false,
                });
              } else {
                const { vendorList } = this.state;
                var index = vendorList.data
                  .map(function (v) {
                    return v._id;
                  })
                  .indexOf(itemval.id);
                vendorList["data"][index]["password"] = data.data.password;
                this.setState({ loadpass: false, vendorList });
              }
            })
            .catch((error) => {
              this.setState({
                hidepassword: false,
                indexNum: "",
                loadpass: false,
              });
            });
        }
      );
    }
  };

  setModalShow = (id) => {
    this.setState({
      modalShow: true,
      deleteId: id,
    });
  };

  onHide = () => {
    this.setState({
      modalShow: false,
    });
  };

  callDelete = async () => {
    this.setState({
      modalShow: false,
    });

    const data = {
      id: this.state.deleteId,
    };

    const userDelete_api_url =
      process.env.REACT_APP_API_URL + "/api/account/deleteAccount";
    await axios
      .post(userDelete_api_url, data, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.data.message,
            successError: "error",
          });
        } else {
          if (data.success) {
            // Get All data
            const vendorList_api_url =
              process.env.REACT_APP_API_URL + "/api/account/getAllAccounts";
            axios
              .post(
                vendorList_api_url,
                { account_type: ["Marketplace", "POS"] },
                {
                  headers: {
                    authorization:
                      "Bearer " + Cookies.get("accessToken"),
                  },
                }
              )
              .then((response) => {
                const { data } = response;

                if (data.error) {
                  this.setState({
                    loader: false,
                    open: true,
                    message: "Something went wrong",
                    successError: "error",
                  });
                } else {
                  this.setState({
                    loader: false,
                    vendorList: data,
                    open: false,
                    message: "",
                    successError: "success",
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  loader: false,
                  open: true,
                  message: "Something went wrong",
                  successError: "error",
                });
              });
          } else {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              successError: "error",
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  gotoScanJobs = (data) => {
    this.props.history.push(`/skybox-scan-jobs/${data.id}`);
  };
  scanAPiCall = async (data) => {
    const param = {
      id: data.id,
      scantype: "Skybox",
    };
    this.setState({
      loader: true,
    });

    // const sourcename = data.Source;
    /*if(data.Source === "Skybox") {
            var vendorListScan_api_url = process.env.REACT_APP_API_URL + "/api/user/scanForSkybox"
        }else {
            var vendorListScan_api_url = process.env.REACT_APP_API_URL + "/api/user/scanForMarketplace"
        }*/

    const vendorListScan_api_url =
      "https://d1wzmy4qssla32.cloudfront.net/api/user/scanAccount";
    await axios
      .post(vendorListScan_api_url, param, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: response.data.message,
            successError: "error",
          });
        } else {
          if (data.success) {
            // Get All data
            const success_message = data.message;
            const vendorList_api_url =
              process.env.REACT_APP_API_URL + "/api/account/getAllAccounts";
            axios
              .post(
                vendorList_api_url,
                { account_type: ["Marketplace", "POS"] },
                {
                  headers: {
                    authorization:
                      "Bearer " + Cookies.get("accessToken"),
                  },
                }
              )
              .then((response) => {
                const { data } = response;
                if (data.error) {
                  this.setState({
                    loader: false,
                    open: true,
                    message: "Something went wrong",
                    successError: "error",
                  });
                } else {
                  this.setState({
                    loader: false,
                    vendorList: data,
                    open: true,
                    message: success_message,
                    successError: "success",
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  loader: false,
                  open: true,
                  message: "Something went wrong",
                  successError: "error",
                });
              });
          } else {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              successError: "error",
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  handleClose = (event, reason) => {
    this.setState({
      open: false,
    });
  };
  render() {
    const btnstyle = {
      backgroundColor: "#002986!important",
      color: "white!important",
      padding: "2px 5px!important",
      textTransform: "none!important",
      fontWeight: "bold",
      margin: "0 6px",
      fontFamily: "Barlow!important",
    };

    const {
      hidepassword,
      indexNum,
      vendorList,
      modalShow,
      loadpass,
      loader
    } = this.state;

    const items = (vendorList.data || [])
      .filter((data) => ["POS", "Marketplace"].includes(data.account_type))
      .map((data) => ({
        id: data._id,
        status: data.is_active,
        Source: data.account_type === "POS" ? data.pos : data.marketplace,
        UserName: data.username,
        Password: data.password,
      }));
    if (items.length === 0) {
      console.log("No Data");
    }

    return (
      <>
        <div className="user-dashboard-wrapper">
          <Helmet>
            <title>Marketplace & POS list</title>
          </Helmet>
          <Header />
          <div className="c-app c-default-layout user-dashboard-body">
            <Usersidebar />
            <div className="c-wrapper">
              <div className="c-body">
                <main className="c-main">
                  <Grid container spacing={2}>
                    <Grid item xs={12} className="space-top-col">
                      <Card sx={{ p: 1 }}>
                        <CardHeader
                          title="Marketplace & POS Accounts"

                          action={
                            <Button
                              component={Link}
                              size="large"
                              variant="contained"
                              sx={{
                                backgroundColor: "#002986!important",
                                color: "white!important",
                                textTransform: "none!important",
                                fontWeight: "bold",
                                fontFamily: "Barlow!important",
                              }}
                              startIcon={<AddIcon />}
                              to="/add-account"
                            >
                              Add
                            </Button>
                          }
                        />

                        <AlertMessage
                          open={this.state.open}
                          message={this.state.message}
                          severity={this.state.successError}
                          closeCall={this.handleClose}
                        />
                        {!loader ? (
                          <CardContent>
                            <DataTable
                              responsive
                              items={items}
                              fields={fields}
                              itemsPerPage={10}
                              hover
                              sorter
                              pagination
                              className="vendor-td"
                              scopedSlots={{
                                Password: (item, index) => {
                                  return (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {indexNum === item.id ? (
                                        <>
                                          {loadpass ? (
                                            <img src={LoadPassword} alt="" />
                                          ) : (
                                            <>
                                              {hidepassword
                                                ? item.Password
                                                : "*******"}
                                            </>
                                          )}{" "}
                                          <VisibilityOffIcon
                                            style={{
                                              cursor: "pointer",
                                              fontSize: "1.3rem",
                                              color: "#002986",
                                            }}
                                            onClick={() =>
                                              this.hiddenPassword(item)
                                            }
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <HiDotsHorizontal
                                            style={{
                                              margin: "0px",
                                              padding: "0px",
                                              position: "relative",
                                              left: "2px",
                                              fontSize: "30px",
                                            }}
                                          />
                                          <HiDotsHorizontal
                                            style={{ fontSize: "30px" }}
                                          />
                                          <VisibilityIcon
                                            style={{
                                              cursor: "pointer",
                                              fontSize: "1.3rem",
                                              color: "#002986",
                                            }}
                                            onClick={() =>
                                              this.hiddenPassword(item)
                                            }
                                          />
                                        </>
                                      )}
                                    </Box>
                                  );
                                },

                                Status: (statusItem, inde) => {
                                  return (
                                    <div className="flex">
                                      <div className="icon-space-left">
                                        {/* {
                                                                                                statusItem.Source ===  "Marketplace" ? <img src={Warning} className="table-data-icon"/> : statusItem.Source ===  "POS" ?  <img src={Warningyellow} className="table-data-icon" /> : <img src={Tickicon} className="table-data-icon"/>
                                                                                            } */}

                                        {statusItem.status === 3 ? (
                                          <img
                                            alt="warning"
                                            src={Warning}
                                            className="table-data-icon"
                                          />
                                        ) : statusItem.status === 2 ? (
                                          <img
                                            alt="yellowWarning"
                                            src={Warningyellow}
                                            className="table-data-icon"
                                          />
                                        ) : statusItem.status === 1 ? (
                                          <img
                                            alt="tick"
                                            src={Tickicon}
                                            className="table-data-icon"
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  );
                                },

                                Actions: (item, index) => {
                                  return (
                                    <Box sx={{ display: "flex" }}>
                                      <Button
                                        component={Link}
                                        size="small"
                                        variant="contained"
                                        sx={btnstyle}
                                        to={`/edit-market-pos/${item.id}`}
                                      >
                                        Edit
                                      </Button>

                                      {item.Source === "Skybox" && (
                                        <Button
                                          type="button"
                                          size="small"
                                          variant="contained"
                                          sx={btnstyle}
                                          onClick={() => this.gotoScanJobs(item)}
                                        >
                                          View Scan
                                        </Button>
                                      )}

                                      <Button
                                        type="button"
                                        size="small"
                                        variant="contained"
                                        sx={btnstyle}
                                        onClick={() => this.scanAPiCall(item)}
                                      >
                                        Scan
                                      </Button>

                                      <Button
                                        type="button"
                                        size="small"
                                        variant="contained"
                                        sx={btnstyle}
                                        onClick={() => this.setModalShow(item.id)}
                                      >
                                        Delete
                                      </Button>
                                    </Box>
                                  );
                                },
                              }}
                            />
                            <Dialog
                              fullWidth={true}
                              scroll="body"
                              maxWidth="sm"
                              open={modalShow}
                              onClose={this.onHide}
                            >
                              <DialogTitle>Delete</DialogTitle>
                              <DialogContent>
                                <IconButton
                                  aria-label="close"
                                  onClick={() => this.onHide()}
                                  sx={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    color: (theme) => theme.palette.grey[500],
                                    p: 1,
                                  }}
                                >
                                  <CancelIcon />
                                </IconButton>
                                <DialogContentText>
                                  Are you sure you want to Delete this action can
                                  not be revert ?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={this.onHide}
                                  sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow",
                                    textTransform: "none",
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={this.callDelete}
                                  sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow",
                                    textTransform: "none",
                                  }}
                                >
                                  Delete
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </CardContent>
                        ) : (
                          <ContainerLoader />
                        )}
                      </Card>
                    </Grid>
                  </Grid>
                </main>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}
export default MarketPoslist;
