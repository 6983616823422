import React from "react";
import InstructionPages from "./containers/instructionguide/InstructionsPages";

const BrowserSessions = React.lazy(() => {
  import("./containers/browser/browserSessions/BrowserSessions")
})

const UsersPackages = React.lazy(() => {
  import("./containers/managePackage/UsersPackages")
})
const Package = React.lazy(() => {
  import("./pages/userdashboard/browser/buyPackages/Packages")
})
const Affiliates = React.lazy(() => {
  import("./pages/userdashboard/affiliates/Affiliates")
})
const PackageManage = React.lazy(() => {
  import("./containers/managePackage/PackageManage")
})
const ManageAffiliates = React.lazy(() => {
  import("./containers/manageAffiliates/ManageAffiliates")
})
const EndUserLicenseAgreementUserPanel = React.lazy(() => {
  import("./pages/userdashboard/browser/endUserLicenseAgreement/EndUserLicenseAgreementUserPanel")
})

const ManageBrowserDetail = React.lazy(() => {
  import("./containers/browser/manageBrowser/ManageBrowserDetail")
})

const EndUserLicenseAgreement = React.lazy(() => {
  import("./containers/browser/endUserLicenseAgreement/EndUserLicenseAgreement")
})

const ManageBrowser = React.lazy(() => {
  import("./containers/browser/manageBrowser/ManageBrowser")
})

const HistoryofNotification = React.lazy(() => {
  import("./pages/userdashboard/HistoryofNotification")
})

const AdminLicensesLogs = React.lazy(() => {
  import("./containers/browser/managelicenses/adminlicenseslog/LicensesLogs")
})
const PurchaseCredit = React.lazy(() => {
  import("./pages/userdashboard/browser/purchaseCredit/PurchaseCredit")
})

const LinksUsedDetail = React.lazy(() => {
  import("./containers/tvTab/ManageLinks/LinksUsedDetail")
})

const LinksDeliveredDetail = React.lazy(() => {
  import("./containers/tvTab/ManageLinks/LinksDeliveredDetail")
})

const LinksUsed = React.lazy(() => {
  import("./containers/tvTab/ManageLinks/LinksUsed")
})

const LinksDelivered = React.lazy(() => {
  import("./containers/tvTab/ManageLinks/LinksDelivered")
})

const ReleaseNotes = React.lazy(() => {
  import("./pages/userdashboard/browser/releaseNotes/ReleaseNotes")
})

const AddReleaseNotes = React.lazy(() => {
  import("./containers/browser/releaseNotes")
})

const ManageLicenses = React.lazy(() =>
  import("./containers/browser/managelicenses/ManageLicenses")
);

const LicenseSummary = React.lazy(() =>
  import("./pages/userdashboard/browser/licensesSummary/NewLicensesSummary")
);
const WhatsNew = React.lazy(() =>
  import("./containers/browser/what'snew/whatsnew")
);

const Dashboard = React.lazy(() => import("./containers/dashboard/Dashboard"));
const Usermanage = React.lazy(() =>
  import("./containers/usermanagement/usermanagement")
);
const Adduser = React.lazy(() => import("./containers/usermanagement/useradd"));
const Userview = React.lazy(() =>
  import("./containers/usermanagement/userview")
);
const Useredit = React.lazy(() =>
  import("./containers/usermanagement/useredit")
);
const AddCommunicationUser = React.lazy(() =>
  import("./containers/communicationusers/add")
);
const EditCommunicationUser = React.lazy(() =>
  import("./containers/communicationusers/edit")
);
const ListCommunicationUser = React.lazy(() =>
  import("./containers/communicationusers/list")
);
const ProxyManagement = React.lazy(() =>
  import("./containers/browser/proxyManagement")
);
const InstructionSection = React.lazy(() =>
  import("./containers/instructionguide/InstructionSection")
);
const Profiles = React.lazy(() => import("./containers/browser/profiles"));
const AddProxy = React.lazy(() => import("./containers/browser/addProxy"));
const EditProxy = React.lazy(() => import("./containers/browser/editProxy"));
const ManageSplitLinks = React.lazy(() =>
  import("containers/splitLinks/splitLinks")
);
const Fingerprint = React.lazy(() =>
  import("./containers/browser/fingerprint")
);
const UserAgent = React.lazy(() => import("./containers/browser/useragent"));

const Contactlist = React.lazy(() =>
  import("./containers/contactmanage/contactlist")
);
const Contactview = React.lazy(() =>
  import("./containers/contactmanage/contactview")
);
const Changepassadmin = React.lazy(() =>
  import("./containers/changepassword/changepassword")
);
const Setuserpassword = React.lazy(() =>
  import("./pages/Setuserpassword/Setuserpassword")
);
const Inventorylist = React.lazy(() =>
  import("./containers/interactiveinvent/inventorylist")
);
const Soldinventlist = React.lazy(() =>
  import("./containers/soldinvent/soldinventlist")
);
const Userdashboard = React.lazy(() =>
  import("./pages/userdashboard/userdashboard")
);
//browser for user
const BrowserLoginDetail = React.lazy(() =>
  import("./pages/browser/browserLoginDetail")
);
const Vendorlist = React.lazy(() =>
  import("./pages/userdashboard/accounts/vendor/vendorlist")
);
const VendorEventslist = React.lazy(() =>
  import("./pages/userdashboard/accounts/vendor/vendorevents")
);
const VendorEventTickets = React.lazy(() =>
  import("./pages/userdashboard/accounts/vendor/vendoreventtickets")
);
const Inventory = React.lazy(() =>
  import("./pages/userdashboard/interactiveinventory")
);
const EventManagement = React.lazy(() =>
  import("./pages/userdashboard/EventManagement")
);
const Soldinventory = React.lazy(() =>
  import("./pages/userdashboard/soldinventory")
);
const Addcredit = React.lazy(() => import("./pages/userdashboard/addcredit"));
const Historypayment = React.lazy(() =>
  import("./pages/userdashboard/historypayment")
);
const UserMessageslist = React.lazy(() =>
  import("./pages/userdashboard/usermessageslist")
);
const Creditpackage = React.lazy(() =>
  import("./containers/creditpackages/creditpackages")
);
const Addcreditpackage = React.lazy(() =>
  import("./containers/creditpackages/addcreditpackages")
);
const Addcustomcredit = React.lazy(() =>
  import("./containers/customcredit/addcustomcredit")
);
const Removecustomcredit = React.lazy(() =>
  import("./containers/customcredit/removecustomcredit")
);
const CreditPackageView = React.lazy(() =>
  import("./containers/creditpackages/creditpackageview")
);
const CreditPackageEdit = React.lazy(() =>
  import("./containers/creditpackages/creditpackagesedit")
);
const VbdOrigAccounts = React.lazy(() =>
  import("./containers/vbdaccounts/vbdaccounts")
);
const AddVbdAccount = React.lazy(() => import("./containers/vbdaccounts/add"));
const VbdAccounts = React.lazy(() => import("./containers/vbdaccounts/lists"));
const EditVbdAccount = React.lazy(() =>
  import("./containers/vbdaccounts/edit")
);
const AutoMessagingFormat = React.lazy(() =>
  import("./containers/auto-messaging-format")
);
const AutoMessagingMailFormat = React.lazy(() =>
  import("./containers/auto-messaging-mail-format")
);
const VbdTicketPrice = React.lazy(() =>
  import("./containers/vbd-ticket-price")
);
const Messageslist = React.lazy(() => import("./containers/messages/lists"));
const IconmingMessageslist = React.lazy(() =>
  import("./containers/messages/IncomingMessagesList")
);
const SendMessage = React.lazy(() => import("./containers/messages/add"));
const Vendor = React.lazy(() => import("./containers/terms-management/vendor"));
const Teams = React.lazy(() => import("./containers/terms-management/teams"));
const League = React.lazy(() => import("./containers/terms-management/league"));
const InternalNotes = React.lazy(() =>
  import("./containers/terms-management/internalnotes")
);
const BlacklistedNumber = React.lazy(() =>
  import("./containers/terms-management/blacklistednumbers")
);
const Announcement = React.lazy(() =>
  import("./containers/Announcement/Announcement")
);
const TermsConditions = React.lazy(() =>
  import("./containers/Terms-Conditions/TermsConditions")
);
const TextRecoginition = React.lazy(() =>
  import("./containers/messages/TextRecognition")
);

const CommunicationPanel = React.lazy(() =>
  import("./containers/dashboard/CommunicationPanel")
);
const Message = React.lazy(() =>
  import("./containers/dashboard/chatPanel/Message")
);
const ChangeComPassword = React.lazy(() =>
  import("./containers/changepassword/changeCompassword")
);

const AdminVendorLists = React.lazy(() =>
  import("./containers/uservendors/lists")
);
const AdminVendorEventslist = React.lazy(() =>
  import("./containers/uservendors/vendorevents")
);
const AdminVendorEventTickets = React.lazy(() =>
  import("./containers/uservendors/vendoreventtickets")
);

const AddEvent = React.lazy(() =>
  import("pages/userdashboard/accounts/vendor/addEvent")
);
const EditVendorEvent = React.lazy(() =>
  import("pages/userdashboard/accounts/vendor/editVendorEvents")
);

const AdminCreditAdded = React.lazy(() => import("./containers/tvTab/ManageLinks/CreditAdded"));
const AdminCreditUsage = React.lazy(() => import("./containers/tvTab/ManageLinks/CreditUsage"));
const verifiedTickets = React.lazy(() =>
  import("pages/verifiedTickets/verifiedTickets")
);

const FontManagement = React.lazy(() =>
  import("./containers/browser/fontManagement")
);

const AddFont = React.lazy(() =>
  import("./containers/browser/addFont")
);

const EditFont = React.lazy(() =>
  import("./containers/browser/editFont")
);


const ManageLogs = React.lazy(() =>
  import("./containers/uservendors/ManageLogs")
);
const Historyofpayment = React.lazy(() =>
  import("./containers/history/HistoryofPayment")
);

const ManageScanLimit = React.lazy(() =>
  import("./containers/ManageScanLimit/ManageScanLimit")
);

const TMPasswordManager = React.lazy(() =>
  import('pages/userdashboard/accounts/tmPasswordManager/TMPasswordManager')
);
const routes = [
  {
    path: "/package",
    name: "Package",
    component: Package,
  },
  {
    path: "/users-packages",
    name: "UsersPackages",
    component: UsersPackages,
  },
  {
    path: "history-of-payment",
    name: "Historyofpayment",
    component: Historyofpayment

  },
  {
    path: "/credit-added-manage-links",
    name: "CreditAdded",
    component: AdminCreditAdded,
  },
  {
    path: "/credit-usage-manage-links",
    name: "CreditUsage",
    component: AdminCreditUsage,
  },
  {
    path: "/manage-logs",
    name: "ManageLogs",
    component: ManageLogs,
  },
  {
    path: "/manage-affiliates",
    name: "Manage Affiliates",
    component: ManageAffiliates,
  },
  {
    path: "/split-links",
    name: "Manage Split Links",
    component: ManageSplitLinks,
  },
  {
    path: "/font-management",
    name: "Fonts",
    component: FontManagement,
  },
  {
    path: "/add-font",
    name: "Add Font",
    component: AddFont,
  },
  {
    path: "/edit-font/:id",
    name: "Edit Font",
    component: EditFont,
  },
  {
    path: "/edit-event/:id",
    name: "Edit Event",
    component: EditVendorEvent,
  },
  {
    path: "/add-event/:id",
    name: "Add Event",
    component: AddEvent,
  },

  { path: "/package-management", name: "Plans", component: PackageManage },
  // {
  //   path: "/package-management-edit/:peditid",
  //   name: "Planedit",
  //   component: Priceedit,
  // },
  { path: "/admin", name: "Dashboard", component: Dashboard },
  { path: "/users-management", name: "Usermanage", component: Usermanage },
  { path: "/add-user", name: "Adduser", component: Adduser },
  {
    path: "/user-management-view/:userid",
    exact: true,
    name: "Userview",
    component: Userview,
  },
  {
    path: "/user-management-edit/:usereditid",
    exact: true,
    name: "Useredit",
    component: Useredit,
  },
  {
    path: "/tm-password-manager",
    exact: true,
    name: "TMPasswordManager",
    component: TMPasswordManager,
  },
  {
    path: "/add-communication-user",
    name: "AddCommunicationUser",
    component: AddCommunicationUser,
  },
  {
    path: "/edit-communication-user/:id",
    exact: true,
    name: "EditCommunicationUser",
    component: EditCommunicationUser,
  },
  {
    path: "/communication-users",
    exact: true,
    name: "ListCommunicationUser",
    component: ListCommunicationUser,
  },
  {
    path: "/contact-management",
    exact: true,
    name: "Contactmanage",
    component: Contactlist,
  },
  {
    path: "/contact-management-view/:id",
    exact: true,
    name: "Contactview",
    component: Contactview,
  },
  {
    path: "/changepass",
    exact: true,
    name: "Changepass",
    component: Changepassadmin,
  },
  {
    path: "/change-user-password",
    exact: true,
    name: "ChangeUserPassword",
    component: Setuserpassword,
  },
  {
    path: "/user-dashboard",
    exact: true,
    name: "User-dashboard",
    component: Userdashboard,
  },
  {
    path: "/affiliates",
    exact: true,
    name: "Affiliates",
    component: Affiliates,
  },
  {
    path: "/history-notification",
    exact: true,
    name: "HistoryofNotification",
    component: HistoryofNotification,
  },
  //browser for login
  {
    path: "/user-browser-login-detail",
    exact: true,
    name: "Browser-Login-user",
    component: BrowserLoginDetail,
  },
  {
    path: "/inventory",
    exact: true,
    name: "Inventory",
    component: Inventory
  },
  {
    path: "/event-management",
    exact: true,
    name: "EventManagement",
    component: EventManagement
  },
  {
    path: "/verifiedticket",
    exact: true,
    name: "verifiedticket",
    component: verifiedTickets,
  },
  {
    path: "/soldinventory",
    exact: true,
    name: "Soldinventory",
    component: Soldinventory,
  },
  {
    path: "/attendance",
    exact: true,
    name: "Soldinventory",
    component: Soldinventory,
  },
  { path: "/addcredit", exact: true, name: "Addcredit", component: Addcredit },
  {
    path: "/history-payment",
    exact: true,
    name: "Historypayment",
    component: Historypayment,
  },
  {
    path: "/messages-list",
    exact: true,
    name: "UserMessageslist",
    component: UserMessageslist,
  },
  {
    path: "/interactive-management",
    exact: true,
    name: "Interactiveinvent",
    component: Inventorylist,
  },
  {
    path: "/sold-inventory-management",
    exact: true,
    name: "Soldinventlist",
    component: Soldinventlist,
  },
  {
    path: "/instruction-management",
    exact: true,
    name: "InstructionSection",
    component: InstructionSection,
  },
  {
    path: "/instruction-pages",
    exact: true,
    name: "InstructionPages",
    component: InstructionPages,
  },
  {
    path: "/vendor-list",
    exact: true,
    name: "Vendorlist",
    component: Vendorlist,
  },
  {
    path: "/vendor-events/:id",
    exact: true,
    name: "VendorEventslist",
    component: VendorEventslist,
  },
  {
    path: "/vendor-event-tickets/:id",
    exact: true,
    name: "VendorEventTickets",
    component: VendorEventTickets,
  },
  {
    path: "/credit-packages",
    exact: true,
    name: "CreditPackage",
    component: Creditpackage,
  },
  {
    path: "/add-credit-packages",
    name: "Addcreditpackage",
    component: Addcreditpackage,
  },
  {
    path: "/add-custom-credit/:id",
    name: "Addcustomcredit",
    component: Addcustomcredit,
  },
  {
    path: "/remove-custom-credit/:id",
    name: "Removecustomcredit",
    component: Removecustomcredit,
  },
  {
    path: "/add-custom-credit",
    name: "Addcustomcredit",
    component: Addcustomcredit,
  },
  {
    path: "/remove-custom-credit",
    name: "Removecustomcredit",
    component: Removecustomcredit,
  },
  {
    path: "/credit-package-view/:id",
    name: "CreditPackageView",
    component: CreditPackageView,
  },
  {
    path: "/credit-package-edit/:id",
    name: "CreditPackageView",
    component: CreditPackageEdit,
  },
  {
    path: "/vbdaccounts/accounts",
    name: "VbdOrigAccounts",
    component: VbdOrigAccounts,
  },
  { path: "/vbdaccounts/add", name: "AddVbdAccount", component: AddVbdAccount },
  { path: "/vbdaccounts/lists", name: "VbdAccounts", component: VbdAccounts },
  {
    path: "/vbdaccounts/edit/:id",
    name: "EditVbdAccount",
    component: EditVbdAccount,
  },
  {
    path: "/auto-messaging-format",
    name: "AutoMessagingFormat",
    component: AutoMessagingFormat,
  },
  {
    path: "/auto-messaging-mail-format",
    name: "AutoMessagingMailFormat",
    component: AutoMessagingMailFormat,
  },
  {
    path: "/vbd-ticket-price",
    name: "VbdTicketPrice",
    component: VbdTicketPrice,
  },
  {
    path: "/manage_scan_limit",
    name: "ManageScanLimit",
    component: ManageScanLimit,
  },
  {
    path: "/manage-browser",
    name: "ManageBrowser",
    component: ManageBrowser,
  },
  {
    path: "/manage-browser-detail/:month/:year",
    name: "ManageBrowserDetail",
    component: ManageBrowserDetail,
  },
  {
    path: "/links-used",
    name: "LinksUsed",
    component: LinksUsed,
  },
  {
    path: "/links-used-detail/:month/:year",
    name: "LinksUsedDetail",
    component: LinksUsedDetail,
  },
  {
    path: "/links-delivered",
    name: "LinksDelivered",
    component: LinksDelivered,
  },
  {
    path: "/links-delivered-detail/:month/:year",
    name: "LinksDeliveredDetail",
    component: LinksDeliveredDetail,
  },
  { path: "/messages/list", name: "Messageslist", component: Messageslist },
  {
    path: "/messages/Incoming-list",
    name: "IncomingMessageslist",
    component: IconmingMessageslist,
  },
  { path: "/message/send", name: "SendMessage", component: SendMessage },
  { path: "/vendor", name: "Vendor", component: Vendor },
  { path: "/team", name: "Team", component: Teams },
  { path: "/league", name: "League", component: League },
  { path: "/internal-notes", name: "Internal Notes", component: InternalNotes },
  {
    path: "/blacklisted-numbers",
    name: "Blacklisted Numbers",
    component: BlacklistedNumber,
  },
  {
    path: "/announcement",
    name: "Announcement",
    component: Announcement,
  },
  {
    path: "/what's-new",
    name: "WhatsNew",
    component: WhatsNew,
  },
  {
    path: "/terms_conditions",
    name: "TermsConditions",
    component: TermsConditions,
  },
  {
    path: "/communication-panel",
    name: "Communication Panel",
    component: CommunicationPanel,
  },
  {
    path: "/communication-panel-order",
    name: "Communication Panel Order",
    component: Message,
  },
  {
    path: "/messages/text-recognition",
    name: "Text Recognition",
    component: TextRecoginition,
  },
  {
    path: "/change-pass",
    name: "Change password",
    component: ChangeComPassword,
  },
  {
    path: "/admin-vendor-lists",
    name: "User Vendors Lists",
    component: AdminVendorLists,
  },
  {
    path: "/admin-vendor-events/:id",
    name: "User Vendors Events Lists",
    component: AdminVendorEventslist,
  },

  {
    path: "/admin-vendor-event-tickets/:id",
    name: "User Vendors Events Tickets",
    component: AdminVendorEventTickets,
  },
  { path: "/add-proxy", name: "AddCommunicationUser", component: AddProxy },
  { path: "/edit-proxy/:id", name: "AddCommunicationUser", component: EditProxy },
  {
    path: "/proxy-management",
    name: "Proxy Management",
    component: ProxyManagement,
  },
  { path: "/view-profiles", name: "Profiles", component: Profiles },
  { path: "/add-release-notes", name: "AddReleaseNotes", component: AddReleaseNotes },
  { path: "/add-enduser-license-agreement", name: "EndUserLicenseAgreement", component: EndUserLicenseAgreement },
  { path: "/manage-licenses", name: "ManageLicenses", component: ManageLicenses },
  { path: "/admin-licenses-logs", name: "AdminLicensesLogs", component: AdminLicensesLogs },
  { path: "/browser-sessions", name: "Browser Sessions", component: BrowserSessions },
  {
    path: "/fingerprint-detail",
    name: "Fingerprint",
    component: Fingerprint,
  },
  {
    path: "/useragent-detail",
    name: "UserAgent",
    component: UserAgent,
  },
  {
    path: "/release-notes",
    name: "ReleaseNotes",
    component: ReleaseNotes,
  },
  {
    path: "/licenses-summary",
    name: "LicenseSummary",
    component: LicenseSummary,
  },
  {
    path: "/purchase-credit",
    name: "PurchaseCredit",
    component: PurchaseCredit,
  },
  {
    path: "/end-user-license-agreement",
    name: "EndUserLicenseAgreementUserPanel",
    component: EndUserLicenseAgreementUserPanel,
  }
];

export default routes;
