import React, { useEffect, useState } from "react";
import "./user-sidebar.scss";
import { useSelector, useDispatch } from "react-redux";
import navigation from "../nav-link";
import History from "images/user-dashboard/instruction-guide.svg";
import Archive from "images/user-dashboard/history-payment.svg";
import { Button, Tooltip, tooltipClasses } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import {
  CSidebar,
  CSidebarNav,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";
import axios from "axios";
import Cookies from 'js-cookie';
import { Language, Paid } from "@mui/icons-material";
import { styled } from "@mui/styles";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    // color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: theme.palette.common.,
  },
}));

const TheSidebar = (() => {
  const dispatch = useDispatch();
  const isAccessbrowser = useSelector((state) => state.UserProfile?.data?.access_browser);
  const isVbdAccattached = useSelector((state) => state.UserProfile?.vbdaccattached)
  const show = useSelector((state) => state.SideBar.sidebarShow);
  const [newNavigation, setNewNavigation] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(JSON.parse(localStorage.getItem("isDropdownToggle")) || "");

  const toggleSidebar = () => {
    dispatch({ type: show === "set" ? "res" : "set", sidebarShow: show === "responsive" ? false : "responsive" });
  };

  const toggleSidebarMobile = () => {
    dispatch({ type: show === "set" ? "res" : "set", sidebarShow: show === "responsive" ? true : "responsive" });
  };

  const handleDropdownToggle = (index, el) => {
    // history.push(el?._children[0]?.to);
    if (index !== openDropdown || newNavigation.length > 1) {
      localStorage.setItem("isDropdownToggle", JSON.stringify(index));
      setOpenDropdown(index === openDropdown ? null : index);
    }
  };

  useEffect(() => {
    if (isAccessbrowser !== undefined && isAccessbrowser) {
      const listingGet = process.env.REACT_APP_API_URL + "/api/user/listPages";
      axios
        .get(listingGet, {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        })
        .then((res) => {
          if (navigation?.length < 9 && res?.data?.data) {
            const datafilter = res?.data?.data?.filter((el) => el.is_active !== 0);
            navigation?.push(
              {
                _tag: "CSidebarNavDropdown",
                name: "Instructions Guide",
                isHide: false,
                icon: (
                  <div>
                    <img src={History} className="sidebar-icon" alt="history" />
                  </div>
                ),
                _children: datafilter?.map((el) => ({
                  _tag: "CSidebarNavItem",
                  name: el.name,
                  to: `/instruction-guide/${el?._id}`,
                  badge: {
                    color: "info",
                  },
                })),
              },
              {
                _tag: "CSidebarNavDropdown",
                name: "Archive",
                isHide: false,
                icon: (
                  <div>
                    <img src={Archive} className="sidebar-icon" alt="history" />
                  </div>
                ),
                _children: [
                  {
                    _tag: "CSidebarNavItem",
                    name: "History of Payment",
                    to: "/history-payment",
                    badge: {
                      color: "info",
                    },
                  },
                  {
                    _tag: "CSidebarNavItem",
                    name: "History of Usage",
                    to: "/history-usage",
                    badge: {
                      color: "info",
                    },
                  },
                  {
                    _tag: "CSidebarNavItem",
                    name: "History of Events",
                    to: "/inventory-past",
                    badge: {
                      color: "info",
                    },
                  },
                  {
                    _tag: "CSidebarNavItem",
                    name: "History of Logs",
                    to: "/history-logs",
                    badge: {
                      color: "info",
                    },
                  },
                  {
                    _tag: "CSidebarNavItem",
                    name: "History of Notifications",
                    to: "/history-notifications",
                    badge: {
                      color: "info",
                    },
                  },
                ],
              },
              {
                _tag: "CSidebarNavItem",
                name: "Payment",
                isHide: false,
                to: "/history-payment",
                icon: (
                  <div>
                    <Paid className="sidebar-icon" alt="history" />
                  </div>
                ),
              },
              {
                _tag: "CSidebarNavDropdown",
                name: "Browser",
                isHide: false,
                icon: (
                  <div>
                    <Language className="sidebar-icon" style={{ opacity: "0.9" }} />
                  </div>
                ),
                _children: [
                  {
                    _tag: "CSidebarNavItem",
                    name: "Licenses Summary",
                    to: "/licenses-summary",
                    badge: {
                      color: "info",
                    },
                  },
                  {
                    _tag: "CSidebarNavItem",
                    name: "Release Notes",
                    to: "/release-notes",
                    badge: {
                      color: "info",
                    },
                  },
                  {
                    _tag: "CSidebarNavItem",
                    name: "End User Agreement",
                    to: "/end-user-license-agreement",
                    badge: {
                      color: "info",
                    },
                  }
                ],
              }
            );
          }
          // let filterHideTabs;
          // if (isVbdAccattached === true && isAccessbrowser === "Yes") {
          //   filterHideTabs = navigation;
          // } else if (isVbdAccattached === true && isAccessbrowser === "No") {
          //   const navigationdata = navigation.filter((item) => item.name === "Browser")
          //   navigationdata[0].isHide = isAccessbrowser === "No" ? true : false;
          //   filterHideTabs = navigation.filter((item) => !item.isHide);
          // } else if (isVbdAccattached === false && isAccessbrowser === "Yes") {
          //   const navigationdata = navigation.filter(item =>
          //     item.name === "Archive" ||
          //     item.name === "Instructions Guide" ||
          //     item.name === "Account" ||
          //     item.name === "Sold Inventory" ||
          //     item.name === "Split Links" ||
          //     item.name === "Interactive Inventory" ||
          //     item.name === "Attendance"
          //   );
          //   if (navigationdata.length > 0) {
          //     navigationdata.forEach(item => {
          //       item.isHide = (isVbdAccattached === false && isAccessbrowser === "Yes");
          //     });
          //   }
          //   filterHideTabs = navigation.filter(item => !item.isHide);
          // } else if (isVbdAccattached === false && isAccessbrowser === "No") {
          //   const navigationdata = navigation.filter(item =>
          //     item.name === "Archive" ||
          //     item.name === "Instructions Guide" ||
          //     item.name === "Account" ||
          //     item.name === "Sold Inventory" ||
          //     item.name === "Split Links" ||
          //     item.name === "Interactive Inventory" ||
          //     item.name === "Attendance" ||
          //     item.name === "Browser" ||
          //     item?.name === "Payment"
          //   );
          //   if (navigationdata.length > 0) {
          //     navigationdata.forEach(item => {
          //       item.isHide = (isVbdAccattached === false && isAccessbrowser === "No");
          //     });
          //   }
          //   filterHideTabs = navigation.filter(item => !item.isHide);
          // }
          // setNewNavigation(filterHideTabs);

          let filterHideTabs = [];
          let hideConditions = [];

          if (isVbdAccattached === true) {
            if (isAccessbrowser === "Yes") {
              hideConditions = ["Payment"];
            } else if (isAccessbrowser === "No") {
              hideConditions = ["Browser", "Payment"];
            }
          } else if (isAccessbrowser === "Yes") {
            hideConditions = [
              "Archive", "Instructions Guide", "Account", "Sold Inventory",
              "Split Links", "Interactive Inventory", "Attendance", "Packages"
            ];
          } else if (isAccessbrowser === "No") {
            hideConditions = [
              "Archive", "Instructions Guide", "Account", "Sold Inventory",
              "Split Links", "Interactive Inventory", "Attendance", "Browser", "Payment"
            ];
          }
          navigation.forEach(item => {
            item.isHide = hideConditions.includes(item.name);
          });
          filterHideTabs = navigation.filter(item => !item.isHide);
          setNewNavigation(filterHideTabs);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }, [isAccessbrowser, isVbdAccattached]);


  return (
    <>
      <CSidebar
        show={show}
        onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}
        className="user-dashboard-sidebar"
      >
        <div className="toggle-wrapper">
          <Button
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            className="c-header-toggle ml-md-3 d-lg-none"
            disableRipple
            sx={{
              backgroundColor: (theme) =>
                theme.palette.primary.main + "!important",
              borderRadius: "0px 4px 4px 0px",
              padding: "6px 7px",
              minWidth: "0px",
              marginLeft: "16px!important",
            }}
            onClick={toggleSidebarMobile}
          >
            <MenuIcon
              sx={{
                color: "#f0f0f0",
              }}
            />
          </Button>
          <Button
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            className="c-header-toggle d-md-down-none"
            disableRipple
            sx={{
              backgroundColor: (theme) =>
                theme.palette.primary.main + "!important",
              borderRadius: "0px 4px 4px 0px",
              padding: "6px 7px",
              minWidth: "0px",
              marginLeft: "16px!important",
            }}
            onClick={toggleSidebar}
          >
            <MenuIcon
              sx={{
                color: "#f0f0f0",
              }}
            />
          </Button>
        </div>
        <CSidebarNav
          style={{
            paddingTop: "33px",
          }}
        >
          {newNavigation && newNavigation?.map((el, index) => {
            if (el._tag === "CSidebarNavDropdown") {
              return (
                <CSidebarNavDropdown
                  name={el.name}
                  icon={el.icon}
                  onClick={() => {
                    handleDropdownToggle(index, el)
                  }}
                  visible={index === openDropdown}
                  className={`c-sidebar-nav-dropdown ${index === openDropdown ? 'c-show' : ''}`}
                  key={index}
                >
                  {el._children.map((el, index) => (
                    <>
                      {/* {el.name === "Event Management" ? (
                       <BootstrapTooltip title="It is under maintenance and we'll be back up and running soon."
                         placement="top"
                       >
                         <span>
                           <CSidebarNavItem
                             name={el.name}
                             className={el.name === "Event Management" ? "disabled" : ""}
                             to={el.to}
                             key={index}
                             target={el.target ? el.target : ""}
                           />
                         </span>
                       </BootstrapTooltip>
                     ) : ( */}
                      <CSidebarNavItem
                        name={el.name}
                        className={el.name}
                        //  className={el.name === "Event Management" ? "disabled" : ""}
                        to={el.to}
                        key={index}
                        target={el.target ? el.target : ""}
                      />
                      {/* )} */}
                    </>
                  ))}
                </CSidebarNavDropdown>
              );
            } else {
              return (
                <CSidebarNavItem
                  name={el.name}
                  onClick={() => handleDropdownToggle(index)}
                  to={el.to}
                  target={el.target ? el.target : ""}
                  icon={el.icon}
                  key={index}
                />
              );
            }
          })
          }
        </CSidebarNav>
      </CSidebar>
    </>
  );
});

export default TheSidebar;