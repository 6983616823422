import React from 'react';
import { Helmet } from "react-helmet";
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import Usersidebar from '../../components/user-sidebar/user-sidebar';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer'
import './instructionGuide.scss';
import { Box, Card, Grid } from '@mui/material';
import loaderGif from "images/GIF-Loader-3.gif";
import Cookies from 'js-cookie';

class InstructionGuide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionData: [],
            loader: false,
            open: false,
            message: '',
            successError: '',
        }
        this.getSectionData = this.getSectionData.bind(this)
    }
    getSectionData = () => {
        this.setState({ loader: true })
        const getSection = process.env.REACT_APP_API_URL + "/api/user/getSectionByPage"
        axios.post(getSection, {
            "_id": this.props.match.params.id
        }, { headers: { authorization: 'Bearer ' + Cookies.get('accessToken') } })
            .then((res) => {
                this.setState({ loader: false })
                this.setState({ sectionData: res?.data?.data })
            })
            .catch((err) => {
                this.setState({ loader: false })
                console.log('err-', err)
            })
    }
    componentDidMount() {
        this.getSectionData();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.getSectionData();
        }
    }
    render() {
        return (
            <>
                <div className="user-dashboard-wrapper">
                    <Helmet>
                        <title>Instruction Guide</title>
                    </Helmet>
                    <Header />
                    <div className="c-app c-default-layout user-dashboard-body">
                        <Usersidebar />
                        <div className="c-wrapper">
                            <div className="c-body">
                                <main className="c-main">
                                    <Grid xs="12" lg="12" md="12" sm="12" className="space-top-col">
                                        <div className="notification-outer add-account-wrapper">
                                            <Card sx={{ boxShadow: "none" }}>
                                                <div className="usage-tbl-header add-account-title mb-0">
                                                    <h3 className='mb-0'>Instruction Guide </h3>
                                                </div>
                                                {this.state.loader ?
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            padding: "25rem 0",
                                                        }}
                                                    >
                                                        <div>
                                                            <img className="loader-size-table" src={loaderGif} alt="no loader" />
                                                        </div>
                                                    </Box>
                                                    :
                                                    <>
                                                        {this.state?.sectionData?.map((item, idx) => {
                                                            return (
                                                                <Row key={idx} className="pt-5 usage-tbl-header instruction-guide-sections">
                                                                    <Col lg={12} md={12}> <h3 className='text-left'>{idx + 1}. {item.name}</h3></Col>
                                                                    <Col lg={item.video === "" ? 12 : 6} md={item.video === "" ? 12 : 6} className='text-justify'>
                                                                        <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                                                    </Col>
                                                                    {item.video !== "" ?
                                                                        <Col lg={6} md={6}>
                                                                            <div dangerouslySetInnerHTML={{ __html: item.video }} />
                                                                        </Col>
                                                                        :
                                                                        null
                                                                    }
                                                                </Row>
                                                            )
                                                        })}
                                                    </>
                                                }
                                            </Card>
                                        </div>
                                    </Grid>
                                </main>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        )
    }
}

export default InstructionGuide;
