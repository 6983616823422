import React from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DatePicker from "react-datepicker";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import customDateFormatLocal from "utils/customDateFormatLocal";
import { HiOutlineDownload } from "react-icons/hi";

import "react-datepicker/dist/react-datepicker.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CircularProgress,
  Autocomplete,
  Chip,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@mui/material";

import { HiOutlineSearch } from "react-icons/hi";
import Pagination from "components/pagination/pagination";
import DataTable from "components/DataTable/DataTable";
import { changeDollarAmountformat } from "utils/changeDollarFormat";
import { ContainerLoader } from "components/common/ContainerLoader";
import customDateFormat from "utils/customDateFormat";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

const moment = extendMoment(originalMoment);

class Historyofpayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usersData: [],
      loader: true,
      open: false,
      message: "",
      allUsers: [],
      disableValue: "All",
      crossCheck: true,
      successError: "success",
      selectedDate: null,
      selectedendDate: null,
      filterDate: {
        keyword: "",
        fromDate: null,
        toDate: null,
      },
      receipt: {},
      dateErrorMsg: "",
      pageLimit: 10,
      modalShow: false,
      pos: [],
      searchPage: 0,
      skip: 0,
      totalRecords: 0,
      testUser: "all",
      status: "",
      userVal: [
        {
          email: "All",
        },
      ],
    };

    this.changePageLimit = this.changePageLimit.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSelectStatus = this.handleSelectStatus.bind(this);
  }

  async componentDidMount() {
    this.userList();
    this.getData();
  }

  handleSelectStatus(e) {
    this.setState({
      status: e.target.value,
    });
  }

  handleChangeTestUser = (event) => {
    this.setState({ testUser: event.target.value });
  }

  handleSelectUser = (event, value) => {
    if (value) {
      if (value.length === 0) {
        this.setState({ disableValue: "", userVal: [] });
      }
      value.map((item) => {
        if (item.email === "All") {
          this.setState({
            disableValue: "All",
            userVal: [
              {
                email: "All",
              },
            ],
          });
        } else {
          this.setState({
            userVal: value,
          });
        }
        return item;
      });
    }
  };

  onPageChanged = (data) => {
    const { currentPage } = data;
    this.setState(
      { loader: true, skip: this.state.pageLimit * (currentPage - 1) },
      () => {
        this.getData();
      }
    );
  };

  SearchDisable = (value) => {
    this.setState({ searchPage: value });
  };

  getData = () => {
    const data = {
      ...this.state.filterDate,
      skip: this.state.skip,
      limit: this.state.pageLimit,
      users: this.state.userVal[0].email === "All" ? [] : this.state.userVal.map((item) => {
        return item._id
      }),
      hasTestUsers: this.state.testUser === "yes" ? true : this.state.testUser === "all" ? null : false,
      status: this.state.status === "all" ? null : this.state.status
    };
    const historyPayment_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/historyOfPayments";

    axios
      .post(historyPayment_api_url, data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            usersData: data.data,
            totalRecords: data.total,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ loader: false });
      });
  };

  userList() {
    const userList_api_url =
      process.env.REACT_APP_API_URL + "/api/user/getAllUsers";
    axios
      .post(
        userList_api_url,
        {},

        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        }
      )
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {

          const sorted = data?.data.sort((a, b) => {
            if (a.email.toLowerCase().trim() < b.email.toLowerCase().trim())
              return -1;
            if (a.email.toLowerCase().trim() > b.email.toLowerCase().trim())
              return 1;
            return 0;
          });
          // push to first index of array

          sorted.unshift({
            email: "All",
          });

          this.setState({
            allUsers: sorted,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  }

  changePageLimit = (evt) => {
    this.setState(
      { loader: true, searchPage: 1, pageLimit: parseInt(evt.target.value) },
      () => {
        this.getData();
      }
    );
  };

  onHandleChange = (e) => {
    const { filterDate } = this.state;
    filterDate[e.target.name] = e.target.value;
    this.setState({ filterDate });
  };

  setStartDate = async (dates) => {
    const { filterDate } = this.state;
    const [start, end] = dates;

    // const getstart = start.valueOf()+hours+mins
    this.setState({ selectedendDate: end, selectedDate: start });

    // fomating date to isoString
    const datestart = moment(start).format("YYYY-MM-DDTHH:mm:ss");
    const dateend = moment(end).format("YYYY-MM-DDTHH:mm:ss");
    filterDate["fromDate"] = datestart + ".000Z";
    if (end) {
      filterDate["toDate"] = dateend + ".000Z";
    }

    this.setState({ filterDate, crossCheck: false });
  };

  clearDate = () => {
    const { filterDate } = this.state;
    this.setState({ selectedendDate: null, selectedDate: null });
    filterDate["fromDate"] = null;
    filterDate["toDate"] = null;
    this.setState({ filterDate, crossCheck: true });
  };


  handleSubmit = () => {
    this.setState({ loader: true, searchPage: 1, skip: 0 }, () => {
      this.getData();
    });
  };

  onHide = () => {
    this.setState({ modalShow: false });
  };

  testWhite(x) {
    var white = new RegExp(/^\s$/);
    return white.test(x.charAt(0));
  }

  wordWrap(str, maxWidth) {
    var newLineStr = "\n";
    var res = "";
    while (str.length > maxWidth) {
      var found = false;
      // Inserts new line at first whitespace of the line
      for (var i = maxWidth - 1; i >= 0; i--) {
        if (this.testWhite(str.charAt(i))) {
          res = res + [str.slice(0, i), newLineStr].join("");
          str = str.slice(i + 1);
          found = true;
          break;
        }
      }
      // Inserts new line at maxWidth position, the word is too long to wrap
      if (!found) {
        res += [str.slice(0, maxWidth), newLineStr].join("");
        str = str.slice(maxWidth);
      }
    }

    return res + str;
  }

  handleCapitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  }

  handleClick = (item) => {
    this.setState({ receipt: item, modalShow: true });
    console.log(item);
    if (item.platform === "anonymous_browser_license") {
      const header = [
        "License Key",
        "License Type",
        "Generated Date",
      ];
      let data = [];
      if (item?.license_ids?.length > 0) {
        data = item?.license_ids?.map((row) => {
          return [
            row?.license_key ?? null,
            this.handleCapitalize(row?.license_type ?? null),
            customDateFormatLocal(row?.createdAt ?? null)
          ];
        });
      }
      const tableWidth = 180;
      const columnWidths = [70, 40, 70];
      const rowHeight = 10;
      let doc = new jsPDF("p", "mm", "a4");
      const logoImg = require("images/logo.png");
      console.log(logoImg);
      doc.addImage(logoImg, "PNG", 70, 10, 60, 60);
      doc.setFontSize(24);
      doc.setFont("cursive", "bold");
      doc.text("Receipt", 86, 80);
      doc.setFontSize(16);
      doc.setFont("cursive", "bold");
      doc.text("Heading:", 20, 100);
      doc.text("Credit:", 20, 110);
      doc.text("Balance:", 20, 120);
      doc.text("Date time:", 20, 130);
      doc.setFontSize(16);
      doc.setFont("cursive", "normal");
      doc.text(item?.heading, 60, 100);
      doc.text("$" + item?.amount, 60, 110);
      doc.text("$" + item?.balance, 60, 120);
      doc.text(customDateFormatLocal(item?.created), 60, 130);
      if (
        (item.license_ids !== "" && item.license_ids !== null) ||
        item.license_ids.length > 0
      ) {
        doc.autoTable({
          startY: 140,
          head: [header],
          body: data,
          theme: "grid",
          columnStyles: {
            0: { cellWidth: columnWidths[0], fontSize: 10 },
            1: { cellWidth: columnWidths[1], fontSize: 10 },
            2: { cellWidth: columnWidths[2], fontSize: 10 },
          },
          styles: {
            fontSize: 8,
            cellPadding: 2,
            overflow: "linebreak",
          },
          tableWidth: tableWidth,
          rowPageBreak: "auto",
          headStyles: {
            fillColor: [56, 88, 160],
          },
          alternateRowStyles: {
            fillColor: [245, 245, 245],
          },
          rowHeight: rowHeight,
        });
      }
      doc.save(`payment-receipt.pdf`);
    } else if (item?.platform === "ticket_liberty") {
      if (item?.heading === "Package Purchased") {
        const header = [
          "Package Name",
          "Price",
          "Tickets Included",
          "Price Per Additional Ticket",
        ];
        let data = [];
        if (item?.soldinventory !== "" && item?.soldinventory !== null) {
          data && data?.push([
            item?.soldinventory?.name,
            `${changeDollarAmountformat(item?.soldinventory?.price)}/month`,
            item?.soldinventory?.tickets_count,
            `${changeDollarAmountformat(item?.soldinventory?.additional_ticket_price)}`,
          ]);
        }
        const tableWidth = 180;
        const columnWidths = [40, 40, 40, 40, 40, 50];
        const rowHeight = 10;
        let doc = new jsPDF("p", "mm", "a4");
        const logoImg = require("images/logo.png");
        doc.addImage(logoImg, "PNG", 70, 10, 60, 60);
        doc.setFontSize(24);
        doc.setFont("cursive", "bold");
        doc.text("Receipt", 86, 80);
        doc.setFontSize(16);
        doc.setFont("cursive", "bold");
        doc.text("Heading:", 20, 100);
        doc.text("Credit:", 20, 110);
        doc.text("Balance:", 20, 120);
        doc.text("Date time:", 20, 130);
        if (
          ((item?.soldinventory !== "" && item?.soldinventory !== null))
        ) {
          doc.text("Description:", 20, 140);
        } else if (item?.description ?? null) {
          doc.text("Description:", 20, 140);
        }
        doc.setFontSize(14);
        doc.setFont("cursive", "normal");
        doc.text(item.heading, 60, 100);
        doc.text("$" + item?.amount, 60, 110);
        doc.text("$" + item?.balance, 60, 120);
        doc.text(customDateFormatLocal(item?.created), 60, 130);
        if (
          ((item?.soldinventory !== "" && item?.soldinventory !== null))
        ) {
          doc.text(this.wordWrap(item?.description ?? ''), 60, 140);
        } else if (item?.description) {
          doc.text(this.wordWrap(item?.description ?? ''), 60, 140);
        }
        if (
          (item?.soldinventory !== "" && item?.soldinventory !== null)
        ) {
          doc.autoTable({
            startY: 150,
            head: [header],
            body: data,
            theme: "grid",
            columnStyles: {
              0: { cellWidth: columnWidths[0], fontSize: 10 },
              1: { cellWidth: columnWidths[1], fontSize: 10 },
              2: { cellWidth: columnWidths[2], fontSize: 10 },
            },
            styles: {
              fontSize: 8,
              cellPadding: 2,
              overflow: "linebreak",
            },
            tableWidth: tableWidth,
            rowPageBreak: "auto",
            headStyles: {
              fillColor: [56, 88, 160],
            },
            alternateRowStyles: {
              fillColor: [245, 245, 245],
            },
            rowHeight: rowHeight,
          });
        }
        doc.save(`payment-receipt ${customDateFormat(item.created)}.pdf`);
      } else {
        const header = [
          "Event Name",
          "Event Date",
          "Vendor Email",
          "Section",
          "Row",
          "Seat",
        ];
        let data = [];
        if (item?.sold_ids?.length > 0) {
          data = item?.sold_ids?.map((row) => {
            return [
              row?.event[0]?.name,
              customDateFormatLocal(row?.event[0]?.date),
              row?.vendor?.username,
              row?.section,
              row?.row,
              row?.seatNumbers,
            ];
          });
        } else if (item?.soldinventory !== "" && item?.soldinventory !== null) {
          data && data?.push([
            item?.soldinventory?.event[0].name,
            customDateFormatLocal(item?.soldinventory?.event[0]?.date),
            item?.soldinventory?.vendor?.username,
            item?.soldinventory?.section,
            item?.soldinventory?.row,
            item?.soldinventory?.seatNumbers,
          ]);
        }
        const tableWidth = 180;
        const columnWidths = [40, 40, 40, 40, 40, 50];
        const rowHeight = 10;
        let doc = new jsPDF("p", "mm", "a4");
        const logoImg = require("images/logo.png");
        doc.addImage(logoImg, "PNG", 70, 10, 60, 60);
        doc.setFontSize(24);
        doc.setFont("cursive", "bold");
        doc.text("Receipt", 86, 80);
        doc.setFontSize(16);
        doc.setFont("cursive", "bold");
        doc.text("Heading:", 20, 100);
        doc.text("Credit:", 20, 110);
        doc.text("Balance:", 20, 120);
        doc.text("Date time:", 20, 130);
        if (
          item?.description !== "" &&
          ((item?.soldinventory !== "" && item?.soldinventory !== null) ||
            (item?.sold_ids && item?.sold_ids?.length > 0))
        ) {
          doc.text("Description:", 20, 140);
        } else if (item?.description) {
          doc.text("Description:", 20, 140);
        }
        doc.setFontSize(14);
        doc.setFont("cursive", "normal");
        doc.text(item.heading, 60, 100);
        doc.text("$" + item?.amount, 60, 110);
        doc.text("$" + item?.balance, 60, 120);
        doc.text(customDateFormatLocal(item?.created), 60, 130);
        if (
          item?.description !== "" &&
          ((item?.soldinventory !== "" && item?.soldinventory !== null) ||
            (item?.sold_ids && item?.sold_ids?.length > 0))
        ) {
          doc.text(this.wordWrap(item?.description), 60, 140);
        } else if (item?.description) {
          doc.text(this.wordWrap(item?.description), 60, 140);
        }
        if (
          (item?.soldinventory !== "" && item?.soldinventory !== null) ||
          (item?.sold_ids && item?.sold_ids?.length > 0)
        ) {
          doc.autoTable({
            startY: 150,
            head: [header],
            body: data,
            theme: "grid",
            columnStyles: {
              0: { cellWidth: columnWidths[0], fontSize: 10 },
              1: { cellWidth: columnWidths[1], fontSize: 10 },
              2: { cellWidth: columnWidths[2], fontSize: 10 },
            },
            styles: {
              fontSize: 8,
              cellPadding: 2,
              overflow: "linebreak",
            },
            tableWidth: tableWidth,
            rowPageBreak: "auto",
            headStyles: {
              fillColor: [56, 88, 160],
            },
            alternateRowStyles: {
              fillColor: [245, 245, 245],
            },
            rowHeight: rowHeight,
          });
        }
        doc.save(`payment-receipt.pdf`);
      }
    }
  };


  render() {
    const { usersData, crossCheck, loader } = this.state;
    // get time zone  from the browser
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const fields = [
      {
        key: "user",
        label: "User",
      },
      {
        key: "heading",
        label: "Description",
      },
      {
        key: "indicator",
        label: "-",
      },
      {
        key: "date",
        label: "Date",
      },
      {
        key: "credit",
        label: "Credit",
      },
      {
        key: "balance",
        label: "Balance",
      },
      {
        key: "status",
        label: "Status",
      },
      {
        key: "receipt",
        label: "Receipt",
      },
    ];

    return (
      <>
        <Helmet>
          <title>History Of Payment</title>
        </Helmet>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="History of Payment" />
              <CardContent className="plan-tbl">
                <ValidatorForm
                  onSubmit={this.handleSubmit}
                  className="filter-form mb-5"
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 2, lg: 2 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      <Grid
                        item
                        className="team"
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                      >
                        <label className="Account_details_box-manage-license">
                          Search Users
                        </label>
                        <Autocomplete
                          multiple
                          fullWidth
                          id="checkboxes-tags-demo"
                          options={this.state.allUsers ?? []}
                          disableCloseOnSelect
                          onChange={this.handleSelectUser}
                          value={this.state.userVal ?? null}
                          isOptionEqualToValue={(option, value) =>
                            option.email === value.email
                          }
                          getOptionLabel={(option) => option.email}
                          renderOption={(props, option, { selected }) => (
                            <List
                              disablePadding
                              {...props}
                              sx={{
                                pointerEvents:
                                  this.state.disableValue === "All" &&
                                    option.email !== "All"
                                    ? "none"
                                    : "auto",
                                width: "100%",
                                bgcolor: "background.paper",
                              }}
                            >
                              <ListItem
                                disablePadding
                                disabled={
                                  this.state.disableValue === "All" &&
                                    option.email !== "All"
                                    ? true
                                    : false
                                }
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    icon={
                                      <CheckBoxOutlineBlank fontSize="medium" />
                                    }
                                    checkedIcon={
                                      <CheckBox fontSize="medium" />
                                    }
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                </ListItemIcon>
                                <ListItemText primary={option.email} />
                              </ListItem>
                            </List>
                          )}
                          renderInput={(params) => (
                            <TextValidator
                              validators={["required"]}
                              errorMessages={["This field is required"]}
                              {...params}

                              sx={{
                                "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error ": {
                                  color: "#C4C4C4"
                                },
                              }}
                              variant="outlined"
                              value={this.state.userVal}
                            />
                          )}
                        />

                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <label className="Account_details_box-manage-license">
                          Search Date
                        </label>
                        <DatePicker
                          placeholderText="Search Date"
                          selected={this.state.selectedDate}
                          startDate={this.state.selectedDate}
                          endDate={this.state.selectedendDate}
                          monthsShown={this.state.monthsShown}
                          selectsRange
                          isClearable={true}
                          customInput={
                            <TextField
                              variant="outlined"
                              className="filter-input"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    className="date-icon"
                                  >
                                    <div className="d-flex">
                                      {crossCheck ? (
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          edge="end"
                                        >
                                          <EventIcon className="text-dark" />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          edge="end"
                                          onClick={() => this.clearDate()}
                                        >
                                          <ClearIcon className="text-dark" />
                                        </IconButton>
                                      )}
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          }
                          onChange={(dates) => this.setStartDate(dates)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <label className="Account_details_box-manage-license">
                          Status
                        </label>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            {this.state.status !== "" ? "" : "Status"}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="status"
                            onChange={(e) => {
                              this.handleSelectStatus(e);
                            }}
                            size="small"
                            value={this.state.status}
                            defaultValue="all"
                            sx={{
                              textAlign: "left",
                            }}
                          >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="manually_removed"> Manually Removed By Admin </MenuItem>
                            <MenuItem value="manually_added">Manually Added by Admin</MenuItem>
                            <MenuItem value="purchased_via_stripe">Credit Purchased via Stripe</MenuItem>
                            <MenuItem value="package_purchased">Package Purchased</MenuItem>
                            <MenuItem value="auto_via_stripe">Auto Added via Stripe</MenuItem>
                            <MenuItem value="nt_tickets_charge">Non-Transferable Tickets charges</MenuItem>
                            <MenuItem value="anonymous_browser_charges">Charges for Browser Subscription </MenuItem>
                            <MenuItem value="lump_sum_charge_nttickets">Lump sum charge for Non-Transferable Tickets</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                       {/* for test user */}
                       <Grid item xs={12} sm={2.5} md={2.5} lg={2.5}>
                        <FormControl fullWidth>
                          <label className="Account_details_box-manage-license">
                            Test Users
                          </label>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="status"
                            value={this.state.testUser}
                            onChange={(e) => this.handleChangeTestUser(e)}
                            size="small"
                            defaultValue="all"
                            sx={{
                              textAlign: "left",
                            }}
                          >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="yes">Yes</MenuItem>
                            <MenuItem value="no">No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={4} md={2.2} lg={2.2} sx={{ marginTop: "47px!important", paddingTop: "0px!important" }}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="filter-btn "
                          startIcon={<HiOutlineSearch />}
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </ValidatorForm>
                {loader ? (
                  <ContainerLoader />
                ) : (
                  <>
                    <DataTable
                      fields={fields}
                      items={usersData}
                      itemsPerPage={this.state.pageLimit}
                      scopedSlots={{
                        user: (item) => <>{item.user && item.user[0].email}</>,
                        date: (item) => (
                          <>{customDateFormatLocal(item.created, timezone)}</>
                        ),
                        credit: (item) => (
                          <>
                            {item.entry_type === "debit" ? "-" : ""}
                            {changeDollarAmountformat(Number(item.amount).toFixed(2))}
                          </>
                        ),
                        indicator: (item) => (
                          <>
                            {item?.isPackagePurchased ?
                              <Chip
                                label="Subscribed"
                                color="success"
                                size="small"
                                sx={{
                                  color: "#fff",
                                }}
                              />
                              :
                              null
                            }
                          </>
                        ),
                        heading: (item) => (
                          <>
                            <div className="container-tablecell">
                              {item?.heading?.length > 35 ? (
                                <section>
                                  <div className="outer-wrapper-payment">
                                    <div className="inner-wrapper">
                                      {item?.heading ?? "-"}
                                    </div>
                                  </div>
                                </section>
                              ) : (
                                <>
                                  {item?.heading ?? "-"}
                                </>
                              )}
                            </div>
                          </>
                        ),
                        balance: (item) => (
                          <>{changeDollarAmountformat(Number(item.balance).toFixed(2))}</>
                        ),
                        status: (item) => (
                          <>
                            <Chip
                              label="Completed"
                              color="success"
                              size="small"
                              sx={{
                                color: "#fff",
                              }}
                            />
                          </>
                        ),
                        receipt: (item) => (
                          <>
                            <IconButton
                              aria-label="download"
                              size="small"
                              onClick={() => this.handleClick(item)}
                            >
                              <HiOutlineDownload
                                style={{
                                  color: "#B90F0F",
                                  fontSize: "24px",
                                }}
                              />
                            </IconButton>
                          </>
                        ),
                      }}
                    />
                  </>
                )}

                <Grid container spacing={2} sx={{ marginTop: "20px" }}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    {this.state.totalRecords > 0 && (
                      <Pagination
                        key="paginationnew"
                        searchPage={this.state.searchPage}
                        SearchDisable={this.SearchDisable}
                        totalRecords={this.state.totalRecords}
                        pageLimit={this.state.pageLimit}
                        onPageChanged={this.onPageChanged}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div
                      style={{
                        textAlign: "right",
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <label>Items per page :</label>
                      <FormControl
                        sx={{
                          m: 1,
                          minWidth: 50,
                        }}
                        size="small"
                      >
                        <Select
                          defaultValue={this.state.pageLimit}
                          value={this.state.pageLimit}
                          name="limit"
                          sx={{
                            "& .MuiSelect-select": {
                              padding: "4px 14px",
                              fontFamily: "Barlow",
                            },
                          }}
                          onChange={(e) => this.changePageLimit(e)}
                        >
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="20">20</MenuItem>
                          <MenuItem value="50">50</MenuItem>
                          <MenuItem value="100">100</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default Historyofpayment;
