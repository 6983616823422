import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Cookies from "js-cookie";

// ui mui and react-bootstrap
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";

//  componets
import { ContainerLoader } from "components/common/ContainerLoader";
import Usersidebar from 'components/user-sidebar/user-sidebar';
import AlertMessage from "components/message/message";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import useAlertMessage from "Hooks/useAlertMessage";


const ReleaseNotes = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [releaseNotesLists, setReleaseNotesLists] = useState([]);
    const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
    /**
     * get all  release notes 
     */
    const getAllReleaseNotesLists = async () => {
        const get_release_Notes = process.env.REACT_APP_API_URL + "/api/browser/getReleaseNotes";
        try {
            setIsLoading(true);
            const res = await axios.post(get_release_Notes, {},
                {
                    headers: { authorization: 'Bearer ' + Cookies.get('accessToken') }
                });
            const { data } = res;
            if (data.error) {
                errorMessage()
                setIsLoading(false);
            } else {
                setReleaseNotesLists(data.data);
                setIsLoading(false);
            }
        } catch (error) {
            errorMessage()
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getAllReleaseNotesLists()
    }, []);

    return (<>
        <div className="user-dashboard-wrapper">
            <Helmet>
                <title>Release Notes</title>
            </Helmet>
            <Header />
            <div className="c-app c-default-layout user-dashboard-body">
                <Usersidebar />
                <div className="c-wrapper">
                    <div className="c-body">
                        <main className="c-main">
                            <Grid container spacing={2}>
                                <Grid item xs={12} className="space-top-col">
                                    <Card sx={{ p: 3 }}>
                                        <CardHeader title="Release Notes" sx={{ px: 2.9 }} />
                                        <CardContent>
                                            {!isLoading ? (
                                                <>
                                                    {releaseNotesLists && releaseNotesLists.length > 0 ? (
                                                        <>
                                                            {releaseNotesLists && releaseNotesLists.map((item, index) => (
                                                                <Row key={index} className="usage-tbl-header instruction-guide-sections">
                                                                    <Col lg={12} md={12}>
                                                                        <h3 className='text-left' style={{ fontWeight: 'normal', fontSize: '20px', fontFamily: "Luminari, fantasy" }}>
                                                                            <span>{item.version}</span> ~ Released on {new Date(item.releasedAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                                                                        </h3>
                                                                    </Col>
                                                                    <Col lg={12} md={12} className='text-justify'>
                                                                        <p dangerouslySetInnerHTML={{ __html: item.notes }} />
                                                                    </Col>

                                                                </Row>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div>
                                                                <div
                                                                >
                                                                    <Typography
                                                                        variant="h6"
                                                                        sx={{
                                                                            fontSize: "1.6rem",
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        No Records Found
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </>

                                                    )}
                                                </>
                                            ) : (
                                                <ContainerLoader />
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </main>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
        <AlertMessage
            open={alertMessage.openAlert}
            message={alertMessage.message}
            severity={alertMessage.alertSeverity}
            closeCall={hideAlert}
        />
    </>);
};
export default ReleaseNotes;