import { Delete, Loop } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
    Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid
    , IconButton, Paper, Radio, RadioGroup, Stack, Tooltip, Typography
} from '@mui/material';
import React from 'react'
import Stripe from "images/stripe.png"
import styled from '@emotion/styled';
import loaderGif from "images/GIF-Loader-3.gif";
import PropTypes from "prop-types";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    marginTop: "10px",
    color: theme.palette.text.secondary,
}));

const ChangePaymentMethod = ({
    hnadleCloseChangeCardInfo,
    stripeCardList,
    getSavedStripeList,
    ispurchaseLoading,
    selectedCardInfo,
    handleOnChangeCardInfo,
    handleAddStripeCustomPayMethod,
    handleUpdateLicense,
    handleVisableDeleteConfimationModal
}) => {
    return (
        <>
            <DialogTitle id="customized-dialog-title"
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: "11px 23px"
                }}>
                Change Payment Method
                <img style={{
                    width: "123px",
                    height: "30px",
                    marginRight: "290px",
                    marginTop: "4px",
                }}
                    src={Stripe}
                    alt="stripe"
                />
                {stripeCardList && stripeCardList?.length > 0 ? (
                    <Typography
                        variant="h5"
                    >
                        <span className="Account_details_box-lic" >
                            <LoadingButton
                                onClick={() => getSavedStripeList()}
                                size="small"
                                loading={ispurchaseLoading}
                                endIcon={ispurchaseLoading ? (<CircularProgress color="inherit" size={20} />) : (<Loop />)}
                                disabled={ispurchaseLoading}
                                loadingPosition="end"
                                variant="contained"
                            >
                                Refresh
                            </LoadingButton>
                        </span>
                    </Typography>
                ) : (null)}
            </DialogTitle>
            <DialogContent>
                <div>
                    <FormControl className="saved-card-details">
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={selectedCardInfo}
                            name="radio-buttons-group"
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Item>
                                        <span className="Account_details_box-lic purchase-license-modal-texttwo heading-modal-choose-payment">
                                            <span className="purchase-lic-modal-heading2"
                                                style={{
                                                    fontSize: "15px",
                                                    padding: "0px 0px 9px 0px"
                                                }}>
                                                Saved Cards
                                            </span>
                                        </span>
                                        <div class="inner-wrapper-purchase-license">
                                            {!ispurchaseLoading ? (
                                                <>
                                                    {stripeCardList && stripeCardList?.length > 0 ? (
                                                        <>
                                                            {stripeCardList && stripeCardList?.map((item, index) => (
                                                                <div className="credit-card-inner" key={index}>
                                                                    <div className={`credit-card ${selectedCardInfo === item.id ? 'selected' : ''}`} onClick={() => handleOnChangeCardInfo(item.id)}>
                                                                        <FormControlLabel
                                                                            key={item.id}
                                                                            value={item.id}
                                                                            control={<Radio sx={{ marginTop: "6px" }} />}
                                                                            sx={{ width: '100%' }}
                                                                            label={
                                                                                <Typography variant="body5" color="textSecondary" sx={{ width: "100%" }}>
                                                                                    <div className="content">
                                                                                        <div className="content-card-name">
                                                                                            <div>Card Holder : {item?.name ?? null}</div>
                                                                                            <div>{(item?.card && item?.card?.brand?.toUpperCase()) ?? null}</div>
                                                                                        </div>
                                                                                        <div className="content-card-detail">
                                                                                            <span className="card-number">XXXX XXXX XXXX {(item?.card && item?.card?.last4) ?? null}</span>
                                                                                            <span>Expiry Date : {(item?.card && item?.card?.exp_month) ?? null}/{(item?.card && item?.card?.exp_year) ?? null}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>
                                                                    &nbsp;&nbsp;
                                                                    <div className="credit-card-delete">
                                                                        <Stack direction="row" spacing={1}>
                                                                            <Tooltip title="Delete">
                                                                                <IconButton aria-label="delete" onClick={() => handleVisableDeleteConfimationModal(item.id)}>
                                                                                    <Delete />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Stack>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <div>
                                                            <td
                                                                colSpan={13}
                                                                style={{ padding: "0.6rem 17rem 25px" }}
                                                            >
                                                                <Typography
                                                                    variant="h6"
                                                                    sx={{
                                                                        fontSize: "1.6rem",
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    No Card Found
                                                                </Typography>
                                                            </td>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        padding: "8rem 0",
                                                    }}
                                                >
                                                    <div style={{ position: 'relative', top: '4px' }}>
                                                        <img className="loader-size-table" src={loaderGif} alt="no loader" />
                                                    </div>
                                                </Box>
                                            )}
                                        </div>
                                    </Item>
                                    {stripeCardList && stripeCardList?.length > 0 ? (
                                        <span className="Account_details_box-lic purchase-license-modal-texttwo">
                                            <span className="purchase-lic-modal-heading2"
                                                style={{
                                                    fontSize: "15px",
                                                    color: "red !important"
                                                }}>
                                                Note: Please hit refresh above to get the latest saved cards
                                            </span>
                                        </span>
                                    ) : (null)}
                                </Grid>
                                <Grid item xs={12} sx={{ paddingTop: "0px!important" }}>
                                    <Item>
                                        <span className="Account_details_box-lic purchase-license-modal-texttwo heading-modal-choose-payment">
                                            <span className="purchase-lic-modal-heading2"
                                                style={{
                                                    fontSize: "15px",
                                                    padding: "0px 0px 9px 0px"
                                                }}>
                                                Add Payment Method
                                            </span>
                                        </span>
                                        <div
                                            className={`credit-card credit-card-other-option ${selectedCardInfo === 'other' ? 'selected' : ''}`}
                                            onClick={() => handleOnChangeCardInfo('other')}
                                        >
                                            <FormControlLabel
                                                value={selectedCardInfo === "other" ? "other" : ""}
                                                control={<Radio sx={{ marginTop: "6px" }} />}
                                                sx={{ width: '100%' }}
                                                label={
                                                    <Typography variant="body5" color="textSecondary" sx={{ width: "100%" }}>
                                                        <div className="content">
                                                            <div className="content-card-name">
                                                                <div className="other-text" >Other</div>
                                                            </div>
                                                        </div>
                                                    </Typography>
                                                }
                                            />
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={hnadleCloseChangeCardInfo}
                    sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                    }}
                >
                    Cancel
                </Button>
                <Button
                    disabled={!selectedCardInfo}
                    onClick={() => selectedCardInfo === "other" ? handleAddStripeCustomPayMethod() : handleUpdateLicense()}
                    sx={{
                        backgroundColor: !selectedCardInfo ? "#dddddd!important" : "#002986!important",
                        color: !selectedCardInfo ? "#808080!important" : "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                    }}
                >
                    {selectedCardInfo === "other" ? "Next" : "Save"}
                </Button>
            </DialogActions>
        </>
    )
}

ChangePaymentMethod.propTypes = {
    stripeCardList: PropTypes.array.isRequired,
    ispurchaseLoading: PropTypes.bool.isRequired,
    selectedCardInfo: PropTypes.string.isRequired,
    getSavedStripeList: PropTypes.func.isRequired,
    hnadleCloseChangeCardInfo: PropTypes.func.isRequired,
    handleOnChangeCardInfo: PropTypes.func.isRequired,
    handleAddStripeCustomPayMethod: PropTypes.func.isRequired,
    handleUpdateLicense: PropTypes.func.isRequired,
};

ChangePaymentMethod.defaultProps = {
    stripeCardList: [],
    ispurchaseLoading: false,
    selectedCardInfo: null,
    hnadleCloseChangeCardInfo: () => { },
    handleUpdateLicense: () => { },
    handleAddStripeCustomPayMethod: () => { },
    handleOnChangeCardInfo: () => { },
    getSavedStripeList: () => { },
};

export default ChangePaymentMethod;
