import React from "react";

// npm  package and library  
import { Link } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import Papa from "papaparse";
import { saveAs } from "file-saver";

// mui components
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";

// mui icons components
import VisibilityIcon from "@mui/icons-material/Visibility";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { HiOutlineSearch } from "react-icons/hi";
import { SaveAlt } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

// i
import DataTable from "components/DataTable/DataTable";
import { ContainerLoader } from "components/common/ContainerLoader";
import Pagination from "components/pagination/pagination";
import { changeDollarAmountformat } from "utils/changeDollarFormat";

const fields = [
  {
    key: "user_code",
    label: "User Code",
  },
  {
    key: "name",
    label: "Name",
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "created",
    label: "Created",
  },
  {
    key: "available_credit",
    label: "Available Credit",
  },
  {
    key: "access_browser",
    label: "Access Browser",
  },
  {
    key: "ticket_price",
    label: "Ticket Price",
  },
  {
    key: "nt_ticket_price",
    label: "NT Ticket Price",
  },
  {
    key: "action",
    label: "Action",
  },
];

class Usermanage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usersData: [],
      modalShow: false,
      filterData: {
        limit: 10,
        skip: 0,
        email: ''
      },
      testUser: "all",
      allvendors: [],
      vendorVal: null,
      pageLimit: 10,
      searchPage: 0,
      totalrecords: 0,
      deleteId: "",
      loader: false,
      accessBrower: "all",
      exportLoader: false
    }
    this.onPageChanged = this.onPageChanged.bind(this);
    this.SearchDisable = this.SearchDisable.bind(this);
    this.getUserEmail = this.getUserEmail.bind(this);
    this.getAllUsers = this.getAllUsers.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectVendor = this.handleSelectVendor.bind(this);
  }

  componentDidMount() {
    this.getUserEmail()
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get('page'), 10) ?? 1;
    if (page) {
      const { filterData } = this.state;
      filterData["skip"] = page;
      this.setState({ filterData })
    }
    this.getAllUsers()
  }

  getUserEmail = async () => {
    try {
      const userList_api_url = process.env.REACT_APP_API_URL + "/api/user/getAllUsers";
      const response = await axios.post(userList_api_url, this?.state?.filterData, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      const { data } = response;
      if (data.error) {
        this.setState({
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      } else {
        const newdata = data.data.map((val) => {
          return {
            label: val.email,
            id: val._id,
          };
        });

        const seen = new Set();
        const filteredArr = newdata.filter((el) => {
          const duplicate = seen.has(el.label);
          seen.add(el.label);
          return !duplicate;
        });
        this.setState({
          allvendors: filteredArr,
          open: false,
          message: "",
          successError: "success",
        });
      }
    } catch (error) {
      this.setState({
        open: true,
        message: "Something went wrong",
        successError: "error",
      });
    }
  }

  getAllUsers = async () => {
    try {
      const params = {
        ...this?.state?.filterData,
        access_browser: this.state?.accessBrower === "all" ? null : this.state?.accessBrower,
        hasTestUsers: this.state.testUser === "yes" ? true : this.state.testUser === "all" ? null : false,
        exportAll: false,
      }
      this.setState({ loader: true });
      const userList_api_url = process.env.REACT_APP_API_URL + "/api/admin/getAllUsers";
      const response = await axios.post(userList_api_url, params, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      const { data } = response;
      if (data.error) {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      } else {
        this.setState({ totalrecords: data.count, })
        this.setState({
          loader: false,
          usersData: data.data,
          open: false,
          message: "",
          successError: "success",
        });
      }
    } catch (error) {
      this.setState({
        loader: false,
        open: true,
        message: "Something went wrong",
        successError: "error",
      });
    }
  }

  setModalShow = (id) => {
    this.setState({
      modalShow: true,
      deleteId: id,
    });
  };

  onHide = () => {
    this.setState({
      modalShow: false,
    });
  };

  onPageChanged(data) {
    const { currentPage, pageLimit } = data;
    const { filterData } = this.state;
    filterData.skip = (currentPage - 1) * pageLimit;
    const skipquery = filterData.limit * (currentPage - 1);
    this.props.history.push(`/users-management?page=${skipquery}&searchPage=${currentPage}`);
    this.setState({ filterData }, () => {
      this.getAllUsers()
    });
  }

  SearchDisable = (value) => {
    this.setState({ searchPage: value });
  };

  changePageLimit = (e) => {
    const { filterData } = this.state;
    filterData.limit = parseInt(e.target.value);
    filterData.skip = 0;
    this.setState(
      {
        searchPage: 1,
        loader: true,
        filterData,
        pageLimit: parseInt(e.target.value),
      },
      () => {
        this.getAllUsers();
      }
    );
  };

  callDelete = async () => {
    this.setState({
      modalShow: false,
    });
    const data = {
      id: this.state.deleteId,
    };
    const userDelete_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/deleteUser";
    await axios
      .post(userDelete_api_url, data)
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.data.message,
            successError: "error",
          });
        } else {
          if (data.success) {
            this.getAllUsers()
          } else {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              successError: "error",
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  exportAllUserList = async () => {
    try {
      this.setState({ exportLoader: true });
      const params = {
        ...this?.state?.filterData,
        access_browser: this.state?.accessBrower === "all" ? null : this.state?.accessBrower,
        exportAll: true
      }
      const userList_api_url = process.env.REACT_APP_API_URL + "/api/admin/getAllUsers";
      const response = await axios.post(userList_api_url, params, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      const { data } = response;
      if (data.error) {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong!, Please try again.",
          successError: "error",
          exportLoader: false
        });
      } else {
        this.exportToCSV(data.data ?? [])
        this.setState({ exportLoader: false });
      }
    } catch (error) {
      this.setState({
        loader: false,
        open: true,
        message: "Something went wrong!, Please try again.",
        successError: "error",
        exportLoader: false
      });
    }
  }

  handleSubmit = () => {
    const { filterData } = this.state;
    filterData["skip"] = 0;
    this.setState(
      { searchPage: 1, filterData, loader: true },
      () => {
        this.getAllUsers()
      }
    );
  }

  handleSelectVendor(e, val) {
    if (val) {
      this.setState({ vendorLabel: val.label, vendorVal: val });
      const { filterData } = this.state;
      filterData["email"] = val.label;
      this.setState({ filterData });
    } else {
      this.setState({ vendorLabel: null, vendorVal: null });
      const { filterData } = this.state;
      filterData["email"] = null;
      this.setState({ filterData });
    }
  }

  handleChangeTestUser = (event) => {
    this.setState({ testUser: event.target.value });
  }

  handleChangeAccessBrower = (event) => {
    this.setState({ accessBrower: event.target.value });
  }

  validationOftheformatValue = (value) => {
    return typeof value === ('number' || 'string') ? value ?? "" : value ?? "";
  }

  convertToCSVAC = (data) => {
    const dataFiltered = data.map((item, index) => {
      return {
        "User Code": '\t' + this.validationOftheformatValue(this.userCodeFormattedNumber(item?.user_code)),
        "Name": this.validationOftheformatValue(item?.name),
        "Email": this.validationOftheformatValue(item?.email),
        "Available Credit": this.validationOftheformatValue(item?.userwallets[0] !== undefined
          ? changeDollarAmountformat(item.userwallets[0].balance)
          : "$0"),
        "Access Browser": this.validationOftheformatValue(item?.access_browser),
        "Ticket Price": '\t' + this.validationOftheformatValue(item?.ticket_price),
        "NT Ticket Price": '\t' + this.validationOftheformatValue(item?.nt_ticket_price),
        "AXS Price": '\t' + this.validationOftheformatValue(item?.axs_price),
        "Primary License Price": '\t' + this.validationOftheformatValue(item?.license_price?.primary),
        "Secondary License Price": '\t' + this.validationOftheformatValue(item?.license_price?.secondary),
        "Created Date": this.validationOftheformatValue(item?.created),
      };
    });
    return Papa.unparse(dataFiltered);
  }

  exportToCSV = (data) => {
    const csv = this.convertToCSVAC(data);
    const file = new Blob([csv], { type: "text/csv" });
    saveAs(file, "user.csv");
  };

  userCodeFormattedNumber = (number) => {
    return String(number).padStart(3, "0");
  };

  render() {
    const { usersData, modalShow, loader, totalrecords } = this.state;
    var arr = [];
    var arr2 = [];
    arr = usersData.map((data) => {
      return {
        _id: data._id,
        user_code: data.user_code,
        name: data.name,
        email: data.email,
        created: data.created,
        available_credit:
          data.userwallets[0] !== undefined
            ? changeDollarAmountformat(data?.userwallets[0]?.balance)
            : "$0",
        // package: data?.package[0],
        action: "",
        credit: "",
        access_browser: data.access_browser ? data.access_browser : "No",
        ticket_price: data.ticket_price ?? "",
        axs_price: data.axs_link_price ?? "",
        license_price: data.license_price ?? "",
        nt_ticket_price: data?.nt_ticket_price ?? 0
      };
    });

    arr2 = arr.map((data) => {
      return {
        _id: data._id,
        user_code: data.user_code,
        name: data.name,
        email: data.email,
        created: data.created,
        available_credit:
          data.available_credit !== undefined
            ? "" + data.available_credit
            : "$0",
        package:
          typeof data.package !== "undefined"
            ? data.package.name
            : "No Package",
        action: "",
        credit: "",
        access_browser: data.access_browser,
        ticket_price: data.ticket_price,
        axs_price: data.axs_price,
        license_price: data?.license_price,
        nt_ticket_price: data?.nt_ticket_price,
      };
    });

    return (
      <>
        <Helmet>
          <title>User List</title>
        </Helmet>
        <div className="smallButton">
          {" "}
          <Button component={Link} to="/add-user" className="theme-btn">
            Add User
          </Button>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="User List"
              />
              <CardContent>
                <ValidatorForm
                  onSubmit={this.handleSubmit}
                  className="filter-form mb-5"
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 2, lg: 2 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      <Grid item
                        className="team"
                        xs={12} sm={4} md={3} lg={3}>
                        <label className="Account_details_box-manage-license">
                          Email
                        </label>
                        <Autocomplete
                          fullWidth
                          className="filter-input"
                          name="filterstatusVal"
                          value={this.state.vendorVal}
                          options={this.state.allvendors}
                          onChange={this.handleSelectVendor}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <TextField
                              value={this.state.vendorVal}
                              placeholder="Email"
                              {...params}
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      {/* for Test user */}
                      <Grid item
                        className="team"
                        xs={12} sm={4} md={3} lg={3}
                      >
                        <FormControl fullWidth>
                          <label className="Account_details_box-manage-license">
                            Test Users
                          </label>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="status"
                            value={this.state.testUser}
                            onChange={(e) => this.handleChangeTestUser(e)}
                            size="small"
                            defaultValue="all"
                            sx={{
                              textAlign: "left",
                            }}
                          >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="yes">Yes</MenuItem>
                            <MenuItem value="no">No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item
                        className="team"
                        xs={12} sm={4} md={3} lg={3}
                      >
                        <FormControl fullWidth>
                          <label className="Account_details_box-manage-license">
                            Access browser
                          </label>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="status"
                            value={this.state.accessBrower}
                            onChange={(e) => this.handleChangeAccessBrower(e)}
                            size="small"
                            defaultValue="all"
                            sx={{
                              textAlign: "left",
                            }}
                          >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="Yes">Enabled</MenuItem>
                            <MenuItem value="No">Disabled</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item
                        xs={12} sm={4} md={3} lg={3} sx={{ paddingTop: "47px!important" }}>
                        <Button
                          variant="contained"
                          type="submit"
                          size="large"
                          className="filter-btn"
                          startIcon={<HiOutlineSearch />}
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </ValidatorForm>
                <Grid item sx={{
                  display: "flex",
                  justifyContent: "end"
                }}>
                  <LoadingButton
                    onClick={() =>
                      this.exportAllUserList()
                    }
                    size="large"
                    endIcon={<SaveAlt />}
                    loading={this.state.exportLoader}
                    disabled={
                      arr2.length > 0
                        ? false
                        : true
                    }
                    loadingPosition="end"
                    sx={{
                      fontFamily: "Bevan, cursive !important",
                      marginBottom: "10px"
                    }}
                    variant="contained"
                  >
                    <span>Export All</span>
                  </LoadingButton>
                </Grid>
                {!loader ? (
                  <DataTable
                    items={arr2}
                    fields={fields}
                    itemsPerPage={this.state.filterData.limit}
                    scopedSlots={{
                      user_code: (item) => {
                        return <>{this.userCodeFormattedNumber(item.user_code)}</>
                      },
                      ticket_price: (item) => {
                        return <>{changeDollarAmountformat(item.ticket_price) ?? "0.00"}</>;
                      },
                      nt_ticket_price: (item) => {
                        return <>{changeDollarAmountformat(item.nt_ticket_price) ?? 0.00}</>;
                      },
                      action: (item, index) => {
                        return (
                          <div className="flex">
                            <IconButton
                              aria-label="edit"
                              sx={{
                                color: "#002986",
                                "&:hover": {
                                  color: "#B90F0F",
                                },
                              }}
                              component={Link}
                              to={`/user-management-view/${item._id}`}
                            >
                              <VisibilityIcon />
                            </IconButton>
                            <IconButton
                              aria-label="edit"
                              sx={{
                                color: "#002986",
                                "&:hover": {
                                  color: "#B90F0F",
                                },
                              }}
                              component={Link}
                              to={`/user-management-edit/${item._id}`}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => this.setModalShow(item._id)}
                              sx={{
                                color: "#002986",
                                "&:hover": {
                                  color: "#B90F0F",
                                },
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        );
                      },
                    }}
                  />
                ) : (
                  <ContainerLoader />
                )}
                {totalrecords > 0 && (
                  <Pagination
                    totalRecords={totalrecords}
                    searchPage={this.state.searchPage}
                    SearchDisable={this.SearchDisable}
                    pageLimit={this.state.pageLimit}
                    onPageChanged={this.onPageChanged}
                  />
                )}
                <div
                  style={{
                    textAlign: "right",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <label>Items per page :</label>
                  <FormControl
                    sx={{
                      m: 1,
                      minWidth: 50,
                    }}
                    size="small"
                  >
                    <Select
                      defaultValue={this.state.filterData.limit}
                      value={this.state.filterData.limit}
                      name="limit"
                      sx={{
                        "& .MuiSelect-select": {
                          padding: "4px 14px",
                          fontFamily: "Barlow",
                        },
                      }}
                      onChange={(e) => this.changePageLimit(e)}
                    >
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="20">20</MenuItem>
                      <MenuItem value="50">50</MenuItem>
                      <MenuItem value="100">100</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <Dialog
                  fullWidth={true}
                  scroll="body"
                  maxWidth="sm"
                  open={modalShow}
                  onClose={this.onHide}
                >
                  <DialogTitle>Delete</DialogTitle>
                  <DialogContent>
                    <IconButton
                      aria-label="close"
                      onClick={() => this.onHide()}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                    <DialogContentText>
                      Are you sure you want to Delete, this action can not be
                      revert ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.onHide}
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={this.callDelete}
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                      }}
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default Usermanage;
