import React from "react";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { BsFillTelephoneFill } from "react-icons/bs";
import EmailIcon from "@mui/icons-material/Email";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

function Footer() {
  return (
    <footer>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} className="footer-left-css">
          <ul
            style={{
              textAlign: "right!important",
            }}
          >
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            <li>
              <Link to="/term-and-condition">Terms and Conditions</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
          <div className="contact-info">
            <List dense={true}>
              <ListItem>
                <ListItemIcon>
                  <BsFillTelephoneFill
                    style={{
                      color: "#c5c5c5",
                    }}
                    size={24}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      component="a"
                      href="tel:1 (800) 914-5040"
                      sx={{
                        color: "#c5c5c5!important",
                        textDecoration: "none!important",
                        marginBottom: "7px",
                      }}
                    >
                      Phone:1 (800) 914-5040
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <EmailIcon
                    sx={{
                      color: "#c5c5c5",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      component="a"
                      href="mailto:support@ticketliberty.com"
                      sx={{
                        color: "#c5c5c5!important",
                        marginBottom: "7px",
                      }}
                    >
                      support@ticketliberty.com
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            padding: "0px!important",
          }}
        >
          <div className="copyRyt">
            &#169;2024 Ticket Liberty Corp, All Rights Reserved.
          </div>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;
